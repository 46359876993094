<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <!-- <button mat-icon-button *ngIf="!opened" (click)="open()">
        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:search'"></mat-icon>
    </button> -->
    <div [id]="'omni-' + id" [ngClass]="{'transparent': transparent}"></div>

    <!-- <div class="absolute inset-0 flex items-center flex-shrink-0 px-2 z-99 bg-card dark:bg-gray-900" *ngIf="opened"
        @slideInTop @slideOutTop>
        <div [id]="'omni-' + id" [ngClass]="'neumorphic'"></div>
    </div> -->
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field class="w-full jbz-mat-no-subscript">
            <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <input matInput [formControl]="searchControl" [matAutocomplete]="matAutocomplete"
                [placeholder]="'Search for a page or a contact'" (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete class="mt-1 rounded max-h-128" [disableRipple]="true" #matAutocomplete="matAutocomplete">
            <mat-option class="px-5 py-0 bg-transparent pointer-events-none h-14 text-md text-secondary"
                *ngIf="results && !results.length">
                No results found!
            </mat-option>
            <ng-container *ngFor="let result of results; trackBy: trackByFn">
                <mat-option class="relative px-5 py-0 group h-14 text-md" [routerLink]="result.link">
                    <ng-container [ngTemplateOutlet]="searchResult" [ngTemplateOutletContext]="{$implicit: result}">
                    </ng-container>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container>

<ng-template #searchResult let-result>

    <!-- Hover indicator -->
    <div class="absolute inset-y-0 left-0 hidden w-1 bg-primary group-hover:flex"></div>

    <!-- Contact result -->
    <ng-container *ngIf="result.resultType === 'contact'">
        <div class="flex items-center">
            <div class="px-1.5 py-1 mr-4 text-xs font-semibold leading-normal rounded text-indigo-50 bg-indigo-600">
                Contact</div>
            <div class="overflow-hidden text-ellipsis">
                <span [innerHTML]="result.title"></span>
            </div>
            <div
                class="flex items-center justify-center flex-shrink-0 w-8 h-8 ml-auto overflow-hidden rounded-full bg-primary-100 dark:bg-black dark:bg-opacity-5">
                <img *ngIf="result.avatar" [src]="result.avatar">
                <mat-icon class="m-0 icon-size-5 text-primary" *ngIf="!result.avatar"
                    [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            </div>
        </div>
    </ng-container>

    <!-- Page result -->
    <ng-container *ngIf="result.resultType === 'page'">
        <div class="flex items-center">
            <div class="px-1.5 py-1 mr-4 text-xs font-semibold leading-normal rounded text-teal-50 bg-teal-600">Page
            </div>
            <div class="flex flex-col overflow-hidden text-ellipsis">
                <span class="overflow-hidden leading-normal text-ellipsis whitespace-nowrap"
                    [innerHTML]="result.title"></span>
                <span
                    class="mt-1 overflow-hidden text-sm leading-normal no-underline text-secondary text-ellipsis whitespace-nowrap"
                    [routerLink]="result.link">{{result.link}}</span>
            </div>
        </div>
    </ng-container>

</ng-template>
