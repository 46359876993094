import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { User } from '@jobzmall/models';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MessagesState } from '../app/ngxs/state';


const mime = require('mime');

@Component({
    selector: 'messages-widget-conversation-placeholder',
    templateUrl: './messages-widget-conversation-placeholder.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class MessagesWidgetConversationPlaceholderComponent extends JMBaseComponent implements OnInit {

    @Select(MessagesState.pinningConversation) pinning$: Observable<User>;


    constructor(

    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    ngOnInit(): void {


    }

    ngOnDestroy(): void {

    }

    ngAfterViewInit(): void {

    }


}
