import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store, Selector } from '@ngxs/store';
import { tap, map, first, catchError } from 'rxjs/operators';
import { NotificationStateModel } from './model';
import { of } from 'rxjs';
import * as Noty from 'noty';
import {
    notificationText,
    notificationImage,
    notificationRouterState,
    notificationIcon
} from '../notification-map';
import { TranslocoService } from '@ngneat/transloco';
import {
    GetNotifications,
    ProcessLiveNotification,
    GetUnreadCount,
    SetRead,
    UnsubscribeFromWebPushNotifications
} from './actions';
import { cloneDeep, extend, merge } from 'lodash-es';
import { Restangular } from '@jobzmall/core/api';
import { Notification } from '@jobzmall/notifications/notifications.types';
import * as dayjs from 'dayjs';
import { Router } from '@angular/router';
import { SoundService } from '@jobzmall/sound';
import { AuthState } from '../../core/auth/ngxs/state';
import { ConfigService } from '@jobzmall/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throws } from 'assert';

@State<NotificationStateModel>({
    name: 'notifications',
    defaults: {
        notifications: [],
        updates: 0,
        countLoading: false,
        loading: false,
        end: false,
        page: 1
    }
})
@Injectable()
export class NotificationState {
    @Selector()
    static notifications(state: NotificationStateModel): Array<any> {
        return state.notifications;
    }

    @Selector()
    static updates(state: NotificationStateModel): number {
        return state.updates;
    }

    @Selector()
    static loading(state: NotificationStateModel): boolean {
        return state.loading;
    }

    @Selector()
    static end(state: NotificationStateModel): boolean {
        return state.end;
    }

    constructor(
        private _snackbar: MatSnackBar,
        private _config: ConfigService,

        private _sound: SoundService,
        private _api: Restangular,
        private _transloco: TranslocoService,
        private _store: Store,
        private _router: Router
    ) {}

    // @Action(SubscribeToWebPushNotifications)
    // subscribeToWebPushNotifications(
    //     ctx: StateContext<NotificationStateModel>,
    //     action: SubscribeToWebPushNotifications
    // ) {
    //     this._swPush.subscription
    //         .pipe(first())
    //         .subscribe((sub: PushSubscription) => {
    //             if (sub) {
    //                 this._api
    //                     .all(`notification/webpush/subscribe`)
    //                     .customPOST(sub.toJSON())
    //                     .pipe(
    //                         first(),
    //                         map((res: any) => res.data),
    //                         catchError((err: any) => of(undefined))
    //                     )
    //                     .subscribe(() => {});
    //             } else {
    //                 this._swPush
    //                     .requestSubscription({
    //                         serverPublicKey: this._config.getSettings(
    //                             'data.vapid.public_key'
    //                         )
    //                     })
    //                     .then((requestedSubscription) =>
    //                         this._api
    //                             .all(`notification/webpush/subscribe`)
    //                             .customPOST(requestedSubscription.toJSON())
    //                             .pipe(
    //                                 first(),
    //                                 map((res: any) => res.data),
    //                                 catchError((err: any) => of(undefined))
    //                             )
    //                             .subscribe(() => {})
    //                     )
    //                     .catch((err) => {});
    //             }
    //         });
    // }

    // @Action(UnsubscribeFromWebPushNotifications)
    // unsubscribeFromWebPushNotifications(
    //     ctx: StateContext<NotificationStateModel>,
    //     action: UnsubscribeFromWebPushNotifications
    // ) {
    //     this._swPush
    //         .unsubscribe()
    //         .then((sub) =>
    //             this._api
    //                 .all(`notification/webpush/subscribe`)
    //                 .customDELETE()
    //                 .pipe(
    //                     first(),
    //                     map((res: any) => res.data),
    //                     catchError((err: any) => of(undefined))
    //                 )
    //                 .subscribe(() => {
    //                     this._snackbar.open(
    //                         'Successfully Unsubscribed to Push Notifications',
    //                         'OK',
    //                         {
    //                             duration: 3000,
    //                             panelClass: ['jobz-snackbar']
    //                         }
    //                     );
    //                 })
    //         )
    //         .catch((err) => {});
    // }

    @Action(ProcessLiveNotification)
    processLiveNotification(
        ctx: StateContext<NotificationStateModel>,
        action: ProcessLiveNotification
    ) {
        let notif = this.transformNotification(action.notification);
        this.setNotyDefaults();
        let noty = new (Noty as any)(
            extend(notif, {
                timeout: 3000,
                closeWith: ['click', 'button']
            })
        );
        noty.on('onClick', () => {
            if (!notif.useRouter) {
                var win = window.open(notif.link, '_blank');
                win.focus();
            } else {
                this._router.navigate([notif.link]);
            }
        });
        noty.show();
        this._sound
            .play('/assets/sounds/pling.m4a')
            .pipe(first())
            .subscribe(() => {});

        if (!ctx.getState().countLoading) {
            let newCount = ctx.getState().updates + 1;
            ctx.patchState({
                updates: newCount
            });
        }
    }

    @Action(GetNotifications)
    getNotifications(
        ctx: StateContext<NotificationStateModel>,
        action: GetNotifications
    ) {
        if (action.refresh) {
            ctx.patchState({
                loading: false,
                end: false,
                notifications: [],
                page: 1
            });
        }
        if (
            !ctx.getState().loading &&
            !ctx.getState().end &&
            this._store.selectSnapshot(AuthState.isAuthenticated)
        ) {
            ctx.patchState({
                loading: true
            });
            return this._api
                .all('notification')
                .customGET(undefined, {
                    page: ctx.getState().page
                })
                .pipe(
                    map((res: any) => res.data),
                    tap((notifs: Array<any>) => {
                        if (notifs.length) {
                            ctx.patchState({
                                page: ctx.getState().page + 1
                            });
                            let newNotifs = [];
                            notifs.forEach((notif: any) => {
                                newNotifs.push(
                                    this.transformNotification(notif)
                                );
                            });
                            ctx.patchState({
                                notifications: ctx
                                    .getState()
                                    .notifications.concat(newNotifs)
                            });
                        } else {
                            ctx.patchState({
                                end: true
                            });
                        }
                        ctx.patchState({
                            loading: false
                        });
                    })
                );
        }
    }

    @Action(GetUnreadCount)
    getUnreadCount(ctx: StateContext<NotificationStateModel>) {
        if (!ctx.getState().countLoading) {
            return this._api
                .all('notification/unread')
                .customGET()
                .pipe(
                    map((res: any) => res.data),
                    map((count: any) => {
                        ctx.patchState({
                            countLoading: false,
                            updates: count
                        });
                        return count;
                    })
                );
        }
    }

    @Action(SetRead)
    setRead(ctx: StateContext<NotificationStateModel>, action: SetRead) {
        const updates = ctx.getState().updates;
        const notifications = cloneDeep(ctx.getState().notifications);

        if (action.notification) {
            const index = notifications.findIndex(
                (notification: any) => notification.id == action.notification.id
            );
            notifications[index].read_at = new Date();
        } else {
            notifications.forEach((notif: any) => {
                if (!notif.read_at) {
                    notif.read_at = new Date();
                }
            });
        }

        ctx.patchState({
            notifications: notifications
        });

        if (action.notification && action.notification.read_at) {
            return of(updates);
        }

        let val = action.notification ? updates - 1 : 0;
        ctx.patchState({
            updates: val
        });

        return this._api
            .all(
                action.notification
                    ? `notification/${action.notification.id}`
                    : 'notification'
            )
            .customPATCH()
            .pipe(
                map(() => {
                    return ctx.getState().updates;
                })
            );
    }

    private transformNotification(notification: any): Notification {
        const type =
            notification.type.split('\\')[
                notification.type.split('\\').length - 1
            ];

        let res: Notification = {
            id: notification.id,
            type,
            created_at: dayjs(notification.created_at).toDate(),
            useRouter: true,
            read_at: dayjs(notification.read_at).toDate()
        };

        res.icon =
            notificationIcon[type] &&
            notificationIcon[type](
                merge(notification, notification.data),
                this._transloco,
                this._store
            );
        res.description =
            notificationText[type] &&
            notificationText[type](
                merge(notification, notification.data),
                this._transloco,
                this._store
            );
        res.image =
            notificationImage[type] &&
            notificationImage[type](
                merge(notification, notification.data),
                this._transloco,
                this._store
            ).image;
        res.link =
            notificationRouterState[type] &&
            notificationRouterState[type](
                merge(notification, notification.data),
                this._transloco,
                this._store
            ).url;
        res.useRouter =
            res.link &&
            res.link.indexOf('http://') === -1 &&
            res.link.indexOf('https://') === -1;

        return res;
    }

    private setNotyDefaults() {
        Noty.overrideDefaults({
            callbacks: {
                onTemplate: function () {
                    let html = `
                        <div class="max-w-sm w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div class="p-4">
                                <div class="flex items-start">
                                    ${
                                        this.options.image
                                            ? `<img class="flex-shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            src="${this.options.image.full_path}" alt="Notification image">`
                                            : `<div class="flex flex-shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                                        <i class="icon-size-5 ${this.options.icon}"></i>
                                    </div>`
                                    }
                                    <div class="ml-3 w-0 flex-1 pt-0.5">
                                        ${
                                            this.options.title
                                                ? `<p class="text-sm font-medium text-gray-900">${this.options.title}</p>`
                                                : ''
                                        }
                                        <p class="mt-1 noty_body text-sm text-gray-500 dark:text-white">
                                        ${this.options.description}
                                        </p>
                                    </div>
                                    <div class="ml-4 flex-shrink-0 flex">

                                    </div>
                                </div>
                            </div>
                        </div>
                    `;

                    this.barDom.innerHTML = html;
                }
            }
        });
    }
}
