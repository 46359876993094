import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    SimpleChanges,
    ChangeDetectorRef,
    Injector
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Media } from '@jobzmall/models/media.model';
import lgVideo from 'lightgallery/plugins/video';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DocumentOverlayComponent } from '../document-overlay/document-overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';

@Component({
    selector: 'media-grid',
    templateUrl: './media-grid.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class MediaGridComponent extends JMBaseComponent implements OnInit {
    @Input() media: Array<Media>;
    @Input() filter = 'image';

    filteredMedia: Array<Media> = [];

    @Input() size: 'normal' | 'small' = 'normal';

    needRefresh = false;
    lightGallery: any;
    overlayRef: OverlayRef;

    settings = {
        counter: false,
        plugins: [lgVideo]
    };

    constructor(
        private _injector: Injector,
        private _overlay: Overlay,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    Math = Math;

    ngOnInit(): void {
        this.filteredMedia = this.media.filter(
            (m: Media) => m.type === this.filter
        );
    }

    ngAfterViewChecked(): void {
        if (this.needRefresh && this.lightGallery) {
            this.lightGallery.refresh();
            this.needRefresh = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.media || changes.filter) {
            this.filteredMedia = this.media.filter(
                (m: Media) => m.type === this.filter
            );
            this._changeDetectorRef.markForCheck();
        }
    }

    onInit = (detail): void => {
        this.lightGallery = detail.instance;
    };

    openDocument(media: Media) {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.overlayRef = this._overlay.create({
                scrollStrategy: this._overlay.scrollStrategies.block(),
                height: '100%',
                width: '100%',
                hasBackdrop: true
            });
            const portal = new ComponentPortal(
                DocumentOverlayComponent,
                undefined,
                Injector.create({
                    parent: this._injector,
                    providers: [
                        {
                            provide: JM_OVERLAY_DATA,
                            useValue: { overlay: this.overlayRef, media }
                        }
                    ]
                })
            );
            const attached = this.overlayRef.attach(portal);
            setTimeout(() => {
                attached.changeDetectorRef.markForCheck();
                attached.changeDetectorRef.detectChanges();
            });
        }
    }
}
