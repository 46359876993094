import { Injectable } from '@angular/core';

import { Adapter } from './adapter';

import { Building, BuildingAdapter } from './building.model';
import { SEO, SEOAdapter } from './seo.model';
import * as dayjs from 'dayjs';
import { Media, MediaAdapter } from './media.model';

export class Floor {
    constructor(
        public id: number,
        public name: string,
        public slug: string,
        public building_id: number,
        public seo: SEO,
        public description: string,
        public vimeo_video?: Media,
        public image?: Media,
        public building?: Building,
        public created_at?: Date,
        public updated_at?: Date,
        public facts?: Array<any>
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class FloorAdapter implements Adapter<Floor> {
    constructor(
        private _seoAdapter: SEOAdapter,
        private _mediaAdapter: MediaAdapter,
        private _buildingAdapter: BuildingAdapter,
    ){

    }
    adapt(item: any): Floor {
        return new Floor(
            item.id,
            item.name,
            item.slug,
            item.building_id,
            this._seoAdapter.adapt(item.seo),
            item.description,
            item.vimeo_url
                ? this._mediaAdapter.adapt({
                      type: 'video',
                      available: true,
                      recorded: false,
                      static: false,
                      full_path: item.vimeo_url,
                      generated_thumbnail: item.image
                          ? item.image.full_path
                          : null,
                      src: item.vimeo_url,
                      external: true,
                      external_type: 'vimeo'
                  })
                : null,
            item.image ? this._mediaAdapter.adapt(item.image) : null,
            item.building ? this._buildingAdapter.adapt(item.building) : null,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.facts
        );
    }
}

