import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { EventEmitter, Output } from '@angular/core';
import { User } from '@jobzmall/models';
import { RefreshUser, UserService, UserState } from '@jobzmall/user';
import { Select, Store } from '@ngxs/store';
import { first, Observable } from 'rxjs';

@Component({
    selector: 'jobseeker-status-header-panel',
    templateUrl: './jobseeker-status-header-panel.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class JobseekerStatusHeaderPanelComponent
    extends JMBaseComponent
    implements OnInit
{
    statuses = [
        {
            name: 'Ready to interview',
            icon: 'mimicons_outline:check-box',
            value: 'ready',
            class: 'text-green-500',
            description:
                "You're actively looking for new work and ready to interview. Your profile will be visible to organizations"
        },
        {
            name: 'Open to offers',
            icon: 'mimicons_outline:dots-box',
            class: 'text-yellow-500',
            value: 'open',
            description:
                "You're not looking but open to hear about new opportunities. Your profile will be visible to organizations"
        },
        {
            name: 'Closed to offers',
            icon: 'mimicons_outline:close-box',
            class: 'text-red-500',
            value: 'closed',
            description:
                "You're not looking and don't want to hear about new opportunities. Your profile will be not be visible to organizations"
        }
    ];

    @Select(UserState.user) user$: Observable<User>;

    @Output() onClosePanel = new EventEmitter();
    @Output() onSave = new EventEmitter();

    constructor(
        private _store: Store,
        private _userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {}

    saveStatus(status: any): void {
        this.onSave.emit(status.value);
        this._userService
            .save(
                { jobseeker_status: status.value },
                this._store.selectSnapshot(UserState.user).slug
            )
            .pipe(first())
            .subscribe(() => {
                this._store.dispatch(new RefreshUser());
            });
    }
}
