import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ViewContainerRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Select } from '@ngxs/store';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';
import { Observable } from 'rxjs';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { AppState } from '@jobzmall/state/ngxs/state';
import { FuseConfigService } from '@fuse/services/config';
import { environment } from 'environments/environment';

@Component({
    selector: 'jm-footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class JMFooterComponent extends JMBaseComponent implements OnInit {
    @Select(AppState.loading) appLoading$: Observable<boolean>;

    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

    private _overlayRef: OverlayRef;

    webURL = environment.webURL;

    constructor(
        public fuseConfigService: FuseConfigService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) {
        super();
    }

    ngOnInit(): void {}

    get currentYear(): number {
        return new Date().getFullYear();
    }
}
