import { Injectable } from "@angular/core";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { Permission, User } from "@jobzmall/models";
import { UserState } from "@jobzmall/user/ngxs/state";
import { Store } from "@ngxs/store";
import { difference } from "lodash-es";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class UserPermissionService {

    constructor(private _store: Store, private _confirmation: FuseConfirmationService) {

    }

    hasOrganizationPermissions(
        permissions: Array<Permission>,
        organizationId: number,
        showDialog = false
    ): Observable<boolean> {
        return this._store.select(UserState.user).pipe(
            map((user: User) => {
                if (user) {
                    if (user.permissions) {
                        if (Object.keys(user.permissions).length) {
                            const storePermissions = user.permissions[organizationId];
                            if (storePermissions && storePermissions.length > 0) {
                                const diff = difference(
                                    this._parsePermissions(permissions),
                                    storePermissions
                                );
                                if (diff.length && showDialog) {
                                    this._confirmation.open({
                                        title: "Insufficient Permissions",
                                        message: 'You are not authorized to view this content. If you find this to be an error please email to <a href="mailto:support@jobzmall.com">support@jobzmall.com</a>',
                                        icon: {
                                            show: true,
                                            name: 'heroicons_outline:exclamation',
                                            color: 'warn'
                                        },
                                        actions: {
                                            confirm: {
                                                show: true,
                                                label: 'Ok',
                                                color: 'warn'
                                            },
                                            cancel: {
                                                show: false,
                                            }
                                        }
                                    })
                                    return false;
                                }
                                return diff.length == 0;
                            }
                        }
                    }
                }
                return false;
            })
        );
    }

    private _parsePermissions(permissions: Array<Permission>): Array<string> {
        return permissions.map(
            (permission: Permission) => `${permission.operation}:${permission.entity}`
        );
    }

}
