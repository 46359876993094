<div class="flex relative h-full w-full flex-row items-center justify-center overflow-hidden">
    <button
        class="absolute z-10 top-3 left-10 transition-all bg-white dark:bg-gray-700 dark:text-white"
        mat-icon-button (click)="close()">
        <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
    </button>

    <div *ngIf="loading" class="h-full w-full items-center justify-center flex flex-col">
        <mat-progress-spinner *ngIf="loading" [diameter]="40" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </div>

    <div class="h-full w-full" [ngClass]="{'hidden': loading}">
        <ngx-doc-viewer (loaded)="loading = false" [url]="data.media.full_path" viewer="google"
            style="width:100%;height:100%;">
        </ngx-doc-viewer>
    </div>
</div>
