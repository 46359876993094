import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const COMPONENTS = [
    ReportDialogComponent
]

@NgModule({
    declarations: COMPONENTS,
    imports: [
        RouterModule,
        NgPipesModule,
        JMPipesModule,
        JMCommonDirectivesModule,
        JMSharedModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        TranslocoModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        CommonModule
    ],
    exports: COMPONENTS,
    providers: []
})
export class JMReportingModule { }
