
import { HelpCenterMockApi } from './apps/help-center/api';
import { IconsMockApi } from './ui/icons/api';
import { AcademyMockApi } from './apps/academy/api';
import { NavigationMockApi } from './common/navigation/api';
import { FileManagerMockApi } from './apps/file-manager/api';

export const mockApiServices = [
    FileManagerMockApi,
    AcademyMockApi,
    IconsMockApi,
    HelpCenterMockApi,
    NavigationMockApi
];
