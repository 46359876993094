import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[readMore]'
})
export class ReadMoreDirective implements AfterViewInit, OnChanges {
    @Input('readMore-length') private maxLength: number;
    @Input('readMore-element') private elementChange: HTMLElement;

    private currentText: string;
    private hideToggle = true;
    private text: string;
    private isCollapsed = true;

    constructor(private el: ElementRef) { }

    /**
     * @inheritDoc
     */
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.text = this.elementChange.innerHTML;
            this.toggleView();
            this.el.nativeElement.addEventListener('click', (event: MouseEvent) => {
                event.preventDefault();
                this.toggleView();
            });
        })
    }

    /**
     * @inheritDoc
     */
    ngOnChanges(): void {
        if (this.text)
            this.toggleView();
    }

    /**
     * Toggle view - full text or not
     */
    private toggleView(): void {
        this.determineView();
        if (!this.hideToggle) {
            this.el.nativeElement.classList.remove('hidden');
        } else {
            this.el.nativeElement.classList.add('hidden');
        }
        this.isCollapsed = !this.isCollapsed;
        if (this.isCollapsed) {
            this.el.nativeElement.querySelector('.more').style.display = 'none';
            this.el.nativeElement.querySelector('.less').style.display = 'inherit';
        } else {
            this.el.nativeElement.querySelector('.more').style.display = 'inherit';
            this.el.nativeElement.querySelector('.less').style.display = 'none';
        }
    }

    /**
     * Determine view
     */
    private determineView(): void {

        const _elementChange = document.getElementById(this.elementChange.id);
        if (!_elementChange)
            return;

        if (this.text.length <= this.maxLength) {
            this.currentText = this.text;
            _elementChange.innerHTML = this.currentText;
            this.isCollapsed = false;
            this.hideToggle = true;

            return;
        }
        this.hideToggle = false;
        if (this.isCollapsed === true) {
            this.currentText = `${this.text.substring(0, this.maxLength)}...`;
            _elementChange.innerHTML = this.currentText;
        } else if (this.isCollapsed === false) {
            this.currentText = this.text;
            _elementChange.innerHTML = this.currentText;
        }
    }
}
