/* ngx-dayjs (c) 2015, 2016 Uri Shaked / MIT Licence */

import {
    Pipe,
    ChangeDetectorRef,
    PipeTransform,
    OnDestroy,
    NgZone
} from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';

@Pipe({ name: 'dayJsTimeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform, OnDestroy {
    private currentTimer: number | null;

    private lastTime: Number;
    private lastValue: string | number | Date | dayjs.Dayjs;
    private lastOmitSuffix: boolean;
    private lastLocale?: string;
    private lastText: string;
    private formatFn: (m: dayjs.Dayjs) => string;

    constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {
        dayjs.extend(relativeTime);
    }

    format(m: dayjs.Dayjs) {
        return m.from(dayjs(), this.lastOmitSuffix);
    }

    transform(
        value: string | number | Date | dayjs.Dayjs,
        omitSuffix?: boolean,
        formatFn?: (m: dayjs.Dayjs) => string
    ): string {
        if (this.hasChanged(value, omitSuffix)) {
            this.lastTime = this.getTime(value);
            this.lastValue = value;
            this.lastOmitSuffix = omitSuffix;
            this.lastLocale = this.getLocale(value);
            this.formatFn = formatFn || this.format.bind(this);
            this.removeTimer();
            this.createTimer();
            this.lastText = this.formatFn(dayjs(value));
        } else {
            this.createTimer();
        }

        return this.lastText;
    }

    ngOnDestroy(): void {
        this.removeTimer();
    }

    private createTimer() {
        if (this.currentTimer) {
            return;
        }

        const dayjsInstance = dayjs(this.lastValue);
        const timeToUpdate = this.getSecondsUntilUpdate(dayjsInstance) * 1000;

        this.currentTimer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return window.setTimeout(() => {
                    this.lastText = this.formatFn(dayjs(this.lastValue));

                    this.currentTimer = null;
                    this.ngZone.run(() => this.cdRef.markForCheck());
                }, timeToUpdate);
            } else {
                return null;
            }
        });
    }

    private removeTimer() {
        if (this.currentTimer) {
            window.clearTimeout(this.currentTimer);
            this.currentTimer = null;
        }
    }

    private getSecondsUntilUpdate(dayjsInstance: dayjs.Dayjs) {
        const howOld = Math.abs(dayjs().diff(dayjsInstance, 'minute'));
        if (howOld < 1) {
            return 1;
        } else if (howOld < 60) {
            return 30;
        } else if (howOld < 180) {
            return 300;
        } else {
            return 3600;
        }
    }

    private hasChanged(
        value: string | number | Date | dayjs.Dayjs,
        omitSuffix?: boolean
    ): boolean {
        return (
            this.getTime(value) !== this.lastTime ||
            this.getLocale(value) !== this.lastLocale ||
            omitSuffix !== this.lastOmitSuffix
        );
    }

    private getTime(value: string | number | Date | dayjs.Dayjs): number {
        if (value instanceof Date) {
            return value.getTime();
        } else if (dayjs.isDayjs(value)) {
            return value.valueOf();
        } else {
            return dayjs(value).valueOf();
        }
    }

    private getLocale(
        value: string | number | Date | dayjs.Dayjs
    ): string | null {
        return dayjs.isDayjs(value) ? value.locale() : dayjs.locale();
    }
}
