import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    forwardRef,
    Inject,
    Optional} from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { connectClearRefinements } from 'instantsearch.js/es/connectors';
import {
    BaseWidget,
    NgAisIndex,
    NgAisInstantSearch
} from 'angular-instantsearch';

@Component({
    selector: 'search-filter-clear-button',
    templateUrl: './search-filter-clear-button.component.html',
    styles: [
        `
            search-filter-clear-button {
                .ais-Panel {
                    margin-bottom: 0px !important;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class SearchFilterClearButtonComponent
    extends BaseWidget
    implements OnInit
{
    state: any = {};

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super('SearchFilterDropdownComponent');
    }

    ngOnInit(): void {
        this.createWidget(connectClearRefinements, {});
        super.ngOnInit();
    }

    ngAfterViewInit() {}

    updateState = (state, isFirstRendering: boolean) => {
        if (isFirstRendering) {
            return;
        }

        this.state = {
            ...state
        };
      
        this._changeDetectorRef.markForCheck();
    };
}
