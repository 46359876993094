import { AuthState } from '../core/auth/ngxs/state';
import { UserState } from '../user/ngxs/state';
import { LanguageState } from '../language/ngxs/state';
import { ProfileState } from '../profile/ngxs/state';
import { NotificationState } from '../notifications/ngxs/state';
import { AppState } from './ngxs/state';
import { MessagesState } from '../messages/app/ngxs/state';

export const JM_STATES = [
    AppState,
    MessagesState,
    NotificationState,
    AuthState,
    UserState,
    ProfileState,
    LanguageState
]
