import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { Organization, OrganizationAdapter, User, UserAdapter } from '@jobzmall/models';

export class Comment {
    constructor(
        public anonymous?: boolean,
        public id?: number,
        public comment?: string,
        public commented?: User | Organization,
        public total_comment_counts?: number,
        public reaction_counts?: any,
        public reaction_state?: any,
        public created_at?: Date,
        public updated_at?: Date,
        public da?: string,
        public highlighted?: boolean,
        public json_ld?: any
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class CommentAdapter implements Adapter<Comment> {
    adapt(item: any): Comment {
        return new Comment(
            item.anonymous,
            item.id,
            item.comment,
            item.commented,
            item.total_comment_counts,
            item.reaction_counts,
            item.reaction_state,
            item.created_at,
            item.updated_at,
            item.da,
            item.highlighted ? true : false,
            item.json_ld
        );
    }
}
