import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs/dist/fp.esm';

@Injectable({ providedIn: 'root' })
export class FingerprintService {
    visitor: any;
    id: string;

    constructor() {}

    init(): Promise<any> {
        return FingerprintJS.load().then((agent: any) => {
            return agent.get().then((visitor: any) => {
                this.visitor = visitor;
                this.id = this.visitor.visitorId;
            });
        });
    }
}
