import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import {
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
    MatAutocompleteModule
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'app/shared/shared.module';
import { MobileOmniComponent } from 'app/layout/common/mobile-omni/mobile-omni.component';
import { TranslocoModule } from '@ngneat/transloco';
import { JMSearchModule } from '@jobzmall/search/search.module';

@NgModule({
    declarations: [MobileOmniComponent],
    imports: [
        RouterModule.forChild([]),
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        SharedModule,
        TranslocoModule,
        JMSearchModule
    ],
    exports: [MobileOmniComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => (): BlockScrollStrategy =>
                overlay.scrollStrategies.block(),
            deps: [Overlay]
        }
    ]
})
export class MobileOmniModule {}
