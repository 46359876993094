import { Directive, ElementRef, EventEmitter, Output, AfterViewInit, OnInit } from '@angular/core';

@Directive({
    selector: '[watchInnerHtml]'
})
export class WatchInnerHtmlDirective {

    @Output() readonly innerHtmlRendered: EventEmitter<any> = new EventEmitter();

    private _observer: MutationObserver;

    constructor(private _el: ElementRef) {
        this._observer = new MutationObserver(mutations => {
            mutations.forEach((mutation, index) => {
                if (mutation.type === 'childList') {
                    this.innerHtmlRendered.emit();
                }
            });
        });
        this._observer.observe(
            this._el.nativeElement,
            { attributes: true, childList: true, characterData: true }
        );
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._observer.disconnect();
    }

}
