import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { defaultNavigation } from './data';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { User } from '@jobzmall/models';
import { UserState } from '@jobzmall/user/ngxs/state';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi {
    private readonly _defaultNavigation: FuseNavigationItem[] =
        defaultNavigation;

    /**
     * Constructor
     */
    constructor(
        private _store: Store,
        private _fuseMockApiService: FuseMockApiService
    ) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        this._defaultNavigation[
            this._defaultNavigation.findIndex((i) => i.id === 'videoresume')
        ].hidden = (item: FuseNavigationItem) => {
            return this._store.select(UserState.user).pipe(
                map((user: User) => {
                    let isHidden = user && user.has_video_collection;
                    return isHidden;
                })
            );
        };

        this._defaultNavigation[
            this._defaultNavigation.findIndex((i) => i.id === 'meetings')
        ].hidden = (item: FuseNavigationItem) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = !auth;
                    return isHidden;
                })
            );
        };

        this._defaultNavigation[
            this._defaultNavigation.findIndex((i) => i.id === 'home')
        ].hidden = (item: FuseNavigationItem) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = !auth;
                    return isHidden;
                })
            );
        };

        this._defaultNavigation[
            this._defaultNavigation.findIndex(
                (i) => i.id === 'mall-unauthenticated'
            )
        ].hidden = (item: FuseNavigationItem) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = auth;
                    return isHidden;
                })
            );
        };

        // Resumes
        let resumesIndex = this._defaultNavigation.findIndex(
            (i) => i.id == 'resumes'
        );
        this._defaultNavigation[resumesIndex].hidden = (
            item: FuseNavigationItem
        ) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = !auth;
                    return isHidden;
                })
            );
        };

        ///// More
        let moreIndex = this._defaultNavigation.findIndex(
            (i) => i.id === 'more'
        );
        // My Jobs
        let myJobsIndex = this._defaultNavigation[moreIndex].children.findIndex(
            (i) => i.id == 'more.my-jobs'
        );
        this._defaultNavigation[moreIndex].children[myJobsIndex].hidden = (
            item: FuseNavigationItem
        ) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = !auth;
                    return isHidden;
                })
            );
        };
        // History
        let historyIndex = this._defaultNavigation[
            moreIndex
        ].children.findIndex((i) => i.id == 'more.history');
        this._defaultNavigation[moreIndex].children[historyIndex].hidden = (
            item: FuseNavigationItem
        ) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = !auth;
                    return isHidden;
                })
            );
        };
        // Genome
        let genomeIndex = this._defaultNavigation[moreIndex].children.findIndex(
            (i) => i.id == 'more.genome-guest'
        );
        this._defaultNavigation[moreIndex].children[genomeIndex].hidden = (
            item: FuseNavigationItem
        ) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = auth;
                    return isHidden;
                })
            );
        };
        genomeIndex = this._defaultNavigation[moreIndex].children.findIndex(
            (i) => i.id == 'more.genome-authenticated'
        );
        this._defaultNavigation[moreIndex].children[genomeIndex].hidden = (
            item: FuseNavigationItem
        ) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = !auth;
                    return isHidden;
                })
            );
        };
        // Settings
        let settingsIndex = this._defaultNavigation[
            moreIndex
        ].children.findIndex((i) => i.id == 'more.settings');
        this._defaultNavigation[moreIndex].children[settingsIndex].hidden = (
            item: FuseNavigationItem
        ) => {
            return this._store.select(AuthState.isAuthenticated).pipe(
                map((auth: boolean) => {
                    let isHidden = !auth;
                    return isHidden;
                })
            );
        };

        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            // Return the response
            return [
                200,
                {
                    default: cloneDeep(this._defaultNavigation),
                    compact: cloneDeep(this._defaultNavigation),
                    horizontal: cloneDeep(this._defaultNavigation),
                    futuristic: cloneDeep(this._defaultNavigation)
                }
            ];
        });
    }
}
