import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export class Benefit {
    constructor(public id?: number, public name?: string) {}
}

@Injectable({
    providedIn: 'root'
})
export class BenefitAdapter implements Adapter<Benefit> {
    adapt(item: any): Benefit {
        return new Benefit(item.id, item.name);
    }
}
