 <ng-container *transloco="let t">
     <div  *ngIf="step == 'fields'" class="w-full mx-auto max-w-100 sm:w-100 sm:mx-0">
         <!-- Logo -->
         <app-logo *ngIf="showLogo" class="flex items-center justify-center w-auto h-15"></app-logo>

         <div class="mx-auto">
             <!-- Title -->
             <div class="mt-8 text-4xl font-extrabold leading-tight tracking-tight">{{t(title)}}</div>
             <div class="flex items-baseline mt-0.5 font-medium">
                 <div>{{t('PUBLIC.SIGNUP.FORM.LABEL.DISCLAIMER_ACCOUNT')}}</div>
                 <a class="ml-1 text-primary-500 hover:underline"
                     (click)="loginClick.emit()">{{t('PUBLIC.LOGIN.LOG_IN')}}
                 </a>
             </div>

             <!-- Alert -->
             <jbz-alert class="mt-8 -mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                 [type]="alert.type" [@shake]="alert.type === 'error'">
                 {{alert.message}}
             </jbz-alert>


             <!-- Sign Up form -->
             <form class="mt-8" [formGroup]="signUpForm" (ngSubmit)="signUpForm.valid && step = 'phone-verify'">

                 <div class="flex flex-row w-full">
                     <div class="flex flex-col w-1/2 mr-1">
                         <!-- First Name field -->
                         <mat-form-field class="w-full">
                             <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.FIRST_NAME')}}</mat-label>
                             <input id="first_name" name="first_name" matInput [formControlName]="'first_name'">
                             <mat-error *ngIf="signUpForm.get('first_name').hasError('required')">
                                 {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                             </mat-error>
                         </mat-form-field>
                     </div>
                     <div class="flex flex-col w-1/2 ml-1">
                         <!-- Last Name field -->
                         <mat-form-field class="w-full">
                             <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.LAST_NAME')}}</mat-label>
                             <input id="last_name" name="last_name" matInput [formControlName]="'last_name'">
                             <mat-error *ngIf="signUpForm.get('last_name').hasError('required')">
                                 {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                             </mat-error>
                         </mat-form-field>
                     </div>
                 </div>

                 <!--Phone field-->
                 <mat-form-field class="w-full">
                     <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.PHONE')}}</mat-label>
                     <ngx-intl-tel-input [separateDialCode]="true" [required]="true" class="mb-1"
                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.Canada,
                        CountryISO.India, CountryISO.France,
                        CountryISO.UnitedKingdom, CountryISO.Turkey]"
                        [excludeCountries]="[CountryISO.Iran,
                        CountryISO.Afghanistan, CountryISO.NorthKorea, CountryISO.Syria,
                        CountryISO.Russia]" [enableAutoCountrySelect]="false"
                         [enablePlaceholder]="true" [searchCountryFlag]="true"
                         [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                         [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" maxLength="15"
                         [phoneValidation]="true" inputId="phone" name="phone" formControlName="phone">
                     </ngx-intl-tel-input>
                     <mat-error *ngIf="signUpForm.get('phone').hasError('required')">
                         {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                     <mat-error *ngIf="signUpForm.get('phone').hasError('validatePhoneNumber')">
                         Invalid Number
                     </mat-error>
                 </mat-form-field>



                 <!-- Email field -->
                 <mat-form-field class="w-full">
                     <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.EMAIL')}}</mat-label>
                     <input autocapitalize="none" autocomplete="off" uniqueEmail id="email" name="email" matInput
                         formControlName="email">
                     <mat-error *ngIf="signUpForm.get('email').hasError('required')">
                         {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                     </mat-error>
                     <mat-error
                         *ngIf="signUpForm.get('email').hasError('email') || signUpForm.get('email').hasError('pattern') || signUpForm.get('email').hasError('emailInvalid')">
                         {{t('PUBLIC.SHARED.FORM.ERROR.INVALID_EMAIL')}}
                     </mat-error>
                     <mat-error *ngIf="!signUpForm.get('email').hasError('required') &&
                        signUpForm.get('email').hasError('emailTaken')">
                         {{t('PUBLIC.SIGNUP.FORM.ERROR.EXISTING_EMAIL')}} <a class="underline cursor-pointer" (click)="loginClick.emit({email:
                             signUpForm.get('email').value})">{{t('PUBLIC.SIGNUP.FORM.ERROR.EXISTING_EMAIL.LINK')}}</a>
                     </mat-error>
                 </mat-form-field>

                 <!-- Password field -->
                 <mat-form-field class="w-full">
                     <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.PASSWORD')}}</mat-label>
                     <input id="password" matInput type="password" formControlName="password" #passwordField>
                     <button mat-icon-button type="button"
                         (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                         matSuffix>
                         <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                             [svgIcon]="'heroicons_solid:eye'">
                         </mat-icon>
                         <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                             [svgIcon]="'heroicons_solid:eye-off'">
                         </mat-icon>
                     </button>
                     <mat-error *ngIf="signUpForm.get('password').hasError('required')">
                         {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                     </mat-error>
                     <mat-error *ngIf="signUpForm.get('password').hasError('minlength')">
                         {{t('PUBLIC.SHARED.FORM.ERROR.PASSWORD_LENGTH')}}
                     </mat-error>

                 </mat-form-field>

                 <!-- ToS and PP -->
                 <div class="text-center w-full mt-1.5">
                     <span>By clicking Continue you accept the terms of our </span>
                     <a class="ml-1 text-primary-500 hover:underline" target="_blank" [href]="['/legal/terms']">Terms of
                         Service
                     </a>
                     <span>and</span>
                     <a class="ml-1 text-primary-500 hover:underline" target="_blank"
                         [href]="['/legal/privacy']">Privacy
                         Policy
                     </a>
                 </div>

                 <!-- Submit button -->
                 <button class="w-full mt-6 jbz-mat-button-large" mat-flat-button [color]="'primary'"
                     [disabled]="signUpForm.invalid" type="submit">
                     <span>
                         {{ t('PUBLIC.SHARED.ACTION.CONTINUE') }}
                     </span>
                 </button>

                 <!-- Separator -->
                 <div class="flex items-center mt-8">
                     <div class="flex-auto mt-px border-t"></div>
                     <div class="mx-2 text-secondary">Or</div>
                     <div class="flex-auto mt-px border-t"></div>
                 </div>

                 <!-- Single sign-on buttons -->
                 <div class="flex flex-col items-center mt-8 space-y-4">

                     <asl-google-signin-button width="270" theme="outline" type='standard' [text]="'signup_with'"
                         shape="pill" size='large'>
                     </asl-google-signin-button>

                     <!-- <apple-signin class="w-full" state="signup" [redirectURI]="'https://dev.jobzmall.com/login'"
                         scope="name email" clientId="com.jobzmall.service" [type]="AppleButtonType.SignUp">
                     </apple-signin> -->
                 </div>


             </form>
         </div>
     </div>
     <phone-verification-form [requireVerification]="false" (onPhoneUpdateClick)="step = 'fields'" *ngIf="step == 'phone-verify'"
         [showLogo]="showLogo" [showTitle]="true" (onSendFail)="onSendFail($event)"
         (onVerifySuccess)="onVerifySuccess()" [phoneCountryCode]="signUpForm.get('phone').value.countryCode"
         [phone]="signUpForm.get('phone').value.e164Number">
     </phone-verification-form>
     <one-tap></one-tap>
 </ng-container>
