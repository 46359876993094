import {
    Component,
    Renderer2,
    Injector,
    Inject,
    ElementRef,
    HostListener,
    ViewChild
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterOutlet
} from '@angular/router';
import { Logout, SocialLogin } from '@jobzmall/core/auth/ngxs/actions';
import {
    Actions,
    ofAction,
    ofActionCompleted,
    Select,
    Store
} from '@ngxs/store';
import { authenticationRedirectExclusions } from './shared/auth/redirect-exclusions';
import { SignUp, Login } from '@jobzmall/core/auth/ngxs/actions';
import { RefreshUser } from '@jobzmall/user/ngxs/actions';
import {
    filter,
    map,
    distinctUntilChanged,
    first,
    takeWhile
} from 'rxjs/operators';
import { ConfigService } from '@jobzmall/config';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';
import { firstValueFrom, Observable } from 'rxjs';
import { AppState } from '@jobzmall/state/ngxs/state';
import { AcceptCookies } from '@jobzmall/state/ngxs/actions';
import { UserState } from '@jobzmall/user/ngxs/state';
import { User } from '@jobzmall/models';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UserDetailConfirmationOverlayComponent } from './shared/auth/components/user-detail-confirmation-overlay/user-detail-confirmation-overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';
import { StateReset } from 'ngxs-reset-plugin';
import { MessagesState } from '@jobzmall/messages';
import {
    WEBSOCKET_SERVICE,
    WebsocketService,
    Restangular
} from '@jobzmall/core';
import { Idle } from '@ng-idle/core';
import Botd from '@fpjs-incubator/botd-agent';
import { environment } from 'environments/environment';
import { SegmentService } from 'ngx-segment-analytics';
import { DOCUMENT, Location } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { ApplicationRef } from '@angular/core';
import { DownloadPWAOverlayComponent } from './shared/components/download-pwa/download-pwa-overlay.component';
import { LinkService } from '@jobzmall/meta';
import { PhoneVerificationOverlayComponent } from '@jobzmall/phone/components/phone-verification-overlay/phone-verification-overlay.component';
import { VerifyPhoneNumberForMessaging } from '@jobzmall/messages/app/ngxs/actions';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: []
})
export class AppComponent {
    @Select(UserState.initialized) userStateInitialized$: Observable<boolean>;
    @Select(AppState.cookiesAccepted) cookiesAccepted$: Observable<boolean>;
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

    @ViewChild('cookie') cookie: ElementRef;

    loaded: boolean;
    overlayRef: OverlayRef;
    lastPath: any;
    isIframe = false;

    pwaInstallEvent: any;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(WINDOW) private _window: Window,
        @Inject(WEBSOCKET_SERVICE) private _websocketService: WebsocketService,
        private _location: Location,
        private _injector: Injector,
        private _idle: Idle,
        private _overlay: Overlay,
        private _activatedRoute: ActivatedRoute,
        private _renderer: Renderer2,
        public router: Router,
        private _segment: SegmentService,
        private _config: ConfigService,
        private _store: Store,
        private _actions: Actions,
        private _api: Restangular,
        private _elementRef: ElementRef,
        private _appRef: ApplicationRef,
        private _link: LinkService
    ) {}

    ngOnInit() {
        this.waitForLoad();
        this.loadGoogleMaps();
        this.registerGlobalActionListeners();
        this.trackPages();
        this._link.startRouteListener();
        this.isIframe = window !== window.parent && !window.opener;

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.pwaInstallEvent = e;
            this.openDownloadPWAOverlay();
        });
    }

    ngAfterViewInit() {
        this._elementRef.nativeElement.removeAttribute('ng-version');
        this._appRef.isStable
            .pipe(takeWhile((val: boolean) => !val, true))
            .subscribe((stable: boolean) => {
                if (stable) {
                    console.log('stable and ready');
                    (this._window as any).prerenderReady = true;
                }
            });
        // this._store.dispatch(new StartListening());
    }

    @HostListener('document:mousemove', ['$event'])
    onDocMouseMove(e) {
        this._idle.watch();
    }

    @HostListener('document:keypress', ['$event'])
    onDocKeyPress(e) {
        this._idle.watch();
    }

    @HostListener('document:click', ['$event'])
    onDocClick(e) {
        this._idle.watch();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (this.cookie) {
            this.cookie.nativeElement.style.transform =
                'rotate(' + window.pageYOffset + 'deg)';
        }
    }
   
    openDownloadPWAOverlay() {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.overlayRef = this._overlay.create({
                scrollStrategy: this._overlay.scrollStrategies.block(),
                height: '100%',
                width: '100%',
                hasBackdrop: true
            });
            const portal = new ComponentPortal(
                DownloadPWAOverlayComponent,
                undefined,
                Injector.create({
                    parent: this._injector,
                    providers: [
                        {
                            provide: JM_OVERLAY_DATA,
                            useValue: {
                                overlay: this.overlayRef
                            }
                        }
                    ]
                })
            );
            let attached = this.overlayRef.attach(portal);
            setTimeout(() => {
                attached.changeDetectorRef.markForCheck();
                attached.changeDetectorRef.detectChanges();
            });

            attached.instance.onClose.pipe(first()).subscribe((accepted) => {
                if (accepted) {
                    this.addPWA();
                }
            });
        }
    }

    addPWA() {
        if (this.pwaInstallEvent) {
            this.pwaInstallEvent.prompt();
        }
    }

    openPhoneVerificationOverlay() {
        if (!this.overlayRef || !this.overlayRef.hasAttached()) {
            this.overlayRef = this._overlay.create({
                scrollStrategy: this._overlay.scrollStrategies.block(),
                height: '100%',
                width: '100%',
                hasBackdrop: true
            });
            const portal = new ComponentPortal(
                PhoneVerificationOverlayComponent,
                undefined,
                Injector.create({
                    parent: this._injector,
                    providers: [
                        {
                            provide: JM_OVERLAY_DATA,
                            useValue: { overlay: this.overlayRef }
                        }
                    ]
                })
            );
            const attached = this.overlayRef.attach(portal);
            setTimeout(() => {
                attached.changeDetectorRef.markForCheck();
                attached.changeDetectorRef.detectChanges();
            });
        }
    }

    registerGlobalActionListeners() {
        // TODO: Bring this back when we want to use Fingerprint Pro
        // this.isAuthenticated$.subscribe((auth: boolean) => {
        //     if (auth) {
        //         this._fingerprint.init().then(() => {
        //             this._api
        //                 .all('dv')
        //                 .customPOST(
        //                     this._fingerprint.visitor
        //                         ? { v: this._fingerprint.visitor }
        //                         : {}
        //                 )
        //                 .pipe(first())
        //                 .subscribe();
        //         });
        //     }
        // });
        this._store.select(UserState.user).subscribe((user: User) => {
            if (user) {
                this._websocketService
                    .observe()
                    .pipe(takeWhile((val) => val === undefined, true))
                    .subscribe(() => {
                        if (this._websocketService.websocket) {
                            this._websocketService.websocket
                                .private(`user.${user.id}`)
                                .stopListening('.user-updated');
                            this._websocketService.websocket
                                .private(`user.${user.id}`)
                                .listen(
                                    '.user-updated',
                                    this.onReload.bind(this)
                                );
                            this._websocketService.websocket
                                .private(`user.${user.id}`)
                                .stopListening('.hard-reload');
                            this._websocketService.websocket
                                .private(`user.${user.id}`)
                                .listen(
                                    '.hard-reload',
                                    this.onHardReload.bind(this)
                                );
                        }
                    });

                if (user.deactivated) {
                    setTimeout(() => {
                        this.router.navigate(['/deactivated']);
                    });
                    return;
                }
                if (user.password_reset) {
                    setTimeout(() => {
                        this.router.navigate(['/reset-password']);
                    });
                    return;
                }

                if (user.needs_info) {
                    if (!this.overlayRef || !this.overlayRef.hasAttached()) {
                        this.overlayRef = this._overlay.create({
                            scrollStrategy:
                                this._overlay.scrollStrategies.block(),
                            height: '100%',
                            width: '100%',
                            hasBackdrop: true
                        });
                        const portal = new ComponentPortal(
                            UserDetailConfirmationOverlayComponent,
                            undefined,
                            Injector.create({
                                parent: this._injector,
                                providers: [
                                    {
                                        provide: JM_OVERLAY_DATA,
                                        useValue: { overlay: this.overlayRef }
                                    }
                                ]
                            })
                        );
                        const attached = this.overlayRef.attach(portal);
                        setTimeout(() => {
                            attached.changeDetectorRef.markForCheck();
                            attached.changeDetectorRef.detectChanges();
                        });
                    }
                }
            }
        });

        this._actions
            .pipe(ofActionCompleted(VerifyPhoneNumberForMessaging))
            .subscribe(() => {
                this.openPhoneVerificationOverlay();
            });

        // Logout handler
        this._actions.pipe(ofActionCompleted(Logout)).subscribe(() => {
            if (!this.urlHasExcludedRedirect()) {
                this.router.navigate(['/login']);
            }
            this._store.dispatch(new StateReset(MessagesState));
        });

        // Login handler
        this._actions
            .pipe(ofActionCompleted(SocialLogin, Login))
            .subscribe(() => {
                // TODO: Onboarding logic here

                this._activatedRoute.queryParamMap
                    .pipe(first())
                    .subscribe((queryParams) => {
                        const user = this._store.selectSnapshot(UserState.user);
                        if (user) {
                            if (user && user.deactivated) {
                                this.router.navigate(['/deactivated']);
                                return;
                            }
                            if (user && user.password_reset) {
                                this.router.navigate(['/reset-password']);
                                return;
                            }

                            if (!this.urlHasExcludedRedirect()) {
                                let redirectURL =
                                    queryParams.get('redirectURL') ||
                                    '/signed-in-redirect';
                                if (redirectURL) {
                                    // Navigate to the redirect url
                                    this.router.navigateByUrl(redirectURL);
                                } else {
                                    this.router.navigate(['/home']);
                                }
                            }
                        }
                    });
            });

        // Signup handler
        this._actions.pipe(ofActionCompleted(SignUp)).subscribe(() => {
            this._activatedRoute.queryParamMap
                .pipe(first())
                .subscribe((queryParams) => {
                    if (!this.urlHasExcludedRedirect()) {
                        let redirectURL = queryParams.get('redirectURL');
                        if (redirectURL) {
                            // Navigate to the redirect url
                            this.router.navigateByUrl(redirectURL);
                        } else {
                            this._store
                                .select(UserState.user)
                                .pipe(
                                    takeWhile((val) => val == undefined, true)
                                )
                                .subscribe((user: User) => {
                                    this.router.navigate([
                                        `/onboard/user/${user.id}/main`
                                    ]);
                                });
                        }
                    }
                });
        });
    }

    urlHasExcludedRedirect() {
        let result = false;
        authenticationRedirectExclusions.forEach((excluded) => {
            if (this.router.url.includes(excluded)) {
                result = true;
            }
        });
        return result;
    }

    loadGoogleMaps() {
        const script = this._renderer.createElement('script');
        const key = this._config.getSettings('data.google.app_key');
        script.async = true;
        script.defer = true;
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${key}`;
        this._renderer.appendChild(document.head, script);
    }

    onHardReload() {
        this._window.location = '/';
    }

    onReload() {
        this._store.dispatch(new RefreshUser());
    }

    trackPages() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this._activatedRoute)
            )
            .subscribe((route: ActivatedRoute) => {
                if (this._location.path() !== this.lastPath) {
                    this.lastPath = this._location.path();
                    this._segment.page(this._location.path());
                }
            });
    }

    waitForLoad() {
        this.loaded = false;
        const sub = this.router.events
            .pipe(
                filter(
                    (e) =>
                        e instanceof NavigationStart ||
                        e instanceof NavigationEnd ||
                        e instanceof NavigationCancel ||
                        e instanceof NavigationError
                ),
                map((e) => e instanceof NavigationEnd),
                distinctUntilChanged()
            )
            .subscribe((loaded) => {
                this.loaded = loaded;
                if (loaded) {
                    sub.unsubscribe();
                }
            });
    }

    acceptCookies() {
        this._store.dispatch(new AcceptCookies());
    }
}
