import {
    Directive,
    Input,
    forwardRef} from '@angular/core';
import {
    ValidationErrors,
    AsyncValidator,
    AbstractControl,
    NG_ASYNC_VALIDATORS} from '@angular/forms';

import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Restangular } from '../../core/api/ngx-restangular';

@Directive({
    selector: '[uniqueEmail]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => UniqueEmailDirective),
            multi: true
        }
    ]
})
export class UniqueEmailDirective implements AsyncValidator {
    @Input('uniqueEmailReverse') reverse = false;

    constructor(private _api: Restangular) {}

    ngOnInit(): void {}

    ngOnDestroy() {}

    validate(c: AbstractControl): Observable<ValidationErrors> {
        // Here we call our static validator function
        return this.validateEmail(c);
    }

    validateEmail(control: AbstractControl): Observable<ValidationErrors> {
        return timer(200).pipe(
            switchMap(() =>
                this._api
                    .all('user')
                    .customPOST({ email: control.value }, 'checkemail')
                    .pipe(
                        map((res: any) => {
                            if (this.reverse) {
                                return { emailNotExists: true };
                            } else {
                                return {};
                            }
                        }),
                        catchError((err: any) => {

                            if (err.status === 409) {
                                if (this.reverse) {
                                    return of({});
                                } else {
                                    return of({ emailTaken: true });
                                }
                            }
                            if (err.status === 406) {
                                return of({ emailInvalid: true });
                            }
                        })
                    )
            )
        );
    }
}
