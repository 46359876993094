 <div *ngIf="(pinning$ | async)"
     class="z-50 flex flex-col mr-8 border rounded-tl-lg rounded-tr-lg shadow-xl cursor-pointer bg-card dark:bg-gray-900 w-90 h-120"
     @expandCollapse>
     <div
         class="flex items-center justify-between w-full border-b rounded-tl-lg rounded-tr-lg cursor-pointer min-h-14
         bg-card">
         <div class="flex items-center">
             <div
                 class="relative flex items-center justify-center w-8 h-8 ml-3 text-gray-600 bg-gray-200 rounded-full cursor-pointer animate-pulse flex-0 dark:bg-gray-700 dark:text-gray-200">

             </div>
             <div class="ml-4">
                 <span
                     class="w-10 h-10 font-medium leading-5 truncate bg-gray-200 cursor-pointer animate-pulse dark:bg-gray-700 hover:underline"></span>
             </div>
         </div>
         <div class="flex items-center">

         </div>
     </div>
     <!-- Conversation -->
     <div class="flex flex-col-reverse flex-grow overflow-y-auto">
         <div
             class="flex flex-col flex-auto flex-shrink p-6 bg-gray-200 dark:bg-gray-700 animate-pulse dark:bg-transparent">

         </div>
     </div>

 </div>
