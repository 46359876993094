import { Constants, ICrypto, Logger } from '@azure/msal-common';
import { OAuthConfig } from '../config/oauth.config';
import { PopupWindowAttributes } from './popup-window-attributes';
import { CryptoOps } from '../crypto/CryptoOps';
import { AuthorizationCodeRequest, BaseOAuthRequest } from '../request';
import { RequestParamBuilder } from '../request/RequestParamBuilder';
import { UrlString } from '../url/UrlString';

export type PopupParams = {
    popup?: Window | null;
    popupName: string;
    popupWindowAttributes: PopupWindowAttributes;
};

export class OAuthStateEncrypter {
    private logger: Logger;
    private browserCrypto: ICrypto;

    constructor() {
        this.logger = new Logger({}, '@nxtg/oauth', '1.0');
        this.browserCrypto = new CryptoOps(this.logger);
    }

    public encrypt(val: string) {
        return this.browserCrypto.base64Encode(val);
    }

    public decrypt(val: string) {
        return this.browserCrypto.base64Decode(val);
    }
}
