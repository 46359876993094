import { Pipe, PipeTransform } from '@angular/core';
import { CountryISO } from '@jobzmall/telephone-input';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

    constructor() {}

    transform(
        value: string,
        country_code: string | CountryISO = CountryISO.UnitedStates
    ): string {
        if (!value) {
            return '';
        }
        let number = this.phoneUtil.parse(value, country_code);

        return this.phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
    }
}
