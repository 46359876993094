import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { LanguageModule } from 'app/layout/common/language/language.module';
import { MessagesBadgeModule } from 'app/layout/common/messages-badge/messages-badge.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { MobileOmniModule } from 'app/layout/common/mobile-omni/mobile-omni.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserMenuModule } from '@jobzmall/common/components/user-menu/user-menu.module';
import { SharedModule } from 'app/shared/shared.module';
import { RecruiterLayoutComponent } from 'app/layout/layouts/vertical/recruiter/recruiter.component';
import { TranslocoModule } from '@ngneat/transloco';
import { HeaderModule } from 'app/layout/common/header/header.module';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { RecruiterHeaderModule } from './header/header.module';
import { FooterModule } from 'app/layout/common/footer/footer.module';
import { JMMessagesModule } from '@jobzmall/messages';
import { JMSupportModule } from '@jobzmall/support/support.module';

@NgModule({
    declarations: [RecruiterLayoutComponent],
    imports: [
        RecruiterHeaderModule,
        JMSharedModule,
        HeaderModule,
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseNavigationModule,
        TranslocoModule,
        LanguageModule,
        MessagesBadgeModule,
        NotificationsModule,
        MobileOmniModule,
        ShortcutsModule,
        UserMenuModule,
        SharedModule,
        FooterModule,
        JMMessagesModule,
        JMSupportModule
    ],
    exports: [RecruiterLayoutComponent]
})
export class RecruiterLayoutModule {}
