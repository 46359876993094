  <div class="relative flex flex-col capitalize">
      <div #button *ngIf="showFilter"
          class="flex items-center px-4 py-3 rounded-full cursor-pointer dark:text-white text-slate-600 bg-slate-200 whitespace-nowrap ring-purple-600 dark:bg-card"
          [ngClass]="{
          'ring-0': !showPanel && !(toggleElement?.state?.items | filterBy: ['isRefined']:true)?.length,
          'ring-1': showPanel || (toggleElement?.state?.items | filterBy: ['isRefined']:true)?.length
      }">
          <span *ngIf="filter.args.icon"
              class="relative flex flex-col items-center px-2 text-sm text-white rounded-full">
              <mat-icon class="icon-size-5" [svgIcon]="filter.args.icon">
              </mat-icon>
          </span>
          <span>{{filter.name}}</span>
          <span
              *ngIf="(filterElement?.state?.items | filterBy: ['isRefined']:true | filterByNot: ['label']:'All':true)?.length"
              class="flex items-center justify-center flex-shrink-0 w-5 h-5 ml-2 text-sm text-white bg-purple-600 rounded-full">{{(filterElement?.state?.items | filterBy: ['isRefined']:true)?.length}}</span>
          <span *ngIf="toggleElement && (toggleElement?.state?.items | filterBy: ['isRefined']:true)?.length"
              class="relative flex flex-col items-center px-2 text-sm text-white rounded-full bg-purple">
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:check'">
              </mat-icon>
          </span>
          <mat-icon *ngIf="!toggleElement" class="ml-2 icon-size-4" [svgIcon]="'heroicons_outline:chevron-down'">
          </mat-icon>
      </div>
      <div #panelWrapper [hidden]="!showPanel"
          class="absolute p-4 mt-1 rounded-lg shadow-xl z-5 max-w-80 top-full bg-card dark:bg-gray-600">
          <ais-panel [ngSwitch]="true" [ngClass]="filter.type" class="whitespace-nowrap filter">
              <div *ngSwitchCase="filter.type == 'toggle'" class="content">
                  <form>
                      <ais-refinement-list #toggleElement [attribute]="filter.args.attribute"
                          [operator]="filter.args.operator">
                      </ais-refinement-list>
                  </form>
              </div>
              <div *ngSwitchCase="filter.type == 'numeric'" class="content">
                  <form>
                      <ais-numeric-menu #filterElement [attribute]="filter.args.attribute" [items]="filter.args.items">
                      </ais-numeric-menu>
                  </form>
              </div>
              <div *ngSwitchCase="filter.type == 'refinement'">
                  <form>
                      <ais-refinement-list #filterElement [searchable]="filter.args.searchable" [searchPlaceholder]="
                                        filter.args.searchPlaceholder
                                    " [attribute]="filter.args.attribute" [operator]="filter.args.operator">
                      </ais-refinement-list>
                  </form>
              </div>
              <div *ngSwitchCase="filter.type == 'hierarchical'">
                  <form>
                      <ais-hierarchical-menu #filterElement [attributes]="filter.args.attributes">
                      </ais-hierarchical-menu>
                  </form>
              </div>
          </ais-panel>
      </div>
  </div>
