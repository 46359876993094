import { Component, OnInit, OnDestroy, Inject, HostListener, Output, EventEmitter } from '@angular/core';
import { SubSink } from 'subsink';
import { JM_OVERLAY_DATA } from '.';
import { Select } from '@ngxs/store';
import { UserState } from '@jobzmall/user/ngxs/state';
import { Media, User } from '@jobzmall/models';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { ESCAPE } from '@angular/cdk/keycodes';
import { WINDOW } from '@ng-web-apis/common';
import { saveAs } from 'file-saver';

@Component({
    template: ''
})
export class JMBaseOverlayComponent implements OnInit, OnDestroy {
    protected subscriptions = new SubSink();
    public loading = false;

    @Output() onClose = new EventEmitter();

    @Select(UserState.user) user$: Observable<User>;

    @HostListener('document:keydown', ['$event']) private handleKeydown(
        event: KeyboardEvent
    ) {
        if (event.key === 'Escape') {
            this.close();
        }
    }

    constructor(@Inject(JM_OVERLAY_DATA) public data: any) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openIntercom() {
        (window as any).Intercom('showNewMessage');
    }

    close(arg: any = null) {
        this.onClose.emit(arg);
        this.data.overlay.dispose();
    }

    download(file: Media, name?: string) {
        saveAs(file.full_path, name || file.src);
    }
}
