import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Store } from '@ngxs/store';
import { UserService } from '@jobzmall/user/user.service';
import { JMBaseOverlayComponent } from '@jobzmall/overlays/overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { WINDOW } from '@ng-web-apis/common';

@Component({
    selector: 'download-pwa-overlay',
    templateUrl: './download-pwa-overlay.component.html',
    styles: [
        `
            download-pwa-overlay {
                width: 100%;
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class DownloadPWAOverlayComponent
    extends JMBaseOverlayComponent
    implements OnInit
{
    constructor(
        @Inject(WINDOW) private _window: Window,
        @Inject(JM_OVERLAY_DATA) public data: any,
        private _store: Store,
        private _confirmation: FuseConfirmationService,
        private _userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super(data);
    }

    ngOnInit(): void {}

    accept() {
        this.onClose.emit(true);
        this.data.overlay.dispose();
    }

    close(arg: any = null) {
        this.onClose.emit(arg);
        this.data.overlay.dispose();
    }
}
