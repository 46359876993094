import { Injectable } from '@angular/core';
import {
    Action,
    State,
    StateContext,
    Selector,
    NgxsAfterBootstrap
} from '@ngxs/store';
import { SupportStateModel } from './model';
import { HideSupportWidget, ShowSupportWidget } from './actions';

@State<SupportStateModel>({
    name: 'support',
    defaults: {
        showSupportWidget: false
    }
})
@Injectable()
export class SupportState implements NgxsAfterBootstrap {
    @Selector()
    static showSupportWidget(state: SupportStateModel): boolean {
        return state.showSupportWidget;
    }

    constructor() {}

    ngxsOnInit() {}

    ngxsAfterBootstrap() {}

    @Action(ShowSupportWidget)
    showSupportWidget(ctx: StateContext<SupportStateModel>) {
        ctx.patchState({
            showSupportWidget: true
        });
    }

    @Action(HideSupportWidget)
    hideSupportWidget(ctx: StateContext<SupportStateModel>) {
        ctx.patchState({
            showSupportWidget: false
        });
    }
}
