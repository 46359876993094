import { Injectable } from '@angular/core';
import { Restangular } from '@jobzmall/core/api';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { first, map } from 'rxjs';
import { ConfigService } from '../../config/config.service';

@Injectable({ providedIn: 'root' })
export class CaptchaService {
    constructor(
        private _config: ConfigService,
        private _recaptcha: ReCaptchaV3Service,
        private _api: Restangular
    ) {}

    executeAndVerify(action: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._recaptcha.execute(
                this._config.getSettings('data.recaptcha.site_key'),
                action,
                (token) => {
                    this._api
                        .all('captcha/verification')
                        .customPOST({
                            captcha: token
                        })
                        .pipe(
                            first(),
                            map((res: any) => res.data)
                        )
                        .subscribe((output) => {
                            if (output.score >= 0.5) {
                                resolve(true);
                            }
                            reject(false);
                        });
                },
                {
                    useGlobalDomain: false
                }
            );
        });
    }
}
