import { SearchUtils } from '../util/search';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
    /**
     * Transform
     *
     * @param {string} value
     * @param {string} searchValue
     * @param {string} replacement
     * @returns {any}
     */
    transform(value: string, searchValue: string, replacement: string): any {
        return value.replace(searchValue, replacement);
    }
}
