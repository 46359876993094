import * as dayjs from 'dayjs';
import {
    timer,
    Subscription,
    Observable,
    BehaviorSubject,
    interval,
    TimeInterval
} from 'rxjs';
import { take, map, timeInterval } from 'rxjs/operators';

export class Stopwatch {
    private _timeSubject: BehaviorSubject<string> = new BehaviorSubject(null);
    private _timer: Observable<any> = interval(1000 /* ms */).pipe(
        timeInterval()
    );
    private _time: number = 0;
    private _sub: Subscription;

    constructor() {}

    get time() {
        return this._timeSubject.asObservable();
    }

    reset(stop: boolean = true) {
        if (stop) {
            this.stop();
        }
        this._time = 0;
    }

    start() {
        this._sub = this._timer.subscribe((x) => {
            this._time++;
            this._timeSubject.next(
                dayjs()
                    .hour(0)
                    .minute(0)
                    .second(this._time)
                    .format('HH : mm : ss')
            );
        });
    }

    stop() {
        this._sub.unsubscribe();
        this._timeSubject.next(null);
    }
}
