import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { JMBaseOverlayComponent } from '@jobzmall/overlays/overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';
import { UserState } from '../../user/ngxs/state';
import { User } from '@jobzmall/models';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
    selector: 'auth-overlay',
    templateUrl: './auth-overlay.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class AuthOverlayComponent
    extends JMBaseOverlayComponent
    implements OnInit
{
    @Select(UserState.user) user$: Observable<User>;

    showLogin = false;

    constructor(
        @Inject(JM_OVERLAY_DATA) public data: any,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super(data);

        this.showLogin = data.showLogin || false;
    }

    ngOnInit(): void {
        this.subscriptions.sink = this.user$.subscribe((user: User) => {
            if (user) {
                this.loggedIn(user);
            }
        });
    }

    loggedIn($event: any) {
        this.onClose.emit($event);
        this.close();
    }

    closeOverlay() {
        this.onClose.emit();
        this.close();
    }
}
