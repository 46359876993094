import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { JMBaseOverlayComponent } from '@jobzmall/overlays/overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';

@Component({
    selector: 'document-overlay',
    templateUrl: './document-overlay.component.html',
    styles: [
        `document-overlay { width: 100%; height: 100%;}`
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class DocumentOverlayComponent extends JMBaseOverlayComponent implements OnInit {

    constructor(
        @Inject(JM_OVERLAY_DATA) public data: any
    ) {
        super(data)

    }

    ngOnInit(): void {
        this.loading = true;
    }
}
