import {
    ClientConfigurationError,
    OIDC_DEFAULT_SCOPES,
    ScopeSet,
    StringUtils
} from '@azure/msal-common';
import { RequestValidator } from './RequestValidator';

export class RequestParamBuilder {
    private parameters: Map<string, string>;

    constructor() {
        this.parameters = new Map<string, string>();
    }

    /**
     * add response_type = code
     */
    addResponseType(responseType: string): void {
        this.parameters.set('response_type', encodeURIComponent(responseType));
    }

    /**
     * add scopes. set addOidcScopes to false to prevent default scopes in non-user scenarios
     * @param scopeSet
     * @param addOidcScopes
     */
    addScopes(scopes: string[], addOidcScopes: boolean = false): void {
        const requestScopes = addOidcScopes
            ? [...(scopes || []), ...OIDC_DEFAULT_SCOPES]
            : scopes || [];

        if (requestScopes.length > 0) {
            const scopeSet = new ScopeSet(requestScopes);
            this.parameters.set(
                'scope',
                encodeURIComponent(scopeSet.printScopes())
            );
        }
    }

    /**
     * add clientId
     * @param clientId
     */
    addClientId(clientId: string): void {
        this.parameters.set('client_id', encodeURIComponent(clientId));
    }

    /**
     * add redirect_uri
     * @param redirectUri
     */
    addRedirectUri(redirectUri: string): void {
        this.parameters.set('redirect_uri', encodeURIComponent(redirectUri));
    }

    /**
     * add prompt
     * @param prompt
     */
    addPrompt(prompt: string): void {
        this.parameters.set('prompt', encodeURIComponent(prompt));
    }

    /**
     * add state
     * @param state
     */
    addState(state: string): void {
        if (!StringUtils.isEmpty(state)) {
            this.parameters.set('state', encodeURIComponent(state));
        }
    }

    /**
     * add nonce
     * @param nonce
     */
    addNonce(nonce: string): void {
        this.parameters.set('nonce', encodeURIComponent(nonce));
    }

    /**
     * add code_challenge and code_challenge_method
     * - throw if either of them are not passed
     * @param codeChallenge
     * @param codeChallengeMethod
     */
    addCodeChallengeParams(
        codeChallenge: string,
        codeChallengeMethod: string
    ): void {
        RequestValidator.validateCodeChallengeParams(
            codeChallenge,
            codeChallengeMethod
        );
        if (codeChallenge && codeChallengeMethod) {
            this.parameters.set(
                'code_challenge',
                encodeURIComponent(codeChallenge)
            );
            this.parameters.set(
                'code_challenge_method',
                encodeURIComponent(codeChallengeMethod)
            );
        } else {
            throw ClientConfigurationError.createInvalidCodeChallengeParamsError();
        }
    }

    /**
     * add the `authorization_code` passed by the user to exchange for a token
     * @param code
     */
    addAuthorizationCode(code: string): void {
        this.parameters.set('code', encodeURIComponent(code));
    }

    /**
     * add the `refreshToken` passed by the user
     * @param refreshToken
     */
    addRefreshToken(refreshToken: string): void {
        this.parameters.set('refresh_token', encodeURIComponent(refreshToken));
    }

    /**
     * add the `code_verifier` passed by the user to exchange for a token
     * @param codeVerifier
     */
    addCodeVerifier(codeVerifier: string): void {
        this.parameters.set('code_verifier', encodeURIComponent(codeVerifier));
    }

    /**
     * add client_secret
     * @param clientSecret
     */
    addClientSecret(clientSecret: string): void {
        this.parameters.set('client_secret', encodeURIComponent(clientSecret));
    }

    /**
     * add pop_jwk to query params
     * @param cnfString
     */
    addPopToken(cnfString: string): void {
        if (!StringUtils.isEmpty(cnfString)) {
            this.parameters.set('token_type', 'pop');
            this.parameters.set('req_cnf', encodeURIComponent(cnfString));
        }
    }

    /**
     * Utility to create a URL from the params map
     */
    createQueryString(): string {
        const queryParameterArray: Array<string> = new Array<string>();

        this.parameters.forEach((value, key) => {
            queryParameterArray.push(`${key}=${value}`);
        });

        return queryParameterArray.join('&');
    }
}
