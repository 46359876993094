import { Inject, Injectable } from '@angular/core';
import { Restangular } from '@jobzmall/core/api';
import { environment } from 'environments/environment';
import { map, Observable, firstValueFrom, first, catchError, of } from 'rxjs';
import { Integration } from './integration.type';
import { OAuthRedirect } from './oauth/popup/oauth-redirect';
import { OAuthStateEncrypter } from './oauth/popup/oauth-validator';

@Injectable({ providedIn: 'root' })
export class IntegrationsService {
    constructor(private _api: Restangular) {}

    getIntegrations(): Observable<Array<Integration>> {
        return this._api
            .all('integration/me')
            .customGET()
            .pipe(map((res: any) => res.data));
    }

    startIntegration(id: string): Observable<string> {
        return this._api
            .all(`integration/${id}`)
            .customGET()
            .pipe(map((res: any) => res.data));
    }

    removeIntegration(id: string): Observable<Integration> {
        return this._api
            .all(`integration/${id}`)
            .customDELETE()
            .pipe(map((res: any) => res.data));
    }

    createOrUpdateIntegration(
        id: string,
        security_key: string,
        auth_code: string,
        code_verifier: string = undefined,
        type: 'popup' | 'redirect' = 'popup'
    ): Promise<Integration> {
        let options = Object.entries({
            security_key,
            auth_code,
            code_verifier,
            type
        }).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
        return firstValueFrom(
            this._api
                .all(`integration/${id}`)
                .customPOST(options)
                .pipe(
                    map((res: any) => res.data),
                    catchError((err: any) => of(false))
                )
        );
    }

    auth(id: string): Promise<Integration> {
        switch (id) {
            case 'google-calendar':
                return this._authGoogleCalendar();
            case 'outlook-calendar':
                return this._authOutlookCalendar();
            case 'zoom-video':
                return this._authZoomVideo();
            case 'deel-payroll':
                return this._authDeelPayroll();
        }
    }

    private _authDeelPayroll() {
        return new Promise<any>((resolve, reject) => {
            firstValueFrom(this.startIntegration('deel-payroll')).then(
                async (val: any) => {
                    if (val.token) {
                        const req = {
                            baseUrl: `${environment.deelOAuthUrl}/oauth2/authorize`,
                            clientId: environment.deelClientId,
                            redirectUri:
                                environment.baseBrowserUrl +
                                '/integrations/callback'
                        };
                        const redirect = new OAuthRedirect(req);
                        redirect.acquireAuthorizeCode({
                            ...req,
                            state: new OAuthStateEncrypter().encrypt(
                                `deel-payroll|${
                                    val.token
                                }|${document.location.pathname.replace(
                                    '/',
                                    '_'
                                )}`
                            ),
                            scopes: [
                                'contracts:read',
                                'contracts:write',
                                'organizations:read'
                            ],
                            redirectUri:
                                environment.baseBrowserUrl +
                                '/integrations/callback',
                            responseType: 'code'
                        });
                        resolve(true);
                    }
                    reject(false);
                }
            );
        });
    }

    private _authGoogleCalendar() {
        return new Promise<any>((resolve, reject) => {
            firstValueFrom(this.startIntegration('google-calendar')).then(
                async (val: any) => {
                    if (val.token) {
                        const client = google.accounts.oauth2.initCodeClient({
                            state: new OAuthStateEncrypter().encrypt(
                                `google-calendar|${
                                    val.token
                                }|${document.location.pathname.replace(
                                    '/',
                                    '_'
                                )}`
                            ),
                            redirect_uri:
                                environment.baseBrowserUrl +
                                '/integrations/callback',
                            client_id: environment.googleAppID,
                            scope: 'email profile https://www.googleapis.com/auth/calendar.readonly openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events',
                            ux_mode: 'redirect'
                        });
                        client.requestCode();
                    } else {
                        reject(false);
                    }
                }
            );
        });
    }

    private _authOutlookCalendar() {
        return new Promise<any>((resolve, reject) => {
            firstValueFrom(this.startIntegration('outlook-calendar')).then(
                async (val: any) => {
                    if (val.token) {
                        const req = {
                            baseUrl:
                                'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
                            clientId: environment.microsoftAppID,
                            redirectUri: environment.baseBrowserUrl,
                            scopes: [
                                'User.Read',
                                'Calendars.ReadWrite',
                                'Calendars.Read',
                                'Calendars.ReadWrite.Shared',
                                'openid',
                                'profile',
                                'offline_access',
                                'email'
                            ]
                        };

                        const redirect = new OAuthRedirect(req);
                        redirect.acquireAuthorizeCode({
                            ...req,
                            state: new OAuthStateEncrypter().encrypt(
                                `outlook-calendar|${
                                    val.token
                                }|${document.location.pathname.replace(
                                    '/',
                                    '_'
                                )}`
                            ),
                            redirectUri:
                                environment.baseBrowserUrl +
                                '/integrations/callback',
                            responseType: 'code'
                        });
                        resolve(true);
                    }
                    reject(false);
                }
            );
        });
    }

    private _authZoomVideo() {
        return new Promise<any>((resolve, reject) => {
            firstValueFrom(this.startIntegration('zoom-video')).then(
                async (val: any) => {
                    if (val.token) {
                        const req = {
                            baseUrl: 'https://zoom.us/oauth/authorize',
                            clientId: environment.zoomAppID,
                            redirectUri: environment.baseBrowserUrl
                        };
                        const redirect = new OAuthRedirect(req);
                        redirect.acquireAuthorizeCode({
                            ...req,
                            state: new OAuthStateEncrypter().encrypt(
                                `zoom-video|${
                                    val.token
                                }|${document.location.pathname.replace(
                                    '/',
                                    '_'
                                )}`
                            ),
                            redirectUri:
                                environment.baseBrowserUrl +
                                '/integrations/callback',
                            responseType: 'code'
                        });
                        resolve(true);
                    }
                    reject(false);
                }
            );
        });
    }
}
