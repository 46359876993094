 <div class="relative flex flex-col items-center justify-center w-full h-full overflow-y-auto sm:pt-8">
     <button class="absolute z-10 transition-all bg-white top-10 right-10 dark:bg-gray-700 dark:text-white"
         mat-icon-button (click)="close()">
         <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
     </button>
     <div *ngIf="step == 'fields'" @slideInRight
         class="flex flex-col items-center justify-center p-12 overflow-hidden bg-card rounded-xl">
         <phone-update-form></phone-update-form>
     </div>
     <div *ngIf="step == 'verify'" @slideInRight
         class="flex flex-col items-center justify-center p-12 overflow-hidden bg-card rounded-xl">
         <phone-verification-form (onPhoneUpdateClick)="step = 'fields'" [showLogo]="true" [showTitle]="true"
             (onSendFail)="onSendFail($event)" (onVerifySuccess)="onVerifySuccess()"
             [phoneCountryCode]="phoneCountryCode" [phone]="e164Number">
         </phone-verification-form>
     </div>
 </div>
