'use strict';
const { URL } = require('url');
import { urlRegex } from './url-regex';
import { normalizeUrl } from './normalize-url';

const getUrlsFromQueryParams = url => {
    const ret = new Set();
    const { searchParams } = (new URL(url.replace(/^(\/\/|(www\.))/, 'http://$2')));

    for (const [, value] of searchParams) {
        if (urlRegex({ exact: true }).test(value)) {
            ret.add(value);
        }
    }

    return ret;
};

export const getUrls = (text: string, options: any = {}) => {
    if (typeof options.exclude !== 'undefined' && !Array.isArray(options.exclude)) {
        throw new TypeError('The `exclude` option must be an array');
    }

    const ret = new Set();

    const add = url => {
        try {
            ret.add(normalizeUrl(url.trim().replace(/\.+$/, ''), options));
        } catch (_) { }
    };

    const urls = text.match(urlRegex()) || [];
    for (const url of urls) {
        add(url);

        if (options.extractFromQueryString) {
            const qsUrls = getUrlsFromQueryParams(url);
            for (const qsUrl of qsUrls) {
                add(qsUrl);
            }
        }
    }

    for (const excludedItem of options.exclude || []) {
        for (const item of ret) {
            const regex = new RegExp(excludedItem);
            if (regex.test(item as any)) {
                ret.delete(item);
                break;
            }
        }
    }

    return ret;
};
