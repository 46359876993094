export const environment = {
    production: false,
    hmr: false,
    webURL: 'https://dev.jobzmall.com',
    apiRoot: 'https://api-dev.jobzmall.com/api/',
    baseBrowserUrl: 'https://dev-app.jobzmall.com',
    baseServerUrl: 'https://dev-app.jobzmall.com',
    jobzAIUrl: 'https://jobz-ai-web.vercel.app',
    googleApiKey: 'AIzaSyADMbJXWUE0gcabonPPQF3qV_ZItnnXQdU',
    facebookAppID: '152313551938859',
    googleAppID:
        '783888142949-7n3df0sof0aagrt776b38q8mj3rj791q.apps.googleusercontent.com',
    microsoftAppID: 'dfab7048-26ed-4925-a1be-ab348ab10673',
    zoomAppID: 'bzcUr1TnTJysd5eiFtPmQ',
    linkedInAppID: '86lk7ojjou1k3r',
    segmentWriteKey: 'ubrSpLu7zkKTnMCQL8UBrFi6Pi5VB6Bo',
    stripeKey: 'pk_test_nhoKaczKQ3BhFeJ7tco5HgHS',
    segmentDebug: true,
    intercomAppID: 'w44t9wxw',
    env: 'dev',
    botDetectApiKey: 'ymKakSqOTiCzCx2a101plTnZ',
    datadogService: 'jobzmall-universal-dev',
    datadogApplicationId: 'b39ad45f-527d-4a35-9c0b-fed09719936d',
    datadogClientToken: 'pub09c7efaaa188f45f56db35062491814e',
    deelClientId: 'b78c852d-de40-49ca-97cd-4fbdfa0edc6b',
    deelOAuthUrl: 'https://auth-demo.letsdeel.com',
    deelUrl: 'https://demo.letsdeel.com'
};
// TODO LAUNCH: CHANGE THESE
