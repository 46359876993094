<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto md:w-160 md:min-w-160 max-h-160 -m-6">
        <!-- Header -->
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
            <div class="text-lg font-medium">Report {{type.toTitleCase()}}</div>
            <button mat-icon-button (click)="dialogRef.close()" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
        <div class="flex flex-col p-6">
            <span class="mb-4 text-lg">
                Please tell us why you want to report this {{type}}
            </span>
             <mat-radio-group class="space-y-4 flex flex-col" [(ngModel)]="reason">
                 <mat-radio-button color="primary" class="my-1" value="I think it's inappropriate for JobzMall">
                     I think it's inappropriate for JobzMall
                 </mat-radio-button>
                 <mat-radio-button color="primary" class="my-1" value="I think it's fake, spam or a scam">
                     I think it's fake, spam or a scam
                 </mat-radio-button>
                 <mat-radio-button color="primary" class="my-1" value="I think this account may have been hacked">
                     I think this account may have been hacked
                 </mat-radio-button>
                 <mat-radio-button color="primary" class="my-1" value="I think something else">
                     I think something else
                 </mat-radio-button>
             </mat-radio-group>
        </div>
        <div *ngIf="reason == 'I think something else'" class="flex-col flex px-6 pb-6">
            <form class="flex-col flex" name="form" #reasonForm="ngForm"
                (ngSubmit)="customReason(reasonForm)">
                <mat-form-field class="w-full">
                    <mat-label>Other Reason</mat-label>
                    <input type="text" matInput name="reason" ngModel #reason="ngModel" required>
                </mat-form-field>
                <div *ngIf="reason" class="flex w-full flex-row items-center justify-end">
                    <div class="col-span-2 flex items-center justify-end mt-4">
                        <button (click)="dialogRef.close()" mat-flat-button>
                            {{t('PUBLIC.SHARED.ACTION.CANCEL')}}
                        </button>
                        <button [disabled]="reasonForm.invalid" class="ml-4" mat-flat-button type="submit"
                            [color]="'primary'">
                            <span *ngIf="!loading">{{t('PUBLIC.SHARED.ACTION.SUBMIT')}}</span>
                            <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
                            </mat-progress-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="reason !== 'I think something else'"
            class="flex w-full flex-row items-center justify-end px-6 pb-6">
            <div class="col-span-2 flex items-center justify-end mt-4">
                <button (click)="dialogRef.close()" mat-flat-button>
                    {{t('PUBLIC.SHARED.ACTION.CANCEL')}}
                </button>
                <button (click)="submit(null)" [disabled]="!reason" class="ml-4" mat-flat-button type="submit" [color]="'primary'">
                    <span *ngIf="!loading">{{t('PUBLIC.SHARED.ACTION.SUBMIT')}}</span>
                    <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </button>
            </div>
        </div>
    </div>
</ng-container>
