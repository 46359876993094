<!-- Messages toggle -->
<!-- <button *ngIf="!( minimizeWidgetCompletely$ | async)" mat-icon-button [routerLink]="['/messages']" #messagesOrigin>
    <ng-container *ngIf="(unreadCount$ | async) > 0">
        <span class="absolute top-0 left-0 right-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                {{(unreadCount$ | async)}}
            </span>
        </span>
    </ng-container>
    <mat-icon
        [svgIcon]="router.url.indexOf('/messages') > -1 ? 'mimicons_solid:messages' : 'mimicons_outline:messages'">
    </mat-icon>
</button> -->
<button *ngIf="!isScreenSmall" class="flex text-current"
    (click)="maximized ? minimizeMessagesWidget() : maximizeMessagesWidget()" mat-icon-button #messagesOrigin>
    <ng-container *ngIf="(unreadCount$ | async) > 0">
        <span class="absolute top-0 left-0 right-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full
                bg-purple-600 text-purple-50 text-xs font-medium">
                {{(unreadCount$ | async)}}
            </span>
        </span>
    </ng-container>
    <mat-icon class="text-current"
        [svgIcon]="router.url.indexOf('/messages') > -1 ? 'mimicons_solid:messages' : 'mimicons_outline:messages'">
    </mat-icon>
</button>
<button *ngIf="isScreenSmall" class="flex text-current" [routerLink]="['/messages']" mat-icon-button>
    <ng-container *ngIf="(unreadCount$ | async) > 0">
        <span class="absolute top-0 left-0 right-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full
                bg-purple-600 text-purple-50 text-xs font-medium">
                {{(unreadCount$ | async)}}
            </span>
        </span>
    </ng-container>
    <mat-icon class="text-current"
        [svgIcon]="router.url.indexOf('/messages') > -1 ? 'mimicons_solid:messages' : 'mimicons_outline:messages'">
    </mat-icon>
</button>
