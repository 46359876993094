<!-- Item wrapper -->
<div class="jbz-vertical-navigation-item-wrapper group"
    [class.jbz-vertical-navigation-item-has-subtitle]="!!item.subtitle" [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <a class="jbz-vertical-navigation-item" *ngIf="item.link && !item.externalLink && !item.function && !item.disabled"
        matTooltipPosition="after" matTooltipClass="navbar-tooltip" [matTooltip]="item.tooltip || ''" [ngClass]="{'jbz-vertical-navigation-item-active':
         rla.isActive,
        'jbz-vertical-navigation-item-active-forced': item.active}" [routerLink]="[item.link]" #rla="routerLinkActive"
        routerLinkActive [routerLinkActiveOptions]="isActiveMatchOptions">
        <ng-container *ngTemplateOutlet="itemTemplate;">
        </ng-container>
    </a>

    <!-- Item with an external link -->
    <a class="jbz-vertical-navigation-item" *ngIf="item.link && item.externalLink && !item.function && !item.disabled"
        [href]="item.link" matTooltipClass="navbar-tooltip" matTooltipPosition="after" [target]="item.target"
        [matTooltip]="item.tooltip ||
        ''">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a function -->
    <div class="jbz-vertical-navigation-item" *ngIf="!item.link && item.function && !item.disabled"
        matTooltipClass="navbar-tooltip" matTooltipPosition="after" [matTooltip]="item.tooltip || ''"
        [ngClass]="{'jbz-vertical-navigation-item-active-forced': item.active}" (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate">
        </ng-container>
    </div>

    <!-- Item with an internal link and function -->
    <a class="jbz-vertical-navigation-item" *ngIf="item.link && !item.externalLink && item.function && !item.disabled"
        matTooltipClass="navbar-tooltip" matTooltipPosition="after" [matTooltip]="item.tooltip || ''" [ngClass]="{'jbz-vertical-navigation-item-active':
         rla.isActive,
        'jbz-vertical-navigation-item-active-forced': item.active}" [routerLink]="[item.link]" #rla="routerLinkActive"
        routerLinkActive [routerLinkActiveOptions]="isActiveMatchOptions" (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate">
        </ng-container>
    </a>

    <!-- Item with an external link and function -->
    <a class="jbz-vertical-navigation-item" *ngIf="item.link && item.externalLink && item.function && !item.disabled"
        matTooltipClass="navbar-tooltip" matTooltipPosition="after" [target]="item.target" [href]="item.link"
        (click)="item.function(item)"
        [matTooltip]="item.tooltip || ''">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a no link and no function -->
    <div class="jbz-vertical-navigation-item" *ngIf="!item.link && !item.function && !item.disabled"
        matTooltipClass="navbar-tooltip" matTooltipPosition="after" [matTooltip]="item.tooltip || ''"
        [ngClass]="{'jbz-vertical-navigation-item-active-forced': item.active}">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item is disabled -->
    <div class="jbz-vertical-navigation-item jbz-vertical-navigation-item-disabled" *ngIf="item.disabled">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

</div>

<!-- Item template -->
<ng-template #itemTemplate>
    <ng-container *transloco="let t">
        <!-- Icon -->
        <mat-icon class="jbz-vertical-navigation-item-icon" [ngClass]="item.classes?.icon"
            *ngIf="(item.icon || item.classes?.icon)" [svgIcon]="item.icon"></mat-icon>

        <!-- Image -->
        <div style="width: 24px; height: 24px;" *ngIf="item.image || item.showImage"
            class="relative flex overflow-hidden text-gray-600 bg-gray-200 rounded-full jbz-vertical-navigation-item-image flex-0 dark:bg-gray-700 dark:text-gray-200">
            <ng-container *ngIf="item.image">
                <img class="object-scale-down w-full h-full rounded-full" [src]="item.image.full_path"
                    [alt]="item.title">
            </ng-container>
            <ng-container *ngIf="!item.image">
                <div
                    class="flex items-center justify-center w-full h-full overflow-hidden text-xs font-semibold text-center text-gray-600 uppercase bg-gray-200 rounded-full group-hover:bg-gray-400 dark:group-hover:bg-gray-900 dark:bg-gray-700 dark:text-gray-200">
                    {{item.title.charAt(0)}}
                </div>
            </ng-container>
        </div>

        <!-- Title & Subtitle -->
        <div class="jbz-vertical-navigation-item-title-wrapper">
            <div class="jbz-vertical-navigation-item-title">
                <span [ngClass]="item.classes?.title">
                    {{t(item.title)}}
                </span>
            </div>
            <div class="jbz-vertical-navigation-item-subtitle" *ngIf="item.subtitle">
                <span [ngClass]="item.classes?.subtitle">
                    {{t(item.subtitle)}}
                </span>
            </div>
        </div>

        <!-- Badge -->
        <div class="jbz-vertical-navigation-item-badge" *ngIf="item.badge">
            <div class="jbz-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes">
                {{item.badge.title}}
            </div>
        </div>
    </ng-container>
</ng-template>
