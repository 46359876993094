import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

export interface IMedia {
    id?: number;
    type?: string;
    recorded?: boolean;
    is_static?: boolean;
    available?: boolean;
    full_path?: string;
    src?: string;
    created_at?: Date;
    updated_at?: Date;
    thumbnail?: IMedia;
    generated_thumbnail?: string;
    watermarked_path?: string;
    watermarked?: boolean;
    owner?: any;
    title?: string;
    description?: string;
    external?: boolean;
    file?: any;
}

export interface UnsplashImage {
    color: string;
    description: string;
    blur_hash: string;
    height: number;
    width: number;
    loading: boolean;
    links: {
        download: string;
        download_location: string;
        html: string;
        self: string;
    };
    urls: {
        full: string;
        raw: string;
        regular: string;
        small: string;
        thumb: string;
    };
}

export class Media implements IMedia {
    id?: number;
    type?: string;
    recorded?: boolean;
    is_static?: boolean;
    available?: boolean;
    full_path?: string;
    src?: string;
    created_at?: Date;
    updated_at?: Date;
    thumbnail?: IMedia;
    generated_thumbnail?: string;
    watermarked_path?: string;
    watermarked?: boolean;
    owner?: any;
    title?: string;
    description?: string;
    external?: boolean;
    external_type?: string;
    explicit_label?: string;
    cm_job_status?: string;
    animated_gif?: string;
    file?: any;
    original_src?: string;
    original_extension?: string;
    extension?: string;
    temp_path?: string;

    constructor(
        id?: number,
        type?: string,
        recorded?: boolean,
        is_static?: boolean,
        available?: boolean,
        full_path?: string,
        src?: string,
        created_at?: Date,
        updated_at?: Date,
        thumbnail?: Media,
        generated_thumbnail?: string,
        watermarked_path?: string,
        watermarked?: boolean,
        owner?: any,
        title?: string,
        description?: string,
        external?: boolean,
        external_type?: string,
        file?: any,
        explicit_label?: string,
        cm_job_status?: string,
        animated_gif?: string,
        extension?: string,
        original_src?: string,
        original_extension?: string,
        temp_path?: string
    ) {
        this.id = id;
        this.type = type;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.recorded = recorded;
        this.is_static = is_static;
        this.available = available;
        this.full_path = full_path;
        this.src = src;
        this.thumbnail = thumbnail;
        this.generated_thumbnail = generated_thumbnail;
        this.watermarked_path = watermarked_path;
        this.watermarked = watermarked;
        this.owner = owner;
        this.title = title;
        this.description = description;
        this.external = external;
        this.external_type = external_type;
        this.file = file;
        this.explicit_label = explicit_label;
        this.cm_job_status = cm_job_status;
        this.animated_gif = animated_gif;
        this.extension = extension;
        this.original_extension = original_extension;
        this.original_src = original_src;
        this.temp_path = temp_path;
    }
}

@Injectable({
    providedIn: 'root'
})
export class MediaAdapter implements Adapter<Media> {
    adapt(item: any): Media {
        if (!item) {
            return null;
        }
        return new Media(
            item.id,
            item.type,
            item.recorded,
            item.static,
            item.available,
            item.full_path,
            item.src,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.thumbnail ? this.adapt(item.thumbnail) : null,
            item.generated_thumbnail,
            item.watermarked_path,
            item.watermarked,
            item.mediable,
            item.title,
            item.description,
            item.external,
            item.external_type,
            item.file,
            item.explicit_label,
            item.cm_job_status,
            item.animated_gif,
            item.extension,
            item.original_src,
            item.original_extension,
            item.temp_path
        );
    }
}
