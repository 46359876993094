import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
    selector: 'mobile-bottom-sheet',
    templateUrl: './mobile-bottom-sheet.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class MobileBottomSheetComponent
    extends JMBaseComponent
    implements OnInit
{
    constructor(
        public bottomSheetRef: MatBottomSheetRef<MobileBottomSheetComponent>,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {}
}
