/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'PUBLIC.NAVIGATION_ITEM.HOME.TITLE',
        tooltip: 'Home',
        type: 'basic',
        link: '/home',
        icon: 'mimicons_outline:home',
        children: []
    },
    {
        id: 'mall-unauthenticated',
        title: 'PUBLIC.NAVIGATION_ITEM.MALL.TITLE',
        tooltip: 'Mall',
        type: 'basic',
        link: '/mall',
        icon: 'mimicons_outline:jobz',
        children: []
    },
    {
        id: 'jobs',
        title: 'PUBLIC.NAVIGATION_ITEM.JOBS.TITLE',
        tooltip: 'Jobs',
        type: 'basic',
        link: '/jobs',
        icon: 'mimicons_outline:case',
        children: []
    },
    {
        id: 'videoresume',
        title: 'PUBLIC.NAVIGATION_ITEM.VIDEO_RESUME_STUDIO.TITLE',
        tooltip: 'Video Resume Studio',
        type: 'basic',
        link: '/video-resume-studio/questionnaire',
        icon: 'mimicons_outline:video',
        children: []
    },
    {
        id: 'meetings',
        title: 'PUBLIC.NAVIGATION_ITEM.CALENDAR.TITLE',
        tooltip: 'Calendar',
        type: 'basic',
        icon: 'mimicons_outline:calendar',
        link: '/calendar'
    },
    {
        id: 'genius',
        title: 'PUBLIC.NAVIGATION_ITEM.GENIUS.TITLE',
        tooltip: 'Genius',
        type: 'basic',
        link: '/genius',
        icon: 'mimicons_outline:route',
        children: []
    },
    {
        id: 'academy',
        title: 'PUBLIC.NAVIGATION_ITEM.ACADEMY.TITLE',
        tooltip: 'Academy',
        type: 'basic',
        link: '/academy',
        icon: 'heroicons_outline:academic-cap',
        children: []
    },
    {
        id: 'resumes',
        title: 'My Resumes',
        icon: 'mimicons_outline:sticker',
        tooltip: 'My Resumes',
        type: 'basic',
        link: '/my-resumes'
    },
    {
        id: 'boards',
        title: 'Job Boards',
        tooltip: 'Job Boards',
        type: 'basic',
        link: '/boards',
        icon: 'mimicons_outline:copy',
        children: []
    },
    {
        id: 'more',
        title: 'PUBLIC.NAVIGATION_ITEM.MORE.TITLE',
        tooltip: 'More',
        type: 'aside',
        icon: 'mimicons_outline:grid-prominent',
        children: [
            {
                id: 'more.my-jobs',
                title: 'My Jobs',
                type: 'collapsable',
                icon: 'mimicons_outline:heart',
                children: [
                    {
                        id: 'more.my-jobs.applications',
                        title: 'My Applications',
                        type: 'basic',
                        link: '/jobs/applications'
                    },
                    {
                        id: 'more.my-jobs.saved',
                        title: 'Saved Jobs',
                        type: 'basic',
                        link: '/jobs/saved'
                    }
                ]
            },
            {
                id: 'more.history',
                title: 'History',
                icon: 'mimicons_outline:undo',
                type: 'basic',
                link: '/history'
            },
            {
                id: 'more.genome-guest',
                title: 'Genome',
                icon: 'mimicons_outline:crop',
                type: 'basic',
                link: '/genome'
            },
            {
                id: 'more.genome-authenticated',
                title: 'Genome',
                icon: 'mimicons_outline:crop',
                type: 'basic',
                link: '/genome/questionnaire/1'
            },
            {
                id: 'more.videoresume',
                title: 'PUBLIC.NAVIGATION_ITEM.VIDEO_RESUME_STUDIO.TITLE',
                type: 'basic',
                link: '/video-resume-studio/questionnaire',
                icon: 'mimicons_outline:video',
                children: []
            },
            {
                id: 'more.organizations',
                title: 'PUBLIC.NAVIGATION_ITEM.ORGANIZATIONS.TITLE',
                type: 'basic',
                link: '/organizations',
                icon: 'mimicons_outline:cards',
                children: []
            },
            {
                id: 'more.talent-solutions',
                title: 'For Employers',
                icon: 'mimicons_outline:cup',
                type: 'basic',
                externalLink: true,
                link: environment.webURL + '/hire'
            },
            {
                id: 'more.coaching-solutions',
                title: 'For Career Coaches',
                icon: 'mimicons_outline:star',
                type: 'basic',
                externalLink: true,
                link: environment.webURL + '/coach'
            },
            {
                id: 'more.settings',
                title: 'Settings',
                icon: 'mimicons_outline:options',
                type: 'basic',
                link: '/user/settings'
            },
            {
                id: 'more.help-center',
                title: 'Help Center',
                icon: 'mimicons_outline:book',
                type: 'basic',
                link: '/help'
            }
        ]
    }
];
