import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({ name: 'dayJsLocale' })
export class LocalePipe implements PipeTransform {
    constructor() {}
    transform(
        value: string | number | Date | dayjs.Dayjs,
        locale: string
    ): dayjs.Dayjs {
        return dayjs(value).locale(locale);
    }
}
