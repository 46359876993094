  <ng-container *transloco="let t">
      <div (mouseleave)="onClosePanel.emit()"
          class="w-screen max-w-md overflow-hidden rounded-lg shadow-lg header-panel lg:max-w-2xl lg:ml-0 ring-1 ring-black ring-opacity-5">
          <div class="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8 lg:grid-cols-2">
              <a (click)="onClosePanel.emit()" [routerLink]="['/jobs']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <mat-icon class="text-current" [svgIcon]="'mimicons_outline:case'"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.FIND_JOB.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.FIND_JOB.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/video-resume-studio/questionnaire']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <mat-icon class="text-current" [svgIcon]="'mimicons_outline:video'"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.RECORD_VIDEO_RESUME.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.RECORD_VIDEO_RESUME.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/genius']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <mat-icon class="text-current" [svgIcon]="'mimicons_outline:route'"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.GENIUS.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.GENIUS.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/signup']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <mat-icon class="text-current fal fa-chart-network"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.PEERS.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.PEERS.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/academy']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <mat-icon class="text-current" [svgIcon]="'heroicons_outline:academic-cap'"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.ACADEMY.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.ACADEMY.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/genome']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <mat-icon class="text-current far fa-dna"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.CAREER_GENOME.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.CAREER_GENOME.DESCRIPTION')}}
                      </p>
                  </div>
              </a>
          </div>
          <div class="px-5 py-5 space-y-6 bg-violet-100 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
              <div class="flow-root">
                  <a (click)="onClosePanel.emit()" [routerLink]="['/video-resume-studio/questionnaire']"
                      class="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-violet-200">
                       <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-violet-500"
                           fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                           <path stroke-linecap="round" stroke-linejoin="round"
                               d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                       </svg>
                      <span class="ml-3">{{t('PUBLIC.HEADER.PANEL.CAREER_SEEKERS.ITEMS.BUILD_VIDEO_RESUME')}}</span>
                  </a>
              </div>
          </div>
      </div>
  </ng-container>
