import { Route, UrlSegment } from '@angular/router';
import { AuthGuard } from '@jobzmall/core/auth/guards/auth.guard';
import { NoAuthGuard } from '@jobzmall/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { MetaGuard } from '@jobzmall/meta';
import { AvaChatModule } from './modules/apps/ava-chat/ava-chat.module';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },
    { path: 'hire', pathMatch: 'full', redirectTo: 'post-job' },

    {
        path: '',
        component: LayoutComponent,
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard, MetaGuard],
        data: {
            showBottomNavigation: true,
            showNavigation: false,
            showHeader: true,
            showFooter: true,
            showFooterMall: false
        },
        resolve: {
            initialData: InitialDataResolver
        },
        children: [
            // Main Landing
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/login'
            }
        ]
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard, MetaGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule)
            },
            {
                path: 'login',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    )
            },
            {
                path: 'signup',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    )
            }
        ]
    },

    // Main Routes
    {
        path: '',
        component: LayoutComponent,
        canActivateChild: [MetaGuard],
        data: {
            showBottomNavigation: true,
            showHeader: true,
            showNavigation: true,
            showFooter: true,
            showFooterMall: false,
            breadcrumb: {
                skip: true
            }
        },
        resolve: {
            initialData: InitialDataResolver
        },
        children: [
            // Authenticated routes
            {
                path: '',
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: {
                    breadcrumb: {
                        skip: true
                    }
                },
                children: [
                    // {
                    //     data: {
                    //         layout: 'empty'
                    //     },
                    //     path: 'linear-onboard',
                    //     loadChildren: () =>
                    //         import(
                    //             'app/modules/pages/linear-onboard/linear-onboard.module'
                    //         ).then((m) => m.LinearOnboardModule)
                    // },
                    {
                        path: 'home',
                        loadChildren: () =>
                            import(
                                'app/modules/dashboards/home/home.module'
                            ).then((m) => m.HomeModule)
                    },
                    {
                        path: 'calendar',
                        loadChildren: () =>
                            import(
                                'app/modules/apps/meetings/meetings.module'
                            ).then((m) => m.MeetingsModule)
                    },

                    {
                        path: 'identity-verification',
                        data: { layout: 'empty' },
                        loadChildren: () =>
                            import(
                                'app/modules/pages/identity-verification/identity-verification.module'
                            ).then((m) => m.IdentityVerificationModule)
                    },

                    {
                        path: 'messages',
                        data: { showFooter: false },
                        loadChildren: () =>
                            import('@jobzmall/chat/chat.module').then(
                                (m) => m.ChatModule
                            )
                    },
                    {
                        path: 'history',
                        data: { showFooter: false },
                        loadChildren: () =>
                            import(
                                'app/modules/pages/history/history.module'
                            ).then((m) => m.HistoryModule)
                    },
                    // {
                    //     path: 'recruiter/dashboard',
                    //     loadChildren: () =>
                    //         import(
                    //             'app/modules/dashboards/recruiter/recruiter-dashboard.module'
                    //         ).then((m) => m.RecruiterDashboardModule)
                    // },

                    {
                        path: 'my-resumes',
                        data: { showFooter: false },
                        loadChildren: () =>
                            import(
                                'app/modules/apps/resume-manager/resumes.module'
                            ).then((m) => m.ResumesModule)
                    },

                    // {
                    //     path: 'connections',
                    //     data: { showFooter: false },
                    //     loadChildren: () =>
                    //         import(
                    //             'app/modules/pages/connections/connections.module'
                    //         ).then((m) => m.ConnectionsModule)
                    // },

                    {
                        path: 'deactivated',
                        data: { showFooter: false },
                        loadChildren: () =>
                            import(
                                'app/modules/auth/deactivated/deactivated.module'
                            ).then((m) => m.DeactivatedModule)
                    },
                    {
                        path: 'deactivate',
                        data: { showFooter: false },
                        loadChildren: () =>
                            import(
                                'app/modules/auth/deactivate/deactivate.module'
                            ).then((m) => m.DeactivateModule)
                    },
                    {
                        path: 'integrations',
                        data: { showFooter: false },
                        loadChildren: () =>
                            import(
                                'app/modules/pages/integrations/integrations.module'
                            ).then((m) => m.IntegrationsModule)
                    },
                    {
                        path: 'user',
                        children: [
                            {
                                path: 'hired',
                                data: { showFooter: false },
                                loadChildren: () =>
                                    import(
                                        'app/modules/pages/hired/hired.module'
                                    ).then((m) => m.HiredModule)
                            },
                            {
                                path: 'settings',
                                data: { showFooter: false },
                                loadChildren: () =>
                                    import(
                                        'app/modules/settings/user/user-settings.module'
                                    ).then((m) => m.UserSettingsModule)
                            }
                        ]
                    }
                ]
            },
            // Empty Layout auth routes for authenticated users
            {
                path: '',
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: {
                    layout: 'empty'
                },
                children: [
                    {
                        path: 'email',
                        children: [
                            {
                                path: 'change',
                                loadChildren: () =>
                                    import(
                                        'app/modules/email/change-email/change-email.module'
                                    ).then((m) => m.ChangeEmailModule)
                            }
                        ]
                    },
                    {
                        path: 'phone',
                        children: [
                            {
                                path: 'update',
                                loadChildren: () =>
                                    import(
                                        'app/modules/phone/update/phone-update.module'
                                    ).then((m) => m.PhoneUpdateModule)
                            },
                            {
                                path: 'verify',
                                loadChildren: () =>
                                    import(
                                        'app/modules/phone/verify/phone-verify.module'
                                    ).then((m) => m.PhoneVerifyModule)
                            }
                        ]
                    },
                    {
                        path: 'onboard',
                        loadChildren: () =>
                            import(
                                'app/modules/pages/onboarding/onboarding.module'
                            ).then((m) => m.OnboardingModule)
                    },

                    {
                        path: 'confirm-account',
                        loadChildren: () =>
                            import(
                                'app/modules/auth/confirm-account/confirm-account.module'
                            ).then((m) => m.AuthConfirmAccountModule)
                    },
                    {
                        path: 'sign-out',
                        loadChildren: () =>
                            import(
                                'app/modules/auth/sign-out/sign-out.module'
                            ).then((m) => m.AuthSignOutModule)
                    }
                ]
            },

            {
                data: {
                    layout: 'empty'
                },
                path: 'suspended',
                loadChildren: () =>
                    import('app/modules/auth/suspended/suspended.module').then(
                        (m) => m.SuspendedModule
                    )
            },

            //References
            {
                path: 'reference',
                loadChildren: () =>
                    import(
                        'app/modules/pages/references/references.module'
                    ).then((m) => m.ReferencesModule)
            },

            {
                path: 'reset-password',
                data: {
                    layout: 'empty'
                },
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule)
            },

            {
                path: 'media',
                loadChildren: () =>
                    import('app/modules/pages/media/media.module').then(
                        (m) => m.MediaModule
                    )
            },

            {
                path: 'legal',
                loadChildren: () =>
                    import('app/modules/pages/legal/legal.module').then(
                        (m) => m.LegalModule
                    )
            },

            {
                path: 'boards',
                loadChildren: () =>
                    import('app/modules/apps/boards/boards.module').then(
                        (m) => m.BoardsModule
                    )
            },

            {
                path: 'mall',
                loadChildren: () =>
                    import('app/modules/apps/mall/mall.module').then(
                        (m) => m.MallModule
                    )
            },
            {
                path: 'coach',
                children: [
                    {
                        path: 'signup',
                        data: {
                            layout: 'empty'
                        },
                        canActivate: [NoAuthGuard],
                        canActivateChild: [NoAuthGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/apps/coach/sign-up/sign-up.module'
                            ).then((m) => m.CoachSignUpModule)
                    },
                    {
                        path: 'services',
                        data: {
                            layout: 'empty'
                        },
                        loadChildren: () =>
                            import(
                                'app/modules/apps/coach/checkout/coach-checkout.module'
                            ).then((m) => m.CoachCheckoutModule)
                    },
                    {
                        path: 'dashboard',
                        canActivate: [AuthGuard],
                        canActivateChild: [AuthGuard],
                        data: {
                            layout: 'empty'
                        },
                        loadChildren: () =>
                            import(
                                'app/modules/dashboards/coach/coach-dashboard.module'
                            ).then((m) => m.CoachDashboardModule)
                    }
                ]
            },

            {
                path: 'post-job',
                data: {
                    layout: 'empty'
                },
                loadChildren: () =>
                    import(
                        'app/modules/apps/job-studio/create/job-studio-create.module'
                    ).then((m) => m.JobStudioCreateModule)
            },

            {
                path: 'invitation',
                loadChildren: () =>
                    import(
                        'app/modules/pages/invitation/invitation.module'
                    ).then((m) => m.InvitationModule)
            },
            {
                path: 'verify-ownership',
                loadChildren: () =>
                    import(
                        'app/modules/pages/verify-ownership/verify-ownership.module'
                    ).then((m) => m.VerifyOwnershipModule)
            },

            {
                path: 'rooms',
                loadChildren: () =>
                    import('@jobzmall/rooms/rooms.module').then(
                        (m) => m.JMRoomsModule
                    )
            },

            // Open Store
            {
                path: 'open-store',
                loadChildren: () =>
                    import(
                        'app/modules/pages/open-store/open-store.module'
                    ).then((m) => m.OpenStoreModule)
            },

            {
                path: 'video/answer',
                data: { showFooter: false },
                loadChildren: () =>
                    import(
                        'app/modules/apps/cinema/video-answer/video-answer.module'
                    ).then((m) => m.VideoAnswerModule)
            },

            // Genome
            {
                path: 'genome',
                loadChildren: () =>
                    import('app/modules/apps/genome/genome.module').then(
                        (m) => m.GenomeModule
                    )
            },

            {
                path: 'help',
                loadChildren: () =>
                    import('app/modules/help-center/help-center.module').then(
                        (m) => m.HelpCenterModule
                    )
            },

            // {
            //     path: 'ava',
            //     loadChildren: () =>
            //         import('app/modules/apps/ava-chat/ava-chat.module').then(
            //             (m) => m.AvaChatModule
            //         )
            // },

            // {
            //     path: 'resume-writing',
            //     data: {
            //         slug: 'resume-writing'
            //     },
            //     loadChildren: () =>
            //         import(
            //             'app/modules/apps/genius/checkout/genius-checkout.module'
            //         ).then((m) => m.GeniusCheckoutModule)
            // },
            // {
            //     path: 'cover-letter-writing',
            //     data: {
            //         slug: 'cover-letter-writing'
            //     },
            //     loadChildren: () =>
            //         import(
            //             'app/modules/apps/genius/checkout/genius-checkout.module'
            //         ).then((m) => m.GeniusCheckoutModule)
            // },

            // Genius
            {
                path: 'genius',
                loadChildren: () =>
                    import('app/modules/apps/genius/genius.module').then(
                        (m) => m.GeniusModule
                    )
            },

            { path: 'profile', redirectTo: 'not-found', pathMatch: 'full' },

            // Profile
            {
                path: 'profile/:slug',
                loadChildren: () =>
                    import('app/modules/pages/profile/profile.module').then(
                        (m) => m.ProfileModule
                    )
            },

            // Interviews
            {
                path: 'interviews',
                loadChildren: () =>
                    import('app/modules/apps/interview/interview.module').then(
                        (m) => m.InterviewModule
                    )
            },

            {
                path: 'prescreening',
                loadChildren: () =>
                    import(
                        'app/modules/apps/interview/pre-interview/pre-interview.module'
                    ).then((m) => m.PreInterviewModule)
            },
            {
                path: 'search',
                loadChildren: () =>
                    import('app/modules/apps/search/search.module').then(
                        (m) => m.SearchModule
                    )
            },
            {
                path: 'lists',
                loadChildren: () =>
                    import('app/modules/pages/lists/lists.module').then(
                        (m) => m.ListsModule
                    )
            },

            {
                path: 'video-resume-studio',
                loadChildren: () =>
                    import(
                        'app/modules/apps/video-resume-studio/video-resume-studio.module'
                    ).then((m) => m.VideoResumeStudioModule)
            },

            // Academy
            {
                path: 'academy',
                loadChildren: () =>
                    import('app/modules/apps/academy/academy.module').then(
                        (m) => m.AcademyModule
                    )
            },

            // Jobs
            {
                path: 'jobs',
                loadChildren: () =>
                    import('app/modules/pages/jobs/jobs.module').then(
                        (m) => m.JobsModule
                    )
            },

            // Organizations
            {
                path: 'organizations',
                loadChildren: () =>
                    import(
                        'app/modules/pages/organizations/organizations.module'
                    ).then((m) => m.OrganizationsModule)
            },

            // 404
            {
                path: 'not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/pages/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module)
            },

            // Profile Tag (put this last so we can catch :slug)

            {
                matcher: (url) => {
                    if (url.length >= 1 && url[0].path.match(/^@[\w-]+$/gm)) {
                        return {
                            consumed: [
                                new UrlSegment(url[0].path, url[0].parameters)
                            ],
                            posParams: {
                                slug: new UrlSegment(url[0].path.substr(1), {})
                            }
                        };
                    }
                    return null;
                },
                loadChildren: () =>
                    import('app/modules/pages/profile/profile.module').then(
                        (m) => m.ProfileModule
                    )
            },

            // Organizations (put this last so we can catch :slug)
            {
                path: ':slug',
                children: [
                    {
                        path: 'welcome',
                        loadChildren: () =>
                            import(
                                'app/modules/pages/organization/welcome/organization-welcome.module'
                            ).then((m) => m.OrganizationWelcomeModule)
                    },
                    { path: 'manage', redirectTo: 'dashboard' },
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/pages/organization/organization.module'
                            ).then((m) => m.OrganizationModule)
                    },
                    {
                        path: 'dashboard',
                        data: { showFooter: false },
                        loadChildren: () =>
                            import(
                                'app/modules/dashboards/organization/organization-dashboard.module'
                            ).then((m) => m.OrganizationDashboardModule)
                    },
                    {
                        path: 'interviews',
                        data: { layout: 'empty' },
                        loadChildren: () =>
                            import(
                                'app/modules/dashboards/organization/interviews/async/async-interview.module'
                            ).then((m) => m.DashboardAsyncInterviewModule)
                    },
                    {
                        path: 'job/:jobSlug',
                        loadChildren: () =>
                            import('app/modules/pages/job/job.module').then(
                                (m) => m.JobModule
                            )
                    }
                ]
            }
        ]
    },

    // Catch all
    { path: '**', redirectTo: 'not-found' }
];
