import { MatDialogRef } from '@angular/material/dialog';

export function makeLongDialogOverlay(
    dialogRef: MatDialogRef<any>,
    closeable = false
) {
    dialogRef['_ref']['overlayRef']['_pane'].className += ' long';
    dialogRef['_ref']['overlayRef']['_host'].className += ' long';
    dialogRef['_ref']['overlayRef']['_backdropElement'].className += ' long';
    if (closeable) {
        dialogRef['_ref']['overlayRef']['_pane'].className += ' closeable';
        dialogRef['_ref']['overlayRef']['_host'].className += ' closeable';
        dialogRef['_ref']['overlayRef']['_backdropElement'].className += ' closeable';
    }
}

export function makeFullscreenDialogOverlay(dialogRef: MatDialogRef<any>) {
    dialogRef['_ref']['overlayRef']['_pane'].className += ' fullscreen';
}

export function makeResponsiveDialogOverlay(dialogRef: MatDialogRef<any>) {
    dialogRef['_ref']['overlayRef']['_pane'].className += ' responsive';
    dialogRef['_ref']['overlayRef']['_host'].className += ' responsive';
    dialogRef['_ref']['overlayRef']['_backdropElement'].className += ' responsive';
}

export function makeTransparentDialogOverlay(dialogRef: MatDialogRef<any>) {
    dialogRef['_ref']['overlayRef']['_pane'].className += ' transparent';
    dialogRef['_ref']['overlayRef']['_host'].className += ' transparent';
    dialogRef['_ref']['overlayRef']['_backdropElement'].className += ' transparent';
}

export function makeBlurDialogOverlay(dialogRef: MatDialogRef<any>) {
    dialogRef['_ref']['overlayRef']['_pane'].className += ' blur';
    dialogRef['_ref']['overlayRef']['_host'].className += ' blur';
    dialogRef['_ref']['overlayRef']['_backdropElement'].className += ' blur';
}
