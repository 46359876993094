import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    Router
} from '@angular/router';
import { combineLatest, Subject, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Layout } from 'app/layout/layout.types';
import { AppConfig } from 'app/core/config/app.config';
import { PLATFORM_ID, ChangeDetectionStrategy } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from '@jobzmall/user/ngxs/state';
import { Scheme, Theme, User } from '@jobzmall/models';
import { JBZ_VERSION } from '@fuse/version';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {
    @Select(UserState.user) user$: Observable<User>;

    config: AppConfig;
    layout: Layout;
    scheme: Scheme;
    showHeader: boolean;
    showFooter: boolean;
    showFooterMall: boolean;
    showNavigation: boolean;
    showBottomNavigation: boolean;

    theme: string;
    themes: [string, any][] = [];
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    /**
     * Constructor
     */
    constructor(
        @Inject(PLATFORM_ID) private _platformId: Object,
        private _activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private _document: any,
        private _renderer2: Renderer2,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfigService: FuseConfigService,
        private _fuseMediaWatcherService: FuseMediaWatcherService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the theme and scheme based on the configuration
        combineLatest([
            this.user$,
            this._fuseConfigService.config$,
            this._fuseMediaWatcherService.onMediaQueryChange$([
                '(prefers-color-scheme: dark)',
                '(prefers-color-scheme: light)'
            ])
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map(([user, config, mql]) => {
                    const options = {
                        scheme: user ? user.scheme : config.scheme,
                        theme: config.theme
                    };

                    // If the scheme is set to 'auto'...
                    if (options.scheme === 'auto') {
                        // Decide the scheme using the media query
                        options.scheme = mql.breakpoints[
                            '(prefers-color-scheme: dark)'
                        ]
                            ? 'dark'
                            : 'light';
                    }

                    return options;
                })
            )
            .subscribe((options) => {
                // Store the options
                this.scheme = options.scheme;
                this.theme = options.theme;

                if (isPlatformBrowser(this._platformId)) {
                    // Update the scheme and theme
                    this._updateScheme();
                    this._updateTheme();
                }
            });

        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                // Store the config
                this.config = config;

                if (isPlatformBrowser(this._platformId)) {
                    // Update the layout
                    this._updateLayout();
                }
            });
        this._router.events
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((event) => {
                // console.log('Test for Routing Bug: ', event);
            });
        // Subscribe to NavigationEnd event
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (isPlatformBrowser(this._platformId)) {
                    // Update the layout
                    this._updateLayout();
                    this._updateShowFooter();
                    this._updateShowHeader();
                    this._updateShowNavigation();
                    this._updateShowBottomNavigation();

                    this._updateSchemeSetting();
                }
            });

        // // Set the app version
        // this._renderer2.setAttribute(
        //     this._document.querySelector('[ng-version]'),
        //     'jbz-version',
        //     JBZ_VERSION
        // );

        // Init state
        this._updateShowHeader();
        this._updateShowFooter();
        this._updateShowNavigation();
        this._updateShowBottomNavigation();

        this._updateSchemeSetting();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void {
        // Set the config
        this._fuseConfigService.config = { layout };
    }

    setShowHeader(showHeader: boolean): void {
        this._fuseConfigService.config = { showHeader };
    }

    setShowFooterMall(showFooterMall: boolean): void {
        this._fuseConfigService.config = { showFooterMall };
    }

    setShowFooter(showFooter: boolean): void {
        this._fuseConfigService.config = { showFooter };
    }

    setShowNavigation(showNavigation: boolean): void {
        this._fuseConfigService.config = { showNavigation };
    }
    setShowBottomNavigation(showBottomNavigation: boolean): void {
        this._fuseConfigService.config = { showBottomNavigation };
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this._fuseConfigService.config = { scheme };
    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme): void {
        this._fuseConfigService.config = { theme };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _updateSchemeSetting(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        this.scheme = this.config.scheme;

        const paths = route.pathFromRoot;

        paths.forEach((path) => {
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.scheme !== undefined
            ) {
                this.scheme = path.routeConfig.data.scheme;
            }
        });

        this.setScheme(this.scheme);

        if (isPlatformBrowser(this._platformId)) {
            // Update the scheme and theme
            this._updateScheme();
            this._updateTheme();
        }
    }

    private _updateShowHeader(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        this.showHeader = this.config.showHeader;

        const paths = route.pathFromRoot;

        paths.forEach((path) => {
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.showHeader !== undefined
            ) {
                this.showHeader = path.routeConfig.data.showHeader;
            }
        });

        this.setShowHeader(this.showHeader);
    }

    private _updateShowFooter(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        this.showFooterMall = this.config.showFooterMall;
        this.showFooter = this.config.showFooter;

        const paths = route.pathFromRoot;

        paths.forEach((path) => {
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.showFooter !== undefined
            ) {
                this.showFooter = path.routeConfig.data.showFooter;
            }
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.showFooterMall !== undefined
            ) {
                this.showFooterMall = path.routeConfig.data.showFooterMall;
            }
        });

        this.setShowFooterMall(this.showFooterMall);
        this.setShowFooter(this.showFooter);
    }

    private _updateShowBottomNavigation(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        this.showBottomNavigation = this.config.showBottomNavigation;

        const paths = route.pathFromRoot;

        paths.forEach((path) => {
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.showBottomNavigation !== undefined
            ) {
                this.showBottomNavigation =
                    path.routeConfig.data.showBottomNavigation;
            }
        });

        this.setShowBottomNavigation(this.showBottomNavigation);
    }

    private _updateShowNavigation(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        this.showNavigation = this.config.showNavigation;

        const paths = route.pathFromRoot;

        paths.forEach((path) => {
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.showNavigation !== undefined
            ) {
                this.showNavigation = path.routeConfig.data.showNavigation;
            }
        });

        this.setShowNavigation(this.showNavigation);
    }

    /**
     * Update the selected layout
     */
    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout;

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) => {
            // Check if there is a 'layout' data
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data.layout
            ) {
                // Set the layout
                this.layout = path.routeConfig.data.layout;
            }
        });
    }

    /**
     * Update the selected scheme
     *
     * @private
     */
    private _updateScheme(): void {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(
                    className,
                    className.split('-')[1]
                );
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(`theme-${this.theme}`);
    }
}
