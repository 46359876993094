import { Injectable } from '@angular/core';
import { Restangular } from '@jobzmall/core/api';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ViewService {
    constructor(private api: Restangular) {}

    log(entityType, entityId, cooldown = 1) {
        this.api
            .all(`insights/view/${entityType}/${entityId}`)
            .customPOST({
                cooldown
            })
            .pipe(first())
            .subscribe(() => {});
    }

    b(entityType, entityId, amount = 52): Observable<any> {
        return this.api
            .all(`insights/view/${entityType}/${entityId}/b`)
            .customPOST({
                amount
            })
            .pipe(map((res: any) => res.data))
    }
}
