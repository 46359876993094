export function removeKeys(obj: Object, keys: Array<string>) {
    var index;
    for (const prop in obj) {
        // important check that this is objects own property
        // not from prototype prop inherited
        if (obj.hasOwnProperty(prop)) {
            // tslint:disable-next-line: switch-default
            switch (typeof obj[prop]) {
                case "string":
                    index = keys.indexOf(prop);
                    if (index > -1) {
                        delete obj[prop];
                    }
                    break;
                case "object":
                    index = keys.indexOf(prop);
                    if (index > -1) {
                        delete obj[prop];
                    } else {
                        removeKeys(obj[prop], keys);
                    }
                    break;
            }
        }
    }
}
