import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { Adapter } from './adapter';
import { MessageAdapter, Message } from './message.model';
import { Media, MediaAdapter } from './media.model';

export class Conversation {
    constructor(
        public id?: number,
        public title?: string,
        public channel?: string,
        public created_at?: Date,
        public updated_at?: Date,
        public unread?: number,
        public muted?: boolean,
        public loading?: boolean,
        public pinned?: boolean,
        public maximized?: boolean,
        public end?: boolean,
        public message_receiver_id?: number,
        public last_message?: Message,
        public image?: Media,
        public members?: Array<any>,
        public meta?: any,
        public latest_reads?: Array<any>,
        public blocked_members?: Array<any>,
        public messages?: Array<Message>,
        public source?: any,
        public subject?: any,
        public source_type?: string,
        public subject_type?: string,
        public source_id?: string,
        public subject_id?: string,
        public sub_image?: Media,
        public is_request?: boolean,
        public message_limit?: number
    ) {}
}

export interface ConversationState {
    conversation: Conversation;
    messages: any;
    page: number;
    loading: boolean;
    end: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ConversationAdapter implements Adapter<Conversation> {
    constructor(
        private _messageAdapter: MessageAdapter,
        private _mediaAdapter: MediaAdapter
    ) {}
    adapt(item: any): Conversation {
        return new Conversation(
            item.id,
            item.title,
            item.channel,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.unread,
            item.muted,
            false,
            false,
            false,
            false,
            item.message_receiver_id,
            item.last_message
                ? this._messageAdapter.adapt(item.last_message)
                : undefined,
            item.image ? this._mediaAdapter.adapt(item.image) : undefined,
            item.members ? item.members : [],
            item.meta,
            item.latest_reads,
            item.blocked_members ? item.blocked_members : [],
            item.messages
                ? item.messages.map((m: any) => this._messageAdapter.adapt(m))
                : [],
            item.source,
            item.subject,
            item.source_type,
            item.subject_type,
            item.source_id,
            item.subject_id,
            item.sub_image
                ? this._mediaAdapter.adapt(item.sub_image)
                : undefined,
            item.is_request,
            item.message_limit
        );
    }
}
