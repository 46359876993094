import { Injectable } from '@angular/core';

import { Adapter } from './adapter';

export interface Language {
  id?: number,
  code: string;
  name: string;
  culture?: string;
}

export const initialLanguage: Language = {
  code: '',
  name: '',
  culture: ''
};

export interface UserLanguage {
     id?: number;
     language_id?: number;
     language_proficiency_id?: number;
     user_id? : number;
     language?: Language;
     proficiency?: LanguageProficiency;
     editing?: boolean;
     delete?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class UserLanguageAdapter implements Adapter<UserLanguage> {
  adapt(item: any): UserLanguage {
    return (new Object({
        id: item.id,
        language_id: item.language_id,
        language_proficiency_id: item.language_proficiency_id,
        user_id: item.user_id,
        language: (item.language? new LanguageAdapter().adapt(item.language) : null),
        proficiency: (item.proficiency ? new LanguageProficiency(item.proficiency.id, item.proficiency.name) : null)
    }) as UserLanguage)
  }
}

export class LanguageProficiency {
  constructor(
    public id: number,
    public name: string
  ){}
}

@Injectable({
  providedIn: 'root'
})
export class LanguageAdapter implements Adapter<Language> {
  adapt(item: any): Language {
    let lang = {
      id: item.id,
      code: item.code || item['iso_639-1'],
      name: item.name,
      culture: item.culture
    } as Language;
    return lang;
  }
}
