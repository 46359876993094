<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- <empty-layout *ngIf="layout === 'empty'"></empty-layout> -->

<!-- Classy -->
<!-- <classy-layout *ngIf="layout === 'classy'"></classy-layout> -->

<!-- Compact -->
<!-- <compact-layout *ngIf="layout === 'compact'"></compact-layout> -->

<!-- Dense -->
<!-- <dense-layout *ngIf="layout === 'dense'"></dense-layout> -->

<!-- Thin -->
<!-- <thin-layout *ngIf="layout === 'thin'"></thin-layout> -->

<ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="'classy'">
        <classy-layout></classy-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'compact'">
        <compact-layout></compact-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'dense'">
        <dense-layout></dense-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'thin'">
        <thin-layout></thin-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'empty'">
        <empty-layout></empty-layout>
    </ng-container>
</ng-container>
