import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Restangular } from '@jobzmall/core/api';
import {
    WebsocketService,
    WEBSOCKET_SERVICE
} from '@jobzmall/core/websocket/websocket.service';
import {
    Conversation,
    ConversationAdapter
} from '@jobzmall/models/conversation.model';
import { Channel } from 'laravel-echo/dist/channel';
import { Store } from '@ngxs/store';
import {
    ProcessNewUnreadMessage,
    ProcessReceiverMessageAdded,
    ProcessReceiverMessageRead,
    ProcessReceiverMessageRemoved,
    ProcessReceiverMessageUpdated,
    ProcessMessageRead,
    ProcessNewMessage
} from './app/ngxs/actions';
import { filter, first, map, takeWhile } from 'rxjs/operators';
import { MessageAdapter } from '@jobzmall/models/message.model';
import { ProcessBulkRead } from './app/ngxs/actions';

export const MESSAGE_SERVICE = new InjectionToken<WebsocketService>(
    'MESSAGE_SERVICE'
);

@Injectable()
export class MessageService {
    currentChannel: Channel;

    constructor(
        @Inject(WEBSOCKET_SERVICE) private _websocketService: WebsocketService,
        private _store: Store,
        private _conversationAdapter: ConversationAdapter,
        private _messageAdapter: MessageAdapter,
        private _api: Restangular
    ) {}

    init() {
        this.initUserEvents();
    }

    initUserEvents() {
        this._websocketService
            .observe()
            .pipe(takeWhile((val) => val === undefined, true))
            .subscribe((val) => {
                this._websocketService.websocket
                    .private(`user.${this._websocketService.user.id}`)
                    .listen('.new-message', (event: any) => {
                        this._store.dispatch(new ProcessNewMessage(event));
                    });

                this._websocketService.websocket
                    .private(`user.${this._websocketService.user.id}`)
                    .listen('.new-unread-message', (event: any) => {
                        this._store.dispatch(
                            new ProcessNewUnreadMessage(event)
                        );
                    });

                this._websocketService.websocket
                    .private(`user.${this._websocketService.user.id}`)
                    .listen('.message-read', (event: any) => {
                        this._store.dispatch(new ProcessMessageRead(event));
                    });

                this._websocketService.websocket
                    .private(`user.${this._websocketService.user.id}`)
                    .listen('.bulk-read', (event: any) => {
                        this._store.dispatch(new ProcessBulkRead(event));
                    });
            });
    }

    startListeningToConversation(
        conversation: Conversation,
        sanityCheck = false
    ) {
        if (!conversation) {
            return;
        }

        if (sanityCheck && this.currentChannel) {
            return;
        }

        if (this.currentChannel) {
            this.stopListeningToConversation();
        }

        this._websocketService
            .observe()
            .pipe(takeWhile((val) => val === undefined, true))
            .subscribe((val) => {
                if (val) {
                    this.currentChannel =
                        this._websocketService.websocket.private(
                            `receiver.${conversation.message_receiver_id}`
                        );

                    this.currentChannel.listen(
                        '.message-added',
                        (event: any) => {
                            this._store.dispatch(
                                new ProcessReceiverMessageAdded(event)
                            );
                        }
                    );
                    this.currentChannel.listen(
                        '.message-removed',
                        (event: any) => {
                            this._store.dispatch(
                                new ProcessReceiverMessageRemoved(event)
                            );
                        }
                    );
                    this.currentChannel.listen(
                        '.message-updated',
                        (event: any) => {
                            this._store.dispatch(
                                new ProcessReceiverMessageUpdated(event)
                            );
                        }
                    );
                    this.currentChannel.listen(
                        '.message-read',
                        (event: any) => {
                            this._store.dispatch(
                                new ProcessReceiverMessageRead(event)
                            );
                        }
                    );
                    this.currentChannel.listen('.bulk-read', (event: any) => {
                        this._store.dispatch(
                            new ProcessReceiverMessageRead(event)
                        );
                    });
                }
            });
    }

    stopListeningToConversation() {
        if (this.currentChannel) {
            this.currentChannel.stopListeningForWhisper('typing');
            this.currentChannel.stopListening('.message-added');
            this.currentChannel.stopListening('.message-removed');
            this.currentChannel.stopListening('.message-updated');
            this.currentChannel.stopListening('.message-read');
            this.currentChannel.stopListening('.bulk-read');
            this.currentChannel = null;
        }
    }

    startCandidateConversation(entityType, entityId, id) {
        return this._api
            .all(`candidate/${entityType}/${entityId}/${id}/conversation`)
            .customPOST()
            .pipe(map((res: any) => res.data));
    }

    startApplicationConversation(applicationId: number) {
        return this._api
            .all(`application/${applicationId}/conversation`)
            .customPOST()
            .pipe(map((res: any) => res.data));
    }

    startCoachOrderConversation(id: number) {
        return this._api
            .all(`coachorder/${id}/conversation`)
            .customPOST()
            .pipe(map((res: any) => res.data));
    }

    startConversation(
        users: Array<number>,
        source_type: string = null,
        source_id: number = null
    ) {
        let options = Object.entries({ users, source_type, source_id }).reduce(
            (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
            {}
        );
        return this._api
            .all('chat')
            .customPOST(options)
            .pipe(map((res: any) => this._conversationAdapter.adapt(res.data)));
    }

    getConversation(channel: string) {
        return this._api
            .all(`chat/${channel}`)
            .customGET()
            .pipe(map((res: any) => this._conversationAdapter.adapt(res.data)));
    }

    getConversationFromReceiverId(id: number) {
        return this._api
            .all(`receiver/${id}/messageable`)
            .customGET()
            .pipe(map((res: any) => this._conversationAdapter.adapt(res.data)));
    }

    getConversationMembers(channel: string) {
        return this._api
            .all(`chat/${channel}/member`)
            .customGET()
            .pipe(map((res: any) => res.data));
    }

    acceptRequest(channel: string) {
        return this._api
            .all(`chat/${channel}/accept`)
            .customPOST()
            .pipe(map((res: any) => this._conversationAdapter.adapt(res.data)));
    }

    getConversationMessages(channel: string, last_id?: number) {
        let options = Object.entries({ last_id }).reduce(
            (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
            {}
        );
        return this._api
            .all(`chat/${channel}/message`)
            .customGET(undefined, options)
            .pipe(
                map((res: any) =>
                    res.data.map((m: any) => this._messageAdapter.adapt(m))
                )
            );
    }

    readConversation(channel: string) {
        return this._api
            .all(`chat/${channel}/read`)
            .customPOST()
            .pipe(map((res: any) => this._conversationAdapter.adapt(res.data)));
    }

    addMessageToConversation(channel: string, data: any) {
        const formData = new FormData();
        formData.append('text', data['text']);
        if (data['temp_id']) {
            formData.append('temp_id', data['temp_id']);
        }
        data['uploads'].forEach((file: File) => {
            formData.append('uploads[]', file, file.name);
        });

        return this._api
            .all(`chat/${channel}/message`)
            .customPOST(formData, '', undefined, {
                'ngsw-bypass': true,
                'Content-Type': (): any => {
                    return undefined;
                }
            })
            .pipe(map((res: any) => this._messageAdapter.adapt(res.data)));
    }

    unsendMessageFromConversation(channel: string, id: number) {
        return this._api
            .all(`chat/${channel}/message/${id}`)
            .customDELETE()
            .pipe(map((res: any) => this._messageAdapter.adapt(res.data)));
    }

    getRequestsCount() {
        return this._api
            .all('chat/request/unread')
            .customGET()
            .pipe(map((res: any) => res.data));
    }

    getUnreadCount() {
        return this._api
            .all('chat/unread')
            .customGET()
            .pipe(map((res: any) => res.data));
    }

    muteConversation(channel: string) {
        return this._api.all(`chat/${channel}/mute`).customPOST();
    }

    unmuteConversation(channel: string) {
        return this._api.all(`chat/${channel}/mute`).customDELETE();
    }

    deleteConversation(channel: string) {
        return this._api.all(`chat/${channel}`).customDELETE();
    }

    archiveConversation(channel: string) {
        return this._api.all(`chat/${channel}/archive`).customPOST();
    }
}
