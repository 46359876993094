import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';

export interface IEducation {
    id?: number;
    degree?: string;
    degree_id?: number;
    description?: string;
    grade?: string;
    field?: string;
    name?: string;
    end_date?: Date;
    expected_date?: Date;
    start_date?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    delete?: boolean;
    logo?: string;
}
export class Education implements IEducation {
    id?: number;
    degree?: string;
    degree_id?: number;
    description?: string;
    grade?: string;
    field?: string;
    name?: string;
    end_date?: Date;
    expected_date?: Date;
    start_date?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    delete?: boolean;
    logo?: string;
    constructor(
        id?: number,
        degree?: string,
        degree_id?: number,
        description?: string,
        grade?: string,
        field?: string,
        name?: string,
        end_date?: Date,
        expected_date?: Date,
        start_date?: Date,
        updated_at?: Date,
        deleted_at?: Date
    ) {
        this.id = id;
        this.degree = degree;
        this.degree_id = degree_id;
        this.description = description;
        this.grade = grade;
        this.field = field;
        this.name = name;
        this.end_date = end_date;
        this.expected_date = expected_date;
        this.start_date = start_date;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}

@Injectable({ providedIn: 'root' })
export class EducationAdapter implements Adapter<Education> {
    adapt(item: any): Education {
        return new Education(
            item.id,
            item.degree,
            item.degree_id,
            item.description,
            item.grade,
            item.field,
            item.name,
            item.end_date ? dayjs(item.end_date).toDate() : null,
            item.expected_date ? dayjs(item.expected_date).toDate() : null,
            item.start_date ? dayjs(item.start_date).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null
        );
    }
}
