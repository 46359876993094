<div *ngIf="!child" [ngClass]="{'jbz-horizontal-navigation-menu-active': trigger.menuOpen,
                 'jbz-horizontal-navigation-menu-active-forced': item.active}" [matMenuTriggerFor]="matMenu"
    (onMenuOpen)="triggerChangeDetection()" (onMenuClose)="triggerChangeDetection()" #trigger="matMenuTrigger">
    <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
</div>

<mat-menu class="jbz-horizontal-navigation-menu-panel" [overlapTrigger]="false" #matMenu="matMenu">

    <ng-container *ngFor="let item of item.children">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(item.hidden && !(item.hidden(item) | async)) || !item.hidden">

            <!-- Basic -->
            <div class="jbz-horizontal-navigation-menu-item" *ngIf="item.type === 'basic'" [disabled]="item.disabled"
                mat-menu-item>
                <jbz-horizontal-navigation-basic-item [item]="item" [name]="name">
                </jbz-horizontal-navigation-basic-item>
            </div>

            <!-- Branch: aside, collapsable, group -->
            <div class="jbz-horizontal-navigation-menu-item"
                *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'"
                [disabled]="item.disabled" [matMenuTriggerFor]="branch.matMenu" mat-menu-item>
                <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
                <jbz-horizontal-navigation-branch-item [child]="true" [item]="item" [name]="name" #branch>
                </jbz-horizontal-navigation-branch-item>
            </div>

            <!-- Divider -->
            <div class="jbz-horizontal-navigation-menu-item" *ngIf="item.type === 'divider'" mat-menu-item>
                <jbz-horizontal-navigation-divider-item [item]="item" [name]="name">
                </jbz-horizontal-navigation-divider-item>
            </div>

        </ng-container>

    </ng-container>

</mat-menu>

<!-- Item template -->
<ng-template let-item #itemTemplate>

    <div class="jbz-horizontal-navigation-item-wrapper"
        [class.jbz-horizontal-navigation-item-has-subtitle]="!!item.subtitle" [matTooltip]="item.tooltip || ''"
        [ngClass]="item.classes?.wrapper">

        <div class="jbz-horizontal-navigation-item" [ngClass]="{'jbz-horizontal-navigation-item-disabled': item.disabled,
                         'jbz-horizontal-navigation-item-active-forced': item.active}">
            <ng-container *transloco="let t">
                <!-- Icon -->
                <mat-icon class="jbz-horizontal-navigation-item-icon" [ngClass]="item.classes?.icon"
                    *ngIf="item.icon || item.classes?.icon" [svgIcon]="item.icon"></mat-icon>

                <!-- Title & Subtitle -->
                <div class="jbz-horizontal-navigation-item-title-wrapper">
                    <div class="jbz-horizontal-navigation-item-title">
                        <span [ngClass]="item.classes?.title">
                            {{t(item.title)}}
                        </span>
                    </div>
                    <div class="jbz-horizontal-navigation-item-subtitle text-hint" *ngIf="item.subtitle">
                        <span [ngClass]="item.classes?.subtitle">
                            {{t(item.subtitle)}}
                        </span>
                    </div>
                </div>

                <!-- Badge -->
                <div class="jbz-horizontal-navigation-item-badge" *ngIf="item.badge">
                    <div class="jbz-horizontal-navigation-item-badge-content" [ngClass]="item.badge.classes">
                        {{item.badge.title}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-template>
