import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@jobzmall/models';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import * as dayjs from 'dayjs';
import { HOURS } from '@jobzmall/models/hours.model';
import ct from 'countries-and-timezones';
import { Restangular } from '@jobzmall/core/api/ngx-restangular';
import { catchError, first, map, Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@jobzmall/user';
import ceil from '@jobzmall/datetime/plugins/dayjs-ceil.plugin';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as utc from 'dayjs/plugin/utc';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import * as timezone from 'dayjs/plugin/timezone';
import { Integration } from '@jobzmall/integrations/integration.type';
import { IntegrationsService } from '@jobzmall/integrations/integrations.service';
import { ValidatorFn } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import {
    RefreshUserIntegrations,
    CreateOrUpdateUserIntegration
} from '../../../user/ngxs/actions';

dayjs.extend(ceil);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const endTimeValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const start_time = fg.get('start_time')?.value;
    const end_time = fg.get('end_time')?.value;

    let startTimeDate = dayjs()
        .set('hour', start_time.split(':')[0])
        .set('minutes', start_time.split(':')[1])
        .set('seconds', 0)
        .set('milliseconds', 0);

    let endTimeDate = dayjs()
        .set('hour', end_time.split(':')[0])
        .set('minutes', end_time.split(':')[1])
        .set('seconds', 0)
        .set('milliseconds', 0);

    if (endTimeDate.isBefore(startTimeDate)) {
        return { endTimeBeforeStartTime: true };
    }

    return null;
};

@Component({
    selector: 'video-meeting-dialog',
    templateUrl: './video-meeting-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class VideoMeetingDialogComponent
    extends JMBaseComponent
    implements OnInit
{
    @Select(UserState.user) user$: Observable<User>;
    @Select(UserState.integrations) integrations$: Observable<
        Array<Integration>
    >;

    user: User;

    calendarIntegrations: Array<Integration> = [];
    videoIntegrations: Array<Integration> = [];

    videoOptions = [];
    calendarOptions = [];

    currentSelection: any = {
        icon: 'mimicons_outline:calendar',
        name: 'Schedule Meeting',
        type: 'scheduled',
        form: new UntypedFormGroup(
            {
                video_integration: new UntypedFormControl('', [
                    Validators.required
                ]),
                date: new UntypedFormControl('', [Validators.required]),
                start_time: new UntypedFormControl('', [Validators.required]),
                end_time: new UntypedFormControl('', [Validators.required]),
                timezone: new UntypedFormControl('', [])
            },
            { validators: [endTimeValidator] }
        )
    };
    meeting: any = {
        calendar_integration_id: 'all',
        video_integration_id: 'jobzmall-rooms'
    };
    minDate = dayjs();

    timezones: any[] = [];
    hours = HOURS;

    constructor(
        public dialogRef: MatDialogRef<VideoMeetingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _changeDetectorRef: ChangeDetectorRef,
        private _api: Restangular,
        private _store: Store,
        private _confirmation: FuseConfirmationService,
        private _snackbar: MatSnackBar
    ) {
        super();
        this._store.dispatch(new RefreshUserIntegrations());
    }

    ngOnInit(): void {
        this.subscriptions.sink = this.integrations$.subscribe(
            (integrations: Array<Integration>) => {
                this.calendarIntegrations = integrations.filter((i) =>
                    i.id.includes('-calendar')
                );
                this.videoIntegrations = integrations.filter((i) =>
                    i.id.includes('-video')
                );
                this.updateCalendarOptions();
                this.updateVideoOptions();
                this._changeDetectorRef.markForCheck();
            }
        );
        this.integrations$
            .pipe(first())
            .subscribe((integrations: Array<Integration>) => {
                if (
                    this.calendarIntegrations.find(
                        (v) => v.id === 'outlook-calendar'
                    )?.refresh_token_expired
                ) {
                    this._store.dispatch(
                        new CreateOrUpdateUserIntegration('outlook-calendar')
                    );
                }
            });

        this.meeting.date = this.minDate;
        this.meeting.start_time = dayjs().ceil('minutes', 15).format('HH:mm');
        let startTimeIndex = this.hours.findIndex(
            (h) => h.twenty_four_hour_format === this.meeting.start_time
        );
        this.meeting.end_time =
            this.hours[
                startTimeIndex === this.hours.length - 1
                    ? 0
                    : startTimeIndex + 1
            ].twenty_four_hour_format;

        this.user = this.data.user;
        this.meeting.subject_type = this.data.subject_type;
        this.meeting.subject_id = this.data.subject_id;

        this.timezones = Object.values(ct.getAllTimezones())
            .sort((a, b) => a.utcOffset - b.utcOffset)
            .map((value) => {
                return {
                    name: `(UTC ${value.utcOffsetStr}) ${value.name}`,
                    value: value.name
                };
            });
        this.meeting.user_id = this.user.id;
        this.subscriptions.sink = this.user$.subscribe((user: User) => {
            this.meeting.timezone = user.timezone;
        });
    }

    updateCalendarOptions() {
        this.calendarOptions = [
            {
                name: 'None',
                id: 'none'
            }
        ];
        if (this.calendarIntegrations.find((v) => v.id == 'google-calendar')) {
            this.calendarOptions.push({
                name: 'Google Calendar',
                id: 'google-calendar'
            });
        }
        if (this.calendarIntegrations.find((v) => v.id == 'outlook-calendar')) {
            this.calendarOptions.push({
                name: 'Outlook Calendar',
                id: 'outlook-calendar'
            });
        }
        this._changeDetectorRef.markForCheck();
    }

    updateVideoOptions() {
        this.videoOptions = [
            {
                name: 'JobzMall Rooms',
                id: 'jobzmall-rooms'
            }
        ];
        if (this.meeting.calendar_integration_id === 'google-calendar') {
            this.videoOptions.push({
                name: 'Google Meet',
                id: 'google-video'
            });
        }
        if (this.videoIntegrations.find((v) => v.id == 'zoom-video')) {
            this.videoOptions.push({
                name: 'Zoom Video',
                id: 'zoom-video'
            });
        }
        this._changeDetectorRef.markForCheck();
    }

    setCurrentSelection(selection) {
        this.currentSelection = selection;
    }

    confirm() {
        if (this.currentSelection.form.valid && !this.loading) {
            this.loading = true;
            this._changeDetectorRef.markForCheck();
            this.meeting.start_utc = dayjs
                .utc(
                    this.meeting.date
                        .set('hour', this.meeting.start_time.split(':')[0])
                        .set('minutes', this.meeting.start_time.split(':')[1])
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                )
                .utc()
                .toISOString();
            this.meeting.end_utc = dayjs
                .utc(
                    this.meeting.date
                        .set('hour', this.meeting.end_time.split(':')[0])
                        .set('minutes', this.meeting.end_time.split(':')[1])
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                )
                .utc()
                .toISOString();
            this._api
                .all('meeting')
                .customPOST(this.meeting)
                .pipe(
                    first(),
                    map((res: any) => res.data),
                    catchError((err: any) => {
                        if (err.status === 449) {
                            this._confirmation
                                .open({
                                    title: 'Zoom Video Integration',
                                    message: err.data.error.message,
                                    icon: {
                                        show: true,
                                        name: 'heroicons_outline:exclamation',
                                        color: 'warn'
                                    },
                                    actions: {
                                        confirm: {
                                            show: true,
                                            label: 'Yes please',
                                            color: 'warn'
                                        },
                                        cancel: {
                                            label: 'Not right now',
                                            show: true
                                        }
                                    }
                                })
                                .afterClosed()
                                .pipe(first())
                                .subscribe((result: string) => {
                                    if (result == 'confirmed') {
                                        this._store.dispatch(
                                            new CreateOrUpdateUserIntegration(
                                                'zoom-video'
                                            )
                                        );
                                    }
                                });
                        } else {
                            this._confirmation.open({
                                title: 'Unknown Error',
                                message: err.data.error.message,
                                icon: {
                                    show: true,
                                    name: 'heroicons_outline:exclamation',
                                    color: 'warn'
                                },
                                actions: {
                                    confirm: {
                                        show: true,
                                        label: 'Ok',
                                        color: 'warn'
                                    },
                                    cancel: {
                                        show: false
                                    }
                                }
                            });
                        }

                        return of(undefined);
                    })
                )
                .subscribe((meeting: any) => {
                    if (meeting) {
                        this._snackbar.open(
                            'Meeting Scheduled Successfully',
                            'OK',
                            {
                                duration: 3000,
                                panelClass: ['jobz-snackbar']
                            }
                        );
                        this.dialogRef.close(meeting);
                    }
                    this.loading = false;
                    this._changeDetectorRef.markForCheck();
                });
        }
    }
}
