import { Observable, fromEvent, of, merge } from 'rxjs';
import { map } from 'rxjs/operators';

export class NetworkConnection {
    public static online: boolean;
    public static online$: Observable<boolean>;

    public static init() {
        NetworkConnection.online$ = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        ).pipe(map(() => navigator.onLine));

        NetworkConnection.online$.subscribe((value) => {
            NetworkConnection.online = value;
        });
    }

    constructor() {
        NetworkConnection.init();
    }
}
