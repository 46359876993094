import { Pipe, PipeTransform } from '@angular/core';
import { clone } from 'lodash-es';
import { getUrls } from '../util/url/get-urls';
import { urlRegex } from '../util/url/url-regex';


@Pipe({ name: 'moveLinksToEnd' })
export class MoveLinksToEndPipe implements PipeTransform {
    transform(value: any): any {
        value = this.moveUrlsToEnd(value);
        return value;
    }

    moveUrlsToEnd(value: string) {
        let newVal = clone(value);

        let old = newVal.match(urlRegex())

        let set = getUrls(newVal, {
            normalizeProtocol: true,
            forceHttps: true,
            stripWWW: false
        });

        if (set && old) {
            old.forEach((part: string, index: number) => {
                newVal = newVal.replace(part, '');
                newVal = newVal.concat(' ' + Array.from(set)[index]);
            });
        }
        return newVal;
    }
}
