export const DAYS = {
    en: [
        { name: 'Monday', value: 'monday' },
        { name: 'Tuesday', value: 'tuesday' },
        { name: 'Wednesday', value: 'wednesday' },
        { name: 'Thursday', value: 'thursday' },
        { name: 'Friday', value: 'friday' },
        { name: 'Saturday', value: 'saturday' },
        { name: 'Sunday', value: 'sunday' }
    ],
    es: [
        { name: 'lunes', value: 'monday' },
        { name: 'martes', value: 'tuesday' },
        { name: 'miércoles', value: 'wednesday' },
        { name: 'jueves', value: 'thursday' },
        { name: 'viernes', value: 'friday' },
        { name: 'sábado', value: 'saturday' },
        { name: 'domingo', value: 'sunday' }
    ]
};
