export class AcceptCookies {
    static readonly type = '[App] Accept Cookies';
    constructor() {}
}
export class SetTimeFormat {
    static readonly type = '[App] Set Time Format';
    constructor() {}
}

export class SetAppLoading {
    static readonly type = '[App] Set Loading Flag';
    constructor(public loading: boolean) {}
}

export class SetAppInitializing {
    static readonly type = '[App] Set Loading Flag';
    constructor(public initializing: boolean) {}
}

export class SetHeaderTransparent {
    static readonly type = '[App] Set Header Transparent';
    constructor(public transparent: boolean) {}
}

export class SetMobileNavigationRelative {
    static readonly type = '[App] Set Mobile Navigation Relative';
    constructor(public relative: boolean) {}
}

export class SetHeaderAbsolute {
    static readonly type = '[App] Set Header Absolute';
    constructor(public absolute: boolean) {}
}
export class SetShowHeaderLogo {
    static readonly type = '[App] Set Show Header Logo';
    constructor(public show: boolean) {}
}

export class SetShowHeaderOmniSearch {
    static readonly type = '[App] Set Show Header Omni Search';
    constructor(public show: boolean) {}
}
