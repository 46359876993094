import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { MessagesWidgetComponent } from './widget/messages-widget.component';
import { MessageService } from './message.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChatScrollDirective } from './directives/chatscroll.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JMMediaModule } from '@jobzmall/media/media.module';
import { LinkPreviewModule } from '@jobzmall/social/link-preview/link-preview.module';
import { FormsModule } from '@angular/forms';
import { MessagesWidgetConversationComponent } from './widget/messages-widget-conversation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FilePondModule } from 'ngx-filepond';
import { MessagesWidgetListComponent } from './widget/messages-widget-list.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MessagesWidgetConversationPlaceholderComponent } from './widget/messages-widget-conversation-placeholder.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { JMVideoMeetingsModule } from '@jobzmall/video/video-meetings/video-meetings.module';

const COMPONENTS = [
    MessagesWidgetComponent,
    MessagesWidgetListComponent,
    MessagesWidgetConversationPlaceholderComponent,
    MessagesWidgetConversationComponent,
    ChatScrollDirective
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        RouterModule,
        NgPipesModule,
        JMPipesModule,
        JMCommonDirectivesModule,
        JMSharedModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
        InfiniteScrollModule,
        MatProgressSpinnerModule,
        CommonModule,
        JMMediaModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        LinkPreviewModule,
        FilePondModule,
        MatProgressBarModule,
        MatMenuModule,
        MatTooltipModule,
        JMVideoMeetingsModule
    ],
    exports: COMPONENTS,
    providers: [MessageService]
})
export class JMMessagesModule {}
