import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';

export interface ICertification {
    id?: number;
    name?: string;
    organization?: string;
    start_date?: Date;
    expiration_date?: Date;
    credential_id?: string;
    credential_url?: string;
    updated_at?: Date;
    deleted_at?: Date;
    delete?: boolean;
    logo?: string;
}
export class Certification implements ICertification {
    id?: number;
    name?: string;
    organization?: string;
    start_date?: Date;
    expiration_date?: Date;
    credential_id?: string;
    credential_url?: string;
    updated_at?: Date;
    deleted_at?: Date;
    delete?: boolean;
    logo?: string;

    constructor(
        id?: number,
        name?: string,
        organization?: string,
        start_date?: Date,
        expiration_date?: Date,
        credential_id?: string,
        credential_url?: string,
        updated_at?: Date,
        deleted_at?: Date
    ) {
        this.id = id;
        this.name = name;
        this.organization = organization;
        this.start_date = start_date;
        this.expiration_date = expiration_date;
        this.credential_id = credential_id;
        this.credential_url = credential_url;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}

@Injectable({ providedIn: 'root' })
export class CertificationAdapter implements Adapter<Certification> {
    adapt(item: any): Certification {
        return new Certification(
            item.id,
            item.name,
            item.organization,
            item.start_date ? dayjs(item.start_date).toDate() : null,
            item.expiration_date ? dayjs(item.expiration_date).toDate() : null,
            item.credential_id,
            item.credential_url,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null
        );
    }
}
