<!-- Item wrapper -->
<div class="jbz-horizontal-navigation-item-wrapper group"
    [class.jbz-horizontal-navigation-item-has-subtitle]="!!item.subtitle" [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <div class="jbz-horizontal-navigation-item"
        *ngIf="item.link && !item.externalLink && !item.function && !item.disabled"
        [ngClass]="{'jbz-horizontal-navigation-item-active-forced': item.active}" [routerLink]="[item.link]"
        [matTooltip]="item.tooltip || ''" [routerLinkActive]="'jbz-horizontal-navigation-item-active'"
        [routerLinkActiveOptions]="isActiveMatchOptions">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item with an external link -->
    <a class="jbz-horizontal-navigation-item" [matTooltip]="item.tooltip || ''"
        *ngIf="item.link && item.externalLink && !item.function && !item.disabled" [target]="item.target"
        [href]="item.link">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a function -->
    <div class="jbz-horizontal-navigation-item" *ngIf="!item.link && item.function && !item.disabled"
        [matTooltip]="item.tooltip || ''" [ngClass]="{'jbz-horizontal-navigation-item-active-forced': item.active}"
        (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item with an internal link and function -->
    <div class="jbz-horizontal-navigation-item"
        *ngIf="item.link && !item.externalLink && item.function && !item.disabled" [matTooltip]="item.tooltip || ''"
        [ngClass]="{'jbz-horizontal-navigation-item-active-forced': item.active}" [routerLink]="[item.link]"
        [routerLinkActive]="'jbz-horizontal-navigation-item-active'" [routerLinkActiveOptions]="isActiveMatchOptions"
        (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item with an external link and function -->
    <a class="jbz-horizontal-navigation-item" *ngIf="item.link && item.externalLink && item.function && !item.disabled"
        [matTooltip]="item.tooltip || ''" [href]="item.link" [target]="item.target" (click)="item.function(item)" mat-menu-item>
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a no link and no function -->
    <div class="jbz-horizontal-navigation-item" *ngIf="!item.link && !item.function && !item.disabled"
        [matTooltip]="item.tooltip || ''" [ngClass]="{'jbz-horizontal-navigation-item-active-forced': item.active}">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item is disabled -->
    <div class="jbz-horizontal-navigation-item jbz-horizontal-navigation-item-disabled" *ngIf="item.disabled">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <ng-container *transloco="let t">

        <!-- Icon -->
        <mat-icon class="jbz-horizontal-navigation-item-icon" [ngClass]="item.classes?.icon"
            *ngIf="item.icon || item.classes?.icon" [svgIcon]="item.icon"></mat-icon>


        <!-- Title & Subtitle -->
        <div class="jbz-horizontal-navigation-item-title-wrapper">
            <div class="jbz-horizontal-navigation-item-title">
                <span [ngClass]="item.classes?.title">
                    {{t(item.title)}}
                </span>
            </div>
            <div class="jbz-horizontal-navigation-item-subtitle text-hint" *ngIf="item.subtitle">
                <span [ngClass]="item.classes?.subtitle">
                    {{t(item.subtitle)}}
                </span>
            </div>
        </div>

        <!-- Badge -->
        <div class="jbz-horizontal-navigation-item-badge" *ngIf="item.badge">
            <div class="jbz-horizontal-navigation-item-badge-content" [ngClass]="item.badge.classes">
                {{item.badge.title}}
            </div>
        </div>
    </ng-container>

</ng-template>
