import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    Inject
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Restangular } from '@jobzmall/core/api';
import { TimeService } from '@jobzmall/core';
import { catchError, map, Observable, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserState } from '@jobzmall/user';
import { User } from '@jobzmall/models';
import { mergeMap } from 'rxjs/operators';

@Component({
    selector: 'quote-card',
    templateUrl: './quote-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class QuoteCardComponent extends JMBaseComponent implements OnInit {
    @Input() showGreeting = false;
    @Input() entity = 'system';
    @Input() entityId = 1;

    quote: any;

    greeting: Observable<string>;

    constructor(
        private _store: Store,
        private _api: Restangular,
        private _time: TimeService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.getQuote();
        this.greeting = this._time.readableTimeOfDay().pipe(
            mergeMap((timeOfDay: string) => {
                return this._store
                    .select(UserState.user)
                    .pipe(
                        map(
                            (user: User) =>
                                `Good ${timeOfDay}, ${user.first_name}`
                        )
                    );
            })
        );
    }

    getQuote() {
        this._api
            .all(`quote/${this.entity}/${this.entityId}`)
            .customGET()
            .pipe(
                map((res: any) => res.data),
                catchError((res: any, caught: any) => of(null))
            )
            .subscribe((value: any) => {
                this.quote = value;
                if (!this.quote) {
                    this.entity = 'system';
                    this.entityId = 1;
                    this.getQuote();
                }
                this._changeDetectorRef.markForCheck();
            });
    }
}
