<ng-container *transloco="let t">
    <div class="min-w-[80vw] flex flex-col flex-auto -m-6 md:w-120 md:min-w-120 max-h-160">
        <!-- Header -->
        <div class="relative px-6 py-4 text-center">
            <mat-icon class="mx-auto text-gray-400 dark:text-white icon-size-10" [svgIcon]="'mimicons_outline:video'">
            </mat-icon>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 mx-auto text-gray-400 dark:text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg> -->
            <h2 class="mt-2 text-lg font-medium">Schedule a Video Meeting</h2>
            <p class="mt-1 text-secondary">
                Schedule a video meeting with <span class="capitalize">{{user.name}}</span>
            </p>
            <mat-icon (click)="dialogRef.close()" class="absolute icon-size-6 top-5 right-5"
                [svgIcon]="'heroicons_solid:x'"></mat-icon>
        </div>
        <div class="flex flex-col p-6">

            <form [formGroup]="currentSelection.form" class="grid w-full grid-cols-2 gap-2 pt-4 mt-4 border-t"
                (ngSubmit)="currentSelection.form.valid && confirm()">

                <div class="col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Video Provider</mat-label>
                        <mat-select [formControl]="$any(currentSelection.form).controls.video_integration"
                            [(ngModel)]="meeting.video_integration_id" required>
                            <mat-option *ngFor="let option of videoOptions; trackBy: ( 'id' |
                                                trackByProperty )" [value]="option.id">
                                {{option.name}}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="$any(currentSelection.form).controls.video_integration.errors?.required">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Date</mat-label>
                        <input [min]="minDate" [(ngModel)]="meeting.date"
                            [formControl]="$any(currentSelection.form).controls.date" matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="currentSelection.form.controls.date.errors?.required">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-span-1">
                    <mat-form-field class="w-full">
                        <mat-label>Start Time</mat-label>
                        <mat-select [formControl]="$any(currentSelection.form).controls.start_time"
                            [(ngModel)]="meeting.start_time" required>
                            <mat-option *ngFor="let hour of hours; trackBy: ( 'twenty_four_hour_format' |
                                                trackByProperty )" [value]="hour.twenty_four_hour_format">
                                <span *ngIf="timeFormat === 'hh:mm A'">{{hour.standard_format}}
                                    {{hour.time_of_day | uppercase}}</span>
                                <span *ngIf="timeFormat === 'HH:mm'">{{hour.twenty_four_hour_format}}
                                </span>
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="$any(currentSelection.form).controls.start_time.errors?.required">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-span-1">
                    <mat-form-field class="w-full">
                        <mat-label>End Time</mat-label>
                        <mat-select [formControl]="$any(currentSelection.form).controls.end_time"
                            [(ngModel)]="meeting.end_time" required>
                            <mat-option *ngFor="let hour of hours; trackBy: ( 'twenty_four_hour_format' |
                                                trackByProperty )" [value]="hour.twenty_four_hour_format">
                                <span *ngIf="timeFormat === 'hh:mm A'">{{hour.standard_format}}
                                    {{hour.time_of_day | uppercase}}</span>
                                <span *ngIf="timeFormat === 'HH:mm'">{{hour.twenty_four_hour_format}}
                                </span>
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="$any(currentSelection.form).controls.start_time.errors?.required">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="$any(currentSelection.form).errors?.endTimeBeforeStartTime">
                        End time cannot be before start time</mat-error>
                </div>
                <div class="col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Timezone</mat-label>
                        <mat-select [formControl]="$any(currentSelection.form).controls.timezone"
                            [(ngModel)]="meeting.timezone" required>
                            <mat-option *ngFor="let tz of timezones" [value]="tz.value">
                                {{tz.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="$any(currentSelection.form).controls.timezone.errors?.required">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-span-2">
                    <span class="text-secondary">An email reminder will be sent to everyone</span>
                </div>

                <div class="flex flex-row items-center justify-end w-full col-span-2 px-6 pb-6">
                    <div class="flex items-center justify-end col-span-2 mt-4">
                        <button type="button" (click)="dialogRef.close()" mat-flat-button>
                            {{t('PUBLIC.SHARED.ACTION.CANCEL')}}
                        </button>
                        <button (click)="confirm()" [disabled]="currentSelection.form.invalid" class="ml-4"
                            mat-flat-button type="submit" [color]="'primary'">
                            <span *ngIf="!loading">{{t('PUBLIC.SHARED.ACTION.SEND')}}</span>
                            <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
                            </mat-progress-spinner>
                        </button>
                    </div>
                </div>
            </form>

        </div>
    </div>
</ng-container>
