import { Message } from '@jobzmall/models';

export class ProcessNewUnreadMessage {
    static readonly type = '[Messages] Process New Unread Message';
    constructor(public event: any) {}
}

export class ProcessNewMessage {
    static readonly type = '[Messages] Process New Message';
    constructor(public event: any) {}
}

export class ProcessMessageRead {
    static readonly type = '[Messages] Process Message Read';
    constructor(public event: any) {}
}

export class ProcessBulkRead {
    static readonly type = '[Messages] Process Bulk Read';
    constructor(public event: any) {}
}

export class GetConversations {
    static readonly type = '[Messages] Get Conversations';
    constructor(public refresh: boolean = false) {}
}

export class GetRequests {
    static readonly type = '[Messages] Get Conversation Requests';
    constructor(public refresh: boolean = false) {}
}

export class ClearConversationMessageLimit {
    static readonly type = '[Messages] Clear Conversation Message Limit';
    constructor(public channel: string) {}
}

export class GetConversationMembers {
    static readonly type = '[Messages] Get Conversation Members';
    constructor(public channel: string) {}
}

export class StartConversation {
    static readonly type = '[Messages] Start Conversation';
    constructor(public userIds: Array<number>, public pin: boolean = false) {}
}

export class StartApplicationConversation {
    static readonly type = '[Messages] Start Application Conversation';
    constructor(public applicationId: number, public pin: boolean = false) {}
}

export class StartCoachOrderConversation {
    static readonly type = '[Messages] Start Coach Order Conversation';
    constructor(public id: number, public pin: boolean = false) {}
}

export class StartCandidateConversation {
    static readonly type = '[Messages] Start Candidate Conversation';
    constructor(
        public entityType: string,
        public entityId: number,
        public id: number,
        public pin: boolean = false
    ) {}
}

export class AcceptRequest {
    static readonly type = '[Messages] Accept Conversation Request';
    constructor(public channel: string) {}
}

export class ReadConversation {
    static readonly type = '[Messages] Mark Specified as Read';
    constructor(public channel: string) {}
}

export class ReadCurrentConversation {
    static readonly type = '[Messages] Mark Current as Read';
    constructor() {}
}

export class SetCurrentConversation {
    static readonly type = '[Messages] Set Current Conversations';
    constructor(public channel: string) {}
}

export class SetCurrentConversationTextValue {
    static readonly type = '[Messages] Set Current Conversations Textbox Value';
    constructor(public value: string) {}
}

export class MinimizeMessagesWidget {
    static readonly type = '[Messages] Minimize Widget';
    constructor(public minimizeAll: boolean = true) {}
}

export class MaximizeMessagesWidget {
    static readonly type = '[Messages] Maximize Widget';
    constructor() {}
}
export class SetMinimizeWidgetCompletely {
    static readonly type = '[Messages] Set Minimize Widget Completely';
    constructor(public value: boolean) {}
}

export class EnableConversationPinning {
    static readonly type = '[Messages] Enable Conversation Pinning';
    constructor() {}
}

export class DisableConversationPinning {
    static readonly type = '[Messages] Disable Conversation Pinning';
    constructor() {}
}

export class PinConversation {
    static readonly type = '[Messages] Pin Conversations';
    constructor(public channel: string) {}
}

export class MaximizeConversation {
    static readonly type = '[Messages] Maximize Conversations';
    constructor(public channel: string) {}
}

export class MinimizeConversation {
    static readonly type = '[Messages] Minimize Conversations';
    constructor(public channel: string) {}
}

export class UnpinAllConversations {
    static readonly type = '[Messages] Unpin All Conversations';
    constructor() {}
}

export class UnpinConversation {
    static readonly type = '[Messages] Unpin Conversations';
    constructor(public channel: string) {}
}

export class ArchiveConversation {
    static readonly type = '[Messages] Archive Conversation';
    constructor(public channel: string) {}
}

export class BlockConversationMember {
    static readonly type = '[Messages] Block Other User Conversation';
    constructor(public member?: any) {}
}

export class DeleteConversation {
    static readonly type = '[Messages] Delete Conversation';
    constructor(public channel: string) {}
}

export class CloseCurrentConversation {
    static readonly type = '[Messages] Close Current Conversation';
    constructor() {}
}

export class MuteConversation {
    static readonly type = '[Messages] Mute Conversation';
    constructor(public channel: string) {}
}

export class UnmuteConversation {
    static readonly type = '[Messages] Unmute Conversations';
    constructor(public channel: string) {}
}

export class GetMessages {
    static readonly type = '[Messages] Get Current Conversation Messages';
    constructor() {}
}

export class GetUnread {
    static readonly type = '[Messages] Get Unread Count';
    constructor() {}
}

export class GetRequestsCount {
    static readonly type = '[Messages] Get Requests Count';
    constructor() {}
}

export class AddMessage {
    static readonly type = '[Messages] Add Message';
    constructor(public text: string = '', public uploads: Array<File> = []) {}
}

export class ResendMessage {
    static readonly type = '[Messages] Resend Message';
    constructor(public message: Message) {}
}

export class UnsendMessage {
    static readonly type = '[Messages] Unsend Message';
    constructor(public id: number) {}
}

export class ProcessReceiverMessageAdded {
    static readonly type = '[Messages] Process Receiver Message Added';
    constructor(public event: any) {}
}

export class ProcessReceiverMessageRemoved {
    static readonly type = '[Messages] Process Receiver Message Removed';
    constructor(public event: any) {}
}

export class ProcessReceiverMessageUpdated {
    static readonly type = '[Messages] Process Receiver Message Updated';
    constructor(public event: any) {}
}

export class ProcessReceiverMessageRead {
    static readonly type = '[Messages] Process Receiver Message Read';
    constructor(public event: any) {}
}
export class ProcessReceiverBulkRead {
    static readonly type = '[Messages] Process Receiver Bulk Read';
    constructor(public event: any) {}
}

export class VerifyPhoneNumberForMessaging {
    static readonly type = '[Messages] Verify Phone Number for Messaging';
    constructor() {}
}
