<div class="jbz-horizontal-navigation-wrapper">

    <ng-container *ngFor="let item of navigation">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(item.hidden && !(item.hidden(item) | async)) || !item.hidden">

            <!-- Basic -->
            <jbz-horizontal-navigation-basic-item class="jbz-horizontal-navigation-menu-item"
                *ngIf="item.type === 'basic'" [item]="item" [name]="name"></jbz-horizontal-navigation-basic-item>

            <!-- Branch: aside, collapsable, group -->
            <jbz-horizontal-navigation-branch-item class="jbz-horizontal-navigation-menu-item"
                *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'" [item]="item"
                [name]="name"></jbz-horizontal-navigation-branch-item>

            <!-- Spacer -->
            <jbz-horizontal-navigation-spacer-item class="jbz-horizontal-navigation-menu-item"
                *ngIf="item.type === 'spacer'" [item]="item" [name]="name"></jbz-horizontal-navigation-spacer-item>

        </ng-container>

    </ng-container>

</div>
