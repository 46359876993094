import { Injectable } from '@angular/core';
import {
    Action,
    State,
    StateContext,
    Selector,
    NgxsAfterBootstrap
} from '@ngxs/store';
import { first } from 'rxjs/operators';
import { AppStateModel } from './model';
import {
    AcceptCookies,
    SetAppLoading,
    SetHeaderTransparent,
    SetHeaderAbsolute,
    SetShowHeaderLogo,
    SetShowHeaderOmniSearch,
    SetMobileNavigationRelative,
    SetTimeFormat
} from './actions';
import { NetworkConnection } from '@jobzmall/core/browser/network-connection';
import { Restangular } from '@jobzmall/core/api';

@State<AppStateModel>({
    name: 'app',
    defaults: {
        showHeaderOmni: true,
        showHeaderLogo: true,
        headerAbsolute: false,
        headerTransparent: false,
        mobileNavigationRelative: false,
        loading: false,
        initialized: false,
        cookiesAccepted: false,
        locationDetails: undefined,
        timeFormat: 'hh:mm A'
    }
})
@Injectable()
export class AppState implements NgxsAfterBootstrap {
    @Selector()
    static timeFormat(state: AppStateModel): string {
        return state.timeFormat;
    }

    @Selector()
    static cookiesAccepted(state: AppStateModel): boolean {
        return state.cookiesAccepted;
    }

    @Selector()
    static mobileNavigationRelative(state: AppStateModel): boolean {
        return state.mobileNavigationRelative;
    }

    @Selector()
    static headerTransparent(state: AppStateModel): boolean {
        return state.headerTransparent;
    }

    @Selector()
    static headerAbsolute(state: AppStateModel): boolean {
        return state.headerAbsolute;
    }

    @Selector()
    static showHeaderLogo(state: AppStateModel): boolean {
        return state.showHeaderLogo;
    }

    @Selector()
    static showHeaderOmni(state: AppStateModel): boolean {
        return state.showHeaderOmni;
    }

    @Selector()
    static loading(state: AppStateModel): boolean {
        return state.loading;
    }

    @Selector()
    static initialized(state: AppStateModel): boolean {
        return state.initialized;
    }

    @Selector() static locationDetails(state: AppStateModel): any {
        return state.locationDetails;
    }

    constructor(private _api: Restangular) {}

    ngxsOnInit() {}

    ngxsAfterBootstrap(ctx: StateContext<AppStateModel>) {
        new NetworkConnection();
        ctx.dispatch(new SetTimeFormat());
        this._api
            .all('user/location')
            .customGET(undefined, { full: true })
            .pipe(first())
            .subscribe((res) => {
                ctx.patchState({
                    locationDetails: res.data,
                    initialized: true
                });
            });
    }

    @Action(AcceptCookies)
    acceptCookies(ctx: StateContext<AppStateModel>) {
        ctx.patchState({
            cookiesAccepted: true
        });
    }

    @Action(SetTimeFormat)
    setTimeFormat(
        ctx: StateContext<AppStateModel>,
        action: SetTimeFormat
    ): void {
        let twentyFour = !new Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric'
        })
            .format(0)
            .match(/\s/);
        ctx.patchState({
            timeFormat: twentyFour ? 'HH:mm' : 'hh:mm A'
        });
    }

    @Action(SetAppLoading)
    setAppLoading(ctx: StateContext<AppStateModel>, action: SetAppLoading) {
        ctx.patchState({
            loading: action.loading
        });
    }

    @Action(SetMobileNavigationRelative)
    setMobileNavigationRelative(
        ctx: StateContext<AppStateModel>,
        action: SetMobileNavigationRelative
    ) {

        if (ctx.getState().mobileNavigationRelative == action.relative) {
            return;
        }
        ctx.patchState({
            mobileNavigationRelative: action.relative
        });
    }

    @Action(SetHeaderTransparent)
    setHeaderTransparent(
        ctx: StateContext<AppStateModel>,
        action: SetHeaderTransparent
    ) {
        if (ctx.getState().headerTransparent == action.transparent) {
            return;
        }
        ctx.patchState({
            headerTransparent: action.transparent
        });
    }

    @Action(SetHeaderAbsolute)
    setHeaderAbsolute(
        ctx: StateContext<AppStateModel>,
        action: SetHeaderAbsolute
    ) {
        if (ctx.getState().headerAbsolute == action.absolute) {
            return;
        }
        ctx.patchState({
            headerAbsolute: action.absolute
        });
    }

    @Action(SetShowHeaderLogo)
    setShowHeaderLogo(
        ctx: StateContext<AppStateModel>,
        action: SetShowHeaderLogo
    ) {
        if (ctx.getState().showHeaderLogo == action.show) {
            return;
        }
        ctx.patchState({
            showHeaderLogo: action.show
        });
    }

    @Action(SetShowHeaderOmniSearch)
    setShowHeaderOmniSearch(
        ctx: StateContext<AppStateModel>,
        action: SetShowHeaderOmniSearch
    ) {
        if (ctx.getState().showHeaderOmni == action.show) {
            return;
        }
        ctx.patchState({
            showHeaderOmni: action.show
        });
    }
}
