import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { MessagesState } from '@jobzmall/messages/app/ngxs/state';
import { Conversation } from '@jobzmall/models/conversation.model';
import { UserState } from '@jobzmall/user/ngxs/state';
import { User } from '@jobzmall/models';
import {
    GetConversations,
    MaximizeMessagesWidget,
    PinConversation,
    MinimizeMessagesWidget,
    SetMinimizeWidgetCompletely
} from '@jobzmall/messages/app/ngxs/actions';
import { NavigationEnd, Router } from '@angular/router';
import { AuthState } from '@jobzmall/core';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

@Component({
    selector: 'messages-badge',
    templateUrl: './messages-badge.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    exportAs: 'messages'
})
export class MessagesBadgeComponent implements OnInit, OnChanges, OnDestroy {
    @Select(MessagesState.conversations) conversations$: Observable<
        Array<Conversation>
    >;
    @Select(MessagesState.conversationsLoading) loading$: Observable<boolean>;
    @Select(UserState.user) user$: Observable<User>;
    @Select(MessagesState.unreadCount) unreadCount$: Observable<number>;
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

    @Select(MessagesState.widgetMaximized)
    widgetMaximized$: Observable<boolean>;

    @Select(MessagesState.minimizeWidgetCompletely)
    minimizeWidgetCompletely$: Observable<boolean>;

    maximized: boolean;

    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<void> = new Subject<void>();
    isScreenSmall: boolean;

    /**
     * Constructor
     */
    constructor(
        public router: Router,
        private _store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _viewContainerRef: ViewContainerRef
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {}

    /**
     * On init
     */
    ngOnInit(): void {
        this.widgetMaximized$.subscribe((value: boolean) => {
            this.maximized = value;
        });
        // this.getConversations();
         this._fuseMediaWatcherService.onMediaChange$
             .pipe(takeUntil(this._unsubscribeAll))
             .subscribe(({ matchingAliases }) => {
                 // Check if the screen is small
                 this.isScreenSmall = !matchingAliases.includes('md');
             });
    }

    maximizeMessagesWidget() {
        this._store.dispatch(new MaximizeMessagesWidget());
    }

    minimizeMessagesWidget() {
        this._store.dispatch(new MinimizeMessagesWidget());
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getConversations(refresh = false) {
        this._store
            .dispatch(new GetConversations(refresh))
            .pipe(first())
            .subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    pinConversation(conversation: Conversation) {
        this._store.dispatch(new PinConversation(conversation.channel));
        this.closePanel();
    }

    /**
     * Open the messages panel
     */
    openPanel(): void {
        if (this.router.url.indexOf('/messages') == 0) {
            return;
        }

        // Return if the messages panel or its origin is not defined
        if (!this._messagesPanel || !this._messagesOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._messagesPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the messages panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'jbz-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._messagesOrigin._elementRef.nativeElement
                )
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    isOnline(conversation, currentUser: User) {
        return conversation.members.find(
            (p: any) => p.user.online && p.user.id !== currentUser.id
        );
    }

    otherUser(conversation: Conversation, currentUser: User) {
        return conversation.members.find(
            (p: any) => p.user.id !== currentUser.id
        );
    }
}
