import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

@Pipe({ name: 'dayJsFromUtc' })
export class FromUtcPipe implements PipeTransform {
    constructor() {
        dayjs.extend(utc);
        dayjs.extend(customParseFormat);
    }
    transform(
        value?: string | number | Date | dayjs.Dayjs,
        format?: string,
        strict: boolean = false
    ): any {
        return dayjs.utc(value, format, strict);
    }
}
