  <ng-container *transloco="let t">
      <div (mouseleave)="onClosePanel.emit()"
          class="w-screen max-w-md overflow-hidden rounded-lg shadow-lg header-panel lg:max-w-2xl lg:ml-0 ring-1 ring-black ring-opacity-5">
          <div class="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8 lg:grid-cols-2">
              <a (click)="onClosePanel.emit()" [routerLink]="['/post-job']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <mat-icon class="text-current" [svgIcon]="'mimicons_outline:case'"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.POST_JOB.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.POST_JOB.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/open-store']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <!-- Heroicon name: outline/cursor-click -->
                      <mat-icon class="text-current far fa-store icon-size-4"></mat-icon>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.OPEN_STORE.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.OPEN_STORE.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/open-store']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                      </svg>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.TALENT_REACH.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.TALENT_REACH.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/open-store']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.PRE_SCREENING.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.PRE_SCREENING.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/open-store']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                              d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                      </svg>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.MARKETPLACE.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.MARKETPLACE.DESCRIPTION')}}
                      </p>
                  </div>
              </a>

              <a (click)="onClosePanel.emit()" [routerLink]="['/open-store']"
                  class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                  <div
                      class="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-md bg-violet-100 text-violet-500 sm:h-12 sm:w-12">
                      <!-- Heroicon name: outline/document-report -->
                      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                  </div>
                  <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.DEI.TITLE')}}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                          {{t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.DEI.DESCRIPTION')}}
                      </p>
                  </div>
              </a>
          </div>
          <div class="px-5 py-5 space-y-6 bg-violet-100 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
              <div class="flow-root">
                  <a (click)="onClosePanel.emit()" [routerLink]="['/post-job']"
                      class="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-violet-200">
                      <mat-icon class="text-violet-500" [svgIcon]="'mimicons_outline:case'"></mat-icon>
                      <span class="ml-3">Post Your First Job</span>
                  </a>
              </div>
              <div class="flow-root">
                  <a (click)="openIntercom()" [routerLink]="['/open-store']"
                      class="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md cursor-pointer hover:bg-violet-200">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="flex-shrink-0 w-6 h-6 text-violet-500">
                          <path stroke-linecap="round" stroke-linejoin="round"
                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                      </svg>
                      <span
                          class="ml-3">{{(t('PUBLIC.HEADER.PANEL.RECRUITERS.ITEMS.FIND_QUALIFIED_CANDIDATES'))}}</span>
                  </a>
              </div>
          </div>
      </div>
  </ng-container>
