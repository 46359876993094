import * as dayjs from 'dayjs';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dayJsIsAfter'
})
export class IsAfterPipe implements PipeTransform {
    transform(
        value: string | number | Date | dayjs.Dayjs,
        otherValue: string | number | Date | dayjs.Dayjs,
        unit?: dayjs.OpUnitType
    ): boolean {
        return dayjs(value).isAfter(dayjs(otherValue), unit);
    }
}
