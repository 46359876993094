import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from 'environments/environment';
import { isPlatformServer } from '@angular/common';

@NgModule()
export class IconsModule {
    /**
     * Constructor
     */
    constructor(
        @Inject(PLATFORM_ID) private _platformId: Object,
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    ) {
        const domain = isPlatformServer(_platformId)
            ? environment.baseBrowserUrl
            : '';

        // Register font awesome icons
        this._matIconRegistry.registerFontClassAlias('fa', 'fa');
        this._matIconRegistry.registerFontClassAlias('fab', 'fab');
        this._matIconRegistry.registerFontClassAlias('fad', 'fad');
        this._matIconRegistry.registerFontClassAlias('fas', 'fas');
        this._matIconRegistry.registerFontClassAlias('far', 'far');
        this._matIconRegistry.registerFontClassAlias('fal', 'fal');

        // Register icon sets
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_twotone',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/material-twotone.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/material-outline.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/material-solid.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'iconsmind',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/iconsmind.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/feather.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/heroicons-outline.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/heroicons-solid.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mimicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/mimicons-filled.svg?v=2'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mimicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/mimicons-outline.svg?v=2'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mimicons_muted',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                domain + '/assets/icons/mimicons-muted.svg'
            )
        );
    }
}
