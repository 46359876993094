import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
export class Plan {
    constructor(
        public id: number,
        public name: string,
        public slug: string,
        public stripe_plan: string,
        public amount: number,
        public currency: string,
        public metadata: any,
        public interval_count: number,
        public interval: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PlanAdapter implements Adapter<Plan> {
    adapt(item: any): Plan {
        let plan = new Plan(
            item.id,
            item.name,
            item.slug,
            item.stripe_plan,
            item.amount,
            item.currency,
            item.metadata,
            item.interval_count,
            item.interval
        );
        if (plan.metadata) {
            plan.metadata.descriptionParts = plan.metadata.description ? plan.metadata.description.split(',') : [];
        }
        return plan;
    }
}
