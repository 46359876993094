<div class="w-full p-0 overflow-hidden text-white bg-black shadow-xl rounded-xl transform-gpu translate-z-0">
    <div *ngIf="quote"
        class="relative flex items-center p-6 bg-center bg-no-repeat bg-cover before:block before:bg-black before:bg-opacity-50 before:absolute before:w-full before:h-full before:rounded-xl before:inset-0 before:z-0 ">
        <video [muted]="true" autoplay loop playsinline jm-autoplay src="/assets/video/boards/board-bg.mp4" class="absolute top-0 left-0 hidden object-cover w-full h-full md:block"></video>
        <div class="absolute top-0 left-0 block object-cover w-full h-full bg-cover beams md:hidden"></div>
        <div class="absolute top-0 left-0 hidden w-full h-full bg-black opacity-60 md:block"></div>
        <div class="flex flex-col items-center justify-center w-full z-1">
            <h3 *ngIf="showGreeting" class="text-3xl text-center capitalize text-transform-gradient text-shadow-lg">
                {{greeting | async}}</h3>
            <p class="py-1 text-lg font-light leading-tight text-center text-gray-300 md:px-8 text-shadow-lg quote">
                <span class="text-2xl text-gray-300">&ldquo;</span>{{quote?.content}}<span
                    class="text-2xl text-gray-300">&rdquo;</span></p>
            <p class="text-lg text-center text-gray-300 md:px-8 text-shadow-lg">{{quote?.author}}</p>
        </div>
    </div>
    <div *ngIf="!quote"
        class="relative flex w-full h-32 text-gray-600 bg-gray-200 rounded-xl flex-0 dark:bg-gray-600 animate-pulse">
    </div>
</div>
