import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { InitialData } from 'app/app.types';
import { FuseConfigService } from '@fuse/services/config';
import { Select } from '@ngxs/store';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';
import { AppState } from '@jobzmall/state/ngxs/state';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'thin-layout',
    templateUrl: './thin.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None
})
export class ThinLayoutComponent implements OnInit, OnDestroy {
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

    @Select(AppState.headerTransparent) headerTransparent$: Observable<boolean>;
    @Select(AppState.headerAbsolute) headerAbsolute$: Observable<boolean>;
    @Select(AppState.showHeaderLogo) showHeaderLogo$: Observable<boolean>;
    @Select(AppState.showHeaderOmni) showHeaderOmni$: Observable<boolean>;

    data: InitialData;
    isScreenSmall: boolean;
    private _unsubscribeAll: Subject<void> = new Subject<void>();
    showHeaderLogo: boolean;
    headerTransparent: boolean;
    headerAbsolute: boolean;
    showHeaderOmni: boolean;
    showHeader: boolean;
    showNavigation: boolean;
    showFooter: boolean;
    showBottomNavigation: boolean;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public fuseConfigService: FuseConfigService,
        private _activatedRoute: ActivatedRoute,
        public router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        combineLatest([
            this.headerAbsolute$,
            this.showHeaderOmni$,
            this.headerTransparent$,
            this.showHeaderLogo$,
            this.fuseConfigService.config$
        ])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                ([
                    headerAbsolute,
                    showHeaderOmni,
                    headerTransparent,
                    showHeaderLogo,
                    config
                ]) => {
                    this.headerAbsolute = headerAbsolute;
                    this.showHeaderOmni = showHeaderOmni;
                    this.headerTransparent = headerTransparent;
                    this.showHeaderLogo = showHeaderLogo;
                    this.showHeader = config.showHeader;
                    this.showNavigation = config.showNavigation;
                    this.showFooter = config.showFooter;
                    this.showBottomNavigation = config.showBottomNavigation;
                }
            );

        // Subscribe to the resolved route data
        this._activatedRoute.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: Data) => {
                this.data = data.initialData;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
