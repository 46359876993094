import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ProfileStateModel } from './model';
import {
    OpenEducationDialog,
    OpenExperienceDialog,
    OpenSkillsDialog,
    OpenSoftSkillsDialog,
    OpenCertificationDialog,
    OpenHonorsDialog,
    OpenLanguageDialog,
    StartUploadResume,
    OpenStoryStudioOverlay,
    OpenReferenceDialog,
    OpenValueBadgeDialog,
    OpenCoachingDialog
} from './actions';
import { of } from 'rxjs';

@State<ProfileStateModel>({
    name: 'profile',
    defaults: {}
})
@Injectable()
export class ProfileState {
    constructor() {}

    @Action(OpenSkillsDialog)
    openSkillsDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenSoftSkillsDialog)
    openSoftSkillsDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenExperienceDialog)
    openExperienceDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenEducationDialog)
    openEducationDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenCertificationDialog)
    openCertificationDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenHonorsDialog)
    openHonorsDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenLanguageDialog)
    openLanguageDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenReferenceDialog)
    openReferenceDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenValueBadgeDialog)
    openValueBadgeDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenStoryStudioOverlay)
    openStoryStudioOverlay(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(StartUploadResume)
    startUploadResume(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }

    @Action(OpenCoachingDialog)
    openCoachingDialog(ctx: StateContext<ProfileStateModel>) {
        return of(true);
    }
}
