import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';

export const coachServiceIconMap = {
    'Career Development Coaching': 'fal fa-user-doctor-message',
    'Executive Coaching': 'fas fa-user-tie',
    'Interview Coaching': 'fas fa-handshake',
    'Leadership Development': 'fas fa-stars',
    'Negotiation Coaching': 'fal fa-comment-dollar',
    'Public Speaking': 'fal fa-podium-star',
    'Resume Review': 'fal fa-glasses-round',
    'Resume Writing': 'fal fa-pen-circle',
    Training: 'fal fa-graduation-cap',
    '30-Minute Career Q&A': 'fal fa-comments',
    'Networking Strategy': 'fal fa-chart-network',
    'Job Search Strategy': 'fal fa-briefcase-arrow-right',
    'Cover Letter Writing': 'fal fa-file-signature'
};

const coachServiceColorMap = {
    'Career Development Coaching': 'bg-red-500',
    'Executive Coaching': 'bg-orange-500',
    'Interview Coaching': 'bg-amber-500',
    'Leadership Development': 'bg-yellow-500',
    'Negotiation Coaching': 'bg-lime-500',
    'Public Speaking': 'bg-green-500',
    'Resume Review': 'bg-teal-500',
    'Resume Writing': 'fal fa-pen-circle',
    Training: 'bg-cyan-500',
    '30-Minute Career Q&A': 'bg-sky-500',
    'Networking Strategy': 'bg-blue-500',
    'Job Search Strategy': 'bg-indigo-500',
    'Cover Letter Writing': 'bg-violet-500'
};

export class CoachService {
    id?: number;
    name: string;
    created_at?: Date;
    updated_at?: Date;
    pivot?: any;
    price?: number;
    markup_price?: number;
    currency_type_id?: number;
    symbol?: string;
    description?: string;
    icon: string;
    code?: string;
    slug?: string;
    accepting_payments?: boolean;
    has_existing_order?: boolean;
    duration?: number;

    constructor(
        name?: string,
        id?: number,
        pivot?: any,
        price?: number,
        markup_price?: number,
        currency_type_id?: number,
        created_at?: Date,
        updated_at?: Date,
        symbol?: string,
        description?: string,
        code?: string,
        accepting_payments?: boolean,
        has_existing_order?: boolean,
        slug?: string,
        duration?: number
    ) {
        (this.id = id), (this.name = name);
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.pivot = pivot;
        this.price = price;
        this.markup_price = markup_price;
        this.currency_type_id = currency_type_id;
        this.symbol = symbol;
        this.description = description;
        this.icon = coachServiceIconMap[this.name];
        this.code = code;
        this.accepting_payments = accepting_payments;
        this.has_existing_order = has_existing_order;
        this.slug = slug;
        this.duration = duration;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CoachServiceAdapter implements Adapter<CoachService> {
    adapt(item: any): CoachService {
        return new CoachService(
            item.name,
            item.id,
            item.pivot,
            item.pivot ? item.pivot.price : item.price,
            (item.pivot ? item.pivot.price : item.price)
                ? (item.pivot ? item.pivot.price : item.price) +
                  (item.pivot ? item.pivot.price : item.price) * 0.2
                : 0,
            item.pivot ? item.pivot.currency_type_id : item.currency_type_id,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.pivot ? item.pivot.symbol : item.symbol,
            item.pivot ? item.pivot.description : item.description,
            item.pivot ? item.pivot.code : item.code,
            item.pivot
                ? item.pivot.accepting_payments
                : item.accepting_payments,
            item.pivot
                ? item.pivot.has_existing_order
                : item.has_existing_order,
            item.slug,
            item.pivot ? item.pivot.duration : item.duration
        );
    }
}
