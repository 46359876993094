<!-- Navigation -->
<jbz-vertical-navigation class="text-white bg-gray-900 print:hidden"
    [appearance]="navigationAppearance" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'"
    [navigation]="data.navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <img class="w-8" src="assets/images/logo/logo.svg" alt="Logo image">
        </div>
    </ng-container>
</jbz-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex items-center w-full h-16 px-4 shadow flex-0 md:px-6 z-49 dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
            </button>
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <language></language>
            <jbz-fullscreen></jbz-fullscreen>
            <mobile-omni [appearance]="'bar'"></mobile-omni>

            <messages-badge></messages-badge>
            <notifications></notifications>
            <user-menu></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex items-center justify-start w-full px-4 border-t flex-0 h-14 md:px-6 z-49 bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">JobzMall &copy; {{currentYear}}</span>
    </div>

    <messages-widget></messages-widget>
    <contact-widget></contact-widget>
</div>
