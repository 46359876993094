import {
    Directive,
    OnInit,
    Input,
    HostListener,
    forwardRef
} from '@angular/core';
import { getUrls } from '@jobzmall/core/util/url/get-urls';
import { UntypedFormControl } from '@angular/forms';

@Directive({
    selector: '[forceHttps]',
    providers: []
})
export class ForceHttpsDirective implements OnInit {
    @Input() formControl: UntypedFormControl;

    constructor() {}

    ngOnInit() {}

    @HostListener('blur') forceHttps() {
        if (this.formControl.value) {
            const arr = getUrls(this.formControl.value, {
                normalizeProtocol: true,
                forceHttps: true,
                stripWWW: false
            });
            let url = Array.from(arr)[0] as any;
            if (url) {
                this.formControl.setValue(url);
            }
        }
    }
}
