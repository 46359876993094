import { Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { ConfigService } from '@jobzmall/config';
import { User } from '@jobzmall/models';
import { ProcessLiveNotification } from '@jobzmall/notifications/ngxs/actions';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { WebsocketConnection } from './websocket-connection';
import { Idle } from '@ng-idle/core';

export const WEBSOCKET_SERVICE = new InjectionToken<WebsocketService>(
    'WEBSOCKET_SERVICE'
);

@Injectable()
export class WebsocketService {
    websocket: WebsocketConnection;
    user: User;
    _behaviorSubject = new BehaviorSubject<WebsocketConnection>(undefined);

    constructor(
        private _idle: Idle,
        private _config: ConfigService,
        private _store: Store
    ) {}

    observe(): Observable<WebsocketConnection> {
        return this._behaviorSubject.asObservable();
    }

    init(user: User) {
        this.user = user;
        this.websocket = new WebsocketConnection(this._config, this._store);
        this.websocket.join(`user.${this.user.id}`);
        this.websocket.join('online');
        this._idle.setIdle(300);
        this._idle.setTimeout(5);
        this._behaviorSubject.next(this.websocket);
        this.initUserEvents();
    }

    destroy() {
        this.user = null;
        this._idle.stop();
        if (this.websocket) {
            this.websocket.disconnect();
            this.websocket = null;
        }
    }

    initUserEvents() {
        this._idle.onIdleEnd.subscribe(() => {
            this.websocket.join('online');
            this._idle.watch();
        });
        this._idle.onTimeout.subscribe(() => {
            this.websocket.leave('online');
        });
        this._idle.watch();

        this.websocket
            .private(`user.${this.user.id}`)
            .notification((notification: any) => {
                this._store.dispatch(new ProcessLiveNotification(notification));
            });
    }
}
