import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, tap, first } from 'rxjs/operators';
import { User, Scheme } from '@jobzmall/models';
import { Select, Store } from '@ngxs/store';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { UserState } from '@jobzmall/user/ngxs/state';
import { Logout } from '@jobzmall/core/auth/ngxs/actions';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { cloneDeep } from 'lodash-es';
import { RefreshUser } from '@jobzmall/user/ngxs/actions';
import { UserService } from '@jobzmall/user/user.service';
import { SetAppLoading } from '@jobzmall/state/ngxs/actions';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'userMenu'
})
export class UserMenuComponent
    extends JMBaseComponent
    implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    @Input() showName: boolean = false;

    @Select(UserState.user) user$: Observable<User>;

    private _unsubscribeAll: Subject<void> = new Subject<void>();

    userRO: User;
    user: User;

    isAuto: boolean;
    currentScheme: Scheme;
    autoType: Scheme;

    /**
     * Constructor
     */
    constructor(
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.subscriptions.sink = combineLatest([
            this.user$,
            this._fuseMediaWatcherService.onMediaQueryChange$([
                '(prefers-color-scheme: dark)',
                '(prefers-color-scheme: light)'
            ])
        ]).subscribe(([user, mql]) => {
            this.autoType = mql.breakpoints['(prefers-color-scheme: dark)']
                ? 'dark'
                : 'light';
            if (user) {
                this.userRO = user;
                this.user = cloneDeep(user);
                this.isAuto = this.user.scheme == 'auto';
                this.currentScheme = this.isAuto
                    ? this.autoType
                    : this.user.scheme;
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        super.ngOnDestroy();
    }

    submit() {
        if (!this.loading) {
            this.loading = true;
            this._store.dispatch(new SetAppLoading(true));
            this._changeDetectorRef.markForCheck();
            if (this.isAuto) {
                this.user.scheme = 'auto';
            } else {
                this.user.scheme = this.currentScheme;
            }
            this._userService
                .save({ scheme: this.user.scheme }, this.user.slug)
                .pipe(first())
                .subscribe((user: User) => {
                    this._store.dispatch(new RefreshUser());
                    this.loading = false;
                    this._store.dispatch(new SetAppLoading(false));
                    this._changeDetectorRef.markForCheck();
                });
        }
    }

    setAuto() {
        this.isAuto = true;
        this.currentScheme = this.autoType;
        this.submit();
        this._changeDetectorRef.markForCheck();
    }

    setLight() {
        this.isAuto = false;
        this.currentScheme = 'light';
        this.submit();
        this._changeDetectorRef.markForCheck();
    }

    setDark() {
        this.isAuto = false;
        this.currentScheme = 'dark';
        this.submit();
        this._changeDetectorRef.markForCheck();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this._store.dispatch(new Logout());
    }
}
