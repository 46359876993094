import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';

@Pipe({ name: 'dayJsTimezoneFormat' })
export class dayJsTimezoneFormatPipe implements PipeTransform {
    constructor() {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        dayjs.extend(advancedFormat);
        dayjs.extend(localizedFormat);
    }

    transform(
        value: string | number | Date | dayjs.Dayjs,
        timezone?: string,
        ...args: any[]
    ) {
        if (!value) {
            return '';
        }

        return timezone
            ? dayjs(value).tz(timezone).format(args[0])
            : dayjs(value).tz(dayjs.tz.guess()).format(args[0]);
    }
}
