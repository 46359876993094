import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    Input,
    Inject
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators
} from '@angular/forms';
import { validateAllFormFields } from '@jobzmall/core/util/forms';
import { RefreshUser } from '@jobzmall/user/ngxs/actions';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@jobzmall/user/ngxs/state';
import { User } from '@jobzmall/models';
import { firstValueFrom, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService } from '@jobzmall/user/user.service';
import { FuseAlertType } from '@fuse/components/alert';
import { CountryISO } from '@jobzmall/telephone-input/enums/country-iso.enum';
import { SearchCountryField } from '@jobzmall/telephone-input/enums/search-country-field.enum';
import { TooltipLabel } from '@jobzmall/telephone-input/enums/tooltip-label.enum';
import { JMBaseOverlayComponent } from '@jobzmall/overlays/overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
    selector: 'user-detail-confirmation-overlay',
    templateUrl: './user-detail-confirmation-overlay.component.html',
    styles: [
        `
            user-detail-confirmation-overlay {
                width: 100%;
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class UserDetailConfirmationOverlayComponent
    extends JMBaseOverlayComponent
    implements OnInit
{
    @Input() title = 'Confirm Your Information';
    @Input() showLogo = true;

    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;

    onSuccess = new EventEmitter<User>();

    @Select(UserState.user) user$: Observable<User>;
    user: User;

    step = 'fields';

    form = new UntypedFormGroup({
        first_name: new UntypedFormControl('', [Validators.required]),
        last_name: new UntypedFormControl('', [Validators.required]),
        phone: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(8)
        ])
    });

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    showAlert = false;
    phoneVerifySuccess = false;
    constructor(
        @Inject(JM_OVERLAY_DATA) public data: any,
        private _store: Store,
        private _confirmation: FuseConfirmationService,
        private _userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super(data);
    }

    ngOnInit(): void {
        this.subscriptions.sink = this.user$.subscribe((user: User) => {
            this.user = user;
            if (this.user) {
                this.form.controls.first_name.setValue(user.first_name);
                this.form.controls.last_name.setValue(user.last_name);
                this.form.controls.phone.setValue(user.phone);
                if (
                    !this.user.needs_info &&
                    this.user.phone_valid &&
                    this.phoneVerifySuccess
                ) {
                    this.close();
                    return;
                }

                if (
                    this.user.phone_valid &&
                    !this.phoneVerifySuccess &&
                    !this.user.needs_info
                ) {
                    this.step = 'phone-verify';
                    this._changeDetectorRef.markForCheck();
                } else {
                    this.step = 'fields';
                    this._changeDetectorRef.markForCheck();
                }
            }
        });
    }

    onVerifySuccess($event: any) {
        this.phoneVerifySuccess = true;
        this._store.dispatch(new RefreshUser());
    }

    onSendFail($event: any) {
        if ($event.status == 409) {
            this._confirmation.open({
                title: 'Too many attempts',
                message: `You have requested a one-time code too many times. Please wait 10 minutes before requesting a new one`,
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation',
                    color: 'warn'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: 'Ok',
                        color: 'warn'
                    },
                    cancel: {
                        show: false
                    }
                }
            });
        }
        if ($event.status == 422) {
            firstValueFrom(
                this._confirmation
                    .open({
                        title: 'Invalid Mobile Number',
                        message: `There seems to be an issue sending a text to this number. The number is either a landline or was not provisioned with a valid mobile carrier. Please change the number and try again`,
                        icon: {
                            show: true,
                            name: 'heroicons_outline:exclamation',
                            color: 'warn'
                        },
                        actions: {
                            confirm: {
                                show: true,
                                label: 'Ok',
                                color: 'warn'
                            },
                            cancel: {
                                show: false
                            }
                        }
                    })
                    .afterClosed()
            ).then(() => {
                this.form.get('phone').setValue(null);
                this.step = 'fields';
                validateAllFormFields(this.form);
            });
        }
    }

    confirm(): void {
        if (!this.loading) {
            // Do nothing if the form is invalid
            if (this.form.invalid) {
                this.alert = {
                    type: 'error',
                    message: 'Correct the fields and try again'
                };

                // Show the alert
                this.showAlert = true;
                validateAllFormFields(this.form);
                return;
            } else {
                this.showAlert = false;
            }

            this.loading = true;

            // TODO - Language Selector for language id

            let details: any = {};
            details.first_name = this.form.value.first_name;
            details.last_name = this.form.value.last_name;
            details.phone_country_code = this.form.value.phone.countryCode;
            details.phone = this.form.value.phone.number;
            this._userService
                .updateUserPassword(this.user.slug, {
                    password: this.form.value.password,
                    password_confirmation: this.form.value.password
                })
                .pipe(first())
                .subscribe(() => {
                    this._userService
                        .save(details, this.user.slug)
                        .pipe(first())
                        .subscribe((user: User) => {
                            this._store.dispatch(new RefreshUser());
                        });
                });
        }
    }
}
