import { Scheme, Theme, Screens, Themes } from '@jobzmall/models';
import { Layout } from '../../layout/layout.types';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
    layout: Layout;
    scheme: Scheme;
    theme: Theme;
    screens: Screens;
    themes: Themes;
    showFooterMall: boolean;
    showFooter: boolean;
    showHeader: boolean;
    showNavigation: boolean;
    showBottomNavigation: boolean
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 */
export const appConfig: AppConfig = {
    layout: 'thin',
    scheme: 'auto',
    screens: {
        xs: '0px',
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1440px'
    },
    theme: 'theme-purple',
    themes: [
        {
            id: 'theme-default',
            name: 'Default'
        },
        {
            id: 'theme-brand',
            name: 'Brand'
        },
        {
            id: 'theme-teal',
            name: 'Teal'
        },
        {
            id: 'theme-rose',
            name: 'Rose'
        },
        {
            id: 'theme-purple',
            name: 'Purple'
        },
        {
            id: 'theme-amber',
            name: 'Amber'
        }
    ],
    showFooterMall: false,
    showHeader: true,
    showFooter: true,
    showNavigation: true,
    showBottomNavigation: true
};
