import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'normalizeMarkdown',
    pure: true
})
export class NormalizeMarkdownPipe implements PipeTransform {
    transform(value: string): string {
        return value.replace(/\t/g, '');
    }
}
