import {
    Component,
    OnInit,
    OnDestroy,
    Inject,
    forwardRef,
    Optional
} from '@angular/core';
import { SubSink } from 'subsink';
import { Media } from '../../models/media.model';
import {
    BaseWidget,
    NgAisIndex,
    NgAisInstantSearch
} from 'angular-instantsearch';
import { nanoid } from 'nanoid';
import { InjectionToken } from '@angular/core';

export const JM_AIS_CONNECTOR_DATA = new InjectionToken<{}>(
    'JM_AIS_CONNECTOR_DATA'
);

@Component({
    template: ''
})
export class JMBaseAISComponent
    extends BaseWidget
    implements OnInit, OnDestroy
{
    protected subscriptions = new SubSink();
    public loading = false;

    constructor(
        @Inject(JM_AIS_CONNECTOR_DATA)
        public connectorData: any,
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch
    ) {
        super('Widget');
    }

    ngOnInit(): void {
        this.createWidget(
            this.connectorData.connector,
            this.connectorData.args
        );
        super.ngOnInit();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openIntercom() {
        (window as any).Intercom('showNewMessage');
    }

    openUrl(url: any) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    download(file: Media, name?: string) {
        const anchor = document.createElement('a');
        anchor.target = '_blank';
        anchor.href = file.full_path;
        anchor.download = name || file.src;

        // Append to the DOM
        document.body.appendChild(anchor);

        // Trigger `click` event
        anchor.click();

        // Remove element from DOM
        document.body.removeChild(anchor);
    }
}
