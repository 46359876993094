import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { ContactWidgetComponent } from './contact-widget/contact-widget.component';
import { SupportState } from './ngxs/state';
import { NgxsModule } from '@ngxs/store';

const COMPONENTS = [ContactWidgetComponent];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        NgxsModule.forFeature([SupportState]),
        RouterModule,
        NgPipesModule,
        JMPipesModule,
        JMCommonDirectivesModule,
        JMSharedModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
        CommonModule
    ],
    exports: COMPONENTS,
    providers: []
})
export class JMSupportModule {}
