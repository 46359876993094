import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { getUrls } from '@jobzmall/core/util/url/get-urls';
import { Restangular } from '@jobzmall/core/api';
import { map, first } from 'rxjs/operators';
import { nanoid } from 'nanoid';
import lgVideo from 'lightgallery/plugins/video';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
    selector: 'link-preview',
    templateUrl: './link-preview.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
            link-preview .hide-links a {
                display: none;
            }
    `],
    animations: fuseAnimations
})
export class LinkPreviewComponent extends JMBaseComponent implements OnInit {

    @Input() body: string;
    @Input() disabled: boolean = false;

    settings = {
        counter: false,
        plugins: [lgVideo]
    };

    youtubeEmbedUrl: string;
    vimeoEmbedUrl: string;
    vimeoThumbnailUrl: Observable<string>;

    youtubeId: any;
    vimeoId: any;
    videoUrls = [];
    nonVideoUrls = [];
    og: any;
    id = nanoid();

    constructor(
        private _httpClient: HttpClient,
        private _api: Restangular,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {

    }

    extractVideoId(url: string) {
        let youtubeId: any;
        let youtubeArr: any;
        if (youtubeArr = url.match(/(\?|&)v=([^&#]+)/)) {
            youtubeId = youtubeArr.pop();
        } else if (youtubeArr = url.match(/(\.be\/)+([^\/]+)/)) {
            youtubeId = youtubeArr.pop();
        } else if (youtubeArr = url.match(/(\embed\/)+([^\/]+)/)) {
            youtubeId = youtubeArr.pop().replace('?rel=0', '');
        }
        if (youtubeId) {
            this.youtubeId = youtubeId;
            this.youtubeEmbedUrl = `https://www.youtube.com/embed/${this.youtubeId}`
            return url;
        }

        let vimeoId: any;
        let vimeoArr: any;
        if (vimeoArr = url.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i)) {
            vimeoId = vimeoArr[1];
        }
        if (vimeoId) {
            this.vimeoId = vimeoId;
            this.vimeoEmbedUrl = `https://player.vimeo.com/video/${this.vimeoId}`;
            this.vimeoThumbnailUrl = this._httpClient.get(`https://vimeo.com/api/v2/video/${this.vimeoId}.json`)
            .pipe(first(), map((res: any)=>{
                return res[0].thumbnail_large;
            }))
            return url;
        }

        return null;
    }

    extractVideoURLS(value: string) {
        const arr = getUrls(value, {
            normalizeProtocol: true,
            forceHttps: true,
            stripWWW: false
        });

        const output: Array<string> = [];
        if (arr) {
            arr.forEach((part: any) => {
                const url = this.extractVideoId(part);
                if (url) {
                    output.push(url);
                    this._changeDetectorRef.markForCheck();
                }
            });
            return output;
        } else {
            return [];
        }
    }

    onInnerHtmlRender() {
        if (!this.disabled) {
            this.videoUrls = this.extractVideoURLS(this.body);
            const urls = Array.from(getUrls(this.body, {
                normalizeProtocol: true,
                forceHttps: true,
                stripWWW: false
            }));
            if (urls && urls.length) {
                this.nonVideoUrls = urls.filter(url => !this.videoUrls.includes(url));
            }
            if (!this.videoUrls.length && this.nonVideoUrls.length) {
                this.loading = true;
                this._api.all('data/og')
                    .customPOST({
                        url: this.nonVideoUrls[0]
                    })
                    .pipe(first(), map((res: any) => res.data))
                    .subscribe((data: any) => {
                        if (data instanceof Array) {
                            this.og = null;
                        } else {
                            this.og = data;
                            this.og['url'] = this.nonVideoUrls[0];
                        }
                        this.loading = false;
                        this._changeDetectorRef.markForCheck();
                    });
            }
        }
    }
}
