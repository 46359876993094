export const JBZ_CONFIG = {
    system: {
        applicationName: 'APP_NAME',
        applicationUrl: '{baseUrl}'
    },
    seo: {
        defaultPageTitle: 'DEFAULT_TITLE',
        pageTitlePositioning: 10,
        pageTitleSeparator: ' | ',
        defaultMetaDescription: 'DEFAULT_META_DESCRIPTION',
        keywords:
            'JobzMall, jobz, jobs, jobs near me, high paying jobs, jobs for millenials, work from home jobs, jobs hiring near me, careers, career, marketplace, video, talent, video talent, ats systems, hiring, post a job'
    },
    i18n: {
        defaultLanguage: {
            id: 1,
            code: 'en',
            name: 'English',
            culture: 'en-US',
            countryCode: 'us'
        },
        availableLanguages: [
            {
                id: 1,
                code: 'en',
                name: 'English',
                culture: 'en-US',
                countryCode: 'us'
            },
            {
                id: 27,
                code: 'es',
                name: 'Español',
                culture: 'es-MX',
                countryCode: 'mx'
            },
            {
                id: 122,
                code: 'tr',
                name: 'Türkçe',
                culture: 'tr-TR',
                countryCode: 'tr'
            }
        ],
        useLocalizedRoutes: false
    }
};
