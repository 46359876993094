import { Injectable, PLATFORM_ID } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LanguageStateModel } from './model';
import { Organization } from '../../models/organization.model';
import { WebsocketConnection } from '../../core/websocket/websocket-connection';
import { TranslocoService, Translation } from '@ngneat/transloco';
import { ChangeLanguage, SetBrowserLanguage } from './actions';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ConfigService } from '../../config/config.service';
import { WindowService } from '../../core/browser/window.service';
import { Language } from '../../models';
import { of, Observable } from 'rxjs';

@State<LanguageStateModel>({
    name: 'language',
    defaults: {
        currentLanguage: {
            id: 1,
            code: 'en',
            name: 'English',
            culture: 'en-US'
        }
    }
})
@Injectable()
export class LanguageState {
    constructor(
        private _config: ConfigService,
        private _transloco: TranslocoService,
        private readonly win: WindowService,
        @Inject(PLATFORM_ID) private readonly _platformId: any
    ) {}

    @Selector()
    static currentLanguage(state: LanguageStateModel) {
        return state.currentLanguage;
    }

    @Action(ChangeLanguage)
    changeLanguage(
        ctx: StateContext<LanguageStateModel>,
        action: ChangeLanguage
    ): Observable<Translation> {
        const languages = this._config.getSettings('i18n.availableLanguages');
        const lang = languages.find((lang: Language) => lang.id == action.id);
        if (lang) {
            ctx.patchState({
                currentLanguage: lang
            });
            // TODO Change to current language once translations are available
            this._transloco.setActiveLang('en');

            return this._transloco.load('en');
        }
        return this._transloco.load('en');
    }

    @Action(SetBrowserLanguage)
    setBrowserLanguage(
        ctx: StateContext<LanguageStateModel>,
        action: SetBrowserLanguage
    ): Observable<Translation> {
        const code = isPlatformBrowser(this._platformId)
            ? (this.win.navigator.language &&
                  this.win.navigator.language.split('-')[0]) ||
              this._transloco.getDefaultLang()
            : this._transloco.getDefaultLang();
        // TODO: Change to current language once translations are available
        this._transloco.setActiveLang('en');
        return this._transloco.load('en');
    }
}
