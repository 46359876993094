import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    Inject,
    ChangeDetectorRef
} from '@angular/core';

import { SimpleChanges } from '@angular/core';
import { JMVideoStorage } from './jm-video-storage';
import { Media, MediaAdapter } from '@jobzmall/models';
import { ViewService } from '@jobzmall/views';
import {
    Restangular,
    WebsocketService,
    WEBSOCKET_SERVICE
} from '@jobzmall/core';
import { first, map, takeWhile } from 'rxjs/operators';
import { JMBaseComponent } from '@jobzmall/components';

@Component({
    selector: 'jm-video',
    templateUrl: './jm-video.component.html',
    styleUrls: ['./jm-video.component.scss']
})
export class JMVideoComponent extends JMBaseComponent implements OnInit {
    // Plyr Two-Way Binding
    plyrComponentValue: Plyr;
    @Output() plyrComponentChange = new EventEmitter<any>();
    @Input()
    get plyrComponent() {
        return this.plyrComponentValue;
    }
    set plyrComponent(val) {
        this.plyrComponentValue = val;
        this.plyrComponentChange.emit(this.plyrComponentValue);
    }

    mediaValue: Media | any;
    @Output() mediaChange = new EventEmitter<any>();
    @Input()
    get media() {
        return this.mediaValue;
    }
    set media(val) {
        this.mediaValue = val;
        this.mediaChange.emit(this.mediaValue);
    }

    @Input() autoplay = false;
    @Input() playsInline = true;
    @Input() checkAvailabilityOnLoad = false;
    @Input() usePendingIfUnavailable = false;

    @Output() onPlayed: EventEmitter<any> = new EventEmitter();
    @Output() onPaused: EventEmitter<any> = new EventEmitter();

    plyrOptions: any = {
        fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true,
            container: null
        }
    };

    ready = false;
    loading = false;
    failed = false;
    show = true;

    firstPlay = true;

    storage: JMVideoStorage;

    youtubeId;
    vimeoId;

    handlers: any = {};

    init = false;

    constructor(
        public view: ViewService,
        @Inject(WEBSOCKET_SERVICE) private _websocketService: WebsocketService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _api: Restangular,
        private _mediaAdapter: MediaAdapter
    ) {
        super();
    }

    ngOnInit(): void {
        if (!(this.media instanceof Media)) {
            this.media = this._mediaAdapter.adapt(this.media);
            this.extractVideoId(this.media.full_path);
            this.init = true;
        } else {
            this.extractVideoId(this.media.full_path);
            this.init = true;
        }
        this.listenToAvailable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (changes.media) {
            if (!(this.media instanceof Media)) {
                this.media = this._mediaAdapter.adapt(this.media);
                this.extractVideoId(this.media.full_path);
                this.init = true;
            } else {
                this.extractVideoId(this.media.full_path);
                this.init = true;
            }
        }
    }

    listenToAvailable() {
        if (this.checkAvailabilityOnLoad) {
            this._api
                .all(`media/${this.media.id}/available`)
                .customGET()
                .pipe(
                    first(),
                    map((res: any) => res.data)
                )
                .subscribe((available) => {
                    this.media.available = available;
                    this._changeDetectorRef.markForCheck();
                });
        }

        if (!this.media.available && this.media.id) {
            this.subscriptions.sink = this._websocketService
                .observe()
                .pipe(takeWhile((val) => val === undefined, true))
                .subscribe((val) => {
                    this._websocketService.websocket
                        .private(`media.${this.media.id}`)
                        .listen('.media-available', (event: any) => {
                            this.media.available = true;
                            this._changeDetectorRef.markForCheck();
                            this._changeDetectorRef.detectChanges();
                        });
                });
        }
    }

    extractVideoId(url: string) {
        let youtubeId: any;
        let youtubeArr: any;
        if ((youtubeArr = url.match(/(\?|&)v=([^&#]+)/))) {
            youtubeId = youtubeArr.pop();
        } else if ((youtubeArr = url.match(/(\.be\/)+([^\/]+)/))) {
            youtubeId = youtubeArr.pop();
        } else if ((youtubeArr = url.match(/(\embed\/)+([^\/]+)/))) {
            youtubeId = youtubeArr.pop().replace('?rel=0', '');
        }
        if (youtubeId) {
            this.youtubeId = youtubeId;
            return url;
        }

        let vimeoId: any;
        let vimeoArr: any;
        if (
            (vimeoArr = url.match(
                /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
            ))
        ) {
            vimeoId = vimeoArr[1];
        }
        if (vimeoId) {
            this.vimeoId = vimeoId;
            return url;
        }

        return null;
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
    }
}
