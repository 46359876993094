import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniqueEmailDirective } from './directives/unique-email.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { UniqueSlugDirective } from './directives/unique-slug.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

const COMPONENTS = [
    UniqueSlugDirective,
    DisableControlDirective,
    AutofocusDirective,
    UniqueEmailDirective
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule
    ],
    exports: [
        ...COMPONENTS,
        ...[
            FormsModule,
            ReactiveFormsModule,
            MatInputModule,
            MatFormFieldModule
        ]
    ],
    providers: []
})
export class JMFormsModule {}
