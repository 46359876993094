import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    ElementRef,
    ViewChild,
    HostListener,
    NgZone,
    Inject,
    SimpleChanges
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Conversation, Message, User } from '@jobzmall/models';
import { NgForm } from '@angular/forms';
import { UserState } from '@jobzmall/user';
import { Select, Store, ofActionCompleted, Actions } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import {
    AddMessage,
    BlockConversationMember,
    MuteConversation,
    ProcessReceiverMessageAdded,
    ReadConversation,
    UnmuteConversation,
    UnpinConversation,
    MaximizeConversation,
    MinimizeConversation,
    ProcessReceiverMessageRead,
    ProcessReceiverBulkRead,
    ClearConversationMessageLimit
} from '../app/ngxs/actions';
import { Channel, PusherPrivateChannel } from 'laravel-echo/dist/channel';
import { MessageService, MESSAGE_SERVICE } from '../message.service';
import { ChatScrollDirective } from '../directives/chatscroll.directive';
import { first } from 'rxjs/operators';
import { ArchiveConversation } from '@jobzmall/messages/app/ngxs/actions';
import { cloneDeep } from 'lodash-es';
import { WebsocketService, WEBSOCKET_SERVICE } from '@jobzmall/core';
import { nanoid } from 'nanoid';
import { SoundService } from '@jobzmall/sound';
import { MessageAdapter } from '@jobzmall/models/message.model';
import { MessagesState } from '../app/ngxs/state';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReportDialogComponent } from '@jobzmall/report';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { NetworkConnection } from '@jobzmall/core/browser/network-connection';
import { AcceptRequest } from '../app/ngxs/actions';
import { VideoMeetingDialogComponent } from '@jobzmall/video/video-meetings/video-meeting-dialog/video-meeting-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

const mime = require('mime');

@Component({
    selector: 'messages-widget-conversation',
    templateUrl: './messages-widget-conversation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class MessagesWidgetConversationComponent
    extends JMBaseComponent
    implements OnInit
{
    @Input() conversation: Conversation;

    @Select(UserState.user) user$: Observable<User>;
    currentUser: User;

    @ViewChild('messageInput') messageInput: ElementRef;
    @ViewChild('replyForm') replyForm: NgForm;
    @ViewChild(ChatScrollDirective) chatScrollDirective: ChatScrollDirective;

    @Select(MessagesState.widgetMaximized)
    widgetMaximized$: Observable<boolean>;
    @Select(MessagesState.minimizeWidgetCompletely)
    minimizeWidgetCompletely$: Observable<boolean>;

    widgetMaximized: boolean;
    minimizeWidgetCompletely: boolean;

    channel: Channel;

    messages: Array<Message> = [];
    messagesLoading = false;
    messagesEnd = false;

    uploads: Array<File> = [];
    othersTyping = false;
    typingTimer: any;
    uploading = false;
    options: any;
    bannerMessage: string;
    isClosed: boolean;

    showVideoMeeting: boolean;

    constructor(
        @Inject(WEBSOCKET_SERVICE) private _websocketService: WebsocketService,
        @Inject(MESSAGE_SERVICE) private _messageService: MessageService,
        private _ngZone: NgZone,
        private _confirmation: FuseConfirmationService,
        private _messageAdapter: MessageAdapter,
        private _sound: SoundService,
        private _actions: Actions,
        private _snackbar: MatSnackBar,
        private _dialog: MatDialog,
        private _router: Router,
        private _store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Decorated methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resize on 'input' and 'ngModelChange' events
     *
     * @private
     */
    @HostListener('input')
    @HostListener('ngModelChange')
    private _resizeMessageInput(): void {
        // This doesn't need to trigger Angular's change detection by itself
        this._ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                // Set the height to 'auto' so we can correctly read the scrollHeight
                this.messageInput.nativeElement.style.height = 'auto';

                // Detect the changes so the height is applied
                this._changeDetectorRef.detectChanges();

                // Get the scrollHeight and subtract the vertical padding
                this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;

                // Detect the changes one more time to apply the final height
                this._changeDetectorRef.detectChanges();
            });
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.startListeningToConversation();

        this.setFilepondOptions();

        this.subscriptions.sink = this.user$.subscribe((user: User) => {
            this.currentUser = user;
            if (this.currentUser) {
                this.setState(this.conversation);
            }
        });

        this.subscriptions.sink = combineLatest([
            this.widgetMaximized$,
            this.minimizeWidgetCompletely$
        ]).subscribe(([widgetMaximized, minimizeWidgetCompletely]) => {
            this.widgetMaximized = widgetMaximized;
            this.minimizeWidgetCompletely = minimizeWidgetCompletely;
            this._changeDetectorRef.markForCheck();
        });

        if (!this.messages.length) {
            this.getMessages();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.stopListeningToConversation();
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    get isMaximized() {
        return (
            (this.widgetMaximized &&
                this.conversation.maximized &&
                this.minimizeWidgetCompletely) ||
            (this.conversation.maximized && !this.minimizeWidgetCompletely)
        );
    }

    unpin(conversation: Conversation) {
        this.stopListeningToConversation();
        this._store.dispatch(new UnpinConversation(conversation.channel));
    }

    getMessages(refresh = false) {
        if (!this.messagesLoading && !this.messagesEnd) {
            this.messagesLoading = true;
            this._changeDetectorRef.markForCheck();

            if (this.chatScrollDirective) {
                this.chatScrollDirective.prepareFor('up');
                setTimeout(() => this.chatScrollDirective.restore());
            }

            let last_id = this.messages[0]?.id;
            this._messageService
                .getConversationMessages(this.conversation.channel, last_id)
                .pipe(first())
                .subscribe((messages: Array<Message>) => {
                    if (messages.length) {
                        if (messages.length < 10) {
                            this.messagesEnd = true;
                        }
                        messages.forEach((message: Message) => {
                            this.messages.unshift(message);
                        });
                    } else {
                        this.messagesEnd = true;
                    }
                    this.messagesLoading = false;
                    this._changeDetectorRef.markForCheck();
                });
        }
    }

    scrollToBottom(): void {
        setTimeout(() => {
            this.chatScrollDirective.elementRef.nativeElement.scrollTop =
                this.chatScrollDirective.elementRef.nativeElement.scrollHeight;
        });
    }

    broadcastTyping() {
        setTimeout(() => {
            let user = this._store.selectSnapshot(UserState.user);
            (this.channel as PusherPrivateChannel).whisper('typing', {
                user,
                typing: true
            });
        }, 300);
        this._changeDetectorRef.markForCheck();
    }

    onTyping() {
        this.othersTyping = true;
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
            this.othersTyping = false;
            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
        }, 1200);
    }

    /**
     * Ready to reply
     */
    readyToReply(): void {
        setTimeout(() => {
            this.focusMessageInput();
            this.scrollToBottom();
        });
    }

    /**
     * Focus to the message input
     */
    focusMessageInput(): void {
        setTimeout(() => {
            this.messageInput.nativeElement.focus();
        });
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (
            !this.replyForm.form.value.message &&
            !this.uploads.length &&
            !this.loading
        ) {
            return;
        }

        if (
            this.conversation.message_limit &&
            this.messages.length >= this.conversation.message_limit
        ) {
            return;
        }

        if (
            this.replyForm.form.value.message &&
            this.replyForm.form.value.message.trim().length === 0
        ) {
            return;
        }

        let id = nanoid();

        this.messages.push(
            new Message(
                id,
                this.replyForm.form.value.message,
                { has_media: this.uploads.length > 0 },
                this.currentUser,
                this.conversation,
                new Date(),
                new Date(),
                'user',
                [],
                this.uploads.length,
                null,
                !NetworkConnection.online,
                id
            )
        );
        this._changeDetectorRef.markForCheck();

        if (!NetworkConnection.online) {
            return;
        }

        this._sound
            .play('/assets/sounds/add-message.m4a')
            .pipe(first())
            .subscribe(() => {});

        firstValueFrom(
            this._messageService.addMessageToConversation(
                this.conversation.channel,
                {
                    text: this.replyForm.form.value.message,
                    temp_id: id,
                    uploads: this.uploads
                }
            )
        ).then(
            (message: Message) => {
                this.messages[this.messages.findIndex((m) => m.id == id)] =
                    message;
                this.uploads = [];
                this._changeDetectorRef.markForCheck();
            },
            () => {
                this.uploads = [];
                this._changeDetectorRef.markForCheck();
            }
        );

        this.uploading = false;
        this.replyForm.reset();
        this.scrollToBottom();
        this._resizeMessageInput();
    }

    remove(message: Message) {
        this._confirmation
            .open({
                title: 'Unsend Message',
                message:
                    'Are you sure you want to unsend this message? It cannot be undone.',
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation',
                    color: 'warn'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: 'Unsend Message',
                        color: 'warn'
                    },
                    cancel: {
                        show: true,
                        label: 'Cancel'
                    }
                }
            })
            .afterClosed()
            .pipe(first())
            .subscribe((result: string) => {
                if (result == 'confirmed') {
                    this.subscriptions.sink = this._messageService
                        .unsendMessageFromConversation(
                            this.conversation.channel,
                            message.id
                        )
                        .pipe(first())
                        .subscribe(() => {
                            message.deleted_at = new Date();
                        });
                }
            });
    }

    /**
     * Toggle mute notifications
     */
    toggleMuteNotifications(): void {
        !this.conversation.muted
            ? this._store.dispatch(
                  new MuteConversation(this.conversation.channel)
              )
            : this._store.dispatch(
                  new UnmuteConversation(this.conversation.channel)
              );
    }

    archive() {
        this._store.dispatch(
            new ArchiveConversation(this.conversation.channel)
        );
    }

    acceptRequest() {
        this._store.dispatch(new AcceptRequest(this.conversation.channel));
        let convo = cloneDeep(this.conversation);
        convo.is_request = false;
        convo.message_limit = 0;
        this.conversation = convo;
        this._changeDetectorRef.markForCheck();
    }

    block() {
        this.subscriptions.sink = this._store
            .dispatch(
                new BlockConversationMember(
                    this.otherUser(
                        this.conversation,
                        this._store.selectSnapshot(UserState.user)
                    )
                )
            )
            .pipe(first())
            .subscribe((value) => {
                if (value) {
                    this._confirmation
                        .open({
                            title: 'Report User',
                            message:
                                'You have successfully blocked this user. Would you like to report this user to the JobzMall team as well?',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:question-mark-circle',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Report',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: true,
                                    label: "I'm okay for now"
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                this.report(
                                    this.conversation,
                                    this._store.selectSnapshot(UserState.user)
                                );
                            }
                        });
                }
            });
    }

    report(conversation: Conversation, currentUser: User) {
        this._dialog.open(ReportDialogComponent, {
            data: {
                entity: this.otherUser(conversation, currentUser).user,
                type: 'user'
            }
        });
    }

    startListeningToConversation() {
        if (this.channel) {
            this.stopListeningToConversation();
        }

        this.channel = this._websocketService.websocket.private(
            `receiver.${this.conversation.message_receiver_id}`
        );

        this.channel.listenForWhisper('typing', this.onTyping.bind(this));
        this.channel.listen('.message-added', (event: any) => {
            this.onReceiverMessageAdded(event);
        });
        this.channel.listen('.message-removed', (event: any) => {
            this.onReceiverMessageRemoved(event);
        });
        this.channel.listen('.message-updated', (event: any) => {
            this.onReceiverMessageUpdated(event);
        });
        this.channel.listen('.message-read', (event: any) => {
            this.onReceiverMessageRead(event);
        });
        this.channel.listen('.bulk-read', (event: any) => {
            this.onReceiverBulkRead(event);
        });
    }

    stopListeningToConversation() {
        if (this.channel) {
            this.channel.stopListeningForWhisper('typing');
            this.channel.stopListening('.message-added');
            this.channel.stopListening('.message-removed');
            this.channel.stopListening('.message-updated');
            this.channel.stopListening('.message-read');
            this.channel.stopListening('.bulk-read');
            this.channel = null;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Websocket Event Methods
    // -----------------------------------------------------------------------------------------------------
    onReceiverMessageAdded(event) {
        if (
            event.receiver_id === this.conversation.message_receiver_id &&
            event.sender_id !== this.currentUser.message_sender_id
        ) {
            if (this.conversation.maximized) {
                this.messages.push(this._messageAdapter.adapt(event));
                this._changeDetectorRef.markForCheck();
                this.scrollToBottom();
                if (!this.conversation.is_request) {
                    this._store.dispatch(
                        new ReadConversation(this.conversation.channel)
                    );
                    this._sound
                        .play('/assets/sounds/message-received.m4a')
                        .pipe(first())
                        .subscribe(() => {});
                }
                this._store.dispatch(
                    new ClearConversationMessageLimit(this.conversation.channel)
                );
            }
        } else if (
            event.receiver_id === this.conversation.message_receiver_id &&
            event.sender_id === this.currentUser.message_sender_id
        ) {
            if (this.conversation.maximized) {
                let existingMessageIndex = this.messages.findIndex(
                    (m) => m.temp_id == event.temp_id
                );
                if (existingMessageIndex > -1) {
                    this.messages[existingMessageIndex] =
                        this._messageAdapter.adapt(event);
                    this._changeDetectorRef.markForCheck();
                } else {
                    this.messages.push(this._messageAdapter.adapt(event));
                }
                this.scrollToBottom();
            }
        }
    }

    onReceiverMessageRemoved(event) {
        if (this.conversation.message_receiver_id === event.receiver_id) {
            let message = this._messageAdapter.adapt(event);
            this.messages[this.messages.findIndex((m) => m.id == message.id)] =
                message;
            this._changeDetectorRef.markForCheck();
        }
    }

    onReceiverMessageUpdated(event) {
        if (this.conversation.message_receiver_id === event.receiver_id) {
            let message = this._messageAdapter.adapt(event);
            this.messages[this.messages.findIndex((m) => m.id == message.id)] =
                message;
            this._changeDetectorRef.markForCheck();
        }
    }

    onReceiverMessageRead(event) {
        this._store.dispatch(new ProcessReceiverMessageRead(event));
    }

    onReceiverBulkRead(event) {
        this._store.dispatch(new ProcessReceiverBulkRead(event));
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    isOnline(conversation: Conversation, currentUser: User) {
        return conversation.members.find(
            (p: any) => p.user.online && p.user.id !== currentUser.id
        );
    }

    otherUser(conversation: Conversation, currentUser: User) {
        return conversation.members.find(
            (p: any) => p.user.id !== currentUser.id
        );
    }

    isMine(message: Message, currentUser: User) {
        return (
            message &&
            message.type === 'user' &&
            message.sender.id === currentUser.id
        );
    }

    adminUser(conversation: Conversation) {
        return conversation.members.find((p: any) => p.role_id == 6);
    }

    scheduleVideoCall() {
        this._dialog
            .open(VideoMeetingDialogComponent, {
                data: {
                    subject_type: this.conversation.subject_type,
                    subject_id: this.conversation.subject_id,
                    user: this.conversation.subject.user
                }
            })
            .afterClosed()
            .pipe(first())
            .subscribe((meeting: any) => {
                if (meeting) {
                    this._snackbar.open(
                        'Meeting Scheduled Successfully',
                        'OK',
                        {
                            duration: 3000,
                            panelClass: ['jobz-snackbar']
                        }
                    );
                }
                this._changeDetectorRef.markForCheck();
            });
    }

    goToTarget($event) {
        $event.stopPropagation();

        const currentUser = this._store.selectSnapshot(UserState.user);

        switch (this.conversation.subject_type) {
            case 'application':
                if (this.conversation.subject.user.id == currentUser.id) {
                    this._router.navigate([
                        '/' + this.conversation.source.slug
                    ]);
                } else {
                    this._router.navigate([
                        '/' +
                            this.conversation.source.slug +
                            '/dashboard/candidates/app/' +
                            this.conversation.subject.id
                    ]);
                }
                break;
            case 'candidate':
                if (this.conversation.subject.user.id == currentUser.id) {
                    this._router.navigate([
                        '/' + this.conversation.source.slug
                    ]);
                } else {
                    this._router.navigate([
                        '/' +
                            this.conversation.source.slug +
                            '/dashboard/talent/detail/' +
                            this.conversation.subject.user_id
                    ]);
                }
                break;
            default:
                let user = this.otherUser(this.conversation, currentUser).user;
                this._router.navigate(['/@' + user.slug]);
                break;
        }
    }

    maximize() {
        this._store.dispatch(
            new MaximizeConversation(this.conversation.channel)
        );
    }

    minimize() {
        this._store.dispatch(
            new MinimizeConversation(this.conversation.channel)
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(
        message: Message,
        messages: Array<Message>,
        i
    ): boolean {
        return (
            i === 0 ||
            (messages[i - 1] && messages[i - 1].sender.id !== message.sender.id)
        );
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message: Message, messages: Array<any>, i): boolean {
        return (
            i === messages.length - 1 ||
            (messages[i + 1] && messages[i + 1].sender.id !== message.sender.id)
        );
    }

    openMessagePractices() {
        this._confirmation.open({
            title: `Messaging Information`,
            message: `
             <div class="prose prose-sm">
                <p>While we do our best to build tools for your well-being, there are malicious parties out there that may also use this platform for their bad intent. As we do our best to catch and terminate such actors on JobzMall, keep these in mind for your safety when interacting with other users. At any point you can contact us for immediate help.</p>
                <ul>
                    <li>Do not provide any financial information, payment, or account credentials as part of the application process. Legitimate companies should not require transfers, checks or the wiring of funds as a condition of the application process.</li>
                    <li>Do not provide any personal information such as social security number via JobzMall. It is really not needed.</li>
                </ul>
              </div>
            `,
            icon: {
                show: true,
                name: 'heroicons_outline:information-circle',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Ok',
                    color: 'primary'
                },
                cancel: {
                    show: false,
                    label: 'Cancel'
                }
            }
        });
    }

    handleAddFile($event: any) {
        this.uploads.push($event.file.file);
    }

    handleRemoveFile($event: any) {
        this.uploads.splice(this.uploads.indexOf($event.file.file), 1);
        if (this.uploads.length == 0) {
            this.uploading = false;
            this._changeDetectorRef.markForCheck();
        }
    }

    setFilepondOptions() {
        const mimes = [
            mime.getType('.pages'),
            mime.getType('.doc'),
            mime.getType('.docx'),
            mime.getType('.pdf')
        ];

        this.options = {
            acceptedFileTypes: ['image/*', 'video/*', ...mimes],
            maxFiles: 8,
            allowMultiple: true,
            instantUpload: false,
            labelIdle: `Drag & Drop your files or <span class="filepond--label-action">Browse</span>`,
            // styleLoadIndicatorPosition: 'center bottom',
            // styleProgressIndicatorPosition: 'right bottom',
            // styleButtonRemoveItemPosition: 'left bottom',
            // styleButtonProcessItemPosition: 'right bottom',
            labelButtonProcessItem: 'Upload',
            allowImageEditor: false,
            allowFilePoster: false,
            imageEditorInstantEdit: false,
            allowImagePreview: false,
            allowImageTransform: false,
            allowImageResize: false,
            allowImageCrop: false,
            allowImageExifOrientation: false,
            allowFileSizeValidation: true,
            maxFileSize: '200MB'
        };
    }

    setState(conversation: Conversation) {
        if (conversation) {
            if (conversation.subject_type == 'application') {
                this.isClosed =
                    conversation.subject.status_id == 5 ||
                    conversation.subject.status_id == 6 ||
                    conversation.subject.status_id == 7 ||
                    conversation.subject.status_id == 8;

                // If I am the applicant, get the admin user/assignee
                if (this.currentUser.id == conversation.subject.user.id) {
                    if (conversation.subject.job) {
                        this.bannerMessage = `Conversation with ${conversation.source.name} for ${conversation.subject.job.title}`;
                    } else {
                        this.bannerMessage = `Conversation with ${conversation.source.name} for Direct Apply`;
                    }
                } else {
                    if (conversation.subject.job) {
                        this.bannerMessage = `Conversation with ${conversation.subject.user.first_name} for ${conversation.subject.job.title}`;
                    } else {
                        this.bannerMessage = `Conversation with ${conversation.subject.user.first_name} for Direct Apply`;
                    }
                    this.showVideoMeeting = !this.isClosed;
                }
            }
            if (conversation.subject_type == 'coach_order') {
                if (this.currentUser.id == conversation.subject.user_id) {
                    this.bannerMessage = `Conversation with regarding your Genius Order`;
                } else {
                    this.bannerMessage = `Conversation regarding ${conversation.subject.service.name} Order`;
                    this.showVideoMeeting = true;
                }
            }
            if (conversation.subject_type == 'candidate') {
                // If I am the candidate, get the admin user/assignee
                if (this.currentUser.id == conversation.subject.user.id) {
                    this.bannerMessage = `Conversation with ${conversation.source.name} through Talent Bank`;
                } else {
                    this.bannerMessage = `Conversation with ${conversation.subject.user.first_name} through Talent Bank`;
                    this.showVideoMeeting = true;
                }
            }
        }
    }
}
