import {
    APP_INITIALIZER,
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf
} from '@angular/core';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { AuthModule } from './auth/auth.module';
import { JMBaseComponent } from '../components/base/base.component';
import { HttpInterceptorModule } from './http/http-interceptor.module';
import { IconsModule } from './icons/icons.module';
import { RestangularConfigFactory } from './api/config';
import { TranslocoCoreModule } from './transloco/transloco.module';
import { configLoaderFactory } from './config/config.factory';
import { HttpClient } from '@angular/common/http';
import { metaConfigFactory } from './config/meta.factory';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigLoader, ConfigModule } from '../config';
import { MetaLoader } from '../meta/meta.loader';
import { RestangularModule } from './api';
import { ConsoleService } from './browser/console.service';
import { WindowService } from './browser/window.service';
import { JM_STATES } from '../state';
import { DataService } from './data/data.service';
import { WebsocketService } from '@jobzmall/core/websocket/websocket.service';
import { WEBSOCKET_SERVICE } from './websocket/websocket.service';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { SoundService } from '@jobzmall/sound/sound.service';
import { MessageService } from '@jobzmall/messages/message.service';
import { MESSAGE_SERVICE } from '../messages/message.service';
import { ViewService } from '@jobzmall/views/view.service';
import { NgCircleProgressModule } from '@jobzmall/progress-circle/progress-circle.module';

import { NgxMaskModule } from 'ngx-mask';
import { MetaModule } from '@jobzmall/meta';
import { UploadService } from './api/uploads/upload.service';
import { FuseConfirmationService } from '@fuse/services/confirmation/confirmation.service';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgIdleModule } from '@ng-idle/core';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CaptchaService } from './services/captcha.service';
import { environment } from 'environments/environment';
import { FingerprintService } from './api/fingerprint.service';
import QuillMarkdown from 'quilljs-markdown';
import { QuillModule } from '@jobzmall/forms/quill/quill.module';

const COMPONENTS = [JMBaseComponent];

const windowFactory = () => window;
const consoleFactory = () => console;

export function websocketFactory(websocket: WebsocketService) {
    return websocket;
}
@NgModule({
    declarations: COMPONENTS,
    imports: [
        NgxStripeModule.forRoot(environment.stripeKey),
        NgIdleModule.forRoot(),
        NgxPageScrollModule,
        NgxPageScrollCoreModule,
        ConfigModule.forRoot(),
        TranslocoCoreModule,
        QuillModule.forRoot(),
        IconsModule,
        HttpInterceptorModule,
        AuthModule,
        NgxCaptchaModule,
        // TODO: Bring this back when we want to use Fingerprint Pro
        // FingerprintjsProAngularModule.forRoot({
        //     loadOptions: {
        //         apiKey: 'JmTYIwAwGjLy4oiGSAYL'
        //         // region: "eu",
        //         // endpoint: "<CUSTOM_ENDPOINT>",
        //         // scriptUrlPattern: "<CUSTOM_SCRIPT_URL>"
        //     },
        //     cacheLocation: CacheLocation.LocalStorage,
        //     cacheTimeInSeconds: 60 * 60 * 24
        // }),
        NgxsModule.forRoot(JM_STATES, {
            developmentMode: !environment.production
        }),
        NgxsStoragePluginModule.forRoot({
            key: ['auth.token', 'app.cookiesAccepted', 'admin.inflate']
        }),
        NgxsResetPluginModule.forRoot(),
        RestangularModule.forRoot(
            [Store, FingerprintService, FuseConfirmationService],
            RestangularConfigFactory
        ),
        NgCircleProgressModule.forRoot({
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#9333ea',
            innerStrokeColor: '#9333ea',
            titleColor: '#9333ea',
            animationDuration: 300
        }),
        NgxMaskModule.forRoot(),
        MetaModule.forRoot()
    ],
    exports: COMPONENTS,
    providers: [
        FingerprintService,
        {
            provide: APP_INITIALIZER,
            useFactory: (fingerprint: FingerprintService) => {
                return () => fingerprint.init();
            },
            deps: [FingerprintService],
            multi: true
        }
    ]
})
export class JMCoreModule {
    static forRoot(): ModuleWithProviders<JMCoreModule> {
        return {
            ngModule: JMCoreModule,
            providers: [
                CaptchaService,
                UploadService,
                DataService,
                SoundService,
                ViewService,
                {
                    provide: WindowService,
                    useFactory: windowFactory
                },
                {
                    provide: ConsoleService,
                    useFactory: consoleFactory
                },
                {
                    provide: ConfigLoader,
                    useFactory: configLoaderFactory,
                    deps: [HttpClient]
                },
                {
                    provide: MetaLoader,
                    useFactory: metaConfigFactory,
                    deps: [TranslocoService]
                },
                {
                    provide: WEBSOCKET_SERVICE,
                    useClass: WebsocketService
                },
                {
                    provide: MESSAGE_SERVICE,
                    useClass: MessageService
                }
            ]
        };
    }

    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: JMCoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error(
                'JMCoreModule has already been loaded. Import this module in the AppModule only.'
            );
        }
    }
}
