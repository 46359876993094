export enum SearchFacet {
    ANNUAL_SALARY = 'annual_salary',
    APPLICATION_COUNT = 'applications_count',
    CREATED_AT = 'created_at_unix',
    END_DATE = 'end_date_unix',
    EXPERIENCE = 'experience',
    JOB_COUNT = 'jobs_count',
    JOB_FUNCTION = 'functions.name',
    JOB_STATUS = 'status',
    JOB_TYPE = 'type',
    LOCATION = 'location',
    REMOTE = 'remote',
    RESUME = 'has_resume',
    REQUIRED_SKILLS = 'required_skills.name',
    START_DATE = 'start_date_unix',
    STORE = 'store_slug',
    STORE_NAME = 'store.name',
    STORE_SIZE_ID = 'store_size_id',
    STORE_STATUS = 'store.status',
    STORE_VISIBLE = 'store.visible',
    USER_BLOCKED = 'blocked',
    USER_TYPE = 'user_type',
    VERIFIED = 'verified',
    VISA = 'visa_explanation',
    VISIBLE = 'visible'
}
