import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

@Pipe({ name: 'dayJsLocal' })
export class LocalTimePipe implements PipeTransform {
    constructor() {
        dayjs.extend(utc);
    }
    transform(value: string | number | Date | dayjs.Dayjs): dayjs.Dayjs {
        return dayjs(value).local();
    }
}
