import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({ name: 'dayJsSubtract' })
export class SubtractPipe implements PipeTransform {
    transform(
        value: string | number | Date | dayjs.Dayjs,
        amount: number,
        unit?: dayjs.ManipulateType
    ): any {
        if (
            typeof amount === 'undefined' ||
            (typeof amount === 'number' && typeof unit === 'undefined')
        ) {
            throw new Error('SubtractPipe: missing required arguments');
        }
        return dayjs(value).subtract(amount, unit);
    }
}
