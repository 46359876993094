import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Optional,
    Inject
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CertificationDialogComponent } from '@jobzmall/profile/components/certification-dialog/certification-dialog.component';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { Restangular } from '@jobzmall/core/api';
import { first, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';

@Component({
    selector: 'report-dialog',
    templateUrl: './report-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class ReportDialogComponent extends JMBaseComponent implements OnInit {
    type: string;
    entity: any;
    reason: string;
    reasonControl = new UntypedFormControl();

    constructor(
        private _store: Store,
        private _snackbar: MatSnackBar,
        private _api: Restangular,
        private _changeDetectorRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<CertificationDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
    }

    ngOnInit(): void {
        this.entity = this.data.entity;
        this.type = this.data.type;
    }

    customReason(form: NgForm) {
        this.submit(form.value.reason);
    }

    submit(reason: string) {
        if (!reason && !this.reason) {
            return;
        }

        this._api
            .all(`${this.type}/${this.entity.id}/report`)
            .customPOST({
                reason: reason || this.reason
            })
            .pipe(
                first(),
                map((res: any) => res.data)
            )
            .subscribe(() => {
                this._snackbar.open(`Reported successfully`, 'OK', {
                    duration: 3000,
                    panelClass: ['jobz-snackbar']
                });
                this.dialogRef.close(true);
            });
    }
}
