<div class="relative flex flex-col items-center justify-center w-screen h-fill z-100">
    <div
        class="flex flex-col items-center justify-center max-w-lg overflow-y-auto text-center max-h-fill z-flex b-6 rounded-xl bg-default">
        <div class="flex flex-col items-center justify-center px-16 py-4">
            <div class="flex items-center justify-center my-6">
                <app-logo class="w-auto h-12"></app-logo>
            </div>

            <span class="mb-3 text-4xl">Add JobzMall to your Home screen?</span>
            <span class="text-lg">Get to JobzMall quickly and easily by adding it to your Home screen</span>
        </div>
        <div class="flex flex-col w-full divide-y ">
            <div class="w-full">
                <button (click)="accept()" mat-button class="w-full min-w-0 text-center text-primary-500">Add to Home
                    screen</button>
            </div>
            <div class="w-full">
                <button (click)="close()" mat-button class="w-full min-w-0 text-center text-default">Cancel</button>
            </div>
        </div>
    </div>

</div>
