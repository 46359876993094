import {
    Constants,
    ICrypto,
    Logger} from '@azure/msal-common';
import { OAuthConfig } from '../config/oauth.config';
import { PopupWindowAttributes } from './popup-window-attributes';
import { CryptoOps } from '../crypto/CryptoOps';
import { AuthorizationCodeRequest, BaseOAuthRequest } from '../request';
import { RequestParamBuilder } from '../request/RequestParamBuilder';
import { UrlString } from '../url/UrlString';

export type PopupParams = {
    popup?: Window | null;
    popupName: string;
    popupWindowAttributes: PopupWindowAttributes;
};

export class OAuthRedirect {
    private logger: Logger;
    private browserCrypto: ICrypto;

    constructor(private config: OAuthConfig) {
        this.logger = new Logger({}, '@nxtg/oauth', '1.0');
        this.browserCrypto = new CryptoOps(this.logger);
    }

    /**
     * Acquires tokens by opening a redirecting window to the /authorize endpoint of the authority
     * @param request
     */
    acquireAuthorizeCode(
        request: BaseOAuthRequest
    ): Promise<boolean> {
        try {
            return this.acquireAuthorizationCodeAsync(
                request
            );
        } catch (e) {
            return Promise.reject(e);
        }
    }

    protected async acquireAuthorizationCodeAsync(
        request: BaseOAuthRequest
    ): Promise<boolean> {
        const authCodeRequest: AuthorizationCodeRequest =
            await this.initializeAuthorizationCodeRequest(request);

        const navigateUrl = await this.getAuthCodeUrl({
            ...request
        });

        document.location.href = navigateUrl;
        return Promise.resolve(true)
    }

    protected async getAuthCodeUrl(
        request: AuthorizationCodeRequest
    ): Promise<string> {
        const queryString = await this.createAuthCodeUrlQueryString(request);

        return UrlString.appendQueryString(request.baseUrl, queryString);
    }

    protected async createAuthCodeUrlQueryString(
        request: AuthorizationCodeRequest
    ): Promise<string> {
        const parameterBuilder = new RequestParamBuilder();

        parameterBuilder.addClientId(request.clientId);

        const requestScopes = [...(request.scopes || [])];
        parameterBuilder.addScopes(requestScopes);

        // validate the redirectUri (to be a non null value)
        parameterBuilder.addRedirectUri(request.redirectUri);

        // add response_mode. If not passed in it defaults to query.
        parameterBuilder.addResponseType(request.responseType);

        if (request.codeChallenge && request.codeChallengeMethod) {
            parameterBuilder.addCodeChallengeParams(
                request.codeChallenge,
                request.codeChallengeMethod
            );
        }

        if (request.prompt) {
            parameterBuilder.addPrompt(request.prompt);
        }

        if (request.nonce) {
            parameterBuilder.addNonce(request.nonce);
        }

        if (request.state) {
            parameterBuilder.addState(request.state);
        }

        return parameterBuilder.createQueryString();
    }

    protected async initializeAuthorizationCodeRequest(
        request: BaseOAuthRequest
    ): Promise<AuthorizationCodeRequest> {
        const generatedPkceParams =
            await this.browserCrypto.generatePkceCodes();

        const authCodeRequest: AuthorizationCodeRequest = {
            ...request,
            codeVerifier: generatedPkceParams.verifier
        };

        authCodeRequest.codeChallenge = generatedPkceParams.challenge;
        authCodeRequest.codeChallengeMethod =
            Constants.S256_CODE_CHALLENGE_METHOD;

        return authCodeRequest;
    }
}
