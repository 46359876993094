import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { Media, MediaAdapter } from './media.model';
import { Adapter } from './adapter';

export interface IMessageReceiver {
    message_receiver_id: number;
}

export class Message {
    constructor(
        public id?: any,
        public text?: string,
        public meta?: any,
        public sender?: any,
        public receiver?: any,
        public created_at?: Date,
        public updated_at?: Date,
        public type?: string,
        public media?: Array<Media>,
        public media_count?: number,
        public deleted_at?: Date,
        public error?: boolean,
        public temp_id?: any
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class MessageAdapter implements Adapter<Message> {
    adapt(item: any): Message {
        return new Message(
            item.id,
            item.text,
            item.meta,
            item.sender,
            item.receiver,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.type,
            item.media
                ? item.media.map((m: any) => new MediaAdapter().adapt(m))
                : [],
            item.media_count ?? item.media ?? item.media.length ?? 0,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null,
            false,
            item.temp_id
        );
    }
}
