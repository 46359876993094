import {
    Media,
    MediaAdapter,
    Organization,
    OrganizationAdapter,
    User,
    UserAdapter,
    Job,
    JobAdapter,
    Location,
    LocationAdapter
} from '@jobzmall/models';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { Adapter } from '@jobzmall/models/adapter';

export class Post {
    constructor(
        public id?: number,
        public anonymous?: boolean,
        public slug?: string,
        public title?: string,
        public type?: string,
        public body?: string,
        public attachments?: any,
        public poster?: Organization | User | undefined,
        public parent_id?: number,
        public parent?: Post,
        public location?: Location,
        public metadata?: any,
        public reaction_counts?: any,
        public reaction_state?: any,
        public total_comment_counts?: number,
        public created_at?: Date,
        public updated_at?: Date,
        public posterable_type?: string,
        public posterable_id?: number,
        public seo?: any,
        public json_ld?: any,
        public feed?: any,
        public tags?: any,
        public pinned?: boolean
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class PostAdapter implements Adapter<Post> {
    constructor(
        private _userAdapter: UserAdapter,
        private _locationAdapter: LocationAdapter,
        private _postAttachmentsAdapter: PostAttachmentsAdapter,
        private _organizationAdapter: OrganizationAdapter
    ) {}

    adapt(item: any): Post {
        return new Post(
            item.id,
            item.anonymous,
            item.slug,
            item.title,
            item.type,
            item.body,
            item.attachments
                ? this._postAttachmentsAdapter.adapt(item.attachments)
                : undefined,
            item.poster
                ? item.poster.floor_id
                    ? this._organizationAdapter.adapt(item.poster)
                    : this._userAdapter.adapt(item.poster)
                : item.poster,
            item.parent_id,
            item.parent ? this.adapt(item.parent) : undefined,
            item.location
                ? this._locationAdapter.adapt(item.location)
                : undefined,
            item.metadata,
            item.reaction_counts,
            item.reaction_state,
            item.total_comment_counts,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.posterable_type,
            item.posterable_id,
            item.seo,
            item.json_ld,
            item.feed,
            item.tags,
            item.pinned
        );
    }
}

export class PostAttachments {
    constructor(public media?: Array<Media>, public postables?: Array<any>) {}
}

@Injectable({
    providedIn: 'root'
})
export class PostAttachmentsAdapter implements Adapter<PostAttachments> {
    constructor(
        private _jobAdapter: JobAdapter,
        private _mediaAdapter: MediaAdapter
    ) {}
    adapt(item: any): PostAttachments {
        return new PostAttachments(
            item.media
                ? item.media.map((m: any) => this._mediaAdapter.adapt(m))
                : [],
            item.postables
                ? item.postables.map((m: any) => {
                      switch (m.postable_type) {
                          case 'job':
                              m.entity = this._jobAdapter.adapt(m.entity);
                              break;
                          default:
                              return m;
                      }
                  })
                : []
        );
    }
}
