import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'employers-header-panel',
    templateUrl: './employers-header-panel.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class EmployersHeaderPanelComponent
    extends JMBaseComponent
    implements OnInit
{
    @Output() onClosePanel = new EventEmitter();

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {}
}
