import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject,
    Input
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Select, Store } from '@ngxs/store';
import { Conversation, Message, User } from '@jobzmall/models';
import { Observable } from 'rxjs';
import { MessagesState } from '../app/ngxs/state';
import { UserState } from '@jobzmall/user';
import { AuthState, WebsocketService, WEBSOCKET_SERVICE } from '@jobzmall/core';

@Component({
    selector: 'messages-widget',
    templateUrl: './messages-widget.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class MessagesWidgetComponent extends JMBaseComponent implements OnInit {
    @Select(MessagesState.pinnedConversations) conversations$: Observable<
        Array<Conversation>
    >;
    @Select(MessagesState.allowPinning) allowPinning$: Observable<boolean>;
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
    @Select(UserState.user) user$: Observable<User>;
    @Select(MessagesState.widgetMaximized)
    widgetMaximized$: Observable<boolean>;
    @Select(MessagesState.minimizeWidgetCompletely)
    minimizeWidgetCompletely$: Observable<boolean>;

    @Input() fitForQuickChat = false;

    constructor(
        @Inject(WEBSOCKET_SERVICE) public websocketService: WebsocketService,
        private _store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {}

    ngOnChanges() {}
}
