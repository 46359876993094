<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <ng-container *ngIf="data.dismissible">
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button mat-icon-button [matDialogClose]="undefined">
                <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <div class="flex flex-col items-center flex-auto p-8 pb-6 sm:flex-row sm:items-start sm:pb-8">

        <!-- Icon -->
        <ng-container *ngIf="data.icon.show">
            <div class="flex items-center justify-center w-10 h-10 rounded-full flex-0 sm:mr-4" [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon.color === 'primary',
                            'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon.color === 'accent',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon.color === 'warn',
                            'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon.color === 'basic',
                            'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon.color === 'info',
                            'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon.color === 'success',
                            'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon.color === 'warning',
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon.color === 'error'
                            }">
                <mat-icon class="text-current" [svgIcon]="data.icon.name"></mat-icon>
            </div>
        </ng-container>

        <ng-container *ngIf="data.title || data.message">
            <div
                class="flex flex-col items-center mt-4 space-y-1 text-center sm:items-start sm:mt-0 sm:pr-8 sm:text-left">

                <!-- Title -->
                <ng-container *ngIf="data.title">
                    <div class="text-xl font-medium leading-6" [innerHTML]="data.title"></div>
                </ng-container>

                <!-- Message -->
                <ng-container *ngIf="data.message">
                    <div class="text-secondary" [innerHTML]="data.message"></div>
                </ng-container>
            </div>
        </ng-container>



    </div>

    <ng-container *ngIf="data.input">

        <form [formGroup]="form"
            class="flex flex-col items-center px-8 mt-4 space-y-1 marker:pb-6 sm:items-start sm:mt-0 sm:pr-8 sm:text-left">

            <ng-container [ngSwitch]="data.input.type">
                <ng-container *ngSwitchCase="'select'">
                    <mat-form-field class="w-full">
                        <mat-label>{{data.input.label}}</mat-label>
                        <mat-select formControlName="content" required>
                            <mat-option *ngFor="let opt of data.input.options" [value]="opt.value">
                                {{opt.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls.content.errors?.required">
                            Required Field</mat-error>
                    </mat-form-field>

                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                    <mat-form-field class="w-full">
                        <mat-label>{{data.input.label}}</mat-label>
                        <input matInput [placeholder]="data.input.placeholder" type="text" formControlName="content" />
                        <mat-error *ngIf="form.controls.content.errors?.required">
                            Required Field</mat-error>
                    </mat-form-field>

                </ng-container>
                <ng-container *ngSwitchCase="'number'">
                    <mat-form-field class="w-full">
                        <mat-label>{{data.input.label}}</mat-label>
                        <input matInput [placeholder]="data.input.placeholder" type="number"
                            formControlName="content" />
                        <mat-error *ngIf="form.controls.content.errors?.required">
                            Required Field</mat-error>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'textarea'">
                    <mat-form-field class="w-full">
                        <mat-label>{{data.input.label}}</mat-label>
                        <textarea matInput [placeholder]="data.input.placeholder" type="number"
                            formControlName="content"></textarea>
                        <mat-error *ngIf="form.controls.content.errors?.required">
                            Required Field</mat-error>
                    </mat-form-field>
                </ng-container>
            </ng-container>

        </form>

    </ng-container>

    <!-- Actions -->
    <ng-container *ngIf="(data.actions.confirm.show || data.actions.cancel.show) && !data.input">
        <div
            class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50 dark:bg-black dark:bg-opacity-10">

            <!-- Cancel -->
            <ng-container *ngIf="data.actions.cancel.show">
                <button mat-stroked-button [matDialogClose]="'cancelled'">
                    {{data.actions.cancel.label}}
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container *ngIf="data.actions.confirm.show">
                <button mat-flat-button [color]="data.actions.confirm.color" [matDialogClose]="'confirmed'">
                    {{data.actions.confirm.label}}
                </button>
            </ng-container>

        </div>
    </ng-container>
    <ng-container *ngIf="(data.actions.confirm.show || data.actions.cancel.show) && data.input">
        <div
            class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50 dark:bg-black dark:bg-opacity-10">

            <!-- Cancel -->
            <ng-container *ngIf="data.actions.cancel.show">
                <button mat-stroked-button [matDialogClose]="undefined">
                    {{data.actions.cancel.label}}
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container *ngIf="data.actions.confirm.show">
                <button [disabled]="form.invalid" mat-flat-button [color]="data.actions.confirm.color"
                    [matDialogClose]="form.controls.content.value">
                    {{data.actions.confirm.label}}
                </button>
            </ng-container>

        </div>
    </ng-container>

</div>
