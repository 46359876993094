import { Directive, OnInit, ElementRef, AfterViewInit } from '@angular/core';

@Directive({ selector: '[jm-autoplay]' })
export class JMAutoplayDirective implements AfterViewInit {

    constructor(public el: ElementRef) { }

    ngAfterViewInit(): void {
        this.el.nativeElement.oncanplaythrough = () => {
            this.el.nativeElement.setAttribute('playsinline', '');
            this.el.nativeElement.setAttribute('muted', '');
            this.el.nativeElement.setAttribute('autoplay', '');
            this.el.nativeElement.muted = true;
            this.el.nativeElement.play();
        }
    }
}
