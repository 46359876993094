<div *ngIf="(user$ | async) && (isAuthenticated$ | async) && (allowPinning$ | async) && websocketService.websocket"
    [ngClass]="{'right-[70px]': fitForQuickChat, 'right-0': !fitForQuickChat, 'bottom-0': !(minimizeWidgetCompletely$ |
    async) || (widgetMaximized$ | async),
    '-bottom-20': (minimizeWidgetCompletely$ | async) && !(widgetMaximized$ | async)}"
    class="fixed z-50 flex flex-row items-end justify-end w-full h-full pointer-events-none">
    <messages-widget-conversation class="mr-8 pointer-events-auto" [conversation]="conversation"
        *ngFor="let conversation of (conversations$ | async); trackBy: ( 'channel' | trackByProperty )">

    </messages-widget-conversation>
    <messages-widget-conversation-placeholder></messages-widget-conversation-placeholder>
    <messages-widget-list class="flex items-end justify-end h-full max-h-3/4">
    </messages-widget-list>
</div>
