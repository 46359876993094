import { NgxIntlTelInputModule } from '@jobzmall/telephone-input/ngx-intl-tel-input.module';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneVerificationFormComponent } from './phone-verification-form/phone-verification-form.component';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TwoFactorComponent } from './two-factor/two-factor.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PhoneUpdateFormComponent } from './phone-update-form/phone-update-form.component';
import { JMSharedModule } from '@jobzmall/shared';
import { CommonModule } from '@angular/common';
import { NgxSplitInputModule } from 'ngx-splitinput';
import { JMFormsModule } from '@jobzmall/forms';
import { PhoneVerificationOverlayComponent } from './phone-verification-overlay/phone-verification-overlay.component';
import { MatIconModule } from '@angular/material/icon';

const COMPONENTS = [
    PhoneUpdateFormComponent,
    TwoFactorComponent,
    PhoneVerificationOverlayComponent,
    PhoneVerificationFormComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatSnackBarModule,
        TranslocoModule,
        MatIconModule,
        NgxIntlTelInputModule,
        FormsModule,
        MatFormFieldModule,
        RouterModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        JMSharedModule,
        NgxSplitInputModule,
        JMFormsModule
    ],
    exports: COMPONENTS,
    providers: []
})
export class JMPhoneComponentsModule {}
