import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { JMBaseOverlayComponent } from '@jobzmall/overlays/overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'share-overlay',
    templateUrl: './share-overlay.component.html',
    styles: [
        `share-overlay { width: 100%; height: 100%;}`
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class ShareOverlayComponent extends JMBaseOverlayComponent implements OnInit {

    copyFormControl = new UntypedFormControl();
    url: string;
    type: string;
    copied = false;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(JM_OVERLAY_DATA) public data: any
    ) {
        super(data)
    }

    ngOnInit(): void {
        this.url = this.data.url || document.URL;
        this.type = this.data.type || 'this page';
    }

    setCopied() {
        this.copied = true;
        this._changeDetectorRef.markForCheck();
        setTimeout(() => {
            this.copied = false;
            this._changeDetectorRef.markForCheck();
        }, 2000)
    }
}
