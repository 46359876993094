import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Renderer2,
    ElementRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@jobzmall/user';
import { Observable } from 'rxjs';
import { Conversation, User } from '@jobzmall/models';
import { MessagesState } from '../app/ngxs/state';
import {
    GetConversations,
    MaximizeMessagesWidget,
    MinimizeMessagesWidget,
    PinConversation
} from '../app/ngxs/actions';
import { first } from 'rxjs/operators';

@Component({
    selector: 'messages-widget-list',
    templateUrl: './messages-widget-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class MessagesWidgetListComponent
    extends JMBaseComponent
    implements OnInit
{
    @Select(MessagesState.conversations) conversations$: Observable<
        Array<Conversation>
    >;
    @Select(MessagesState.conversationsLoading) loading$: Observable<boolean>;
    @Select(MessagesState.unreadCount) unreadCount$: Observable<number>;
    @Select(MessagesState.requestsCount) requestsCount$: Observable<number>;
    @Select(MessagesState.widgetMaximized) maximized$: Observable<boolean>;

    @Select(MessagesState.minimizeWidgetCompletely)
    minimizeWidgetCompletely$: Observable<boolean>;

    @Select(UserState.user) user$: Observable<User>;

    @Select(MessagesState.pinningConversation) pinning$: Observable<boolean>;

    maximized = false;
    minimizeCompletely = false;

    constructor(
        private _element: ElementRef,
        private _store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.sink = this.conversations$.subscribe(
            (conversations: any) => {
                this._changeDetectorRef.markForCheck();
                this._changeDetectorRef.detectChanges();
            }
        );
        this.subscriptions.sink = this.maximized$.subscribe(
            (maximized: boolean) => {
                this.maximized = maximized;
                this._changeDetectorRef.markForCheck();
            }
        );
        this.subscriptions.sink = this.minimizeWidgetCompletely$.subscribe(
            (minimizeCompletely: boolean) => {
                this.minimizeCompletely = minimizeCompletely;
                this._changeDetectorRef.markForCheck();
            }
        );
        this.getConversations();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getConversations(refresh = false) {
        this._store.dispatch(new GetConversations(refresh));
    }

    pinConversation(conversation: Conversation) {
        this._store.dispatch(new PinConversation(conversation.channel));
    }

    maximize() {
        this._store.dispatch(new MaximizeMessagesWidget());
    }

    minimize() {
        this._store.dispatch(
            new MinimizeMessagesWidget(this.minimizeCompletely)
        );
    }
}
