import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Media, MediaAdapter } from './media.model';

export class FeaturedStore {
    constructor(
        public id: number,
        public name: string,
        public slug: string,
        public image: Media,
        public jobs_count: number
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class FeaturedStoreAdapter implements Adapter<FeaturedStore> {
    adapt(item: any): FeaturedStore {
        return new FeaturedStore(
            item.id,
            item.name,
            item.slug,
            (item.image ? new MediaAdapter().adapt(item.image) : null),
            item.jobs_count
        );
    }
}
