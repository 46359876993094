import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
const { Howl, Howler } = require('howler');

@Injectable()
export class SoundService {
    howler = Howler;

    set volume(value: number) {
        this.howler.volume(value);
    }

    get volume(): number {
        return this.howler.volume();
    }

    mute(value = true) {
        this.howler.mute(value);
    }

    howl(options: any) {
        return new Howl(options);
    }

    play(src: string) {
        const sound = new Howl({
            src: [src],
            autoplay: true
        });

        return fromEvent(sound, 'end');
    }
}
