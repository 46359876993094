import {
    Component,
    OnInit,
    Input,
    Inject,
    OnDestroy,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil, first, catchError, startWith, map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Restangular } from '@jobzmall/core/api';
import { validateAllFormFields } from '@jobzmall/core/util/forms';
import { User } from '@jobzmall/models';
import { UserState } from '@jobzmall/user/ngxs/state';
import { UserService } from '@jobzmall/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { SplitInputService } from 'ngx-splitinput';
import { JMBaseComponent } from '@jobzmall/components';

@Component({
    selector: 'phone-verification-form',
    styleUrls: ['./phone-verification-form.component.scss'],
    templateUrl: './phone-verification-form.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class PhoneVerificationFormComponent
    extends JMBaseComponent
    implements OnInit, OnDestroy
{
    @Input() title = 'Verify Your Phone Number';
    @Input() showTitle = false;
    @Input() showLogo = false;
    @Input() appearance = 'fill';
    @Input() floatLabel = false;
    @Input() phone: string;
    @Input() phoneCountryCode: string;
    @Input() requireVerification = true;

    @Input() loading = false;

    @Output() onSendFail: EventEmitter<any> = new EventEmitter();
    @Output() onSendSuccess: EventEmitter<any> = new EventEmitter();
    @Output() onVerifyFail: EventEmitter<any> = new EventEmitter();
    @Output() onVerifySuccess: EventEmitter<any> = new EventEmitter();
    @Output() onPhoneUpdateClick: EventEmitter<any> = new EventEmitter();

    sent = false;
    sid: string;

    notificationSends = 0;
    sendingVerificationCode = false;

    @Select(UserState.user) user$: Observable<User>;

    form = new UntypedFormGroup({
        code: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6)
        ])
    });

    _unsubscribeAll = new Subject<void>();
    invalid: boolean;
    code: string;

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _splitInputService: SplitInputService,
        private _snackbar: MatSnackBar,
        private readonly _userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.phone) {
            this.onSendFail.emit({
                status: 422
            });
        } else {
            this.sendVerificationCode();
        }
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        super.ngOnDestroy();
    }

    onTextEntered($event) {
        this.code = $event;
        this.verify();
    }

    sendVerificationCode(notify = false) {
        if (!this.loading) {
            this.loading = notify;
            this._userService
                .startPhoneVerification(this.phone, this.phoneCountryCode)
                .pipe(
                    catchError((err: any) => {
                        this.onSendFail.emit(err);
                        return of(undefined);
                    }),
                    first()
                )
                .subscribe((sid: any) => {
                    if (sid) {
                        this.notificationSends++;
                        this.sid = sid;
                        this.onSendSuccess.emit(sid);
                        this.loading = false;
                        if (notify) {
                            this._snackbar.open(
                                'Code sent successfully',
                                'OK',
                                {
                                    duration: 3000,
                                    panelClass: ['jobz-snackbar']
                                }
                            );
                        }
                        this._changeDetector.markForCheck();
                    }
                });
        }
    }

    reset() {
        this.sent = false;
    }

    skipVerification() {
        if (!this.loading) {
            this.loading = true;
            this.onVerifySuccess.emit();
        }
    }

    verify() {
        if (!this.loading) {
            this.loading = true;
            this.invalid = false;

            this._userService
                .submitPhoneVerification(
                    this.phone,
                    this.code,
                    this.phoneCountryCode
                )
                .pipe(
                    catchError(() => {
                        return of(undefined);
                    }),
                    first()
                )
                .subscribe((data: any) => {
                    if (data) {
                        if (data === 'approved') {
                            this.loading = false;
                            this.invalid = false;
                            this.onVerifySuccess.emit();
                            setTimeout(() => {
                                this.form.controls.code.setErrors(null);
                            });
                            this._changeDetector.markForCheck();
                            return;
                        }
                    }

                    this.loading = false;
                    this.onVerifyFail.emit();
                    this.invalid = true;
                    this.code = null;
                    this._splitInputService.clearSplitInput();
                    this._changeDetector.markForCheck();
                });
        } else {
            validateAllFormFields(this.form);
        }
    }
}
