import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { AuthState } from '../../core/auth/ngxs/state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { uniqueId } from 'lodash-es';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class AppLogoComponent extends JMBaseComponent implements OnInit {
    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
    @Input() link;
    @Input() routerLink;

    @Input() forceWhite: boolean;
    @Input() forceNormal: boolean;

    appName: string;
    patternId = this.uuid();
    imageId = this.uuid();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _translocoService: TranslocoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.sink = this.isAuthenticated$.subscribe(
            (isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    this.routerLink = ['/'];
                    this.link = undefined;
                } else {
                    this.link = environment.webURL;
                    this.routerLink = undefined;
                }
                this._changeDetectorRef.markForCheck();
            }
        );
        this.appName = this._translocoService.translate('APP_NAME');
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
         super.ngOnDestroy();
    }

    uuid() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
        return uuid;
    }
}
