import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../ngxs/state';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild {
    /**
     * Constructor
     */
    constructor(private _store: Store, private _router: Router) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._check(state);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._check(state);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @private
     */
    private _check(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const isAuthenticated = this._store.selectSnapshot(
            AuthState.isAuthenticated
        );
        let url;
        let queryParams = {};
        if (isAuthenticated && !this._isFallback(state)) {
            if (state.root?.queryParams?.redirectUrl) {
                url = decodeURIComponent(state.root?.queryParams?.redirectUrl);
                console.log(url);
                console.log(url.split('?'));
                if (url.indexOf('?') > -1) {
                    queryParams = Object.fromEntries(
                        new URLSearchParams(url.split('?')[1])
                    );
                    url = url.split('?')[0];
                    console.log(queryParams);
                } else {
                    url = decodeURIComponent(
                        state.root?.queryParams?.redirectUrl
                    );
                }
            }
            return of(
                this._router.createUrlTree([url || '/home'], {
                    queryParams
                })
            );
        }

        return of(true);
    }

    private _isFallback(state: RouterStateSnapshot): boolean {
        return state.url === '/home';
    }
}
