import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayJsDuration' })
export class DurationPipe implements PipeTransform {
    constructor() {
        dayjs.extend(duration);
        dayjs.extend(relativeTime);
    }

    transform(
        value: number,
        unit?: duration.DurationUnitType,
        suffix = false
    ): string {
        if (typeof unit === 'undefined') {
            throw new Error(
                'DurationPipe: missing required time unit argument'
            );
        }
        return dayjs.duration(value, unit).humanize(suffix);
    }
}
