import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Restangular } from '@jobzmall/core/api/ngx-restangular';
import { first, map, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { Select } from '@ngxs/store';
import { UserState } from '../../../user/ngxs/state';
import { User } from '@jobzmall/models';

@Component({
    selector: 'upcoming-meetings-card',
    templateUrl: './upcoming-meetings-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class UpcomingMeetingsCardComponent
    extends JMBaseComponent
    implements OnInit
{
    @Input() link = '/calendar';

    @Select(UserState.user) user$: Observable<User>;

    now = dayjs();
    meetings: Array<any> = [];
    constructor(
        private _api: Restangular,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.getMeetings();
    }

    getMeetings() {
        if (!this.loading && !this.end) {
            this.loading = true;
            this._api
                .all('meeting')
                .customGET(undefined, {
                    page: this.page
                })
                .pipe(
                    first(),
                    map((res: any) => res.data)
                )
                .subscribe((meetings: Array<any>) => {
                    if (meetings.length) {
                        meetings.forEach((m: any) => {
                            this.meetings.push(m);
                        });
                        this.page++;
                    } else {
                        this.end = true;
                    }
                    this.loading = false;
                    this._changeDetectorRef.markForCheck();
                });
        }
    }
}
