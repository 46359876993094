/* eslint-disable */
export const categories = [
    {
        id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        title: 'Virtual',
        slug: 'virtual'
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        title: 'Firebase',
        slug: 'firebase'
    },
    {
        id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
        title: 'Cloud',
        slug: 'cloud'
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        title: 'In-Person',
        slug: 'in-person'
    }
];
export const courses = [
    {
        id: '1',
        title: 'Effective Communications and Human Relations',
        slug: 'effective-communications-and-human-relations',
        description: 'Develop Your Leadership Potential',
        type: 'virtual',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Jun 28, 2021',
        featured: true,
        cost: 'Free',
        progress: {
            currentStep: 3,
            completed: 2
        }
    },
    {
        id: 'f924007a-2ee9-470b-a316-8d21ed78277f',
        title: 'Dale Carnegie Unlimited Series: High Impact Presentations',
        slug: 'basics-of-typeScript',
        description: 'Beginner course for Typescript and its basics',
        type: 'virtual',
        duration: 60,
        totalSteps: 11,
        updatedAt: 'Nov 01, 2021',
        cost: '$99',
        featured: true,
        progress: {
            currentStep: 5,
            completed: 3
        }
    },
    {
        id: '0c06e980-abb5-4ba7-ab65-99a228cab36b',
        title: 'Develop Your Leadership Potential',
        slug: 'android-n-quick-settings',
        description: 'Step by step guide for Android N: Quick Settings',
        type: 'in-person',
        duration: 120,
        totalSteps: 11,
        updatedAt: 'May 08, 2021',
        featured: false,
        cost: '$99',
        progress: {
            currentStep: 10,
            completed: 1
        }
    },
    {
        id: '1b9a9acc-9a36-403e-a1e7-b11780179e38',
        title: 'Build an App for the Google Assistant with Firebase',
        slug: 'build-an-app-for-the-google-assistant-with-firebase',
        description: 'Dive deep into Google Assistant apps using Firebase',
        type: 'firebase',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Jan 09, 2021',
        featured: false,
        progress: {
            currentStep: 4,
            completed: 3
        }
    },
    {
        id: '55eb415f-3f4e-4853-a22b-f0ae91331169',
        title: 'Keep Sensitive Data Safe and Private',
        slug: 'keep-sensitive-data-safe-and-private',
        description: 'Learn how to keep your important data safe and private',
        type: 'in-person',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Jan 14, 2021',
        featured: false,
        progress: {
            currentStep: 6,
            completed: 0
        }
    },
    {
        id: 'fad2ab23-1011-4028-9a54-e52179ac4a50',
        title: "Manage Your Pivotal Cloud Foundry App's Using Apigee Edge",
        slug: 'manage-your-pivotal-cloud-foundry-apps-using-apigee-Edge',
        description: 'Introductory course for Pivotal Cloud Foundry App',
        type: 'cloud',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Jun 24, 2021',
        featured: false,
        progress: {
            currentStep: 6,
            completed: 0
        }
    },
    {
        id: 'c4bc107b-edc4-47a7-a7a8-4fb09732e794',
        title: 'Build a PWA Using Workbox',
        slug: 'build-a-pwa-using-workbox',
        description: 'Step by step guide for building a PWA using Workbox',
        type: 'virtual',
        duration: 120,
        totalSteps: 11,
        updatedAt: 'Nov 19, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: '1449f945-d032-460d-98e3-406565a22293',
        title: 'Cloud Functions for Firebase',
        slug: 'cloud-functions-for-firebase',
        description: 'Beginners guide of Firebase Cloud Functions',
        type: 'firebase',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Jul 11, 2021',
        featured: false,
        progress: {
            currentStep: 3,
            completed: 1
        }
    },
    {
        id: 'f05e08ab-f3e3-4597-a032-6a4b69816f24',
        title: 'Building a gRPC Service with Java',
        slug: 'building-a-grpc-service-with-java',
        description: 'Learn more about building a gRPC Service with Java',
        type: 'cloud',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Mar 13, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 1
        }
    },
    {
        id: '181728f4-87c8-45c5-b9cc-92265bcd2f4d',
        title: 'Looking at Campaign Finance with BigQuery',
        slug: 'looking-at-campaign-finance-with-bigquery',
        description: 'Dive deep into BigQuery: Campaign Finance',
        type: 'cloud',
        duration: 60,
        totalSteps: 11,
        updatedAt: 'Nov 01, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: 'fcbfedbf-6187-4b3b-89d3-1a7cb4e11616',
        title: 'Personalize Your iOS App with Firebase User Management',
        slug: 'personalize-your-ios-app-with-firebase-user-management',
        description:
            'Dive deep into User Management on iOS apps using Firebase',
        type: 'firebase',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Aug 08, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: '5213f6a1-1dd7-4b1d-b6e9-ffb7af534f28',
        title: 'Customize Network Topology with Subnetworks',
        slug: 'customize-network-topology-with-subnetworks',
        description: 'Dive deep into Network Topology with Subnetworks',
        type: 'virtual',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'May 12, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: '02992ac9-d1a3-4167-b70e-8a1d5b5ba253',
        title: 'Building Beautiful UIs with Flutter',
        slug: 'building-beautiful-uis-with-flutter',
        description:
            "Dive deep into Flutter's hidden secrets for creating beautiful UIs",
        type: 'virtual',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Sep 18, 2021',
        featured: false,
        progress: {
            currentStep: 8,
            completed: 2
        }
    },
    {
        id: '2139512f-41fb-4a4a-841a-0b4ac034f9b4',
        title: 'Firebase Android',
        slug: 'firebase-android',
        description: 'Beginners guide of Firebase for Android',
        type: 'in-person',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Apr 24, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: '65e0a0e0-d8c0-4117-a3cb-eb74f8e28809',
        title: 'Simulating a Thread Network Using OpenThread',
        slug: 'simulating-a-thread-network-using-openthread',
        description:
            'Introductory course for OpenThread and Simulating a Thread Network',
        type: 'virtual',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Jun 05, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: 'c202ebc9-9be3-433a-9d38-7003b3ed7b7a',
        title: 'Your First Progressive Web App',
        slug: 'your-first-progressive-web-app',
        description: 'Step by step guide for creating a PWA from scratch',
        type: 'virtual',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Oct 14, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: '980ae7da-9f77-4e30-aa98-1b1ea594e775',
        title: 'Launch Cloud Datalab',
        slug: 'launch-cloud-datalab',
        description: 'From start to finish: Launch Cloud Datalab',
        type: 'cloud',
        duration: 60,
        totalSteps: 11,
        updatedAt: 'Dec 16, 2021',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0
        }
    },
    {
        id: 'c9748ea9-4117-492c-bdb2-55085b515978',
        title: 'Cloud Firestore',
        slug: 'cloud-firestore',
        description: 'Step by step guide for setting up Cloud Firestore',
        type: 'firebase',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Apr 04, 2021',
        featured: false,
        progress: {
            currentStep: 2,
            completed: 0
        }
    }
];
export const demoCourseContent = `
<p class="lead">
    Leaders need to be proactive in finding solutions to problems before they arise. Pinpointing opportunities for improvement through creative brainstorming, and logically evaluating options assists in decision making and avoiding issues before they develop. Apply proven tools to inspire your team to envision the possibilities!
</p>

<h2 text-2xl sm:text-3xl>
    What You'll Learn
</h2>
<p class="lead">
    You'll learn how to come to reasonable solutions more quickly and creatively. Using the Critical Thinking Process introduced in the course, you’ll gather and interpret relevant information that leads to practical solutions. Through a proven process you'll identify the root cause of your most pressing problems and evaluate the corrective measures that lead to breakthrough results.
</p>
<h2 text-2xl sm:text-3xl>
    Why you want to learn it
</h2>
<p class="lead">
In today’s competitive environment, professionals who are both creative and logical in their thinking are in high demand. Complex business challenges call for people who can not only make decisions, but make the RIGHT decisions. Gain critical thinking skills and tools that make you a leader in the market place.
</p>

<h2 text-2xl sm:text-3xl>
    How it will help you
</h2>
<p class="lead">
Employing innovative approaches for visualization and fact finding, you’ll identify root causes of problems. Understanding the problem is half the battle! Once you evaluate issues clearly, you’ll be ready to generate fruitful solutions and make decisions that transform your business.
</p>
<p class="lead">At the end of this session, you will be able to:
</p>
<ul class="lead">
    <li>Use the Critical Thinking Process to gather and interpret relevant information and come to well-reasoned conclusions and solutions</li>
    <li>Differentiate between the Green-light and Red-light thinking mechanism</li>
    <li>Employ visualization and fact finding to verify that you have identified the real cause of the problem</li>
    <li>Critically evaluate alternatives using deductive and inductive reasoning strategies, the ladder of inference, and logic trees
</li>
<li>Apply decision-making techniques to choose effective solutions.</li>
</ul>
`;
export const demoCourseSteps = [
    {
        order: 0,
        title: 'Introduction',
        subtitle: 'Introducing the library and how it works',
        content: `<h2 class="text-2xl sm:text-3xl">Introduction</h1> ${demoCourseContent}`
    },
    {
        order: 1,
        title: 'Get the sample code',
        subtitle: 'Where to find the sample code and how to access it',
        content: `<h2 class="text-2xl sm:text-3xl">Get the sample code</h1> ${demoCourseContent}`
    },
    {
        order: 2,
        title: 'Create a Firebase project and Set up your app',
        subtitle:
            'How to create a basic Firebase project and how to run it locally',
        content: `<h2 class="text-2xl sm:text-3xl">Create a Firebase project and Set up your app</h1> ${demoCourseContent}`
    },
    {
        order: 3,
        title: 'Install the Firebase Command Line Interface',
        subtitle: 'Setting up the Firebase CLI to access command line tools',
        content: `<h2 class="text-2xl sm:text-3xl">Install the Firebase Command Line Interface</h1> ${demoCourseContent}`
    },
    {
        order: 4,
        title: 'Deploy and run the web app',
        subtitle: 'How to build, push and run the project remotely',
        content: `<h2 class="text-2xl sm:text-3xl">Deploy and run the web app</h1> ${demoCourseContent}`
    },
    {
        order: 5,
        title: 'The Functions Directory',
        subtitle: 'Introducing the Functions and Functions Directory',
        content: `<h2 class="text-2xl sm:text-3xl">The Functions Directory</h1> ${demoCourseContent}`
    },
    {
        order: 6,
        title: 'Import the Cloud Functions and Firebase Admin modules',
        subtitle:
            'Create your first Function and run it to administer your app',
        content: `<h2 class="text-2xl sm:text-3xl">Import the Cloud Functions and Firebase Admin modules</h1> ${demoCourseContent}`
    },
    {
        order: 7,
        title: 'Welcome New Users',
        subtitle: 'How to create a welcome message for the new users',
        content: `<h2 class="text-2xl sm:text-3xl">Welcome New Users</h1> ${demoCourseContent}`
    },
    {
        order: 8,
        title: 'Images moderation',
        subtitle: 'How to moderate images; crop, resize, optimize',
        content: `<h2 class="text-2xl sm:text-3xl">Images moderation</h1> ${demoCourseContent}`
    },
    {
        order: 9,
        title: 'New Message Notifications',
        subtitle: 'How to create and push a notification to a user',
        content: `<h2 class="text-2xl sm:text-3xl">New Message Notifications</h1> ${demoCourseContent}`
    },
    {
        order: 10,
        title: 'Congratulations!',
        subtitle: 'Nice work, you have created your first application',
        content: `<h2 class="text-2xl sm:text-3xl">Congratulations!</h1> ${demoCourseContent}`
    }
];
