import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, forwardRef, Inject, Input, Optional, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { BaseWidget, NgAisIndex, NgAisInstantSearch } from 'angular-instantsearch';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import { noop } from '../utils';
import { CurrentRefinementsRenderState } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';



@Component({
    selector: 'filter-button',
    templateUrl: './filter-button.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class FilterButtonComponent extends BaseWidget implements OnInit {

    @Input() showText = false;
    @Output() onClick = new EventEmitter();

    public state: any = {

    };

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super('FilterButton');
    }

    ngOnInit(): void {
        this.createWidget(connectCurrentRefinements, {});
        super.ngOnInit();
    }
}
