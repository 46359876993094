import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Select } from '@ngxs/store';
import { AuthState } from '@jobzmall/core';
import { Observable } from 'rxjs';
import { AppState } from '@jobzmall/state/ngxs/state';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MobileBottomSheetComponent } from './mobile-bottom-sheet.component';
import { UserState } from '../../../../../../../libs/nxtg-shared/src/user/ngxs/state';
import { User } from '@jobzmall/models';

@Component({
    selector: 'mobile-navigation',
    templateUrl: './mobile-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class MobileNavigationComponent
    extends JMBaseComponent
    implements OnInit
{
    @Select(AppState.mobileNavigationRelative)
    mobileNavigationRelative$: Observable<boolean>;
    @Select(UserState.user) user$: Observable<User>;

    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

    menuOpen = false;

    constructor(
        private _bottomSheet: MatBottomSheet,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {}

    openSheet(): void {
        this._bottomSheet.open(MobileBottomSheetComponent, {});
    }
}
