import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    Input,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';
import { Observable } from 'rxjs';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { environment } from 'environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { waitForGlobal } from '@jobzmall/core/util/wait-for-global';
import { User } from '@jobzmall/models';
import { WindowService } from '@jobzmall/core/browser/window.service';
import { SocialLogin } from '@jobzmall/core/auth/ngxs/actions';
import { MatDialog } from '@angular/material/dialog';
import { CaptchaService } from '@jobzmall/core/services/captcha.service';

@Component({
    selector: 'one-tap',
    template: `<div></div>`
})
export class OneTapComponent
    extends JMBaseComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() cancelOnTapOutside = false;

    @Select(AuthState.isAuthenticated) authenticated$: Observable<boolean>;

    googleId;

    constructor(
        private _captcha: CaptchaService,
        private _dialog: MatDialog,
        private _store: Store,
        private readonly _win: WindowService,
        @Inject(PLATFORM_ID) private _platformId: Object
    ) {
        super();

        this.googleId = environment.googleAppID;
    }

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.subscriptions.sink = this.authenticated$.subscribe(
            (auth: boolean) => {
                if (isPlatformBrowser(this._platformId)) {
                    if (!auth) {
                        this.init();
                    } else {
                        this._win['google']['accounts'].id.cancel();
                    }
                }
            }
        );
    }

    sortParams(url: string) {
        let queryParams = url.split('?')[1];
        let params;
        if (queryParams) {
            params = queryParams.split('&');
        }
        let pair = null;
        let data = {};
        if (params) {
            params.forEach((d) => {
                pair = d.split('=');
                data[`${pair[0]}`] = pair[1];
            });
        }
        return data;
    }

    init() {
        waitForGlobal('google', () => {
            this._win['google']['accounts'].id.initialize({
                client_id: this.googleId,
                cancel_on_tap_outside: this.cancelOnTapOutside,
                callback: (event) => {
                    this.subscriptions.sink = this._store
                        .dispatch(
                            new SocialLogin({
                                provider: 'google',
                                access_token: event.credential
                            })
                        )
                        .subscribe((user: User) => {
                            if (!user) {
                                this._win['google']['accounts'].id.prompt();
                            } else {
                                this._dialog.closeAll();
                            }
                        });
                }
            });
            this._win['google']['accounts'].id.prompt();
        });
    }

    ngOnDestroy(): void {
        this._win['google']['accounts'].id.cancel();
        super.ngOnDestroy();
    }
}
