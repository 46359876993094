import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

@Pipe({ name: 'dayJsDifference' })
export class DifferencePipe implements PipeTransform {
    transform(
        value: string | number | Date | dayjs.Dayjs,
        otherValue: string | number | Date | dayjs.Dayjs,
        unit?: duration.DurationUnitType,
        precision?: boolean
    ): number {
        const date = dayjs(value);
        const date2 = otherValue !== null ? dayjs(otherValue) : dayjs();

        return date.diff(date2, unit, precision);
    }
}
