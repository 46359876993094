import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';

@Pipe({ name: 'dayJsDateFormat' })
export class DateFormatPipe implements PipeTransform {
    constructor() {
        dayjs.extend(utc);
        dayjs.extend(advancedFormat);
        dayjs.extend(localizedFormat);
    }
    transform(
        value: string | number | Date | dayjs.Dayjs,
        ...args: any[]
    ): string {
        if (!value) {
            return '';
        }

        return dayjs(value).format(args[0]);
    }
}
