<div class="flex items-center justify-center w-screen h-fill">
    <div class="flex flex-col p-6 mx-auto rounded shadow-lg bg-card">
        <div class="flex flex-row items-center justify-between">
            <span class="text-2xl font-bold capitalize">Share {{type}} 🤗</span>
            <mat-icon (click)="close()" class="cursor-pointer icon-size-5" [svgIcon]="'heroicons_solid:x'"></mat-icon>
        </div>
        <div class="relative flex flex-row my-6">
            <mat-form-field class="w-full jbz-mat-dense jbz-mat-no-subscript">
                <button class="flex items-center" [url]="url" (click)="setCopied()" shareButton="copy" matSuffix>
                    <mat-icon class="mr-1 cursor-pointer icon-size-5"
                        [svgIcon]="copied ? 'heroicons_solid:check' : 'heroicons_solid:duplicate'"></mat-icon>
                    Copy Link
                </button>

                <input [readonly]="true" matInput [formControl]="copyFormControl" autocapitalize="off"
                    spellcheck="false" [autocomplete]="'off'" [placeholder]="copied ? 'Copied!' : url">
            </mat-form-field>
        </div>
        <div class="grid grid-cols-2 gap-6 mb-4">
            <button [url]="url" shareButton="facebook" class="flex flex-row items-center col-span-1">
                <mat-icon class="mr-2 fab fa-facebook icon-size-4"></mat-icon>
                Share on Facebook
            </button>
            <button [url]="url" shareButton="twitter" class="flex flex-row items-center col-span-1">
                <mat-icon class="mr-2 fab fa-twitter icon-size-4"></mat-icon>
                Share on Twitter
            </button>
            <button [url]="url" shareButton="linkedin" class="flex flex-row items-center col-span-1">
                <mat-icon class="mr-2 fab fa-linkedin icon-size-4"></mat-icon>
                Share on LinkedIn
            </button>
            <button [url]="url" shareButton="reddit" class="flex flex-row items-center col-span-1">
                <mat-icon class="mr-2 fab fa-reddit icon-size-4"></mat-icon>
                Share on Reddit
            </button>
        </div>
    </div>
</div>
