import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { Floor, FloorAdapter } from './floor.model';
import { Media, MediaAdapter } from './media.model';
import { SEOAdapter, SEO } from './seo.model';
import * as dayjs from 'dayjs';

export class Industry {
    constructor(
        public id: number,
        public name: string,
        public description: string,
        public slug: string,
        public floor_id: number,
        public floor: Floor,
        public image: Media,
        public created_at?: Date,
        public updated_at?: Date,
        public seo?: SEO
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class IndustryAdapter implements Adapter<Industry> {
    constructor(
        private _floorAdapter: FloorAdapter,
        private _mediaAdapter: MediaAdapter,
        private _seoAdapter: SEOAdapter
    ) {}
    adapt(item: any): Industry {
        return new Industry(
            item.id,
            item.name,
            item.description,
            item.slug,
            item.floor_id,
            item.floor ? this._floorAdapter.adapt(item.floor) : null,
            item.image ? this._mediaAdapter.adapt(item.image) : null,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.seo ? this._seoAdapter.adapt(item.seo) : null
        );
    }
}
