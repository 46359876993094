import * as dayjs from 'dayjs';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayJsAdd' })
export class AddPipe implements PipeTransform {
    transform(
        value: string | number | Date | dayjs.Dayjs,
        amount: number,
        unit: dayjs.ManipulateType
    ): dayjs.Dayjs {
        if (
            typeof amount === 'undefined' ||
            (typeof amount === 'number' && typeof unit === 'undefined')
        ) {
            throw new Error('AddPipe: missing required arguments');
        }

        return dayjs(value).add(amount, unit);
    }
}
