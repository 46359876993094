<div class="jbz-vertical-navigation-item-wrapper" [class.jbz-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <div class="jbz-vertical-navigation-item" matTooltipClass="navbar-tooltip" matTooltipPosition="after"
        [matTooltip]="item.tooltip || ''" [ngClass]="{'jbz-vertical-navigation-item-active': active,
                    'jbz-vertical-navigation-item-disabled': item.disabled,
                    'jbz-vertical-navigation-item-active-forced': item.active}">
        <ng-container *transloco="let t">
            <!-- Icon -->
            <mat-icon class="jbz-vertical-navigation-item-icon" [ngClass]="item.classes?.icon"
                *ngIf="item.icon || item.classes?.icon" [svgIcon]="item.icon"></mat-icon>


            <!-- Title & Subtitle -->
            <div class="jbz-vertical-navigation-item-title-wrapper">
                <div class="jbz-vertical-navigation-item-title">
                    <span [ngClass]="item.classes?.title">
                        {{t(item.title)}}
                    </span>
                </div>
                <div class="jbz-vertical-navigation-item-subtitle" *ngIf="item.subtitle">
                    <span [ngClass]="item.classes?.subtitle">
                        {{t(item.subtitle)}}
                    </span>
                </div>
            </div>

            <!-- Badge -->
            <div class="jbz-vertical-navigation-item-badge" *ngIf="item.badge">
                <div class="jbz-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes">
                    {{item.badge.title}}
                </div>
            </div>
        </ng-container>
    </div>

</div>

<ng-container *ngIf="!skipChildren">

    <div class="jbz-vertical-navigation-item-children">

        <ng-container *ngFor="let item of item.children; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !(item.hidden(item) | async)) || !item.hidden">

                <!-- Basic -->
                <jbz-vertical-navigation-basic-item *ngIf="item.type === 'basic'" [item]="item" [name]="name">
                </jbz-vertical-navigation-basic-item>

                <!-- Collapsable -->
                <jbz-vertical-navigation-collapsable-item *ngIf="item.type === 'collapsable'" [item]="item"
                    [name]="name" [autoCollapse]="autoCollapse"></jbz-vertical-navigation-collapsable-item>

                <!-- Divider -->
                <jbz-vertical-navigation-divider-item *ngIf="item.type === 'divider'" [item]="item" [name]="name">
                </jbz-vertical-navigation-divider-item>

                <!-- Group -->
                <jbz-vertical-navigation-group-item *ngIf="item.type === 'group'" [item]="item" [name]="name">
                </jbz-vertical-navigation-group-item>

                <!-- Spacer -->
                <jbz-vertical-navigation-spacer-item *ngIf="item.type === 'spacer'" [item]="item" [name]="name">
                </jbz-vertical-navigation-spacer-item>

            </ng-container>

        </ng-container>

    </div>

</ng-container>
