<ng-container *transloco="let t">
    <div class="z-50 flex flex-col border rounded-tl-lg rounded-tr-lg shadow-xl cursor-pointer bg-card dark:bg-gray-900 min-w-60 max-h-120"
        [ngClass]="{'w-90': isMaximized, 'max-w-[17rem]': !isMaximized}">
        <div (click)="isMaximized ? minimize() : maximize()"
            class="flex items-center justify-between w-full border-b rounded-tl-lg rounded-tr-lg cursor-pointer min-h-14 bg-card">
            <div class="flex items-center flex-auto flex-shrink">
                <div (click)="isMaximized && goToTarget($event);"
                    class="relative flex items-center justify-center w-8 h-8 ml-3 text-gray-600 bg-gray-200 rounded-full cursor-pointer flex-0 dark:bg-gray-700 dark:text-gray-200">
                    <ng-container *ngIf="conversation.image">
                        <img class="object-cover w-full h-full rounded-full" [src]="conversation.image.full_path"
                            alt="Contact avatar" />
                    </ng-container>
                    <ng-container *ngIf="!conversation.image">
                        <div
                            class="flex items-center justify-center w-full h-full text-lg text-gray-600 uppercase bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-200">
                            {{conversation.title.charAt(0)}}
                        </div>
                    </ng-container>
                </div>
                <div class="flex items-center">
                    <span (click)="isMaximized && goToTarget($event);"
                        class="pl-4 font-medium leading-5 truncate cursor-pointer max-w-36 hover:underline">{{conversation.title}}</span>
                    <span *ngIf="conversation.unread" class="px-2 ml-2 py-0.5 text-sm text-white bg-pink-600
                    rounded-full">{{(conversation.unread)}}</span>
                </div>
            </div>
            <div (click)="$event.stopPropagation();" class="flex items-center">
                <button (click)="scheduleVideoCall()" matTooltipClass="jbz-tooltip" matTooltip="Schedule Video Meeting"
                    *ngIf="showVideoMeeting" mat-icon-button>
                    <mat-icon class="text-current icon-size-5" [svgIcon]="'mimicons_outline:video'"></mat-icon>
                </button>
                <mat-icon *ngIf="conversation.muted" class="text-current icon-size-5"
                    [svgIcon]="'heroicons_solid:volume-off'"></mat-icon>
                <button *ngIf="isMaximized" mat-icon-button [matMenuTriggerFor]="conversationHeaderMenu">
                    <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_outline:dots-vertical'"></mat-icon>
                    <mat-menu #conversationHeaderMenu>

                        <!-- <button mat-menu-item [routerLink]="['/messages/' + conversation.channel]">
                            <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_solid:external-link'">
                            </mat-icon>
                            Open In Messages
                        </button> -->
                        <button mat-menu-item (click)="toggleMuteNotifications()">
                            <ng-container *ngIf="!conversation.muted">
                                <mat-icon [svgIcon]="'heroicons_outline:volume-off'"></mat-icon>
                                {{t('PUBLIC.SHARED.ACTION.MUTE')}}
                            </ng-container>
                            <ng-container *ngIf="conversation.muted">
                                <mat-icon [svgIcon]="'heroicons_outline:volume-up'"></mat-icon>
                                {{t('PUBLIC.SHARED.ACTION.UNMUTE')}}
                            </ng-container>
                        </button>
                        <button (click)="archive()" mat-menu-item>
                            <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                            {{t('PRIVATE.MESSAGING.ACTION.ARCHIVE_CONVERSATION')}}
                        </button>
                        <button *ngIf="!conversation?.blocked_members.length && !conversation.subject_type"
                            (click)="block()" mat-menu-item>
                            <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
                            {{t('PRIVATE.MESSAGING.ACTION.BLOCK_USER')}}
                        </button>
                    </mat-menu>
                </button>
                <button mat-icon-button (click)="unpin(conversation)">
                    <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="isMaximized" @expandCollapse class="relative flex flex-col flex-auto flex-shrink overflow-hidden">
            <div *ngIf="bannerMessage"
                class="absolute top-0 left-0 flex items-center justify-center w-full p-2 text-center text-yellow-700 bg-yellow-200 text-md z-1">
                {{bannerMessage}} <mat-icon (click)="bannerMessage = undefined"
                    class="ml-2 cursor-pointer text-yellow-70 icon-size-3" [svgIcon]="'heroicons_solid:x'">
                </mat-icon>
            </div>
            <!-- Conversation -->
            <div chatScrollDirective class="relative flex flex-col-reverse flex-grow overflow-y-auto h-100"
                infiniteScroll [infiniteScrollUpDistance]="9.9" [scrollWindow]="false" [infiniteScrollThrottle]="150"
                (scrolledUp)="getMessages()">

                <div class="flex flex-col flex-auto flex-shrink p-6 bg-card dark:bg-transparent">

                    <div class="flex flex-row items-center justify-center w-full">
                        <mat-spinner class="mb-3" *ngIf="messagesLoading" color="accent" [diameter]="40">
                        </mat-spinner>
                    </div>

                    <ng-container
                        *ngFor="let message of messages; let i = index; let first = first; let last = last; trackBy: ('id' | trackByProperty )">
                        <!-- Start of the day -->
                        <ng-container *ngIf="first || (messages[i - 1]?.created_at | date:'d') !== (message.created_at
                                | date:'d')">
                            <div class="flex items-center justify-center my-3 -mx-6">
                                <div class="flex-auto border-b"></div>
                                <div class="mx-4 text-sm font-medium leading-5 flex-0 text-secondary">
                                    {{message.created_at | date: 'longDate'}}
                                </div>
                                <div class="flex-auto border-b"></div>
                            </div>
                        </ng-container>

                        <div class="flex flex-row items-end w-full group" [ngClass]="{'justify-end': isMine(message, user$ | async),
                                        'justify-start': !isMine(message, user$ | async)}">

                            <div *ngIf="isLastMessageOfGroup(message, messages, i) && !isMine(message, user$ | async)"
                                class="relative flex items-center justify-center w-6 h-6 mb-4 mr-3 text-gray-600 bg-gray-200 rounded-full flex-0 dark:bg-gray-700 dark:text-gray-200">
                                <ng-container *ngIf="message.sender.image">
                                    <img class="object-cover w-full h-full rounded-full"
                                        [src]="message.sender.image.full_path" alt="Contact avatar" />
                                </ng-container>
                                <ng-container *ngIf="!message.sender.image">
                                    <div
                                        class="flex items-center justify-center w-full h-full text-sm text-gray-600 uppercase bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-200">
                                        {{message.sender.name.charAt(0)}}
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="!isLastMessageOfGroup(message, messages, i) && !isMine(message, user$ | async)"
                                class="relative flex items-center justify-center w-6 h-6 mr-3 flex-0">
                            </div>
                            <div class="flex flex-col w-full" [ngClass]="{'items-end': isMine(message, user$ | async),
                                        'items-start': !isMine(message, user$ | async),
                                        'mt-0.5': i > 0 && isMine(messages[i - 1], user$ | async) ===
                                        isMine(message, user$
                                        | async),
                                        'mt-3': i > 0 && isMine(messages[i - 1], user$ | async) !==
                                        isMine(message, user$ |
                                        async)}">

                                <div *ngIf="isFirstMessageOfGroup(message, messages, i) && !isMine(message, user$ | async)"
                                    class="text-secondary text-sm mt-2 mb-0.5">
                                    {{message.sender.name}}
                                </div>


                                <!-- Message Bubble -->
                                <div *ngIf="message.text && !message.deleted_at" class="relative px-3 py-2 rounded-lg"
                                    [ngClass]="{'bg-blue-500 text-blue-50': isMine(message, user$ | async),
                                            'bg-gray-500 text-gray-50': !isMine(message, user$ | async), 'max-w-3/4':
                                            !linkPreview.youtubeEmbedUrl && !linkPreview.vimeoEmbedUrl}">
                                    <!-- Speech bubble tail -->
                                    <ng-container *ngIf="last || isMine(messages[i + 1], user$ | async) !==
                                            isMine(message, user$ |
                                            async)">

                                        <div class="absolute bottom-0 w-3 transform" [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': isMine(message, user$
                                                | async),
                                                    'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !isMine(message,
                                                    user$ | async)}">
                                            <ng-container *ngTemplateOutlet="speechBubbleExtension">
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <button (click)="remove(message)" *ngIf="isMine(message, user$ | async)"
                                        matTooltip="Unsend Message" mat-icon-button
                                        class="absolute hidden transform -translate-x-1/2 -translate-y-1/2 group-hover:block -left-5 top-1/2">
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>


                                    <!-- Message -->
                                    <link-preview #linkPreview [disabled]="message.media?.length > 0" class="min-w-4"
                                        [body]="message.text">
                                    </link-preview>
                                </div>

                                <!-- Unsent Message Bubble -->
                                <div *ngIf="message.deleted_at" class="relative px-3 py-2 rounded-lg max-w-3/4"
                                    [ngClass]="{'bg-blue-500 text-blue-50': isMine(message, user$ | async),
                                            'bg-gray-500 text-gray-50': !isMine(message, user$ | async)}">
                                    <!-- Speech bubble tail -->
                                    <ng-container *ngIf="last || isMine(messages[i + 1], user$ | async) !==
                                            isMine(message, user$ |
                                            async)">

                                        <div class="absolute bottom-0 w-3 transform" [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': isMine(message, user$
                                                | async),
                                                    'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !isMine(message,
                                                    user$ | async)}">
                                            <ng-container *ngTemplateOutlet="speechBubbleExtension">
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <!-- Message -->
                                    <span class="text-sm italic min-w-4">Unsent message</span>
                                </div>

                                <div *ngIf="message.meta?.has_media && !(message.media).length && !message.deleted_at"
                                    class="relative mt-4 rounded-lg max-w-3/4">
                                    <div class="m-2 bg-gray-300 rounded animate-pulse dark:bg-gray-500 w-50 h-50">

                                    </div>
                                </div>

                                <!-- Images Bubble -->
                                <div *ngIf="(message.media | filterBy: ['type']: 'image').length && !message.deleted_at"
                                    class="relative mt-4 rounded-lg max-w-3/4">
                                    <button (click)="remove(message)" *ngIf="isMine(message, user$ | async)"
                                        matTooltip="Unsend Message" mat-icon-button
                                        class="absolute hidden transform -translate-x-1/2 -translate-y-1/2 group-hover:block -left-5 top-1/2">
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>


                                    <media-grid size="small" class="w-full" *ngIf="message.media?.length" filter="image"
                                        [media]="message.media">
                                    </media-grid>
                                </div>

                                <!-- Videos Bubble -->
                                <div *ngIf="(message.media | filterBy: ['type']: 'video').length && !message.deleted_at"
                                    class="relative mt-4 rounded-lg max-w-3/4">
                                    <button (click)="remove(message)" *ngIf="isMine(message, user$ | async)"
                                        matTooltip="Unsend Message" mat-icon-button
                                        class="absolute hidden transform -translate-x-1/2 -translate-y-1/2 group-hover:block -left-5 top-1/2">
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>

                                    <media-grid size="small" *ngIf="message.media?.length" filter="video"
                                        [media]="message.media">
                                    </media-grid>
                                </div>

                                <!-- Documents Bubble -->
                                <div *ngIf="(message.media | filterBy: ['type']: 'document').length && !message.deleted_at"
                                    class="relative mt-4 rounded-lg max-w-3/4">
                                    <button (click)="remove(message)" *ngIf="isMine(message, user$ | async)"
                                        matTooltip="Unsend Message" mat-icon-button
                                        class="absolute hidden transform -translate-x-1/2 -translate-y-1/2 group-hover:block -left-5 top-1/2">
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>

                                    <media-grid size="small" *ngIf="message.media?.length" filter="document"
                                        [media]="message.media">
                                    </media-grid>
                                </div>

                                <!-- Time -->
                                <ng-container *ngIf="(first
                                       || last
                                       || isMine(messages[i + 1], user$ | async) !== isMine(message, user$ |
                                       async)
                                       || messages[i + 1]?.created_at !== message.created_at) && !message.deleted_at">
                                    <div class="flex items-center">
                                        <div class="my-0.5 text-sm font-medium text-secondary" [ngClass]="{'mr-3': isMine(message, user$ | async),
                                                'ml-3': !isMine(message, user$ | async)}">
                                            {{message.created_at | date:'shortTime'}}
                                        </div>

                                        <div [matTooltip]="'Message failed to send.'"
                                            *ngIf="isMine(message, user$ | async) && message.error"
                                            class="flex items-center justify-center w-3 h-3 text-white bg-red-400 rounded-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2" viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path fill-rule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </ng-container>
                    <div *ngIf="othersTyping" class="flex flex-col items-start mt-3">
                        <div class="relative px-3 py-2 bg-gray-500 rounded-lg max-w-3/4 text-gray-50">
                            <!-- Speech bubble tail -->
                            <ng-container>
                                <div
                                    class="absolute bottom-0 w-3 mb-px -ml-px text-gray-500 transform -left-1 -scale-x-1">
                                    <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                                </div>
                            </ng-container>
                            <!-- Message -->
                            <div class="flex flex-row py-1 leading-5 min-w-4">
                                <span class="flex w-2 h-2 mr-1 bg-gray-400 rounded-full animate-pulse flex-0"></span>
                                <span class="flex w-2 h-2 mr-1 bg-gray-400 rounded-full animate-pulse flex-0"></span>
                                <span class="flex w-2 h-2 bg-gray-400 rounded-full animate-pulse flex-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="!conversation.blocked_members.length && conversation.is_request"
                class="flex flex-col items-center justify-center border-t">
                <div class="flex flex-col items-center justify-center p-10">
                    <span class="text-lg text-center text-secondary">
                        <span class="text-semibold text-default">{{conversation.title}}</span> wants
                        to
                        send you a message
                    </span>
                    <span class="mt-2 text-center text-secondary">
                        Read our <span (click)="openMessagePractices()"
                            class="text-blue-500 underline cursor-pointer">messaging best-practices</span> to avoid
                        malicious
                        actors and scams
                    </span>
                </div>
                <div class="grid w-full grid-cols-3 border-t divide-x">
                    <button (click)="block()" type="button" class="flex items-center justify-center py-5 text-red-800">
                        Block
                    </button>
                    <button (click)="archive()" type="button"
                        class="flex items-center justify-center py-5 text-red-600">
                        Delete
                    </button>
                    <button (click)="acceptRequest()" type="button" class="flex items-center justify-center py-5">
                        Accept
                    </button>
                </div>
            </div>

            <div *ngIf="conversation.blocked_members.length"
                class="flex flex-col items-center justify-center px-8 py-4">
                <span class="text-lg font-semibold text-center">You blocked
                    <span class="capitalize">{{conversation.blocked_members[0].user.name}}</span>. You can view history
                    here or <a class="underline text-primary-600" (click)="archive()">delete
                        the conversation permanently</a></span>
            </div>
            <div *ngIf="isClosed" class="flex flex-col items-center justify-center p-10">
                <span class="text-lg font-semibold text-center">The application associated with this
                    conversation has been closed</span>
            </div>

            <!-- Message field -->
            <form *ngIf="!conversation.blocked_members.length && !isClosed && !conversation.is_request"
                class="flex flex-col" action="#" #replyForm="ngForm" (ngSubmit)="reply($event)"
                (keydown.enter)="reply($event)">
                <div *ngIf="uploading" class="flex items-center justify-center w-full">
                    <div class="flex w-full mx-4 my-4">
                        <file-pond (onremovefile)="handleRemoveFile($event)" (onprocessfile)="uploading = false"
                            (onaddfile)="handleAddFile($event)" class="w-full jobzfilepond" #pond [options]="options">
                        </file-pond>
                    </div>
                </div>

                <div class="flex flex-col p-2 border-t bg-gray-50 dark:bg-transparent">
                    <mat-form-field class="w-full jbz-mat-rounded jbz-mat-dense jbz-mat-no-subscript jbz-mat-bold">
                        <button matPrefix (click)="uploading = !uploading" type="button" mat-icon-button>
                            <mat-icon [svgIcon]="'heroicons_outline:paper-clip'"></mat-icon>
                        </button>
                        <textarea
                            [disabled]="(conversation.message_limit && messages.length >= conversation.message_limit)"
                            ngModel (keydown)="broadcastTyping()" name="message" [placeholder]="(conversation.message_limit && messages.length >=
                            conversation.message_limit) ? 'Limit Reached' : 'Type your message'"
                            class="my-0 resize-none min-h-5" style="margin: 11px 0 !important; padding: 0 !important;"
                            [rows]="1" matInput #messageInput></textarea>
                        <button [matTooltip]="(conversation.message_limit && messages.length >= conversation.message_limit) ?
                            'You may only send up to 3 message in a request' : null" matSuffix [disabled]="(!replyForm.form.value.message && !uploads?.length) ||
                            !replyForm.form.valid || (conversation.message_limit > 0 && messages.length >=
                            conversation.message_limit)" type="submit" mat-icon-button>
                            <mat-icon *ngIf="!loading" class="transform rotate-90"
                                [svgIcon]="'heroicons_outline:paper-airplane'">
                            </mat-icon>
                            <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
                            </mat-progress-spinner>
                        </button>
                    </mat-form-field>
                    <div class="flex items-center justify-between ">

                        <!-- <div class="flex items-center my-px h-11">
                            <button (click)="uploading = !uploading" type="button" mat-icon-button>
                                <mat-icon [svgIcon]="'heroicons_outline:paper-clip'"></mat-icon>
                            </button>
                        </div> -->

                        <!-- <div class="flex items-center my-px h-11">
                            <button [disabled]="!(replyForm.form.value.message || uploads?.length)" type="submit"
                                mat-icon-button>
                                <mat-icon *ngIf="!loading" class="transform rotate-90"
                                    [svgIcon]="'heroicons_outline:paper-airplane'">
                                </mat-icon>
                                <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
                                </mat-progress-spinner>
                            </button>
                        </div> -->
                    </div>
                </div>


            </form>
        </div>

    </div>
    <!-- Speech bubble tail SVG -->
    <!-- @formatter:off -->
    <ng-template #speechBubbleExtension>
        <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                    d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                    fill="currentColor" fill-rule="nonzero"></path>
            </g>
        </svg>
    </ng-template>
    <!-- @formatter:on -->
</ng-container>
