import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { LanguageModule } from 'app/layout/common/language/language.module';
import { MessagesBadgeModule } from 'app/layout/common/messages-badge/messages-badge.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { MobileOmniModule } from 'app/layout/common/mobile-omni/mobile-omni.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserMenuModule } from '@jobzmall/common/components/user-menu/user-menu.module';
import { SharedModule } from 'app/shared/shared.module';
import { FuturisticLayoutComponent } from 'app/layout/layouts/vertical/futuristic/futuristic.component';

@NgModule({
    declarations: [FuturisticLayoutComponent],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseNavigationModule,
        LanguageModule,
        MessagesBadgeModule,
        NotificationsModule,
        MobileOmniModule,
        ShortcutsModule,
        UserMenuModule,
        SharedModule
    ],
    exports: [FuturisticLayoutComponent]
})
export class FuturisticLayoutModule {}
