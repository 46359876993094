import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MediaGridComponent } from './media-grid/media-grid.component';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { FuseMasonryModule } from '@fuse/components/masonry';
import { LightgalleryModule } from 'lightgallery/angular';
import { MatButtonModule } from '@angular/material/button';
import { DocumentOverlayComponent } from './document-overlay/document-overlay.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseCardModule } from '@fuse/components/card';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { JMVideoComponentModule } from '../video/jm-video/jm-video.module';

const COMPONENTS = [
    DocumentOverlayComponent,
    MediaGridComponent
]

@NgModule({
    declarations: COMPONENTS,
    imports: [
        NgxDocViewerModule,
        LightgalleryModule,
        FuseCardModule,
        MatProgressSpinnerModule,
        JMCommonDirectivesModule,
        MatButtonModule,
        FuseMasonryModule,
        CommonModule,
        JMPipesModule,
        NgPipesModule,
        MatIconModule,
        JMVideoComponentModule
    ],
    exports: COMPONENTS,
    providers: [],
})
export class JMMediaModule { }
