import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    HostBinding,
    forwardRef,
    Inject,
    Optional
} from '@angular/core';

import * as dayjs from 'dayjs';

import { union } from 'lodash-es';

import { ConfigService } from '@jobzmall/config';
import { SearchFilter } from '../search-filters/search-filters.component';
import { SearchFacet } from '@jobzmall/models';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import {
    BaseWidget,
    NgAisIndex,
    NgAisInstantSearch
} from 'angular-instantsearch';

@Component({
    selector: 'admin-filters',
    templateUrl: './admin-filters.component.html'
})
export class AdminFiltersComponent extends BaseWidget implements OnInit {
    @Input() searchInstance: any;
    @Input() type: string;
    @Input() kind: string = 'drawer';
    @Input() showApply = false;
    @Input() @HostBinding('class') class;
    filters: Array<SearchFilter> = [];
    @Output() filtersInitialized: EventEmitter<any> = new EventEmitter();
    @Output() filtersClosed: EventEmitter<any> = new EventEmitter();

    @Output() cleared: EventEmitter<any> = new EventEmitter();

    state: any = {};

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch,
        private _config: ConfigService
    ) {
        super('AdminSearchFilters');
    }

    ngOnInit(): void {
        this.createWidget(connectCurrentRefinements, {});
        super.ngOnInit();

        this.initFilters();
    }

    closeFilters() {
        this.filtersClosed.emit({});
    }

    initFilters() {
        switch (this.type) {
            case 'curated_locations':
                this.filters = this.curatedLocationFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'faqs':
                this.filters = this.faqFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'glossary':
                this.filters = this.glossaryFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'product_access_requests':
                this.filters = this.productAccessRequestFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'video_collections':
                this.filters = this.videoCollectionFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'jobs':
                this.filters = this.jobFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'reports':
                this.filters = this.reportFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'stores':
                this.filters = this.storeFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'articles':
                this.filters = this.articleFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'posts':
                this.filters = this.postFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'users':
                this.filters = this.userFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'employers':
                this.filters = this.employerFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'applications':
                this.filters = this.applicationFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'talentpoolapplication':
                this.filters = this.talentPoolApplicationFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'claimrequests':
                this.filters = this.claimRequestFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'starrequests':
                this.filters = this.starRequestFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'genomes':
                this.filters = this.genomeFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'genes':
                this.filters = this.geneFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'usergenomes':
                this.filters = this.userGenomeFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'skills':
                this.filters = this.skillFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'quotes':
                this.filters = this.quoteFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'communities':
                this.filters = this.communityFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'discussions':
                this.filters = this.discussionFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'videocollectionquestions':
                this.filters = this.videoCollectionQuestionFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'videotopics':
                this.filters = this.videoTopicFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'videocollectionanswers':
                this.filters = this.videoCollectionAnswerFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'geniusorders':
                this.filters = this.geniusOrderFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'coachorders':
                this.filters = this.coachOrderFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'coaches':
                this.filters = this.coachFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'zv':
                this.filters = this.zvFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'lists':
                this.filters = this.listFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'courses':
                this.filters = this.courseFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'featuredjoblists':
                this.filters = this.featuredJobListFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'featuredcoachlists':
                this.filters = this.featuredCoachListFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'jobtransferrequests':
                this.filters = this.jobTransferRequestFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'boards':
                this.filters = this.boardFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'tag_clusters':
                this.filters = this.tagClusterFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'board_jobs':
                this.filters = this.boardJobFilters();
                this.filtersInitialized.emit(this.filters);
                break;

            case 'job_templates':
                this.filters = this.jobTemplateFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'career_guides':
                this.filters = this.careerGuideFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            default:
                break;
        }
    }

    careerGuideFilters(): SearchFilter[] {
        const arr = [
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('numeric', 'FAQ Count', {
                attribute: 'faqs_count',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, label: 'Any Value' },
                    { start: 1, end: 10, label: '1-10' },
                    { start: 11, end: 30, label: '11-30' },
                    { start: 31, end: 50, label: '31-50' },
                    { start: 51, end: 75, label: '51-75' },
                    { start: 76, end: 100, label: '76-100' },
                    { start: 101, label: '100+' }
                ]
            }),
            new SearchFilter('refinement', 'Approved', {
                attribute: 'approved',
                operator: 'and'
            })
        ];
        return arr;
    }

    jobTemplateFilters(): SearchFilter[] {
        const arr = [
            new SearchFilter('refinement', 'Approved', {
                attribute: 'approved',
                operator: 'and'
            })
        ];
        return arr;
    }

    jobTransferRequestFilters(): SearchFilter[] {
        const arr = [
            new SearchFilter('refinement', 'Organizations', {
                attribute: SearchFacet.STORE_NAME,
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
        return arr;
    }

    featuredCoachListFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Country', {
                attribute: 'location.country.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Countries...'
            }),
            new SearchFilter('refinement', 'City', {
                attribute: 'location.city.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Cities...'
            })
        ];
        return arr;
    }

    glossaryFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('numeric', 'FAQ Count', {
                attribute: 'faqs_count',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, label: 'Any Value' },
                    { start: 1, end: 10, label: '1-10' },
                    { start: 11, end: 30, label: '11-30' },
                    { start: 31, end: 50, label: '31-50' },
                    { start: 51, end: 75, label: '51-75' },
                    { start: 76, end: 100, label: '76-100' },
                    { start: 101, label: '100+' }
                ]
            })
        ];
        return arr;
    }

    faqFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Entity Name', {
                attribute: 'owner.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Names...'
            }),
            new SearchFilter('refinement', 'Entity Title', {
                attribute: 'owner.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
        return arr;
    }

    curatedLocationFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Country', {
                attribute: 'location.country.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Countries...'
            }),
            new SearchFilter('refinement', 'Population', {
                attribute: 'population.name',
                operator: 'and'
            })
        ];
        return arr;
    }

    featuredJobListFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Country', {
                attribute: 'location.country.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Countries...'
            }),
            new SearchFilter('refinement', 'City', {
                attribute: 'location.city.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Cities...'
            })
        ];
        return arr;
    }

    productAccessRequestFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Account', {
                attribute: 'account.accountable.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Accounts...'
            }),
            new SearchFilter('refinement', 'Contact', {
                attribute: 'account.contactable.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Contacts...'
            }),
            new SearchFilter('refinement', 'Account Type', {
                attribute: 'account.accountable_type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Currency', {
                attribute: 'currency_type.name',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Amount Paid', {
                attribute: 'amount_paid',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: 0,
                        end: 100,
                        label: '0-100'
                    },
                    {
                        start: 101,
                        end: 500,
                        label: '101-500'
                    },
                    {
                        start: 501,
                        end: 1000,
                        label: '501-1000'
                    },
                    {
                        start: 1001,
                        end: 2500,
                        label: '1001-2500'
                    },
                    {
                        start: 2500,
                        label: '2500+'
                    }
                ]
            })
        ];
        return arr;
    }

    videoCollectionFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Questions', {
                attribute: 'questions',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Questions...'
            }),
            new SearchFilter('numeric', 'Video Count', {
                attribute: 'answers_count',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: 1,
                        end: 1,
                        label: '1'
                    },
                    {
                        start: 2,
                        end: 2,
                        label: '2'
                    },
                    {
                        start: 3,
                        end: 3,
                        label: '3'
                    },
                    {
                        start: 4,
                        end: 5,
                        label: '5'
                    },
                    {
                        start: 5,
                        label: '5+'
                    }
                ]
            })
        ];
        return arr;
    }

    courseFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Type', {
                attribute: 'type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Types...'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'address',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),

            new SearchFilter('numeric', 'Price', {
                attribute: 'unit_cost',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: 0,
                        end: 0,
                        label: 'Free'
                    },
                    {
                        start: 1,
                        end: 250,
                        label: '$1 - $250'
                    },
                    {
                        start: 251,
                        end: 500,
                        label: '$250 - $500'
                    },
                    {
                        start: 500,
                        label: '$500+'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Providers', {
                attribute: 'provider.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Providers...'
            }),
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            })
        ];
        return arr;
    }

    articleFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Date Updated', {
                attribute: 'updated_at_unix',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    coachOrderFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Payment Captured', {
                attribute: 'payment_captured',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Service', {
                attribute: 'service.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for services...'
            }),
            new SearchFilter('refinement', 'Currency Type', {
                attribute: 'currency_type.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for currencies...'
            }),
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    geniusOrderFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Product', {
                attribute: 'product',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for products...'
            }),
            new SearchFilter('refinement', 'Line Item', {
                attribute: 'selected_line_item',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Email', {
                attribute: 'email',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for emails...'
            }),
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    videoCollectionAnswerFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Approved', {
                attribute: 'approved',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Pinned', {
                attribute: 'pinned',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Explicit Content', {
                attribute: 'video.explicit_label',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'User', {
                attribute: 'user.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for users...'
            }),
            new SearchFilter('refinement', 'Question Type', {
                attribute: 'question.type',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    videoTopicFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Languages', {
                attribute: 'language',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for languages...'
            }),
            new SearchFilter('refinement', 'Retired', {
                attribute: 'is_retired',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Type', {
                attribute: 'type',
                operator: 'and'
            })
        ];
    }

    videoCollectionQuestionFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Languages', {
                attribute: 'language',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for languages...'
            }),
            new SearchFilter('refinement', 'Topics', {
                attribute: 'topics',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for topics...'
            }),
            new SearchFilter('refinement', 'Type', {
                attribute: 'type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Retired', {
                attribute: 'is_retired',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    reportFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Report Type', {
                attribute: 'reportable_type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for report types...'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Assignable', {
                attribute: 'assignable_id',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Resolved', {
                attribute: 'resolved',
                operator: 'and'
            })
        ];
    }

    communityFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Categories', {
                attribute: 'categories',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for categories...'
            }),
            new SearchFilter('refinement', 'Type', {
                attribute: 'communable_type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for types...'
            }),
            new SearchFilter('refinement', 'Top', {
                attribute: 'top',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Visible', {
                attribute: 'visible',
                operator: 'and'
            })
        ];
    }

    discussionFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Approved', {
                attribute: 'approved',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    postFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Poster', {
                attribute: 'poster.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for posters...'
            }),
            new SearchFilter('refinement', 'Poster Type', {
                attribute: 'posterable_type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for poster types...'
            }),
            new SearchFilter('refinement', 'Type', {
                attribute: 'type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for types...'
            }),
            new SearchFilter('refinement', 'Visible', {
                attribute: 'visible',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Comment Count', {
                attribute: 'comments_count',
                operator: 'or',
                items: [
                    { label: 'Any' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, end: 5, label: '1-5' },
                    { start: 6, end: 10, label: '6-10' },
                    { start: 11, end: 25, label: '11-25' },
                    { start: 26, end: 50, label: '26-50' },
                    { start: 50, label: '50+' }
                ]
            })
        ];
    }

    userGenomeFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Genome', {
                attribute: 'genome.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for genomes...'
            }),
            new SearchFilter('refinement', 'Category', {
                attribute: 'genome.category.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for categories...'
            }),
            new SearchFilter('refinement', 'User', {
                attribute: 'user.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for users...'
            })
        ];
    }

    quoteFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Type', {
                attribute: 'quotable_type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for types...'
            }),
            new SearchFilter('refinement', 'Author', {
                attribute: 'author',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for authors...'
            }),
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    skillFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Type', {
                attribute: 'type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Approved', {
                attribute: 'approved',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Source', {
                attribute: 'source',
                operator: 'and'
            })
        ];
    }

    claimRequestFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Submitted At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Store', {
                attribute: 'store.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Stores...'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            })
        ];
    }

    starRequestFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Submitted At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            })
        ];
    }

    geneFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Arcgenes', {
                attribute: 'arcgene.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Arcgenes...'
            }),
            new SearchFilter('refinement', 'Category', {
                attribute: 'category.name',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    zvFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Premium', {
                attribute: 'premium',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    listFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Type', {
                attribute: 'type',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    genomeFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Genes', {
                attribute: 'genes.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Genes...'
            }),
            new SearchFilter('refinement', 'Category', {
                attribute: 'category.name',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Created At', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            })
        ];
    }

    talentPoolApplicationFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Date Applied', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Verified User', {
                attribute: 'verified',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Talent Pool', {
                attribute: 'pool',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Talent Pools...'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'Qualifications', {
                attribute: 'qualifications',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Qualifications...'
            }),
            new SearchFilter('hierarchical', 'Questions Answered', {
                attributes: ['questions', 'answers']
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Resume', {
                attribute: 'resume',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Cover Letter', {
                attribute: 'has_cover_letter',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Video', {
                attribute: 'has_video',
                operator: 'and'
            })
        ];
    }

    applicationFilters(): SearchFilter[] {
        return [
            new SearchFilter('toggle', 'Show Video Resumes Only', {
                attribute: 'has_video_collection',
                operator: 'and',
                icon: 'mimicons_outline:video'
            }),
            new SearchFilter('numeric', 'Date Applied', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Year Applied', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().year(2023).startOf('year').unix(),
                        end: dayjs().year(2023).endOf('year').unix(),
                        label: '2023'
                    },
                    {
                        start: dayjs().year(2022).startOf('year').unix(),
                        end: dayjs().year(2022).endOf('year').unix(),
                        label: '2022'
                    },
                    {
                        start: dayjs().year(2021).startOf('year').unix(),
                        end: dayjs().year(2021).endOf('year').unix(),
                        label: '2021'
                    },
                    {
                        start: dayjs().year(2020).startOf('year').unix(),
                        end: dayjs().year(2020).endOf('year').unix(),
                        label: '2020'
                    },
                    {
                        start: dayjs().year(2019).startOf('year').unix(),
                        end: dayjs().year(2019).endOf('year').unix(),
                        label: '2019'
                    },
                    {
                        start: dayjs().year(2018).startOf('year').unix(),
                        end: dayjs().year(2018).endOf('year').unix(),
                        label: '2018'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Job Created At', {
                attribute: 'job_created_at_unix',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Verified User', {
                attribute: 'verified',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Organization', {
                attribute: 'store_name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Job', {
                attribute: 'job',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Jobs...'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'Job Titles', {
                attribute: 'prior_experience.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('refinement', 'Prior Experience', {
                attribute: 'prior_experience.company',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            }),

            new SearchFilter('refinement', 'School', {
                attribute: 'education.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Schools...'
            }),
            new SearchFilter('refinement', 'Degree', {
                attribute: 'education.degree',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Degree Field', {
                attribute: 'education.field',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Fields...'
            }),
            new SearchFilter('refinement', 'Certifications', {
                attribute: 'certifications.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Certifications...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Job Type', {
                attribute: 'job_type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Qualifications', {
                attribute: 'qualifications',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Qualifications...'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Resume', {
                attribute: 'resume',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Cover Letter', {
                attribute: 'has_cover_letter',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Video', {
                attribute: 'has_video',
                operator: 'and'
            })
        ];
    }

    coachFilters(): SearchFilter[] {
        let arr = [
            new SearchFilter('refinement', 'Coach Verified', {
                attribute: 'coach_verified',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Services', {
                attribute: 'coach_services.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Services...'
            }),
            new SearchFilter('numeric', 'Signed Up', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'Anytime' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(3, 'month').unix(),
                        label: 'Past 3 Months'
                    },
                    {
                        start: dayjs().subtract(6, 'month').unix(),
                        label: 'Past 6 Months'
                    },
                    {
                        start: dayjs().subtract(12, 'month').unix(),
                        label: 'Past Year'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Last Logged In', {
                attribute: 'last_login_unix',
                operator: 'or',
                items: [
                    { label: 'Anytime' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(3, 'month').unix(),
                        label: 'Past 3 Months'
                    },
                    {
                        start: dayjs().subtract(6, 'month').unix(),
                        label: 'Past 6 Months'
                    },
                    {
                        start: dayjs().subtract(12, 'month').unix(),
                        label: 'Past Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'Blocked', {
                attribute: SearchFacet.USER_BLOCKED,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'User Verified', {
                attribute: SearchFacet.VERIFIED,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Phone Verified', {
                attribute: 'phone_verified',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Deactivated', {
                attribute: 'deactivated',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Public', {
                attribute: SearchFacet.VISIBLE,
                operator: 'and'
            })
        ];
        return arr;
    }

    userFilters(): SearchFilter[] {
        let arr = [
            new SearchFilter('numeric', 'Signed Up', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'Anytime' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(3, 'month').unix(),
                        label: 'Past 3 Months'
                    },
                    {
                        start: dayjs().subtract(6, 'month').unix(),
                        label: 'Past 6 Months'
                    },
                    {
                        start: dayjs().subtract(12, 'month').unix(),
                        label: 'Past Year'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Year Signed Up', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().year(2023).startOf('year').unix(),
                        end: dayjs().year(2023).endOf('year').unix(),
                        label: '2023'
                    },
                    {
                        start: dayjs().year(2022).startOf('year').unix(),
                        end: dayjs().year(2022).endOf('year').unix(),
                        label: '2022'
                    },
                    {
                        start: dayjs().year(2021).startOf('year').unix(),
                        end: dayjs().year(2021).endOf('year').unix(),
                        label: '2021'
                    },
                    {
                        start: dayjs().year(2020).startOf('year').unix(),
                        end: dayjs().year(2020).endOf('year').unix(),
                        label: '2020'
                    },
                    {
                        start: dayjs().year(2019).startOf('year').unix(),
                        end: dayjs().year(2019).endOf('year').unix(),
                        label: '2019'
                    },
                    {
                        start: dayjs().year(2018).startOf('year').unix(),
                        end: dayjs().year(2018).endOf('year').unix(),
                        label: '2018'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Last Logged In', {
                attribute: 'last_login_unix',
                operator: 'or',
                items: [
                    { label: 'Anytime' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(3, 'month').unix(),
                        label: 'Past 3 Months'
                    },
                    {
                        start: dayjs().subtract(6, 'month').unix(),
                        label: 'Past 6 Months'
                    },
                    {
                        start: dayjs().subtract(12, 'month').unix(),
                        label: 'Past Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'jobseeker_status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'User Type', {
                attribute: SearchFacet.USER_TYPE,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Links', {
                attribute: 'link_types',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Link Types...'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'City', {
                attribute: 'location_data.city.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Cities...'
            }),
            new SearchFilter('refinement', 'Country', {
                attribute: 'location_data.country.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Countries...'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'prior_experience.company',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Member Of Organizations', {
                attribute: 'orgs.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            }),
            new SearchFilter('refinement', 'Job Titles', {
                attribute: 'prior_experience.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('refinement', 'School', {
                attribute: 'education.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Schools...'
            }),
            new SearchFilter('refinement', 'Degree', {
                attribute: 'education.degree',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Degree Field', {
                attribute: 'education.field',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Fields...'
            }),
            new SearchFilter('refinement', 'Certifications', {
                attribute: 'certifications.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Certifications...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            }),
            new SearchFilter('refinement', 'Blocked', {
                attribute: SearchFacet.USER_BLOCKED,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Verified', {
                attribute: SearchFacet.VERIFIED,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Phone Verified', {
                attribute: 'phone_verified',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Phone Country Code', {
                attribute: 'phone_country_code',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Country Codes...'
            }),
            new SearchFilter('refinement', 'Deactivated', {
                attribute: 'deactivated',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Public', {
                attribute: SearchFacet.VISIBLE,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Is Coach', {
                attribute: 'is_coaching',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Coach Verified', {
                attribute: 'coach_verified',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Resume', {
                attribute: SearchFacet.RESUME,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Video Resume', {
                attribute: 'has_video_collection',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Image', {
                attribute: 'has_image',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Application Count', {
                attribute: SearchFacet.APPLICATION_COUNT,
                operator: 'or',
                items: [
                    { label: 'Any' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, end: 3, label: '1-3' },
                    { start: 3, end: 5, label: '3-5' },
                    { start: 6, end: 10, label: '6-10' },
                    { start: 11, end: 25, label: '11-25' },
                    { start: 26, label: '26+' }
                ]
            })
        ];
        return arr;
    }

    employerFilters(): SearchFilter[] {
        let arr = [
            new SearchFilter('refinement', 'Member Of Organizations', {
                attribute: 'orgs.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Organization Status', {
                attribute: 'orgs.status',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Signed Up', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'Anytime' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(3, 'month').unix(),
                        label: 'Past 3 Months'
                    },
                    {
                        start: dayjs().subtract(6, 'month').unix(),
                        label: 'Past 6 Months'
                    },
                    {
                        start: dayjs().subtract(12, 'month').unix(),
                        label: 'Past Year'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Year Signed Up', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().year(2023).startOf('year').unix(),
                        end: dayjs().year(2023).endOf('year').unix(),
                        label: '2023'
                    },
                    {
                        start: dayjs().year(2022).startOf('year').unix(),
                        end: dayjs().year(2022).endOf('year').unix(),
                        label: '2022'
                    },
                    {
                        start: dayjs().year(2021).startOf('year').unix(),
                        end: dayjs().year(2021).endOf('year').unix(),
                        label: '2021'
                    },
                    {
                        start: dayjs().year(2020).startOf('year').unix(),
                        end: dayjs().year(2020).endOf('year').unix(),
                        label: '2020'
                    },
                    {
                        start: dayjs().year(2019).startOf('year').unix(),
                        end: dayjs().year(2019).endOf('year').unix(),
                        label: '2019'
                    },
                    {
                        start: dayjs().year(2018).startOf('year').unix(),
                        end: dayjs().year(2018).endOf('year').unix(),
                        label: '2018'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Last Logged In', {
                attribute: 'last_login_unix',
                operator: 'or',
                items: [
                    { label: 'Anytime' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(3, 'month').unix(),
                        label: 'Past 3 Months'
                    },
                    {
                        start: dayjs().subtract(6, 'month').unix(),
                        label: 'Past 6 Months'
                    },
                    {
                        start: dayjs().subtract(12, 'month').unix(),
                        label: 'Past Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'jobseeker_status',
                operator: 'and'
            }),

            new SearchFilter('refinement', 'Links', {
                attribute: 'link_types',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Link Types...'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'City', {
                attribute: 'location_data.city.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Cities...'
            }),
            new SearchFilter('refinement', 'Country', {
                attribute: 'location_data.country.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Countries...'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'prior_experience.company',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),

            new SearchFilter('refinement', 'Job Titles', {
                attribute: 'prior_experience.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('refinement', 'School', {
                attribute: 'education.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Schools...'
            }),
            new SearchFilter('refinement', 'Degree', {
                attribute: 'education.degree',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Degree Field', {
                attribute: 'education.field',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Fields...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            }),
            new SearchFilter('refinement', 'Blocked', {
                attribute: SearchFacet.USER_BLOCKED,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Verified', {
                attribute: SearchFacet.VERIFIED,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Phone Verified', {
                attribute: 'phone_verified',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Phone Country Code', {
                attribute: 'phone_country_code',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Country Codes...'
            }),
            new SearchFilter('refinement', 'Deactivated', {
                attribute: 'deactivated',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Public', {
                attribute: SearchFacet.VISIBLE,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Video Resume', {
                attribute: 'has_video_collection',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Image', {
                attribute: 'has_image',
                operator: 'and'
            })
        ];
        return arr;
    }

    storeFilters(): SearchFilter[] {
        let orgSizes = union(
            [{ label: 'All' }],
            this._config.getSettings('data.store.sizes') as object[]
        ).map((item: any) => {
            return { start: item.id, end: item.id, label: item.name };
        });
        let arr = [
            new SearchFilter('refinement', 'Paying Organization', {
                attribute: 'pc',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Organization Size', {
                attribute: SearchFacet.STORE_SIZE_ID,
                operator: 'or',
                items: orgSizes
            }),
            new SearchFilter('numeric', 'Date Created', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'Anytime' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(3, 'month').unix(),
                        label: 'Past 3 Months'
                    },
                    {
                        start: dayjs().subtract(6, 'month').unix(),
                        label: 'Past 6 Months'
                    },
                    {
                        start: dayjs().subtract(12, 'month').unix(),
                        label: 'Past Year'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Job Count', {
                attribute: SearchFacet.JOB_COUNT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, end: 10, label: '1-10' },
                    { start: 11, end: 50, label: '11-50' },
                    { start: 51, end: 100, label: '51-100' },
                    { start: 101, end: 500, label: '101-500' },
                    { start: 500, label: '500+' }
                ]
            }),

            new SearchFilter('numeric', 'Members Count', {
                attribute: 'members_count',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, label: 'Any Value' },
                    { start: 1, end: 3, label: '1-3' },
                    { start: 3, end: 5, label: '3-5' },
                    { start: 6, end: 10, label: '6-10' },
                    { start: 11, end: 25, label: '11-25' },
                    { start: 26, label: '26+' }
                ]
            }),
            new SearchFilter('numeric', 'Application Count', {
                attribute: 'applications_count',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, end: 10, label: '1-10' },
                    { start: 11, end: 100, label: '11-100' },
                    { start: 101, end: 500, label: '101-500' },
                    { start: 501, end: 1000, label: '501-1000' },
                    { start: 1000, label: '1000+' }
                ]
            }),
            new SearchFilter('numeric', 'FAQ Count', {
                attribute: 'faqs_count',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, label: 'Any Value' },
                    { start: 1, end: 10, label: '1-10' },
                    { start: 11, end: 30, label: '11-30' },
                    { start: 31, end: 50, label: '31-50' },
                    { start: 51, end: 75, label: '51-75' },
                    { start: 76, end: 100, label: '76-100' },
                    { start: 101, label: '100+' }
                ]
            }),
            new SearchFilter('numeric', 'Store Strength', {
                attribute: 'ss',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: '0' },
                    { start: 1, end: 30, label: '1-30' },
                    { start: 31, end: 80, label: '31-80' },
                    { start: 81, end: 100, label: '801-100' },
                    { start: 101, end: 149, label: '101-149' },
                    { start: 150, label: '150+' }
                ]
            }),
            new SearchFilter('refinement', 'Store Status', {
                attribute: SearchFacet.JOB_STATUS,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Industry', {
                attribute: 'industry.name',
                searchable: true,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Country', {
                attribute: 'location.country.name',
                searchable: true,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Links', {
                attribute: 'link_types',
                searchable: true,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Videos', {
                attribute: 'has_videos',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Image', {
                attribute: 'has_image',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Background Image', {
                attribute: 'has_background',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Twitter', {
                attribute: 'has_twitter',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Description', {
                attribute: 'has_description',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Deficits', {
                attribute: 'ssn',
                searchable: true,
                operator: 'and'
            })
        ];
        return arr;
    }

    jobFilters(): SearchFilter[] {
        return [
            new SearchFilter('toggle', 'Magic', {
                attribute: 'aig',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Year Posted', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().year(2023).startOf('year').unix(),
                        end: dayjs().year(2023).endOf('year').unix(),
                        label: '2023'
                    },
                    {
                        start: dayjs().year(2022).startOf('year').unix(),
                        end: dayjs().year(2022).endOf('year').unix(),
                        label: '2022'
                    },
                    {
                        start: dayjs().year(2021).startOf('year').unix(),
                        end: dayjs().year(2021).endOf('year').unix(),
                        label: '2021'
                    },
                    {
                        start: dayjs().year(2020).startOf('year').unix(),
                        end: dayjs().year(2020).endOf('year').unix(),
                        label: '2020'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Date Posted', {
                attribute: SearchFacet.CREATED_AT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Job Type', {
                attribute: SearchFacet.JOB_TYPE,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: SearchFacet.STORE_NAME,
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Has Video', {
                attribute: 'has_video_collection',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Remote', {
                attribute: 'remote_type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'required_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Job Function', {
                attribute: 'functions',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Job Tier', {
                attribute: 'tier',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: SearchFacet.JOB_STATUS,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Experience Level', {
                attribute: SearchFacet.EXPERIENCE,
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Annual Salary', {
                attribute: SearchFacet.ANNUAL_SALARY,
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 10000, end: 30000, label: '$10,000 - $30,000' },
                    { start: 30001, end: 50000, label: '$30,000 - $50,000' },
                    { start: 50001, end: 80000, label: '$50,000 - $80,000' },
                    { start: 80001, end: 100000, label: '$80,000 - $100,000' },
                    { start: 100001, label: '$100,000+' }
                ]
            }),
            new SearchFilter('refinement', 'Visa Support', {
                attribute: SearchFacet.VISA,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Store Status', {
                attribute: SearchFacet.STORE_STATUS,
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Has Salary Estimate', {
                attribute: 'has_salary_estimate',
                operator: 'and'
            })
        ];
    }
    tagClusterFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Created At', {
                attribute: 'created_at_unix',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Tag Count', {
                attribute: 'tags_count',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, end: 3, label: '1-3' },
                    { start: 3, end: 5, label: '3-5' },
                    { start: 6, end: 10, label: '6-10' },
                    { start: 11, end: 25, label: '11-25' },
                    { start: 26, label: '26+' }
                ]
            }),
            new SearchFilter('refinement', 'Language', {
                attribute: 'language',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            }),
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            })
        ];
    }
    boardFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Created At', {
                attribute: 'created_at_unix',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    }
                ]
            }),
            new SearchFilter('numeric', 'Job Count', {
                attribute: SearchFacet.JOB_COUNT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, end: 10, label: '1-10' },
                    { start: 11, end: 50, label: '11-50' },
                    { start: 51, end: 100, label: '51-100' },
                    { start: 101, end: 500, label: '101-500' },
                    { start: 500, label: '500+' }
                ]
            }),
            new SearchFilter('refinement', 'Language', {
                attribute: 'language',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            }),
            new SearchFilter('refinement', 'Country', {
                attribute: 'location.country.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Countries...'
            }),
            new SearchFilter('refinement', 'City', {
                attribute: 'location.city.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Cities...'
            }),
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            })
        ];
    }

    boardJobFilters(): SearchFilter[] {
        return [
            new SearchFilter('toggle', 'Magic', {
                attribute: 'job.aig',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Date Posted', {
                attribute: 'job.created_at_unix',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().startOf('day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'day').startOf('day').unix(),
                        end: dayjs().subtract(1, 'day').endOf('day').unix(),
                        label: 'Yesterday'
                    },
                    {
                        start: dayjs().subtract(2, 'day').startOf('day').unix(),
                        end: dayjs().subtract(2, 'day').endOf('day').unix(),
                        label: '2 days ago'
                    },
                    {
                        start: dayjs().subtract(3, 'day').startOf('day').unix(),
                        end: dayjs().subtract(3, 'day').endOf('day').unix(),
                        label: '3 days ago'
                    },

                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Job Type', {
                attribute: 'job.type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'job.store.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Has Video', {
                attribute: 'job.has_video_collection',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Remote', {
                attribute: 'job.remote_type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'job.required_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Job Function', {
                attribute: 'job.functions',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'job.status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Experience Level', {
                attribute: 'job.experience',
                operator: 'and'
            }),
            new SearchFilter('numeric', 'Annual Salary', {
                attribute: 'job.annual_salary',
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 10000, end: 30000, label: '$10,000 - $30,000' },
                    { start: 30001, end: 50000, label: '$30,000 - $50,000' },
                    { start: 50001, end: 80000, label: '$50,000 - $80,000' },
                    { start: 80001, end: 100000, label: '$80,000 - $100,000' },
                    { start: 100001, label: '$100,000+' }
                ]
            }),
            new SearchFilter('refinement', 'Visa Support', {
                attribute: 'job.visa',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Store Status', {
                attribute: 'job.store.status',
                operator: 'and'
            })
        ];
    }
}
