import { Notification } from '@jobzmall/notifications/notifications.types';

export class ProcessLiveNotification {
    static readonly type = '[Notifications] Process Live Notification';
    constructor(public notification: Notification) { }
}

export class GetNotifications {
    static readonly type = '[Notifications] Get Notification';
    constructor(public refresh: boolean) { }
}

export class SetRead {
    static readonly type = '[Notifications] Set Unread';
    constructor(public notification?: Notification) {}
}

export class GetUnreadCount {
    static readonly type = '[Notifications] Get Unread Count';
    constructor() { }
}

export class SubscribeToWebPushNotifications {
    static readonly type = '[Notifications] Subscribe To Web Push Notifications';
    constructor() {}
}

export class UnsubscribeFromWebPushNotifications {
    static readonly type = '[Notifications] Unsubscribe From Web Push Notifications';
    constructor() {}
}
