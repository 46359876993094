 <ng-container *transloco="let t">
     <div *ngIf="step == 'fields'" class="w-full mx-auto max-w-100 sm:w-100 sm:mx-0">
         <!-- Logo -->
         <app-logo *ngIf="showLogo" class="flex items-center justify-center w-auto h-15"></app-logo>

         <div class="mx-auto">
             <!-- Title -->
             <div class="mt-8 text-4xl font-extrabold leading-tight tracking-tight">
                 {{t('PUBLIC.SHARED.ACTION.SIGN_IN')}}</div>
             <div *ngIf="showSignup" class="flex items-baseline mt-0.5 font-medium">
                 <div>{{t('PUBLIC.LOGIN.NO_ACCOUNT')}}</div>
                 <a class="ml-1 text-primary-500 hover:underline" (click)="signUpClick.emit()">
                     {{t('PUBLIC.SHARED.ACTION.SIGN_UP')}}
                 </a>
             </div>

             <!-- Alert -->
             <jbz-alert class="mt-8 -mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                 [type]="alert.type" [@shake]="alert.type === 'error'">
                 {{alert.message}}
             </jbz-alert>

             <!-- Sign in form -->
             <form class="mt-8" [formGroup]="signInForm" #signInNgForm="ngForm">

                 <!-- Email field -->
                 <mat-form-field class="w-full">
                     <mat-label>{{t('PUBLIC.LOGIN.FORM.LABEL.EMAIL')}}</mat-label>
                     <input autocapitalize="none" autocomplete="off" [disabled]="readonlyEmail" id="email" matInput
                         [formControlName]="'email'">
                     <mat-error *ngIf="signInForm.get('email').hasError('required')">
                         {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                     </mat-error>
                     <mat-error *ngIf="signInForm.get('email').hasError('email')">
                         {{t('PUBLIC.SHARED.FORM.ERROR.INVALID_EMAIL')}}
                     </mat-error>
                 </mat-form-field>

                 <!-- Password field -->
                 <mat-form-field class="w-full">
                     <mat-label>Password</mat-label>
                     <input id="password" matInput type="password" [formControlName]="'password'" #passwordField>
                     <button mat-icon-button type="button"
                         (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                         matSuffix>
                         <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                             [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                         <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                             [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                     </button>
                     <mat-error>
                         {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                     </mat-error>
                 </mat-form-field>

                 <!-- Actions -->
                 <div class="inline-flex items-end justify-between w-full mt-1.5">
                     <a class="font-medium text-md text-primary-500 hover:underline"
                         [routerLink]="['/forgot-password']">{{t('PUBLIC.LOGIN.FORGOT_PASSWORD')}}
                     </a>
                 </div>

                 <!-- Submit button -->
                 <button class="w-full mt-6 jbz-mat-button-large" mat-flat-button [color]="'primary'"
                     [disabled]="signInForm.disabled" (click)="signIn()">
                     <span *ngIf="!signInForm.disabled">
                         {{t('PUBLIC.SHARED.ACTION.SIGN_IN')}}
                     </span>
                     <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24" [mode]="'indeterminate'">
                     </mat-progress-spinner>
                 </button>

                 <!-- Separator -->
                 <div class="flex items-center mt-8">
                     <div class="flex-auto mt-px border-t"></div>
                     <div class="mx-2 text-secondary">Or</div>
                     <div class="flex-auto mt-px border-t"></div>
                 </div>

                 <!-- Single sign-on buttons -->
                 <div class="flex flex-col items-center mt-8 space-y-4">

                     <asl-google-signin-button width="270" theme="outline" type='standard' [text]="'signin_with'"
                         shape="pill" size='large'>
                     </asl-google-signin-button>



                     <!-- <apple-signin class="w-full" state="signup" [redirectURI]="'https://dev.jobzmall.com/login'"
                         scope="name email" clientId="com.jobzmall.service" [type]="AppleButtonType.SignUp">
                     </apple-signin> -->
                 </div>
             </form>
         </div>
     </div>

     <two-factor  *ngIf="step == 'two-factor'" [phoneCountryCode]="phoneCountryCode" [phone]="phone"
         (onSendFail)="onSendFail($event)" (onVerifySuccess)="onVerifySuccess($event)"></two-factor>
     <one-tap></one-tap>
 </ng-container>
