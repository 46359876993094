import { Pipe, PipeTransform } from '@angular/core';
import { Select } from '@ngxs/store';
import { environment } from 'environments/environment';
import { map, Observable, switchMap } from 'rxjs';
import { LanguageState } from '@jobzmall/language';
import { Language } from '@jobzmall/models';
import { Restangular } from '@jobzmall/core/api';

@Pipe({
    name: 'googleTranslate'
})
export class GoogleTranslatePipe implements PipeTransform {
    @Select(LanguageState.currentLanguage)
    currentLanguage$: Observable<Language>;

    constructor(private _api: Restangular) {}

    transform(value: any, ...args: any[]): Observable<any> {
        return this.currentLanguage$.pipe(
            switchMap(() => {
                return this._api
                    .all('translate/text')
                    .customPOST({
                        text: value
                    })
                    .pipe(map((res: any) => res.data));
            })
        );
    }
}
