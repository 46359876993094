import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Store } from '@ngxs/store';
import { environment } from 'environments/environment';
import { AuthState } from '../auth/ngxs/state';
import { FingerprintService } from './fingerprint.service';

export function RestangularConfigFactory(
    RestangularProvider: any,
    store: Store,
    fingerprint: FingerprintService,
    confirmation: FuseConfirmationService
) {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/x.laravel.v1+json'
    };

    RestangularProvider.setBaseUrl(environment.apiRoot);
    RestangularProvider.setDefaultHeaders(headers);
    RestangularProvider.addFullRequestInterceptor(
        (
            element: any,
            operation: any,
            path: any,
            url: any,
            headers: any,
            resParams: any,
            config: any
        ) => {
            const token = store.selectSnapshot<string>(AuthState.token);

            let result = {
                headers: { ...headers }
            };

            if (token && url.includes(environment.apiRoot)) {
                result.headers['Authorization'] = `Bearer ${token}`;
            }

            if (fingerprint.id && url.includes(environment.apiRoot)) {
                result.headers['X-JBZ-Fingerprint'] = fingerprint.id;
                // TODO: Bring this back when we want to use Fingerprint Pro
                // if (fingerprint.visitor?.confidence) {
                //     result.headers['X-JBZ-Fingerprint-Confidence'] =
                //         fingerprint.visitor?.confidence.score.toString();
                // }
            }

            return result;
        }
    );

    RestangularProvider.addErrorInterceptor(
        (response, subject, responseHandler) => {
            if (response.status === 400) {
                if (response.data && response.data.error) {
                    if (!environment.production) {
                        confirmation.open({
                            title: 'Error',
                            message:
                                'There was a unexpected problem processing your request. Please try again.',
                            icon: {
                                show: true,
                                name: 'mat_outline:pest_control',
                                color: 'warn'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Ok',
                                    color: 'warn'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        });
                    }
                }
            }
            return true; // error not handled
        }
    );
}
