import { EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {
    UntypedFormGroup,
    Validators,
    UntypedFormControl
} from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { SearchCountryField } from '@jobzmall/telephone-input/enums/search-country-field.enum';
import { TooltipLabel } from '@jobzmall/telephone-input/enums/tooltip-label.enum';
import { CountryISO } from '@jobzmall/telephone-input/enums/country-iso.enum';
import { validateAllFormFields } from '@jobzmall/core/util/forms';
import { Store, Select } from '@ngxs/store';
import { SignUp, SocialLogin } from '@jobzmall/core/auth/ngxs/actions';
import { cloneDeep, extend } from 'lodash-es';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AppState } from '@jobzmall/state/ngxs/state';
import { firstValueFrom, Observable } from 'rxjs';
import { JMBaseComponent } from '@jobzmall/components';
import { Router } from '@angular/router';
import { checkBlockedPhoneNumberValidator } from '@jobzmall/telephone-input/check-blocked-phone.validator';
import { Restangular } from '@jobzmall/core/api';
import { CaptchaService } from '@jobzmall/core/services/captcha.service';
import { AuthService } from '@jobzmall/core';

@Component({
    selector: 'signup-form',
    templateUrl: './signup-form.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SignupFormComponent extends JMBaseComponent implements OnInit {
    @Input() title = 'Join the Community';
    @Input() showLogo = true;
    @Input() extendedData;
    @Input() email: string;
    @Input() readonlyEmail = false;

    @Output() loginClick = new EventEmitter();

    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;

    selectedCountryISO = CountryISO.UnitedStates;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    signUpForm: UntypedFormGroup;
    showAlert: boolean = false;

    step = 'fields';

    @Select(AppState.locationDetails) locationDetails$: Observable<any>;

    constructor(
        private _auth: AuthService,
        public router: Router,
        private _api: Restangular,
        private _captcha: CaptchaService,
        private _confirmation: FuseConfirmationService,
        private _store: Store
    ) {
        super();
    }

    ngOnInit(): void {
        this.signUpForm = new UntypedFormGroup({
            first_name: new UntypedFormControl('', [Validators.required]),
            last_name: new UntypedFormControl('', [Validators.required]),
            phone: new UntypedFormControl(
                '',
                [Validators.required],
                [checkBlockedPhoneNumberValidator(this._api)]
            ),
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
            ]),
            password: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(8)
            ])
        });

        if (this.readonlyEmail) {
            this.signUpForm.get('email').disable();
        }
        if (this.email) {
            this.signUpForm.get('email').setValue(this.email);
        }

        this.subscriptions.sink = this.locationDetails$.subscribe(
            (locationDetails: any) => {
                if (locationDetails) {
                    this.selectedCountryISO = <CountryISO>(
                        locationDetails.iso_code.toLowerCase()
                    );
                }
            }
        );

        this.subscriptions.sink = this._auth.authState('google').subscribe(()=>{

        })
    }

    socialAuth(provider: string) {
        this._store.dispatch(new SocialLogin({ provider }));
    }

    onVerifySuccess() {
        this.signUp();
    }

    onSendFail($event: any) {
        if ($event.status == 409) {
            this._confirmation.open({
                title: 'Too many attempts',
                message: `You have requested a one-time code too many times. Please wait 10 minutes before requesting a new one`,
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation',
                    color: 'warn'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: 'Ok',
                        color: 'warn'
                    },
                    cancel: {
                        show: false
                    }
                }
            });
        }
        if ($event.status == 422) {
            firstValueFrom(
                this._confirmation
                    .open({
                        title: 'Invalid Mobile Number',
                        message: `There seems to be an issue sending a text to this number. The number is either a landline or was not provisioned with a valid mobile carrier. Please change the number and try again`,
                        icon: {
                            show: true,
                            name: 'heroicons_outline:exclamation',
                            color: 'warn'
                        },
                        actions: {
                            confirm: {
                                show: true,
                                label: 'Ok',
                                color: 'warn'
                            },
                            cancel: {
                                show: false
                            }
                        }
                    })
                    .afterClosed()
            ).then(() => {
                this.signUpForm.get('phone').setValue(null);
                this.step = 'fields';
                validateAllFormFields(this.signUpForm);
            });
        }
    }

    signUp(): void {
        // Do nothing if the form is invalid
        if (this.signUpForm.invalid) {
            return;
        }

        // TODO - Language Selector for language id
        let userCopy = cloneDeep(this.signUpForm.value);
        userCopy.email = this.signUpForm.controls.email.value;
        userCopy.password_confirmation = userCopy.password;
        userCopy.phone_country_code = this.signUpForm.value.phone.countryCode;
        userCopy.phone = this.signUpForm.value.phone.number;

        if (this.extendedData) {
            userCopy = extend(userCopy, this.extendedData);
        }

        this._store.dispatch(new SignUp('jobseeker', { user: userCopy }));
    }
}
