  <ng-container *transloco="let t">
      <div (mouseleave)="onClosePanel.emit()"
          class="w-screen max-w-sm overflow-hidden overflow-y-auto bg-white shadow-lg max-h-120 header-panel rounded-2xl lg:ml-0 ring-1 ring-black ring-opacity-5">
          <div class="mb-[64px] relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
              <a *ngFor="let org of (organizations$ | async); trackBy: ( 'id' | trackByProperty )"
                  (click)="onClosePanel.emit()" [routerLink]="['/' + org.slug + '/dashboard']"
                  class="flex items-start p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50 ">
                  <div class="relative flex w-10 h-10 overflow-hidden text-gray-600 bg-gray-200 rounded-full ">
                      <ng-container *ngIf="org.image">
                          <img class="object-scale-down w-full h-full rounded-full" [src]="org.image.full_path"
                              [alt]="'Profile avatar'">
                      </ng-container>
                      <ng-container *ngIf="!org.image">
                          <div
                              class="flex items-center justify-center w-full h-full overflow-hidden text-lg font-semibold text-center text-gray-600 uppercase bg-gray-200 rounded-full group-hover:bg-gray-400 ">
                              {{org.name.charAt(0)}}
                          </div>
                      </ng-container>
                  </div>
                  <div class="flex-1 pl-4 ">
                      <p class="text-lg font-medium text-gray-900">
                          {{org.name}}
                      </p>
                      <p title="{{org.jobs_count | humanNumber}} Jobs" class="mt-1 truncate text-secondary">
                          {{org.jobs_count | humanNumber}} Jobs
                      </p>
                  </div>
              </a>

          </div>
          <div
              class="absolute bottom-0 w-full px-5 py-5 space-y-6 rounded-br-2xl rounded-bl-2xl bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
              <div class="flow-root">
                  <a (click)="onClosePanel.emit()" [routerLink]="['/post-job']"
                      class="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 ">
                      <mat-icon class="text-gray-400 fas fa-briefcase icon-size-4"></mat-icon>
                      <span class="ml-3">Post a Job</span>
                  </a>
              </div>
              <div class="flow-root">
                  <a (click)="onClosePanel.emit()" [routerLink]="['/open-store']"
                      class="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 ">
                      <mat-icon class="text-gray-400 fas fa-circle-plus icon-size-4"></mat-icon>
                      <span class="ml-3">Open New Store</span>
                  </a>
              </div>
          </div>
      </div>
  </ng-container>
