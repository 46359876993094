<div
    class="z-50 flex flex-col max-h-full border rounded-tl-lg rounded-tr-lg shadow-xl cursor-pointer pointer-events-auto bg-card dark:bg-gray-900 w-80">
    <div (click)="maximized ? minimize() : maximize()"
        class="flex items-center justify-between w-full border-b rounded-tl-lg rounded-tr-lg cursor-pointer min-h-14 bg-card">
        <div class="flex items-center">
            <div
                class="relative flex items-center justify-center w-8 h-8 ml-3 text-gray-600 bg-gray-200 rounded-full cursor-pointer flex-0 dark:bg-gray-700 dark:text-gray-200">
                <ng-container *ngIf="(user$ | async).image">
                    <img class="object-cover w-full h-full rounded-full" [src]="(user$ | async).image.full_path"
                        alt="Contact avatar" />
                </ng-container>
                <ng-container *ngIf="!(user$ | async).image">
                    <div
                        class="flex items-center justify-center w-full h-full text-lg text-gray-600 uppercase bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-200">
                        {{(user$ | async).name.charAt(0)}}
                    </div>
                </ng-container>
            </div>
            <div class="ml-4">
                <span class="font-medium leading-5 truncate cursor-pointer">Messaging</span>
                <span *ngIf="(unreadCount$ | async)" class="px-2 ml-2 py-0.5 text-sm text-white bg-pink-600
                    rounded-full">{{(unreadCount$ | async)}}</span>
            </div>
        </div>
        <div class="flex items-center">
            <button class="mr-2" (click)="$event.stopPropagation(); maximized ? minimize() : maximize()"
                mat-icon-button>
                <mat-icon [ngClass]="{'rotate-180': maximized}" [svgIcon]="'heroicons_outline:chevron-up'">
                </mat-icon>
            </button>
        </div>
    </div>


    <div *ngIf="maximized" @expandCollapse chatScrollDirective class="flex flex-col flex-grow overflow-y-auto"
        infiniteScroll [infiniteScrollDistance]="9.9" [scrollWindow]="false" [infiniteScrollThrottle]="150"
        (scrolled)="getConversations()">

        <div [routerLink]="['/messages/requests']" *ngIf="(requestsCount$ |async) > 0"
            class="sticky top-0 left-0 flex items-center justify-end w-full p-2 py-2 pb-0 text-indigo-600 cursor-pointer">
            {{(requestsCount$ |async) | i18nPlural: {
                            '=0'   : 'No requests',
                            '=1'   : '# request',
                            'other': '# requests'
                        } }}

        </div>

        <!-- Messages -->
        <ng-container *ngFor="let conversation of (conversations$ | async); trackBy: ( 'id' | trackByProperty )">
            <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                [ngClass]="{'unread': conversation.unread > 0}">

                <a class="flex flex-auto py-5 pl-6 cursor-pointer" (click)="pinConversation(conversation)">
                    <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                </a>

                <!-- Actions -->
                <div class="relative flex flex-col my-5 ml-2 mr-6">
                    <button class="w-6 h-6 min-h-6" color="primary" mat-icon-button *ngIf="conversation.unread > 0">
                        <span>{{conversation.unread}}</span>
                    </button>
                </div>
            </div>

            <!-- Message content template -->
            <ng-template #messageContent>
                <div
                    class="relative flex w-8 h-8 mr-4 text-gray-600 bg-gray-200 rounded-full flex-0 dark:bg-gray-700 dark:text-gray-200">
                    <ng-container *ngIf="conversation.image">
                        <img class="object-scale-down w-full h-full rounded-full" [src]="conversation.image.full_path"
                            [alt]="'Profile avatar'">
                    </ng-container>
                    <ng-container *ngIf="!conversation.image">
                        <div
                            class="flex items-center justify-center w-full h-full overflow-hidden text-lg font-semibold text-center text-gray-600 uppercase bg-gray-200 rounded-full group-hover:bg-gray-400 dark:group-hover:bg-gray-900 dark:bg-gray-700 dark:text-gray-200">
                            {{conversation.title.charAt(0)}}
                        </div>
                    </ng-container>
                    <div *ngIf="conversation.sub_image"
                        class="absolute flex items-center justify-center w-4 h-4 text-gray-600 bg-gray-200 rounded-full -bottom-2 -right-2 flex-0 dark:bg-gray-700 dark:text-gray-200">
                        <ng-container *ngIf="conversation.sub_image">
                            <img class="object-cover w-full h-full rounded-full"
                                [src]="conversation.sub_image.full_path" alt="Contact avatar" />
                        </ng-container>
                    </div>
                </div>
                <!-- Title, description & time -->
                <div class="flex flex-col flex-auto">
                    <ng-container>
                        <div class="flex items-center">
                            <div class="font-semibold line-clamp-1" [innerText]="conversation.title"></div>
                            <mat-icon *ngIf="conversation.muted" class="ml-2 text-current icon-size-4"
                                [svgIcon]="'heroicons_solid:volume-off'"></mat-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="conversation.last_message?.sender.id !== (user$ | async).id">
                        <div class="break-words w-60 line-clamp-2" [class.text-blue-500]="conversation.unread > 0"
                            [class.dark:text-blue-500]="conversation.unread > 0">
                            <span class="font-semibold"
                                *ngIf="conversation.last_message?.sender.id == (user$ | async)?.id">
                                You:
                            </span>
                            {{conversation.last_message?.media?.length ? 'Sent ' + conversation.last_message?.media.length + ' files' : conversation.last_message?.text}}
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="conversation.last_message?.sender.id == (user$ | async).id && conversation.last_message">
                        <div class="break-words w-60 line-clamp-2">
                            <span class="font-semibold"></span>{{conversation.last_message?.text}}
                        </div>
                    </ng-container>
                    <div class="mt-2 text-sm leading-none text-secondary">
                        {{conversation.last_message?.created_at | date}}
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="loading$ | async">
            <div class="flex flex-col items-center justify-center flex-auto p-4">
                <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'">
                </mat-progress-spinner>
            </div>
        </ng-container>

        <!-- No conversations -->
        <ng-container *ngIf="!(conversations$ | async).length && !(loading$ | async)">
            <div class="flex flex-col items-center justify-center flex-auto px-8 py-12 sm:justify-start">
                <div class="flex items-center justify-center rounded-full flex-0 w-14 h-14 bg-primary-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="black" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                    </svg>
                </div>
                <div class="mt-5 text-2xl font-semibold tracking-tight">No conversations</div>
                <div class="w-full mt-1 text-center max-w-60 text-md text-secondary">When you have conversations,
                    they
                    will appear here.</div>
            </div>
        </ng-container>
    </div>


</div>
