<!-- Notifications toggle -->
<button class="text-current" mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container *ngIf="(unreadCount$ | async) > 0">
        <span class="absolute top-0 left-0 right-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full
                bg-purple-600 text-purple-50 text-xs font-medium">
                {{(unreadCount$ | async)}}
            </span>
        </span>
    </ng-container>
    <mat-icon class="text-current"
        [svgIcon]="router.url.indexOf('/notifications') > -1 ? 'mimicons_solid:bell' : 'mimicons_outline:bell'">
    </mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div
        class="fixed inset-0 flex flex-col overflow-hidden shadow-lg sm:static sm:inset-auto sm:min-w-90 sm:w-90 sm:rounded-2xl">

        <!-- Header -->
        <div class="flex items-center flex-shrink-0 py-4 pl-6 pr-4 bg-primary text-on-primary">
            <div class="mr-3 -ml-1 sm:hidden">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Notifications</div>

        </div>

        <!-- Content -->
        <div infinite-scroll [infiniteScrollDistance]="5" [scrollWindow]="false" (scrolled)="getNotifications()"
            class="relative flex flex-col flex-auto overflow-y-auto divide-y sm:max-h-120 bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let notification of (notifications$ | async); trackBy: trackByFn">
                <div (click)="setRead(notification); closePanel();"
                    class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !notification.read_at}">

                    <!-- Notification with a link -->
                    <ng-container *ngIf="notification.link">
                        <!-- Normal links -->
                        <ng-container *ngIf="!notification.useRouter">
                            <a class="flex flex-auto px-5 py-5 pl-6 cursor-pointer" [href]="notification.link">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                        <!-- Router links -->
                        <ng-container *ngIf="notification.useRouter">
                            <a class="flex flex-auto px-5 py-5 pl-6 cursor-pointer" [routerLink]="notification.link">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <!-- Notification without a link -->
                    <ng-container *ngIf="!notification.link">
                        <div class="flex flex-auto px-5 py-5 pl-6">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>
                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <ng-container *ngIf="notification.icon && !notification.image">
                        <div
                            class="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-4 bg-gray-200 rounded-full dark:bg-gray-700">
                            <mat-icon class="icon-size-5" [ngClass]="notification.icon">
                            </mat-icon>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <ng-container *ngIf="notification.image">
                        <img class="flex-shrink-0 object-cover object-center w-8 h-8 mr-4 overflow-hidden rounded-full"
                            [src]="notification.image?.full_path" [alt]="'Notification image'">
                    </ng-container>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="notification.title">
                            <div class="font-semibold line-clamp-1" [innerHTML]="notification.title"></div>
                        </ng-container>
                        <ng-container *ngIf="notification.description">
                            <div [innerHTML]="notification.description"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{notification.created_at | date:'MMM dd, h:mm a'}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="loading$ | async">
                <div class="flex flex-col items-center justify-center flex-auto p-4">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </div>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!(notifications$ | async).length && !(loading$ | async)">
                <div class="flex flex-col items-center justify-center flex-auto px-8 py-12 sm:justify-start">
                    <div class="flex items-center justify-center rounded-full flex-0 w-14 h-14 bg-primary-100">
                        <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No notifications</div>
                    <div class="w-full mt-1 text-center max-w-60 text-md text-secondary">When you have notifications,
                        they will appear here.</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
