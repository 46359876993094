import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { LinkPreviewComponent } from './link-preview.component';
import { LinkyModule } from 'ngx-linky';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { NgxResponsiveEmbedModule } from 'ngx-responsive-embed';
import { LightgalleryModule } from 'lightgallery/angular';

const COMPONENTS = [
    LinkPreviewComponent
]

@NgModule({
    declarations: COMPONENTS,
    imports: [
        JMCommonDirectivesModule,
        NgxResponsiveEmbedModule,
        LightgalleryModule,
        CommonModule,
        JMPipesModule,
        MatProgressSpinnerModule,
        LinkyModule
    ],
    exports: COMPONENTS,
    providers: [],
})
export class LinkPreviewModule { }
