import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { catchError, map, mergeMap, take, filter } from 'rxjs/operators';
import { UserService } from '@jobzmall/user/user.service';
import { Restangular } from '../api';
import {
    SocialAuthService,
    SocialUser,
    GoogleLoginProvider,
    FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { Store } from '@ngxs/store';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Injectable({ providedIn: 'root' })
export class AuthService {
    /**
     * Constructor
     */
    constructor(
        private _confirmation: FuseConfirmationService,
        private _store: Store,
        private _socialAuthService: SocialAuthService,
        private _api: Restangular,
        private _userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Login
     *
     * @param credentials
     */
    login(credentials: { email: string; password: string }): Observable<any> {
        return this._api
            .all('user/login')
            .customPOST(credentials)
            .pipe(map((res: any) => res.data));
    }

    /**
     * Logout
     */
    logout(): Observable<any> {
        return this._api.all('user/logout').customPOST();
    }

    socialAuth(provider: string): Observable<any> {
        this._socialAuthService.signOut().catch((reason: any) => {});

        let providerId;

        switch (provider) {
            case 'google':
                providerId = GoogleLoginProvider.PROVIDER_ID;
                break;
            default:
                return of(undefined);
        }

        return from(this._socialAuthService.signIn(providerId)).pipe(
            mergeMap(() => {
                return this.authState(provider).pipe(take(1));
            }),
            catchError((err: any, caught: any) => {
                return of(undefined);
            })
        );
    }

    authState(provider: string): Observable<any> {
        return this._socialAuthService.authState.pipe(
            filter(Boolean),
            take(1),
            catchError((err: any, caught: any) => {
                this._confirmation.open({
                    title: 'Uh Oh!',
                    message:
                        'There was a problem logging you in. Please try again.',
                    icon: {
                        show: true,
                        name: 'heroicons_outline:exclamation',
                        color: 'warn'
                    },
                    actions: {
                        confirm: {
                            show: true,
                            label: 'Ok',
                            color: 'primary'
                        },
                        cancel: {
                            show: false
                        }
                    }
                });
                return of(undefined);
            }),
            mergeMap((user: SocialUser) => {
                if (user) {
                    return this.socialLogin(provider, user.authToken);
                } else {
                    return of(undefined);
                }
            })
        );
    }

    socialLogin(provider: string, access_token: string) {
        return this._api
            .all('social/auth')
            .customPOST({ grant_type: 'social', provider, access_token })
            .pipe(map((res: any) => res.data));
    }
}
