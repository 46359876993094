<ng-container *transloco="let t">
    <div class="w-full mx-auto max-w-100 sm:w-100 sm:mx-0">
        <!-- Logo -->
        <app-logo *ngIf="showLogo" class="flex items-center justify-center w-auto h-15"></app-logo>

        <div class="mt-8 text-4xl font-extrabold leading-tight tracking-tight">
            {{t('PUBLIC.TWO_FACTOR_FORM.TITLE')}}</div>
        <div class="flex items-baseline mt-3 mb-8">
            <p>
                {{ t('PUBLIC.TWO_FACTOR_FORM.SUBTITLE.1', {phone: phone}) }}
            </p>
        </div>

        <ngx-split-input class="grid w-full grid-cols-6 mt-8 mb-8" (completed)="onTextEntered($event)">
             <input class="col-span-1 max-w-[55px]" [disabled]="loading" jmAutofocus ngxSplitInput pattern="[0-9]*"
                 type="text" maxlength="1">
             <input class="col-span-1 max-w-[55px]" [disabled]="loading" ngxSplitInput type="text" pattern="[0-9]*"
                 maxlength="1">
             <input class="col-span-1 max-w-[55px]" [disabled]="loading" ngxSplitInput type="text" pattern="[0-9]*"
                 maxlength="1">
             <input class="col-span-1 max-w-[55px]" [disabled]="loading" ngxSplitInput type="text" pattern="[0-9]*"
                 maxlength="1">
             <input class="col-span-1 max-w-[55px]" [disabled]="loading" ngxSplitInput type="text" pattern="[0-9]*"
                 maxlength="1">
             <input class="col-span-1 max-w-[55px]" [disabled]="loading" ngxSplitInput type="text" pattern="[0-9]*"
                 maxlength="1">
        </ngx-split-input>
        <span *ngIf="invalid" class="block pb-3 text-sm text-red-600">
            {{t('PUBLIC.TWO_FACTOR_FORM.ERROR.CODE.INVALID')}} <a class="underline"
                (click)="sendVerificationCode(true)">{{t('PUBLIC.TWO_FACTOR_FORM.ERROR.CODE.INVALID.ACTION')}}</a>
        </span>

         <span *ngIf="notificationSends > 0" (click)="sendVerificationCode(true)"
             class="block w-auto mx-auto text-center underline cursor-pointer text-md text-primary-600 dark:text-primary-400">Code
             Not Received?</span>

         <span *ngIf="notificationSends > 1" (click)="openIntercom()"
             class="block w-auto mx-auto mt-4 text-center cursor-pointer text-secondary text-md ">Still having
             trouble? Contact Us Now</span>

        <mat-progress-spinner class="mx-auto mt-10" [ngClass]="{'opacity-100': loading, 'opacity-0':!loading}"
            [diameter]="24" [mode]="'indeterminate'">

        </mat-progress-spinner>

    </div>
</ng-container>
