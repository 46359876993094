import * as dayjs from 'dayjs';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dayJsFilterByIsAfter'
})
export class FilterByIsAfter implements PipeTransform {
    transform(
        value: Array<string | number | Date | dayjs.Dayjs>,
        otherValue: string | number | Date | dayjs.Dayjs,
        unit?: dayjs.OpUnitType
    ): Array<string | number | Date | dayjs.Dayjs> {
        return value.filter((d: string | number | Date | dayjs.Dayjs) => {
            return dayjs(d).isAfter(dayjs(otherValue), unit);
        });
    }
}
