'use strict';

export class IpRegex {
    word = '[a-fA-F\\d:]';
    b = (options: any) => options && options.includeBoundaries ?
        `(?:(?<=\\s|^)(?=${this.word})|(?<=${this.word})(?=\\s|$))` :
        '';

    v4 = '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';

    v6seg = '[a-fA-F\\d]{1,4}';
    v6 = `
(
(?:${this.v6seg}:){7}(?:${this.v6seg}|:)|                                // 1:2:3:4:5:6:7::  1:2:3:4:5:6:7:8
(?:${this.v6seg}:){6}(?:${this.v4}|:${this.v6seg}|:)|                         // 1:2:3:4:5:6::    1:2:3:4:5:6::8   1:2:3:4:5:6::8  1:2:3:4:5:6::1.2.3.4
(?:${this.v6seg}:){5}(?::${this.v4}|(:${this.v6seg}){1,2}|:)|                 // 1:2:3:4:5::      1:2:3:4:5::7:8   1:2:3:4:5::8    1:2:3:4:5::7:1.2.3.4
(?:${this.v6seg}:){4}(?:(:${this.v6seg}){0,1}:${this.v4}|(:${this.v6seg}){1,3}|:)| // 1:2:3:4::        1:2:3:4::6:7:8   1:2:3:4::8      1:2:3:4::6:7:1.2.3.4
(?:${this.v6seg}:){3}(?:(:${this.v6seg}){0,2}:${this.v4}|(:${this.v6seg}){1,4}|:)| // 1:2:3::          1:2:3::5:6:7:8   1:2:3::8        1:2:3::5:6:7:1.2.3.4
(?:${this.v6seg}:){2}(?:(:${this.v6seg}){0,3}:${this.v4}|(:${this.v6seg}){1,5}|:)| // 1:2::            1:2::4:5:6:7:8   1:2::8          1:2::4:5:6:7:1.2.3.4
(?:${this.v6seg}:){1}(?:(:${this.v6seg}){0,4}:${this.v4}|(:${this.v6seg}){1,6}|:)| // 1::              1::3:4:5:6:7:8   1::8            1::3:4:5:6:7:1.2.3.4
(?::((?::${this.v6seg}){0,5}:${this.v4}|(?::${this.v6seg}){1,7}|:))           // ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8  ::8             ::1.2.3.4
)(%[0-9a-zA-Z]{1,})?                                           // %eth0            %1
`.replace(/\s*\/\/.*$/gm, '').replace(/\n/g, '').trim();

    ipRegex;

    constructor() {
        this.ipRegex = (options: any) => options && options.exact ?
            new RegExp(`(?:^${this.v4}$)|(?:^${this.v6}$)`) :
            new RegExp(`(?:${this.b(options)}${this.v4}${this.b(options)})|(?:${this.b(options)}${this.v6}${this.b(options)})`, 'g');

        this.ipRegex.v4 = (options: any) => options && options.exact ? new RegExp(`^${this.v4}$`) : new RegExp(`${this.b(options)}${this.v4}${this.b(options)}`, 'g');
        this.ipRegex.v6 = (options: any) => options && options.exact ? new RegExp(`^${this.v6}$`) : new RegExp(`${this.b(options)}${this.v6}${this.b(options)}`, 'g');
    }



}

