<div class="relative flex flex-col items-center justify-center w-screen h-fill backdrop-blur-xl">
    <button class="absolute z-10 transition-all bg-white top-5 right-10 dark:bg-gray-700 dark:text-white"
        mat-icon-button (click)="closeOverlay()">
        <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
    </button>
    <div
        class="flex flex-col items-start justify-start max-h-fill px-16 pt-4 pb-6 overflow-y-auto rounded-xl bg-default">
        <signup-form [email]="data.email" [showLogo]="false" title="Signup to Continue" *ngIf="!showLogin"
            (loginClick)="showLogin = true">
        </signup-form>
        <login-form [email]="data.email" *ngIf="showLogin" (signUpClick)="showLogin = false"></login-form>
    </div>
</div>
