import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SupportState } from '../ngxs/state';

@Component({
    selector: 'contact-widget',
    templateUrl: './contact-widget.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class ContactWidgetComponent extends JMBaseComponent implements OnInit {
    @Select(SupportState.showSupportWidget) showSupportWidget$: Observable<boolean>;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {}
}
