export const HOURS = [
    {
        military_format: '0000',
        twenty_four_hour_format: '00:00',
        standard_format: '12:00',
        time_of_day: 'am'
    },
    {
        military_format: '0015',
        twenty_four_hour_format: '00:00',
        standard_format: '12:15',
        time_of_day: 'am'
    },
    {
        military_format: '0030',
        twenty_four_hour_format: '00:30',
        standard_format: '12:30',
        time_of_day: 'am'
    },
    {
        military_format: '0045',
        twenty_four_hour_format: '00:45',
        standard_format: '12:45',
        time_of_day: 'am'
    },
    {
        military_format: '0100',
        twenty_four_hour_format: '01:00',
        standard_format: '01:00',
        time_of_day: 'am'
    },
    {
        military_format: '0115',
        twenty_four_hour_format: '01:15',
        standard_format: '01:15',
        time_of_day: 'am'
    },
    {
        military_format: '0130',
        twenty_four_hour_format: '01:30',
        standard_format: '01:30',
        time_of_day: 'am'
    },
    {
        military_format: '0145',
        twenty_four_hour_format: '01:45',
        standard_format: '01:45',
        time_of_day: 'am'
    },
    {
        military_format: '0200',
        twenty_four_hour_format: '02:00',
        standard_format: '02:00',
        time_of_day: 'am'
    },
    {
        military_format: '0215',
        twenty_four_hour_format: '02:15',
        standard_format: '02:15',
        time_of_day: 'am'
    },
    {
        military_format: '0230',
        twenty_four_hour_format: '02:30',
        standard_format: '02:30',
        time_of_day: 'am'
    },
    {
        military_format: '0245',
        twenty_four_hour_format: '02:45',
        standard_format: '02:45',
        time_of_day: 'am'
    },
    {
        military_format: '0300',
        twenty_four_hour_format: '03:00',
        standard_format: '03:00',
        time_of_day: 'am'
    },
    {
        military_format: '0315',
        twenty_four_hour_format: '03:15',
        standard_format: '03:15',
        time_of_day: 'am'
    },
    {
        military_format: '0330',
        twenty_four_hour_format: '03:30',
        standard_format: '03:30',
        time_of_day: 'am'
    },
    {
        military_format: '0345',
        twenty_four_hour_format: '03:45',
        standard_format: '03:45',
        time_of_day: 'am'
    },
    {
        military_format: '0400',
        twenty_four_hour_format: '04:00',
        standard_format: '04:00',
        time_of_day: 'am'
    },
    {
        military_format: '0415',
        twenty_four_hour_format: '04:15',
        standard_format: '04:15',
        time_of_day: 'am'
    },
    {
        military_format: '0430',
        twenty_four_hour_format: '04:30',
        standard_format: '04:30',
        time_of_day: 'am'
    },
    {
        military_format: '0445',
        twenty_four_hour_format: '04:45',
        standard_format: '04:45',
        time_of_day: 'am'
    },
    {
        military_format: '0500',
        twenty_four_hour_format: '05:00',
        standard_format: '05:00',
        time_of_day: 'am'
    },
    {
        military_format: '0515',
        twenty_four_hour_format: '05:15',
        standard_format: '05:15',
        time_of_day: 'am'
    },
    {
        military_format: '0530',
        twenty_four_hour_format: '05:30',
        standard_format: '05:30',
        time_of_day: 'am'
    },
    {
        military_format: '0545',
        twenty_four_hour_format: '05:45',
        standard_format: '05:45',
        time_of_day: 'am'
    },
    {
        military_format: '0600',
        twenty_four_hour_format: '06:00',
        standard_format: '06:00',
        time_of_day: 'am'
    },
    {
        military_format: '0615',
        twenty_four_hour_format: '06:15',
        standard_format: '06:15',
        time_of_day: 'am'
    },
    {
        military_format: '0630',
        twenty_four_hour_format: '06:30',
        standard_format: '06:30',
        time_of_day: 'am'
    },
    {
        military_format: '0645',
        twenty_four_hour_format: '06:45',
        standard_format: '06:45',
        time_of_day: 'am'
    },
    {
        military_format: '0700',
        twenty_four_hour_format: '07:00',
        standard_format: '07:00',
        time_of_day: 'am'
    },
    {
        military_format: '0715',
        twenty_four_hour_format: '07:15',
        standard_format: '07:15',
        time_of_day: 'am'
    },
    {
        military_format: '0730',
        twenty_four_hour_format: '07:30',
        standard_format: '07:30',
        time_of_day: 'am'
    },
    {
        military_format: '0745',
        twenty_four_hour_format: '07:45',
        standard_format: '07:45',
        time_of_day: 'am'
    },
    {
        military_format: '0800',
        twenty_four_hour_format: '08:00',
        standard_format: '08:00',
        time_of_day: 'am'
    },
    {
        military_format: '0815',
        twenty_four_hour_format: '08:15',
        standard_format: '08:15',
        time_of_day: 'am'
    },
    {
        military_format: '0830',
        twenty_four_hour_format: '08:30',
        standard_format: '08:30',
        time_of_day: 'am'
    },
    {
        military_format: '0845',
        twenty_four_hour_format: '08:45',
        standard_format: '08:45',
        time_of_day: 'am'
    },
    {
        military_format: '0900',
        twenty_four_hour_format: '09:00',
        standard_format: '09:00',
        time_of_day: 'am'
    },
    {
        military_format: '0915',
        twenty_four_hour_format: '09:15',
        standard_format: '09:15',
        time_of_day: 'am'
    },
    {
        military_format: '0930',
        twenty_four_hour_format: '09:30',
        standard_format: '09:30',
        time_of_day: 'am'
    },
    {
        military_format: '0945',
        twenty_four_hour_format: '09:45',
        standard_format: '09:45',
        time_of_day: 'am'
    },
    {
        military_format: '1000',
        twenty_four_hour_format: '10:00',
        standard_format: '10:00',
        time_of_day: 'am'
    },
    {
        military_format: '1015',
        twenty_four_hour_format: '10:15',
        standard_format: '10:15',
        time_of_day: 'am'
    },
    {
        military_format: '1030',
        twenty_four_hour_format: '10:30',
        standard_format: '10:30',
        time_of_day: 'am'
    },
    {
        military_format: '1045',
        twenty_four_hour_format: '10:45',
        standard_format: '10:45',
        time_of_day: 'am'
    },
    {
        military_format: '1100',
        twenty_four_hour_format: '11:00',
        standard_format: '11:00',
        time_of_day: 'am'
    },
    {
        military_format: '1115',
        twenty_four_hour_format: '11:15',
        standard_format: '11:15',
        time_of_day: 'am'
    },
    {
        military_format: '1130',
        twenty_four_hour_format: '11:30',
        standard_format: '11:30',
        time_of_day: 'am'
    },
    {
        military_format: '1145',
        twenty_four_hour_format: '11:45',
        standard_format: '11:45',
        time_of_day: 'am'
    },
    {
        military_format: '1200',
        twenty_four_hour_format: '12:00',
        standard_format: '12:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1215',
        twenty_four_hour_format: '12:15',
        standard_format: '12:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1230',
        twenty_four_hour_format: '12:30',
        standard_format: '12:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1245',
        twenty_four_hour_format: '12:45',
        standard_format: '12:45',
        time_of_day: 'pm'
    },
    {
        military_format: '1300',
        twenty_four_hour_format: '13:00',
        standard_format: '1:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1315',
        twenty_four_hour_format: '13:15',
        standard_format: '1:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1330',
        twenty_four_hour_format: '13:30',
        standard_format: '1:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1345',
        twenty_four_hour_format: '13:45',
        standard_format: '1:45',
        time_of_day: 'pm'
    },
    {
        military_format: '1400',
        twenty_four_hour_format: '14:00',
        standard_format: '2:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1415',
        twenty_four_hour_format: '14:15',
        standard_format: '2:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1430',
        twenty_four_hour_format: '14:30',
        standard_format: '2:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1445',
        twenty_four_hour_format: '14:45',
        standard_format: '2:45',
        time_of_day: 'pm'
    },
    {
        military_format: '1500',
        twenty_four_hour_format: '15:00',
        standard_format: '3:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1515',
        twenty_four_hour_format: '15:15',
        standard_format: '3:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1530',
        twenty_four_hour_format: '15:30',
        standard_format: '3:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1545',
        twenty_four_hour_format: '15:45',
        standard_format: '3:45',
        time_of_day: 'pm'
    },
    {
        military_format: '1600',
        twenty_four_hour_format: '16:00',
        standard_format: '4:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1615',
        twenty_four_hour_format: '16:15',
        standard_format: '4:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1630',
        twenty_four_hour_format: '16:30',
        standard_format: '4:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1645',
        twenty_four_hour_format: '16:45',
        standard_format: '4:45',
        time_of_day: 'pm'
    },
    {
        military_format: '1700',
        twenty_four_hour_format: '17:00',
        standard_format: '5:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1715',
        twenty_four_hour_format: '17:15',
        standard_format: '5:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1730',
        twenty_four_hour_format: '17:30',
        standard_format: '5:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1745',
        twenty_four_hour_format: '17:45',
        standard_format: '5:45',
        time_of_day: 'pm'
    },
    {
        military_format: '1800',
        twenty_four_hour_format: '18:00',
        standard_format: '6:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1815',
        twenty_four_hour_format: '18:15',
        standard_format: '6:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1830',
        twenty_four_hour_format: '18:30',
        standard_format: '6:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1845',
        twenty_four_hour_format: '18:45',
        standard_format: '6:45',
        time_of_day: 'pm'
    },
    {
        military_format: '1900',
        twenty_four_hour_format: '19:00',
        standard_format: '7:00',
        time_of_day: 'pm'
    },
    {
        military_format: '1915',
        twenty_four_hour_format: '19:15',
        standard_format: '7:15',
        time_of_day: 'pm'
    },
    {
        military_format: '1930',
        twenty_four_hour_format: '19:30',
        standard_format: '7:30',
        time_of_day: 'pm'
    },
    {
        military_format: '1945',
        twenty_four_hour_format: '19:45',
        standard_format: '7:45',
        time_of_day: 'pm'
    },
    {
        military_format: '2000',
        twenty_four_hour_format: '20:00',
        standard_format: '8:00',
        time_of_day: 'pm'
    },
    {
        military_format: '2015',
        twenty_four_hour_format: '20:15',
        standard_format: '8:15',
        time_of_day: 'pm'
    },
    {
        military_format: '2030',
        twenty_four_hour_format: '20:30',
        standard_format: '8:30',
        time_of_day: 'pm'
    },
    {
        military_format: '2045',
        twenty_four_hour_format: '20:45',
        standard_format: '8:45',
        time_of_day: 'pm'
    },
    {
        military_format: '2100',
        twenty_four_hour_format: '21:00',
        standard_format: '9:00',
        time_of_day: 'pm'
    },
    {
        military_format: '2115',
        twenty_four_hour_format: '21:15',
        standard_format: '9:15',
        time_of_day: 'pm'
    },
    {
        military_format: '2130',
        twenty_four_hour_format: '21:30',
        standard_format: '9:30',
        time_of_day: 'pm'
    },
    {
        military_format: '2145',
        twenty_four_hour_format: '21:45',
        standard_format: '9:45',
        time_of_day: 'pm'
    },
    {
        military_format: '2200',
        twenty_four_hour_format: '22:00',
        standard_format: '10:00',
        time_of_day: 'pm'
    },
    {
        military_format: '2215',
        twenty_four_hour_format: '22:15',
        standard_format: '10:15',
        time_of_day: 'pm'
    },
    {
        military_format: '2230',
        twenty_four_hour_format: '22:30',
        standard_format: '10:30',
        time_of_day: 'pm'
    },
    {
        military_format: '2245',
        twenty_four_hour_format: '22:45',
        standard_format: '10:45',
        time_of_day: 'pm'
    },
    {
        military_format: '2300',
        twenty_four_hour_format: '23:00',
        standard_format: '11:00',
        time_of_day: 'pm'
    },
    {
        military_format: '2315',
        twenty_four_hour_format: '23:15',
        standard_format: '11:15',
        time_of_day: 'pm'
    },
    {
        military_format: '2330',
        twenty_four_hour_format: '23:30',
        standard_format: '11:30',
        time_of_day: 'pm'
    },
    {
        military_format: '2345',
        twenty_four_hour_format: '23:45',
        standard_format: '11:45',
        time_of_day: 'pm'
    }
];
