// module
import { Window } from './window';
import { map } from 'rxjs/operators';
import { fromEvent, merge, Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WindowService implements Window {
    navigator: any = {};
    location: any = {};

    alert(msg: string): void {
        return;
    }

    confirm(msg: string): void {
        return;
    }
}
