import { Injectable } from "@angular/core";
import { Media, MediaAdapter } from "./media.model";
import { Adapter } from './adapter';
import * as dayjs from 'dayjs';

export class RoomRecording {
    constructor(
        public id?: number,
        public title?: string,
        public description?: string,
        public video?: Media,
        public subject_type?: string,
        public subject_id?: number,
        public subject?: any,
        public source_type?: string,
        public source_id?: number,
        public source?: any,
        public created_at?: Date,
        public updated_at?: Date,
        public comments_count?: number,
        public room?: any
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class RoomRecordingAdapter implements Adapter<RoomRecording> {
    constructor(
        private _mediaAdapter: MediaAdapter
    ) {

    }
    adapt(item: any): RoomRecording {
        return new RoomRecording(
            item.id,
            item.title,
            item.description,
            item.video ? this._mediaAdapter.adapt(item.video) : null,
            item.subject_type,
            item.subject_id,
            item.subject,
            item.source_type,
            item.source_id,
            item.source,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.total_comment_counts,
            item.room
        );
    }
}
