export var waitForGlobal = function (key: any, callback: any) {
    if (getNested(window, ...key.split('.'))) {
        callback();
    } else {
        setTimeout(() => {
            waitForGlobal(key, callback);
        }, 100);
    }
};

function getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}
