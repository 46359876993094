export class OpenSkillsDialog {
    static readonly type = '[Profile] Open Skills Dialog';
    constructor() {}
}

export class OpenSoftSkillsDialog {
    static readonly type = '[Profile] Open Soft Skills Dialog';
    constructor() {}
}

export class OpenExperienceDialog {
    static readonly type = '[Profile] Open Experience Dialog';
    constructor() {}
}

export class OpenEducationDialog {
    static readonly type = '[Profile] Open Education Dialog';
    constructor() {}
}

export class OpenCertificationDialog {
    static readonly type = '[Profile] Open Cert Dialog';
    constructor() {}
}
export class OpenHonorsDialog {
    static readonly type = '[Profile] Open Honors Dialog';
    constructor() {}
}
export class OpenReferenceDialog {
    static readonly type = '[Profile] Open Reference Dialog';
    constructor() {}
}
export class OpenLanguageDialog {
    static readonly type = '[Profile] Open Language Dialog';
    constructor() {}
}

export class StartUploadResume {
    static readonly type = '[Profile] Start Upload Resume';
    constructor() {}
}

export class OpenValueBadgeDialog {
    static readonly type = '[Profile] Open Value Badge Dialog';
    constructor() {}
}

export class OpenStoryStudioOverlay {
    static readonly type = '[Profile] Open Story Studio Overlay';
    constructor() {}
}

export class OpenCoachingDialog {
    static readonly type = '[Profile] Open Coaching Dialog';
    constructor() {}
}
