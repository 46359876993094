import { environment } from 'environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { MetaStaticLoader } from '../../meta/meta.loader';
import { JBZ_CONFIG } from '@jobzmall/config/jbz-config';
import { map } from 'rxjs';


export const metaConfigFactory = (loco: TranslocoService) => {
    return new MetaStaticLoader({
        callback: (cur: string) => {
            return loco.selectTranslation().pipe(
                map(() => {
                    return loco.translate(cur);
                })
            );
        },
        pageTitlePositioning: JBZ_CONFIG.seo.pageTitlePositioning,
        pageTitleSeparator: JBZ_CONFIG.seo.pageTitleSeparator,
        applicationName: JBZ_CONFIG.system.applicationName,
        applicationUrl: environment.baseBrowserUrl,
        defaults: {
            title: JBZ_CONFIG.seo.defaultPageTitle,
            description: JBZ_CONFIG.seo.defaultMetaDescription,
            'og:site_name': JBZ_CONFIG.system.applicationName,
            'og:type': 'website',
            'og:image':
                'https://assets.jobzmall.com/static/images/new/og-standard.png',
            'og:locale': JBZ_CONFIG.i18n.defaultLanguage.culture,
            'og:locale:alternate': JBZ_CONFIG.i18n.availableLanguages
                .map((cur: any) => cur.culture)
                .toString(),
            keywords: JBZ_CONFIG.seo.keywords
        }
    });
};
