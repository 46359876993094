import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { PlyrModule } from 'ngx-plyr';
import { JMVideoComponent } from './jm-video.component';
import { NgxResponsiveEmbedModule } from 'ngx-responsive-embed';
import { VideoThumbnailComponent } from './video-thumbnail/video-thumbnail.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const COMPONENTS = [JMVideoComponent, VideoThumbnailComponent];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        RouterModule,
        NgPipesModule,
        JMPipesModule,
        JMCommonDirectivesModule,
        JMSharedModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
        NgxResponsiveEmbedModule,
        PlyrModule,
        CommonModule,
        MatProgressSpinnerModule
    ],
    exports: COMPONENTS,
    providers: []
})
export class JMVideoComponentModule {}
