import {
    ViewChild,
    ViewEncapsulation,
    Output,
    EventEmitter,
    Input
} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {
    UntypedFormGroup,
    Validators,
    UntypedFormBuilder,
    NgForm
} from '@angular/forms';
import { FuseAlertType } from '@fuse/components/alert';
import { Store } from '@ngxs/store';
import { Login, SocialLogin } from '@jobzmall/core/auth/ngxs/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CaptchaService } from '@jobzmall/core/services/captcha.service';
import { first, firstValueFrom } from 'rxjs';
import { AuthService } from '@jobzmall/core';

@Component({
    selector: 'login-form',
    templateUrl: './login-form.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginFormComponent extends JMBaseComponent implements OnInit {
    @ViewChild('signInNgForm') signInNgForm: NgForm;

    @Input() readonlyEmail = false;
    @Input() email: string;
    @Input() showSignup = true;
    @Input() showLogo = true;

    @Output() signUpClick = new EventEmitter();

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    signInForm: UntypedFormGroup;
    showAlert: boolean = false;

    step = 'fields';
    phone: any;
    phoneCountryCode: string;

    /**
     * Constructor
     */
    constructor(
        private _auth: AuthService,
        private _captcha: CaptchaService,
        private _confirmation: FuseConfirmationService,
        private _activatedRoute: ActivatedRoute,
        private _store: Store,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.signInForm.controls.email.setValue(this.email);
        if (this.readonlyEmail) {
            this.signInForm.controls.email.disable();
        }
         this.subscriptions.sink = this._auth
             .authState('google')
             .subscribe(() => {});
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     */
    signIn(): void {
        // Return if the form is invalid
        if (this.signInForm.invalid) {
            return;
        }

        // Disable the form
        this.signInForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Sign in
        this._store.dispatch(new Login(this.signInForm.value)).pipe(first()).subscribe(
            () => {
                // Redirect handled in AppComponent listener
            },
            (error: any) => {
                if (error.status === 428) {
                    this.phone = error.data.metadata.phone;
                    this.phoneCountryCode =
                        error.data.metadata.phone_country_code;
                    this.step = 'two-factor';
                } else if (error.status === 403) {
                    this._router.navigate(['/suspended']);
                } else if (error.status === 429) {
                    // Re-enable the form
                    this.signInForm.disable();

                    // Set the alert
                    this.alert = {
                        type: 'error',
                        message: 'There have been too many login attempts. Please wait 10 minutes before trying again.'
                    };

                    // Show the alert
                    this.showAlert = true;
                } else {
                    // Re-enable the form
                    this.signInForm.enable();

                    // Set the alert
                    this.alert = {
                        type: 'error',
                        message: 'Wrong email or password'
                    };

                    // Show the alert
                    this.showAlert = true;
                }
            }
        );
    }

    socialAuth(provider: string) {
        this._store.dispatch(new SocialLogin({ provider }));
    }

    onVerifySuccess($event: any) {
        this.phone = null;
        this.step = 'fields';
        this.signIn();
    }

    onSendFail($event: any) {
        if ($event.status == 409) {
            this._confirmation.open({
                title: 'Too many attempts',
                message: `You have requested a one-time code too many times. Please wait 10 minutes before requesting a new one`,
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation',
                    color: 'warn'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: 'Ok',
                        color: 'warn'
                    },
                    cancel: {
                        show: false
                    }
                }
            });
        }
        if ($event.status == 422) {
            firstValueFrom(
                this._confirmation
                    .open({
                        title: 'Invalid Mobile Number',
                        message: `There seems to be an issue sending a text to this number. The number is either a landline or was not provisioned with a valid mobile carrier. Please change the number and try again`,
                        icon: {
                            show: true,
                            name: 'heroicons_outline:exclamation',
                            color: 'warn'
                        },
                        actions: {
                            confirm: {
                                show: true,
                                label: 'Ok',
                                color: 'warn'
                            },
                            cancel: {
                                show: false
                            }
                        }
                    })
                    .afterClosed()
            ).then(() => {
                this.step = 'fields';
            });
        }
    }
}
