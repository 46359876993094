import { environment } from 'environments/environment';
import { ConfigService } from '../../config';
import Echo from 'laravel-echo';
import { Store } from '@ngxs/store';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';

export class WebsocketConnection extends Echo {
    constructor(config: ConfigService, store: Store) {
        const token = store.selectSnapshot<string>(AuthState.token);
        let headers = {};
        if (token) {
            headers = {
                Authorization: `Bearer ${token}`
            };
        }
        
        super({
            broadcaster: 'pusher',
            key: config.getSettings('data.pusher.app_key'),
            cluster: config.getSettings('data.pusher.app_cluster'),
            authEndpoint: `${environment.apiRoot}broadcasting/auth`,
            auth: {
                headers
            },
            forceTLS: true
        });
    }
}
