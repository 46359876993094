import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of, firstValueFrom } from 'rxjs';
import { catchError, map, first } from 'rxjs/operators';
import { User } from '../models/user.model';
import {
    Organization,
    OrganizationAdapter
} from '../models/organization.model';
import { pick } from 'lodash-es';
import * as dayjs from 'dayjs';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { UserAdapter } from '../models/user.model';
import { Restangular } from '../core/api/ngx-restangular';
import { SegmentService } from 'ngx-segment-analytics';
import { MediaAdapter } from '@jobzmall/models/media.model';
import { Store } from '@ngxs/store';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    /**
     * Constructor
     */
    constructor(
        @Inject(WINDOW) private _window: Window,
        private _confirmation: FuseConfirmationService,
        private _store: Store,
        private _mediaAdapter: MediaAdapter,
        private _router: Router,
        private _segment: SegmentService,
        private _userAdapter: UserAdapter,
        private _orgAdapter: OrganizationAdapter,
        private _api: Restangular,
        private _httpClient: HttpClient
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    user(): Observable<User> {
        return this._api
            .all('user')
            .customGET()
            .pipe(
                map((res: any) => res.data),
                map((data: any) => {
                    if (data) {
                        return this._userAdapter.adapt(data);
                    } else {
                        return undefined;
                    }
                }),
                catchError((err: any) => {
                    if (err.status == 403) {
                        this._router.navigate(['/suspended']);
                    }
                    return of(undefined);
                })
            );
    }

    stores(): Observable<Array<Organization>> {
        return this._api
            .all('store/mine')
            .customGET()
            .pipe(
                catchError((err: any, caught: any) => of([])),
                map((res: any) => res.data),
                map((stores: Array<any>) => {
                    if (stores && stores.length) {
                        return stores.map((store: any) =>
                            this._orgAdapter.adapt(store)
                        );
                    } else {
                        return [];
                    }
                })
            );
    }

    forgotPassword(data: { email: string }) {
        return this._api
            .all('user/password/forgot')
            .customPOST(data)
            .pipe(map((res: any) => res.data));
    }

    updateUserPassword(
        slug: string,
        data: {
            password: string;
            password_confirmation: string;
            email?: string;
            token?: string;
        }
    ) {
        return this._api
            .all(`user/${slug}/password/update`)
            .customPOST(data)
            .pipe(map((res: any) => res.data));
    }

    ping() {
        this._api
            .all('user/ping')
            .customPOST()
            .pipe(
                first(),
                map((res: any) => res.data)
            )
            .subscribe(() => {});
    }

    resetPassword(data: {
        password: string;
        password_confirmation: string;
        email?: string;
        token?: string;
    }) {
        return this._api
            .all('user/password/reset')
            .customPOST(data)
            .pipe(map((res: any) => res.data));
    }

    forceResetPassword(data: {
        slug: string;
        email: string;
        token: string;
        password: string;
        password_confirmation: string;
    }) {
        return this._api
            .all(`user/${data.slug}/password/update`)
            .customPOST(data)
            .pipe(map((res: any) => res.data));
    }

    updateExternals(user?: any) {
        if (user) {
            const state = pick(user, [
                'first_name',
                'last_name',
                'slug',
                'id',
                'deactivated',
                'phone',
                'phone_e164',
                'email',
                'user_type',
                'two_factor',
                'pronouns',
                'jobseeker_status',
                'has_resume',
                'has_management_experience',
                'professional_title',
                'is_coaching',
                'coach_verified',
                'can_sell',
                'visible',
                'user_hash'
            ]);
            state['name'] = user['first_name'] + ' ' + user['last_name'];
            state['created_at'] = dayjs(user.created_at).unix();
            state['user_id'] = user.id;
            state['app_id'] = environment.intercomAppID;
            state['hide_default_launcher'] = true;
            state['Video Resume'] = user['has_video_collection'];
            state['Location'] = user['location'];
            state['Applications Count'] = user['applications_count'];
            state['Profile Strength'] = user['ps'];
            state['Completed Main Onboarding'] = user.completed_main_onboarding;
            state['Can Work Remotely'] = user.can_remote;
            state['Willing to Relocate'] = user.will_relocate;
            if (user.last_applied) {
                state['Date Last Applied'] = dayjs(user.last_applied).unix();
            }
            if (user.last_check_in) {
                state['Last Check In'] = dayjs(user.last_check_in).unix();
            }
            if (user.email_verified_at) {
                state['Email Verified At'] = dayjs(
                    user.email_verified_at
                ).unix();
            }
            if (user.image) {
                state['profile_image'] = user.image.full_path;
                state['avatar'] = {
                    type: 'avatar',
                    image_url: user.image.full_path
                };
            }

            if ((this._window as any).Intercom) {
                (this._window as any).Intercom('boot', state);
            }

            this._segment.identify(user.id, {
                phone: user.phone_e164,
                phone_country_code: user.phone_country_code,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name
            });
        } else {
            const state = {};
            state['app_id'] = environment.intercomAppID;
            state['hide_default_launcher'] = true;
            if ((this._window as any).Intercom) {
                (this._window as any).Intercom('destroy');
                (this._window as any).Intercom('boot', state);
            }
        }
    }

    authlogs(page = 1) {
        return this._api
            .all('user/authlogs')
            .customGET(undefined, { page: page })
            .pipe(map((res: any) => res.data));
    }

    signUp(type: string, data: object) {
        return this._api
            .all('user/signup')
            .customPOST(data, type)
            .pipe(map((res: any) => res.data));
    }

    startPhoneVerification(phone: string, phone_country_code: string) {
        return this._api
            .all('phone/code')
            .customPOST({
                phone,
                phone_country_code
            })
            .pipe(map((res: any) => res.data));
    }

    submitPhoneVerification(
        phone,
        phone_code: any,
        phone_country_code: string
    ) {
        return this._api
            .all('phone/code/verify')
            .customPOST({
                phone,
                phone_country_code,
                phone_code
            })
            .pipe(map((res: any) => res.data));
    }

    sendVerificationEmail(
        slug: any,
        successCallback?: () => void,
        errorCallback?: () => void
    ) {
        this._api
            .all(`user/${slug}/verify/resend`)
            .customPOST()
            .pipe(first())
            .subscribe(
                (res: any) => {
                    this._confirmation
                        .open({
                            title: 'Email Sent',
                            message:
                                'A new verification email has been sent! Please check your email.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:check',
                                color: 'success'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Ok',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe(() => {
                            if (successCallback) {
                                successCallback();
                            }
                        });
                },
                (res: any) => {
                    this._confirmation
                        .open({
                            title: 'Too many attempts',
                            message:
                                'Please wait a while before trying to resend.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:exclamation',
                                color: 'warn'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Ok',
                                    color: 'warn'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe(() => {
                            if (errorCallback) {
                                errorCallback();
                            }
                        });
                }
            );
    }

    bySlug(slug: string): BehaviorSubject<User> {
        return this._api
            .all(['user', slug].filter((val) => val).join('/'))
            .customGET()
            .pipe(
                map((res: any) => res.data),
                map((data: any) => this._userAdapter.adapt(data))
            );
    }

    profileStrength(slug: string): BehaviorSubject<User> {
        return this._api
            .all(['user', slug, 'ps'].filter((val) => val).join('/'))
            .customGET()
            .pipe(map((res: any) => res.data));
    }

    save(data: any, slug: string): Observable<any> {
        if (data.slug) {
            if (data.slug === slug) {
                delete data.slug;
            }
        }
        return this._api
            .all(`user/${slug}`)
            .customPATCH(this._userAdapter.anon(data))
            .pipe(
                map((res: any) => res.data),
                map((data: any) => {
                    return data;
                })
            );
    }

    savePreferences(data: any, slug: string): Observable<any> {
        let options = Object.entries(data).reduce(
            (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
            {}
        );
        return this._api
            .all(`user/${slug}/preference`)
            .customPATCH(options)
            .pipe(map((res: any) => res.data));
    }

    getPreferences(slug: string): Observable<any> {
        return this._api
            .all(`user/${slug}/preference`)
            .customGET(undefined)
            .pipe(map((res: any) => res.data));
    }

    verifyResetPasswordToken(email: string, token: string) {
        return this._api
            .all('user/password/reset/verify')
            .customPOST({
                email,
                token
            })
            .pipe(
                map((res: any) => {
                    return true;
                }),
                catchError((err: any, caught: any) => {
                    return of(false);
                })
            );
    }

    getUserOnboarding(id: number) {
        return this._api
            .all(`entity/user/${id}/onboarding/main`)
            .customGET()
            .pipe(
                map((res: any) => res.data),
                catchError((err: any, caught: any) => of(undefined))
            );
    }

    sendEmailChangeRequest(slug: string, email: string) {
        return this._api
            .all(`user/${slug}/email/change`)
            .customPOST({
                email
            })
            .pipe(map((res: any) => res.data));
    }

    verifyEmailChangeRequest(url: string) {
        return this._api
            .all(url)
            .customGET()
            .pipe(map((res: any) => res.data));
    }

    deleteResume(slug: string): BehaviorSubject<any> {
        return this._api
            .all(`user/${slug}/resume`)
            .customDELETE()
            .pipe(map((res: any) => res.data));
    }

    acceptConnectionRequest(slug: string) {
        return this._api
            .all(`user/${slug}/connectionRequest/accept`)
            .customPOST()
            .pipe(map((res: any) => res.data));
    }

    removeConnection(slug: string) {
        return this._api
            .all(`user/${slug}/connection`)
            .customDELETE()
            .pipe(map((res: any) => res.data));
    }

    createConnectionRequest(slug: string) {
        return this._api
            .all(`user/${slug}/connectionRequest`)
            .customPOST()
            .pipe(map((res: any) => res.data));
    }

    cancelOrDenyConnectionRequest(slug: string) {
        return this._api
            .all(`user/${slug}/connectionRequest`)
            .customDELETE()
            .pipe(map((res: any) => res.data));
    }

    connections(page = 1, count = 10) {
        return this._api
            .all(`user/connection`)
            .customGET(undefined, { page, count })
            .pipe(
                map((res: any) =>
                    res.data.map((u) => this._userAdapter.adapt(u))
                )
            );
    }

    connectionRequests(page = 1, count = 10) {
        return this._api
            .all(`user/connectionRequest`)
            .customGET(undefined, { page, count })
            .pipe(
                map((res: any) =>
                    res.data.map((u) => this._userAdapter.adapt(u))
                )
            );
    }

    uploadMedia(
        data: { file: File; title?: string; description?: string },
        slug: string
    ) {
        let formData = new FormData();
        formData.append('file', data.file);
        data.title && formData.append('title', data.title);
        data.description && formData.append('description', data.description);

        return this._api
            .all('user')
            .customPOST(formData, `${slug}/media`, undefined, {
                'Content-Type': (): any => {
                    return undefined;
                }
            })
            .pipe(
                map((res: any) => {
                    return res.data;
                }),
                map((val: any) => this._mediaAdapter.adapt(val))
            );
    }

    openUserIntercom(slug) {
        this._api
            .all(`/user/${slug}/intercom`)
            .customGET()
            .pipe(
                first(),
                map((res: any) => res.data)
            )
            .subscribe((url: string) => {
                if (url) {
                    window.open(url, '_blank');
                }
            });
    }

    block(user: User) {
        return firstValueFrom(
            this._confirmation
                .open({
                    title: `Block ${user.first_name} ${user.last_name}`,
                    message: `Are you sure you want to block ${user.first_name}? They won't be able to find your profile, posts, or message you on JobzMall. JobzMall won't let them know you blocked them.`,
                    icon: {
                        show: true,
                        name: 'heroicons_outline:question-mark-circle',
                        color: 'primary'
                    },
                    actions: {
                        confirm: {
                            show: true,
                            label: 'Block',
                            color: 'primary'
                        },
                        cancel: {
                            show: true,
                            label: 'Cancel'
                        }
                    }
                })
                .afterClosed()
        ).then((result: string) => {
            if (result == 'confirmed') {
                return firstValueFrom(
                    this._api.all(`/user/${user.slug}/block`).customPOST()
                ).then(
                    (res: any) => {
                        return firstValueFrom(of(res));
                    },
                    () => {
                        this._confirmation.open({
                            title: 'Uh Oh!',
                            message:
                                'Something went wrong while blocking this user',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:exclamation',
                                color: 'warn'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Ok',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        });
                        return firstValueFrom(of(false));
                    }
                );
            }
            return firstValueFrom(of(false));
        });
    }
}
