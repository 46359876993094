<!-- Button -->
<button mat-flat-button class="p-0 text-current bg-transparent" [matMenuTriggerFor]="languages">
    <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: currentLanguage}"></ng-container>
    <span class="ml-2">{{currentLanguage?.name}}</span>
</button>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #languages="matMenu">
    <ng-container *ngFor="let lang of availableLanguages; trackBy: trackByFn">
        <button mat-menu-item (click)="setActiveLang(lang)">
            <span class="flex items-center">
                <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang}">
                </ng-container>
                <span class="ml-3">{{lang?.name}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template let-lang #flagImage>
    <div class="flex items-center">
        <span class="relative w-6 overflow-hidden rounded-sm shadow">
            <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
            <img class="w-full" [src]="'assets/images/flags/' + lang?.culture + '.svg'"
                [alt]="'Flag image for ' + lang?.name">
        </span>
    </div>
</ng-template>
