import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors
} from '@angular/forms';
import { Restangular } from '@jobzmall/core/api';
import { Observable, timer, switchMap, map } from 'rxjs';

export function checkBlockedPhoneNumberValidator(
    api: Restangular
): AsyncValidatorFn {
    return (
        control: AbstractControl
    ):
        | Promise<ValidationErrors | null>
        | Observable<ValidationErrors | null> => {
        return timer(200).pipe(
            switchMap(() => {
                return api
                    .all('phone')
                    .customPOST(
                        { phone_e164: control.value.e164Number },
                        'check'
                    )
                    .pipe(
                        map((res: any) => {
                            if (res.data === true) {
                                return {};
                            } else {
                                return { validatePhoneNumber: true };
                            }
                        })
                    );
            })
        );
    };
}
