import { Injector } from "@angular/core";

let jbzInjectorRef: Injector;

export function jbzInjector(injector?: Injector): Injector {
    if (!injector) {
        return jbzInjectorRef;
    }
    jbzInjectorRef = injector;
    return jbzInjectorRef;
}
