export const authenticationRedirectExclusions: Array<string> = [
    '/apply',
    '/genius',
    '/open-store',
    '/post-job',
    '/genome/questionnaire',
    '/cinema/stars/questionnaire',
    '/video-resume-studio',
    '/invitation',
    '/verify-ownership',
    '/invite',
    '/zv/create',
    '/claim',
    '/humans-of-talent/questionnaire',
    '/reference',
    '/suspended',
    '/genius/checkout',
    '/rooms',
    '/profile',
    '/@',
    '/coach/signup',
    '/interviews',
    '/prescreening'
];
