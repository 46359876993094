export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: { email: string; password: string }) {}
}

export class SetToken {
    static readonly type = '[Auth] Set Token';
    constructor(public token?: string) {}
}

export class SocialLogin {
    static readonly type = '[Auth] Social Login';
    constructor(public payload: { provider: string; access_token?: string }) {}
}

export class Logout {
    static readonly type = '[Auth] Logout';
}

export class SignUp {
    static readonly type = '[Auth] Sign Up';
    constructor(public type: string, public data: Object) {}
}
