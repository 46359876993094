import { PipeTransform, Pipe } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as utc from 'dayjs/plugin/utc';

@Pipe({ name: 'dayJsLocalRelativeFormat' })
export class LocalRelativePipe implements PipeTransform {
    constructor() {
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
    }

    transform(value: string | number | Date | dayjs.Dayjs) {
        return dayjs(value).local().fromNow();
    }
}
