export interface Permission {
    entity: PermissionEntity;
    operation: PermissionOperation;
}

export enum PermissionEntity {
    STORE_SETTINGS = 'store-settings',
    STORE_JOBS = 'store-jobs',
    STORE_APPLICATIONS = 'store-applications',
    STORE_POSTS = 'store-posts',
    STORE_ADMINS = 'store-admins',
    STORE_MEMBERS = 'store-members',
    STORE_BILLING = 'store-billing'
}

export enum PermissionOperation {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
    READ = 'read'
}