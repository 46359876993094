<ng-container *transloco="let t">
    <div class="flex flex-col items-center justify-center w-full h-full bg-card">
        <div @slideInRight *ngIf="step === 'fields'" class="w-full mx-auto max-w-100 sm:w-100 sm:mx-0">
            <!-- Logo -->
            <app-logo *ngIf="showLogo" class="flex items-center justify-center w-auto h-15"></app-logo>

            <div class="mx-auto text-center">
                <!-- Title -->
                <div class="mt-8 text-4xl font-extrabold leading-tight tracking-tight">Confirm Your Information</div>
                <div class="mt-3 text-xl font-semibold leading-tight tracking-tight">Before you can continue, we need
                    you to confirm some information</div>

                <!-- Alert -->
                <jbz-alert class="mt-8 -mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                    [type]="alert.type" [@shake]="alert.type === 'error'">
                    {{alert.message}}
                </jbz-alert>

                <!-- User Detail Confirmation form -->
                <form autocomplete="off" class="mt-8" [formGroup]="form"
                    (ngSubmit)="form.valid && confirm()">

                    <div class="flex flex-row w-full">
                        <div class="flex flex-col mr-1 w-50">
                            <!-- First Name field -->
                            <mat-form-field class="w-full">
                                <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.FIRST_NAME')}}</mat-label>
                                <input id="first_name" name="first_name" matInput [formControlName]="'first_name'">
                                <mat-error *ngIf="form.get('first_name').hasError('required')">
                                    {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="flex flex-col ml-1 w-50">
                            <!-- Last Name field -->
                            <mat-form-field class="w-full">
                                <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.LAST_NAME')}}</mat-label>
                                <input id="last_name" name="last_name" matInput [formControlName]="'last_name'">
                                <mat-error *ngIf="form.get('last_name').hasError('required')">
                                    {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!--Phone field-->
                    <mat-form-field class="w-full">
                        <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.PHONE')}}</mat-label>
                        <ngx-intl-tel-input [separateDialCode]="true" [required]="true" class="mb-1"
                           [preferredCountries]="[CountryISO.UnitedStates, CountryISO.Canada,
                           CountryISO.India, CountryISO.France,
                           CountryISO.UnitedKingdom, CountryISO.Turkey]"
                           [excludeCountries]="[CountryISO.Iran,
                           CountryISO.Afghanistan, CountryISO.NorthKorea, CountryISO.Syria,
                           CountryISO.Russia]" [enableAutoCountrySelect]="false"
                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" maxLength="15"
                            [phoneValidation]="true" inputId="phone" name="phone" formControlName="phone">
                        </ngx-intl-tel-input>
                        <mat-error *ngIf="form.get('phone').hasError('required')">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                        <mat-error *ngIf="form.get('phone').hasError('validatePhoneNumber')">Invalid Number
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full">
                        <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.PASSWORD')}}</mat-label>
                        <input id="password" matInput type="password" formControlName="password" #passwordField>
                        <button mat-icon-button type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'">
                            </mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-off'">
                            </mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('password').hasError('required')">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}
                        </mat-error>
                        <mat-error *ngIf="form.get('password').hasError('minlength')">
                            {{t('PUBLIC.SHARED.FORM.ERROR.PASSWORD_LENGTH')}}
                        </mat-error>

                    </mat-form-field>


                    <!-- Submit button -->
                    <button class="w-full mt-6 jbz-mat-button-large" mat-flat-button [color]="'primary'"
                        [disabled]="form.invalid" type="submit">
                        <span>
                            {{ t('PUBLIC.SHARED.ACTION.CONTINUE') }}
                        </span>
                    </button>
                </form>
            </div>
        </div>
        <phone-verification-form [requireVerification]="false" @slideInRight (onPhoneUpdateClick)="step = 'fields'" *ngIf="step === 'phone-verify'"
            [showLogo]="showLogo" [showTitle]="true" (onSendFail)="onSendFail($event)"
            (onVerifySuccess)="onVerifySuccess($event)" [phoneCountryCode]="user.phone_country_code"
            [phone]="user.phone_e164">
        </phone-verification-form>
    </div>
</ng-container>
