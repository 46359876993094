import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../ngxs/state';
import { RefreshUser, UserState } from '@jobzmall/user';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    /**
     * Constructor
     */
    constructor(private _store: Store, private _router: Router) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._check(state);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._check(state);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (!this._store.selectSnapshot(UserState.initialized)) {
            this._store.dispatch(new RefreshUser());
        }
        return this._store.select(UserState.initialized).pipe(
            filter(Boolean),
            mergeMap(() => {
                const user = this._store.selectSnapshot(UserState.user);
                const isAuthenticated = this._store.selectSnapshot(
                    AuthState.isAuthenticated
                );

                if (!isAuthenticated && !this._isFallback(state)) {
                    return of(
                        this._router.createUrlTree(['/login'], {
                            queryParams: { redirectURL: state.url }
                        })
                    );
                }
                if (user) {
                    if (user.deactivated && state.url !== '/deactivated') {
                        return of(this._router.createUrlTree(['/deactivated']));
                    }
                    if (
                        user.password_reset &&
                        state.url !== '/reset-password'
                    ) {
                        return of(
                            this._router.createUrlTree(['/reset-password'])
                        );
                    }
                }

                return of(true);
            })
        );
    }

    private _isFallback(state: RouterStateSnapshot): boolean {
        return state.url === '/login';
    }
}
