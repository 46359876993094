import { Injectable } from '@angular/core';
import {
    OrganizationAdapter,
    OrganizationSuggestionToOrganizationAdapter
} from '@jobzmall/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Restangular } from '../api';

@Injectable()
export class DataService {
    constructor(
        private _api: Restangular,
        private _organizationSuggestionAdapter: OrganizationSuggestionToOrganizationAdapter,
        private _organizationAdapter: OrganizationAdapter
    ) {}

    enhance(type: string, query: string, mapData: boolean): Observable<any> {
        return this._api
            .all('data')
            .customGET('enhance', { type: type, query: query, map: mapData })
            .pipe(map((res: any) => res.data));
    }

    suggest(
        query: string,
        existing = true,
        only_existing = false
    ): Observable<any> {
        return this._api
            .all('store')
            .customGET('autocomplete', {
                query: query,
                existing,
                only_existing
            })
            .pipe(
                map((res: any) => {
                    return res.data.map((item: any) => {
                        if (item.id) {
                            return this._organizationAdapter.adapt(item);
                        } else {
                            return this._organizationSuggestionAdapter.adapt(
                                item
                            );
                        }
                    });
                })
            );
    }
}
