import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    ElementRef,
    ViewChild,
    forwardRef,
    Inject,
    Optional,
    HostListener,
    HostBinding
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { SearchFilter } from '../search-filters/search-filters.component';
import { OverlayRef } from '@angular/cdk/overlay';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import {
    BaseWidget,
    NgAisIndex,
    NgAisInstantSearch
} from 'angular-instantsearch';
import { NgAisHierarchicalMenu } from 'angular-instantsearch/hierarchical-menu/hierarchical-menu';
import { NgAisNumericMenu } from 'angular-instantsearch/numeric-menu/numeric-menu';
import { NgAisRefinementList } from 'angular-instantsearch/refinement-list/refinement-list';
import { NgAisToggle } from 'angular-instantsearch/toggle/toggle';

@Component({
    selector: 'search-filter-dropdown',
    templateUrl: './search-filter-dropdown.component.html',
    styles: [
        `
            search-filter-dropdown {
                .ais-Panel {
                    margin-bottom: 0px !important;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class SearchFilterDropdownComponent
    extends BaseWidget
    implements OnInit
{
    exclude = { isRefined: true, label: 'All', value: '%7B%7D' };

    state: any = {};

    @ViewChild('toggleElement') toggleElement: NgAisRefinementList;
    @ViewChild('filterElement') filterElement:
        | NgAisNumericMenu
        | NgAisRefinementList
        | NgAisHierarchicalMenu;
    @ViewChild('panelWrapper') private panelWrapper: ElementRef;
    @ViewChild('button') private button: ElementRef;

    @Input() filter: SearchFilter;

    private _overlayRef: OverlayRef;

    showPanel = false;
    showFilter = false;
    initialized = false;

    canBeClicked = false;

    @HostBinding('class') get classList(): any {
        return {
            hidden: !this.showFilter
        };
    }

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super('SearchFilterDropdownComponent');
    }

    ngOnInit(): void {
        this.createWidget(connectCurrentRefinements, {});
        super.ngOnInit();
    }

    ngAfterViewInit() {}

    updateState = (state, isFirstRendering: boolean) => {
        if (isFirstRendering) {
            return;
        }

        this.state = {
            ...state
        };
        this.showPanel = false;
        setTimeout(() => {
            this.canBeClicked =
                this.filterElement?.state?.items?.length > 0 ||
                this.toggleElement !== undefined;
        });
        this._changeDetectorRef.markForCheck();
        if (!this.initialized) {
            this.initialized = true;
            setTimeout(() => {
                this.showFilter =
                    this.filterElement?.state?.items?.length > 0 ||
                    this.toggleElement !== undefined;
                this._changeDetectorRef.markForCheck();
                this._changeDetectorRef.detectChanges();
            });
            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
        }
    };

    @HostListener('document:click', ['$event.target'])
    public onPageClick(targetElement) {
        if (!this.canBeClicked) {
            return;
        }
        if (this.button) {
            if (this.button.nativeElement.contains(targetElement)) {
                if (this.toggleElement) {
                    this.toggleElement.state.refine('true');
                } else {
                    this.showPanel = !this.showPanel;
                }
                return;
            }

            const clickedInside =
                this.panelWrapper.nativeElement.contains(targetElement);
            if (!clickedInside) {
                this.showPanel = false;
            }
        }
    }
}
