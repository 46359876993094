import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { UserMenuComponent } from './user-menu.component';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [UserMenuComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        JMCommonDirectivesModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule
    ],
    exports: [UserMenuComponent]
})
export class UserMenuModule {}
