import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { JMBaseOverlayComponent } from '@jobzmall/overlays/overlay.component';
import { JM_OVERLAY_DATA } from '@jobzmall/overlays';
import { User } from '@jobzmall/models';
import { firstValueFrom, Observable } from 'rxjs';
import { RefreshUser, UserState } from '@jobzmall/user';
import { Select, Store } from '@ngxs/store';
import { ChangeDetectorRef } from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { validateAllFormFields } from '@jobzmall/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'phone-verification-overlay',
    templateUrl: './phone-verification-overlay.component.html',
    styles: [
        `
            phone-verification-overlay {
                @apply h-screen w-screen;
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class PhoneVerificationOverlayComponent
    extends JMBaseOverlayComponent
    implements OnInit
{
    step;

    @Select(UserState.user) user$: Observable<User>;
    phoneCountryCode: string;
    e164Number: string;
    user: User;

    constructor(
        @Inject(JM_OVERLAY_DATA) public data: any,
        private _snackbar: MatSnackBar,
        private _store: Store,
        private _confirmation: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super(data);
    }

    ngOnInit(): void {
        this.subscriptions.sink = this.user$.subscribe((user: User) => {
            this.user = user;
            if (user) {
                this.phoneCountryCode = this.user.phone_country_code;
                this.e164Number = this.user.phone_e164;
                if (this.user.phone_valid && this.user.phone_verified_at) {
                    this.onVerifySuccess()
                } else {
                    this.step = 'verify';
                }
            }
            this._changeDetectorRef.markForCheck();
        });
    }

    onStorySubmit($event: any) {
        this.onClose.emit($event);
        this.close();
    }

    closeOverlay() {
        this.onClose.emit();
        this.close();
    }

    onVerifySuccess() {
        this._store.dispatch(new RefreshUser())
        this._snackbar.open('Phone Verified Successfully', 'OK', {
            duration: 3000,
            panelClass: ['jobz-snackbar']
        });
        this.close();
    }

    onSendFail($event: any) {
        if ($event.status == 409) {
            this._confirmation.open({
                title: 'Too many attempts',
                message: `You have requested a one-time code too many times. Please wait 10 minutes before requesting a new one`,
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation',
                    color: 'warn'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: 'Ok',
                        color: 'warn'
                    },
                    cancel: {
                        show: false
                    }
                }
            });
        }
        if ($event.status == 422) {
            firstValueFrom(
                this._confirmation
                    .open({
                        title: 'Invalid Mobile Number',
                        message: `There seems to be an issue sending a text to this number. The number is either a landline or was not provisioned with a valid mobile carrier. Please change the number and try again`,
                        icon: {
                            show: true,
                            name: 'heroicons_outline:exclamation',
                            color: 'warn'
                        },
                        actions: {
                            confirm: {
                                show: true,
                                label: 'Ok',
                                color: 'warn'
                            },
                            cancel: {
                                show: false
                            }
                        }
                    })
                    .afterClosed()
            ).then(() => {
                this.step = 'fields';
            });
        }
    }
}
