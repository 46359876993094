  <ng-container *transloco="let t">
      <div (mouseleave)="onClosePanel.emit()"
          class="w-screen max-w-xs overflow-hidden overflow-y-auto bg-white shadow-lg max-h-120 header-panel rounded-2xl lg:ml-0 ring-1 ring-black ring-opacity-5">
          <div class="mb-[64px] relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
              <a *ngFor="let status of statuses; trackBy: ( 'name' | trackByProperty )" (click)="saveStatus(status)"
                  class="flex items-start p-3 -m-3 transition duration-150 ease-in-out rounded-md cursor-pointer hover:bg-gray-50 ">
                  <mat-icon [ngClass]="status.class" [svgIcon]="status.icon"></mat-icon>
                  <div class="flex-1 pl-4 ">
                      <p class="text-lg font-medium text-gray-900">
                          {{status.name}}
                      </p>
                      <p class="mt-1 text-secondary">
                          {{status.description}}
                      </p>
                  </div>
              </a>

          </div>
      </div>
  </ng-container>
