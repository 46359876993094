import { Adapter } from './adapter';
import { Injectable } from '@angular/core';

export class SEO {
    constructor(
        public image: string,
        public description: string,
        public title: string
    ) { };
}

@Injectable({
    providedIn: 'root'
})
export class SEOAdapter implements Adapter<SEO> {
    adapt(item: any): SEO {
        return new SEO(
            item.image,
            item.description,
            item.title
        );
    }
}
