    <router-outlet #outlet="outlet"></router-outlet>



    <div *ngIf="!(isAuthenticated$ | async) && !(cookiesAccepted$ | async) && router.url === '/'"
        class="fixed bottom-0 z-50 items-end justify-end hidden w-full pb-2 sm:flex sm:pb-5">
        <div class="px-2 sm:px-6 lg:px-8">
            <div class="p-2 rounded-lg shadow-lg bg-primary-600 sm:p-3">
                <div class="flex items-center justify-between space-x-4">
                    <div class="flex items-center">
                        <span class="flex p-2 rounded-lg bg-primary-800">
                            <img alt="Cookie" #cookie src="/assets/images/etc/cookie.webp" class="w-6 h-6" />
                        </span>
                        <p class="ml-3 font-medium text-white truncate">
                            <span class="inline">
                                We use cookies
                            </span>
                        </p>
                    </div>
                    <div
                        class="flex items-center flex-shrink-0 order-3 w-full mt-2 space-x-3 sm:order-2 sm:mt-0 sm:w-auto">
                        <button (click)="acceptCookies()"
                            class="flex items-center justify-center px-4 py-2 text-sm font-medium bg-white border border-transparent rounded-md shadow-sm cursor-pointer text-primary-600 hover:bg-primary-50">
                            Accept
                        </button>
                        <button (click)="acceptCookies()"
                            class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-white rounded-md shadow-sm cursor-pointer">
                            Opt Out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
