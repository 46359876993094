import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';

export class Location {
    constructor(
        public formatted_address?: string,
        public address1?: string,
        public address2?: string,
        public latitude?: number,
        public longitude?: number,
        public city?: City,
        public country?: Country,
        public region?: Region,
        public created_at?: Date,
        public deleted_at?: Date,
        public updated_at?: Date,
        public id?: number,
        public json_ld?: any
    ) {}
}
@Injectable({
    providedIn: 'root'
})
export class LocationAdapter implements Adapter<Location> {
    adapt(item: any): Location {
        return new Location(
            item.formatted_address,
            item.address1,
            item.address2,
            item.latitude || item.lat,
            item.longitude || item.lng || item.lon,
            item.city ? new CityAdapter().adapt(item.city) : null,
            item.country ? new CountryAdapter().adapt(item.country) : null,
            item.region ? new RegionAdapter().adapt(item.region) : null,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.json_ld
        );
    }
}

export class City {
    constructor(
        public id: number,
        public country_id: number,
        public region_id: number,
        public latitude: number,
        public longitude: number,
        public name: string
    ) {}
}
@Injectable({
    providedIn: 'root'
})
export class CityAdapter implements Adapter<City> {
    adapt(item: any): City {
        return new City(
            item.id,
            item.country,
            item.region,
            item.latitude,
            item.longitude,
            item.name
        );
    }
}

export class Country {
    constructor(public id: number, public code: string, public name: string) {}
}
@Injectable({
    providedIn: 'root'
})
export class CountryAdapter implements Adapter<Country> {
    adapt(item: any): Country {
        return new Country(item.id, item.code, item.name);
    }
}

export class Region {
    constructor(
        public id: number,
        public code: string,
        public name: string,
        public country_id: number
    ) {}
}
@Injectable({
    providedIn: 'root'
})
export class RegionAdapter implements Adapter<Region> {
    adapt(item: any): Region {
        return new Region(item.id, item.code, item.name, item.country_id);
    }
}
