import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLogoComponent } from './logo/logo.component';
import { RouterModule } from '@angular/router';
import { ShareOverlayComponent } from './share-overlay/share-overlay.component';
import { ShareModule } from 'ngx-sharebuttons';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { QuoteCardComponent } from './quote-card/quote-card.component';

const COMPONENTS = [
    ShareOverlayComponent,
    QuoteCardComponent,
    AppLogoComponent
];
@NgModule({
    declarations: COMPONENTS,
    imports: [
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        CommonModule,
        MatIconModule,
        ShareModule,
        RouterModule
    ],
    exports: COMPONENTS,
    providers: []
})
export class JMSharedModule {}
