import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

@Pipe({ name: 'dayJsParse' })
export class ParsePipe implements PipeTransform {
    constructor() {
        dayjs.extend(customParseFormat);
    }
    transform(
        value: string | number | Date | dayjs.Dayjs,
        format?: dayjs.OptionType,
        strict?: boolean
    ): dayjs.Dayjs {
        return dayjs(value, format, strict);
    }
}
