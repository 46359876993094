export class RefreshUser {
    static readonly type = '[User] Refresh User';
    constructor() {}
}


export class TrackDevice {
    static readonly type = '[User] Track Device';
    constructor() {}
}


export class CreateOrUpdateUserIntegration {
    static readonly type = '[User] Create or Update Integration';
    constructor(public id: string) {}
}

export class UserIntegrationSuccess {
    static readonly type = '[User] Integration Success';
    constructor(public id: string) {}
}

export class UserIntegrationFailed {
    static readonly type = '[User] Integration Failed';
    constructor(public id: string) {}
}

export class RemoveUserIntegration {
    static readonly type = '[User] Remove User Integration';
    constructor(public id: string) {}
}

export class RefreshUserIntegrations {
    static readonly type = '[User] Refresh User Integrations';
    constructor() {}
}

export class RefreshUserOnboarding {
    static readonly type = '[User] Refresh User Onboarding';
    constructor() {}
}

export class RefreshOrganizations {
    static readonly type = '[User] Get Organizations';
    constructor() {}
}

export class InitWebsocketConnection {
    static readonly type = '[User] Init Websocket';
    constructor() {}
}

export class DestroyWebsocketConnection {
    static readonly type = '[User] Destroy Websocket';
    constructor() {}
}

export class UpdateExternals {
    static readonly type = '[User] Update Externals';
    constructor() {}
}
