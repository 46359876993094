import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightPipe } from './highlight.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafePipe } from './safe-url.pipe';
import { MoveLinksToEndPipe } from './move-links-to-end.pipe';
import { TrackByPropertyPipe } from './track-by-property.pipe';
import { HumanNumberPipe } from './human-number.pipe';
import { ArraySortPipe } from './sort.pipe';
import { AsPipe } from './as.pipe';
import { FilterPipe } from './filter.pipe';
import { JoinPipe } from './join.pipe';
import { ReplacePipe } from './replace.pipe';
import { FilterByNotPipe } from './filter-by-not.pipe';
import { GoogleTranslatePipe } from './googletranslate.pipe';
import { MediaActivePipe } from './media-active.pipe';
import { PhonePipe } from './phone.pipe';
import { LocationPipe } from './location.pipe';
import { StripBrsPipe } from './strip-brs.pipe';
import { NormalizeMarkdownPipe } from './normalize-markdown.pipe';

const PIPES = [
    MediaActivePipe,
    FilterPipe,
    ArraySortPipe,
    HighlightPipe,
    SafeHtmlPipe,
    MoveLinksToEndPipe,
    SafePipe,
    AsPipe,
    JoinPipe,
    TrackByPropertyPipe,
    HumanNumberPipe,
    ReplacePipe,
    FilterByNotPipe,
    GoogleTranslatePipe,
    LocationPipe,
    PhonePipe,
    NormalizeMarkdownPipe,
    StripBrsPipe
];

@NgModule({
    declarations: PIPES,
    imports: [CommonModule],
    exports: PIPES
})
export class JMPipesModule {}
