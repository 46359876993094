import { Directive, ElementRef, Input, AfterViewChecked } from '@angular/core';

import * as tinycolor from 'tinycolor2';

@Directive({
    selector: '[backgroundColor]'
})
export class BackgroundColorDirective implements AfterViewChecked {
    
    @Input() backgroundColor: string;

    constructor(private el: ElementRef) {}

    ngAfterViewChecked(): void {
        //Called after every check of the component's view. Applies to components only.
        //Add 'implements AfterViewChecked' to the class.
        if (!this.backgroundColor) return;

        this.el.nativeElement.style.backgroundColor = this.backgroundColor;
        let rgb = tinycolor(this.backgroundColor).toRgb();
        this.el.nativeElement.style.color = this.colourIsLight(
            rgb.r,
            rgb.g,
            rgb.b
        )
            ? 'black'
            : 'white';
        // this.el.nativeElement.style.color = tinycolor
        //     .mostReadable(this.backgroundColor, ['black', 'white'], {
        //         includeFallbackColors: true
        //     })
        //     .toHexString();
    }

    colourIsLight(r, g, b): boolean {
        let a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return a < 0.5;
    }
}
