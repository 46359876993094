import { Injectable } from '@angular/core';

import { map } from 'lodash-es';

import * as dayjs from 'dayjs';

import {
    Location,
    Language,
    LanguageAdapter,
    LocationAdapter,
    Media,
    MediaAdapter,
    Organization,
    OrganizationAdapter,
    SEO,
    SEOAdapter,
    Skill,
    SkillAdapter,
    Benefit,
    BenefitAdapter,
    SupplementalCompensation,
    SupplementalCompensationAdapter
} from '@jobzmall/models';
import { Adapter } from '@jobzmall/models/adapter';
import { SkillType } from './skill.model';

export class Job {
    constructor(
        public id: number,
        public compensation: JobCompensation,
        public draft: boolean,
        public end_date: Date,
        public information: JobInformation,
        public qualifications: Array<JobQualification>,
        public functions: Array<any>,
        public remote: boolean,
        public required_languages: Array<Language>,
        public required_skills: Array<Skill>,
        public slug_short: string,
        public slug: string,
        public soft_skills: Array<Skill>,
        public start_date: Date,
        public status: JobStatus,
        public title: string,
        public type: JobType,
        public user_state: JobUserState,
        public location?: Location | any,
        public experience?: JobExperienceLevel,
        public address?: string,
        public annual_salary?: string,
        public building_name?: string,
        public building_slug?: string,
        public city?: string,
        public country?: string,
        public company_description?: string,
        public created_at?: Date,
        public deleted_at?: Date,
        public education?: string, // TODO: Make this a selectable option and model
        public floor_name?: string,
        public floor_slug?: string,
        public qualifications_html?: string,
        public required_skills_html?: string,
        public seo?: SEO,
        public soft_skills_html?: string,
        public state?: string,
        public store_color?: string,
        public store_logo?: Media,
        public store_name?: string,
        public store_slug?: string,
        public store_id?: number,
        public store?: Organization,
        public updated_at?: Date,
        public title_truncated?: string,
        public reference_number?: string,
        public visa?: boolean,
        public applicants?: number,
        public job_education_id?: number,
        public currency_type_id?: number,
        public external_link?: string,
        public use_external_link?: boolean,
        public json_ld?: any,
        public video_collection_id?: number,
        public has_video_collection?: boolean,
        public verified?: boolean,
        public video_only?: boolean,
        public video_guidelines?: string,
        public benefits?: Array<Benefit>,
        public supplemental_compensation?: Array<SupplementalCompensation>,
        public questionnaire_id?: number,
        public hybrid?: boolean,
        public tier_id?: number,
        public renewed_at?: Date,
        public salary_estimate?: string
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class JobAdapter implements Adapter<Job> {
    constructor(
        private _supplementalCompensationAdapter: SupplementalCompensationAdapter,
        private _benefitAdapter: BenefitAdapter,
        private _skillAdapter: SkillAdapter,
        private _jobQualificationAdapter: JobQualificationAdapter,
        private _jobExperienceLevelAdapter: JobExperienceLevelAdapter,
        private _locationAdapter: LocationAdapter,
        private _jobTypeAdapter: JobTypeAdapter,
        private _jobUserStateAdapter: JobUserStateAdapter,
        private _languageAdapter: LanguageAdapter,
        private _jobInformationAdapter: JobInformationAdapter,
        private _jobCompensationAdapter: JobCompensationAdapter,
        private _seoAdapter: SEOAdapter,
        private _mediaAdapter: MediaAdapter,
        private _organizationAdapter: OrganizationAdapter
    ) {}
    adapt(item: any): Job {
        return new Job(
            item.id,
            this._jobCompensationAdapter.adapt(item),
            item.draft,
            item.end_date ? dayjs(item.end_date).toDate() : null,
            this._jobInformationAdapter.adapt(item),
            item.qualifications
                ? item.qualifications.map((qual: any) =>
                      this._jobQualificationAdapter.adapt(qual)
                  )
                : [],
            item.functions,
            item.remote,
            (item.required_languages || []).map((lang: any) => {
                return this._languageAdapter.adapt(lang);
            }),
            item.required_skills.map((skill: any) =>
                this._skillAdapter.adapt(skill)
            ),
            item.slug_short,
            item.slug,
            item.soft_skills.map((skill: any) =>
                this._skillAdapter.adapt(skill)
            ),
            item.start_date ? dayjs(item.start_date).toDate() : null,
            item.status,
            item.title,
            this._jobTypeAdapter.adapt(item.job_type),
            this._jobUserStateAdapter.adapt(item),
            item.location_data
                ? this._locationAdapter.adapt(item.location_data)
                : null,
            this._jobExperienceLevelAdapter.adapt(item.experience_level),
            item.address,
            item.annual_salary,
            item.building_name,
            item.building_slug,
            item.city,
            item.country,
            item.company_description,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null,
            item.education,
            item.floor_name,
            item.floor_slug,
            map(item.soft_skills, 'name').join('<span>&middot;</span>'),
            map(item.required_skills, 'name').join('<span>&middot;</span>'),
            item.seo ? this._seoAdapter.adapt(item.seo) : null,
            map(item.required_skills, 'name').join('<span>&middot;</span>'),
            item.state,
            item.store_color ||
                (item.store ? item.store.color : null) ||
                item.color,
            item.store_logo ? this._mediaAdapter.adapt(item.store_logo) : null,
            item.store_name,
            item.store_slug,
            item.store_id,
            item.store ? this._organizationAdapter.adapt(item.store) : null,
            item.updated_at ? new Date(item.updated_at) : null,
            item.title.length > 24
                ? item.title.substring(0, 30).trim() + '...'
                : null,
            item.reference_number,
            item.visa,
            item.applicants,
            item.job_education_id,
            item.currency_type_id,
            item.external_link,
            item.use_external_link,
            item.json_ld,
            item.video_collection_id,
            item.has_video_collection,
            item.verified,
            item.video_only,
            item.video_guidelines,
            item.benefits
                ? item.benefits.map((b: any) => this._benefitAdapter.adapt(b))
                : [],
            item.supplemental_compensation
                ? item.supplemental_compensation.map((b: any) =>
                      this._supplementalCompensationAdapter.adapt(b)
                  )
                : [],
            item.questionnaire_id,
            item.hybrid,
            item.tier_id,
            item.renewed_at ? dayjs(item.renewed_at).toDate() : null,
            item.salary_estimate
        );
    }

    adaptFromSearch(item: any): Job {
        return new Job(
            item.id,
            this._jobCompensationAdapter.adapt(item),
            item.draft,
            item.end_date ? dayjs(item.end_date).toDate() : null,
            this._jobInformationAdapter.adapt(item),
            [],
            item.functions,
            item.remote,
            (item.required_languages || []).map((lang: string) => {
                return this._languageAdapter.adapt({ name: lang });
            }),
            item.required_skills.map((skill: string) =>
                this._skillAdapter.adapt({
                    name: skill,
                    type: SkillType.Functional
                })
            ),
            item.slug_short,
            item.slug,
            item.soft_skills.map((skill: any) =>
                this._skillAdapter.adapt({
                    name: skill,
                    type: SkillType.Soft
                })
            ),
            item.start_date ? dayjs(item.start_date).toDate() : null,
            item.status,
            item.title,
            this._jobTypeAdapter.adapt(item.job_type),
            this._jobUserStateAdapter.adapt(item),
            item.location_data
                ? this._locationAdapter.adapt(item.location_data)
                : null,
            this._jobExperienceLevelAdapter.adapt(item.experience_level),
            item.address,
            item.annual_salary,
            item.building_name,
            item.building_slug,
            item.city,
            item.country,
            item.company_description,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null,
            item.education,
            item.floor_name,
            item.floor_slug,
            map(item.soft_skills, 'name').join('<span>&middot;</span>'),
            map(item.required_skills, 'name').join('<span>&middot;</span>'),
            item.seo ? this._seoAdapter.adapt(item.seo) : null,
            map(item.required_skills, 'name').join('<span>&middot;</span>'),
            item.state,
            item.store_color ||
                (item.store ? item.store.color : null) ||
                item.color,
            item.store_logo ? this._mediaAdapter.adapt(item.store_logo) : null,
            item.store_name,
            item.store_slug,
            item.store_id,
            item.store ? this._organizationAdapter.adapt(item.store) : null,
            item.updated_at ? new Date(item.updated_at) : null,
            item.title.length > 24
                ? item.title.substring(0, 30).trim() + '...'
                : null,
            item.reference_number,
            item.visa,
            item.applicants,
            item.job_education_id,
            item.currency_type_id,
            item.external_link,
            item.use_external_link,
            item.json_ld,
            item.video_collection_id,
            item.has_video_collection,
            item.verified,
            item.video_only,
            item.video_guidelines,
            item.benefits
                ? item.benefits.map((b: any) => this._benefitAdapter.adapt(b))
                : [],
            item.supplemental_compensation
                ? item.supplemental_compensation.map((b: any) =>
                      this._supplementalCompensationAdapter.adapt(b)
                  )
                : [],
            item.questionnaire_id,
            item.hybrid,
            item.tier_id,
            item.renewed_at ? dayjs(item.renewed_at).toDate() : null,
            item.salary_estimate
        );
    }

    anon(item: Job): any {
        return {
            id: item.id,
            title: item.title,
            status: item.status,
            compensation_custom: item.compensation.label,
            compensation_option_id: item.compensation.option_id,
            compensation_type_id: item.compensation.type_id,
            compensation: item.compensation.min,
            compensation_max: item.compensation.max,
            currency_type_id: item.currency_type_id,
            job_experience_id: item.experience.id,
            job_education_id: item.job_education_id,
            draft: item.draft,
            end_date: item.end_date.toUTCString(),
            end_date_utc: item.end_date.toUTCString(),
            start_date: item.start_date.toUTCString(),
            start_date_utc: item.start_date.toUTCString(),
            information: item.information.text,
            information_markdown: item.information.markdown,
            required_languages: item.required_languages.map(
                (item: Language) => {
                    return {
                        id: item.id,
                        name: item.name,
                        'iso_639-1': item.code
                    };
                }
            ),
            required_skills: item.required_skills.map((skill: Skill) => {
                return {
                    id: skill.id,
                    name: skill.name,
                    type: skill.type,
                    source: skill.source,
                    custom: skill.custom,
                    pivot: skill.pivot
                };
            }),
            soft_skills: item.soft_skills.map((skill: Skill) => {
                return {
                    id: skill.id,
                    name: skill.name,
                    type: skill.type,
                    source: skill.source,
                    custom: skill.custom,
                    pivot: skill.pivot
                };
            }),
            qualifications: item.qualifications.map(
                (qual: JobQualification) => {
                    return {
                        id: qual.id,
                        name: qual.name,
                        pivot: qual.pivot
                    };
                }
            ),
            functions: item.functions.map((func: JobFunction) => {
                return {
                    id: func.id,
                    name: func.name,
                    pivot: func.pivot
                };
            }),
            job_type_id: item.type.id,
            slug: item.slug,
            slug_short: item.slug_short,
            location_details: item.location as any,
            address: item.address,
            lat: item.location ? item.location.latitude : null,
            lon: item.location ? item.location.longitude : null,
            state: item.state,
            visa: item.visa,
            remote: item.remote,
            reference_number: item.reference_number,
            store_id: item.store ? item.store.id : item.store_id,
            applicants: item.applicants,
            external_link: item.external_link,
            use_external_link: item.use_external_link,
            video_collection_id: item.video_collection_id,
            video_only: item.video_only,
            video_guidelines: item.video_guidelines,
            benefits: item.benefits,
            supplemental_compensation: item.supplemental_compensation,
            questionnaire_id: item.questionnaire_id,
            hybrid: item.hybrid,
            salary_estimate: item.salary_estimate
        };
    }
}

export enum JobStatus {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
    EXPIRED = 'expired',
    DRAFT = 'draft'
}

export class JobInformation {
    constructor(public text?: string, public markdown?: string) {}
}

@Injectable({
    providedIn: 'root'
})
export class JobInformationAdapter implements Adapter<JobInformation> {
    adapt(item: any): JobInformation {
        return new JobInformation(item.information, item.information_markdown);
    }
}

export class JobUserState {
    constructor(
        public applied: boolean,
        public saved: boolean,
        public following: boolean
    ) {}
}
@Injectable({
    providedIn: 'root'
})
export class JobUserStateAdapter implements Adapter<JobUserState> {
    adapt(item: any): JobUserState {
        return new JobUserState(item.applied, item.saved, item.following);
    }
}

export class JobCompensation {
    constructor(
        public label: string,
        public option_id?: number,
        public type_id?: number,
        public min?: number,
        public max?: number
    ) {}
}
@Injectable({
    providedIn: 'root'
})
export class JobCompensationAdapter implements Adapter<JobCompensation> {
    adapt(item: any): JobCompensation {
        return new JobCompensation(
            item.compensation_custom,
            item.compensation_option_id,
            item.compensation_type_id,
            item.compensation,
            item.compensation_max
        );
    }
}

export class JobExperienceLevel {
    constructor(public id: number, public name: string) {}
}
@Injectable({
    providedIn: 'root'
})
export class JobExperienceLevelAdapter implements Adapter<JobExperienceLevel> {
    adapt(item: any): JobExperienceLevel {
        return new JobExperienceLevel(item.id, item.name);
    }
}

export class JobType {
    constructor(public id: number, public name: string) {}
}
@Injectable({
    providedIn: 'root'
})
export class JobTypeAdapter implements Adapter<JobType> {
    adapt(item: any): JobType {
        return new JobType(item.id, item.name);
    }
}

export class JobFunction {
    constructor(public id: number, public name: string, public pivot?: any) {}
}
@Injectable({
    providedIn: 'root'
})
export class JobFunctionAdapter implements Adapter<JobFunction> {
    adapt(item: any): JobFunction {
        return new JobFunction(item.id, item.name, item.pivot);
    }
}

export class JobQualification {
    constructor(public id: number, public name: string, public pivot?: any) {}
}
@Injectable({
    providedIn: 'root'
})
export class JobQualificationAdapter implements Adapter<JobQualification> {
    adapt(item: any): JobQualification {
        return new JobQualification(item.id, item.name, item.pivot);
    }
}
