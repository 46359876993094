import { Inject, Injectable } from '@angular/core';
import {
    Action,
    NgxsAfterBootstrap,
    Selector,
    State,
    StateContext,
    Store
} from '@ngxs/store';
import { tap, map } from 'rxjs/operators';
import { UserStateModel } from './model';
import { UserService } from '../user.service';
import {
    RefreshUser,
    UpdateExternals,
    RefreshOrganizations,
    RefreshUserOnboarding,
    InitWebsocketConnection,
    DestroyWebsocketConnection,
    CreateOrUpdateUserIntegration,
    UserIntegrationFailed,
    TrackDevice
} from './actions';
import { User } from '../../models/user.model';
import { Organization } from '../../models/organization.model';
import {
    ChangeLanguage,
    SetBrowserLanguage
} from '@jobzmall/language/ngxs/actions';
import { Observable, of, first, firstValueFrom } from 'rxjs';
import { NotificationState } from '../../notifications/ngxs/state';
import {
    WEBSOCKET_SERVICE,
    WebsocketService
} from '@jobzmall/core/websocket/websocket.service';
import {
    GetUnreadCount,
    SubscribeToWebPushNotifications
} from '../../notifications/ngxs/actions';
import { StateReset } from 'ngxs-reset-plugin';
import {
    MESSAGE_SERVICE,
    MessageService
} from '@jobzmall/messages/message.service';
import { Logout } from '@jobzmall/core/auth/ngxs/actions';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';
import { GetUnread, GetRequestsCount } from '../../messages/app/ngxs/actions';
import { Integration } from '@jobzmall/integrations/integration.type';
import {
    RefreshUserIntegrations,
    RemoveUserIntegration,
    UserIntegrationSuccess
} from './actions';
import { IntegrationsService } from '../../integrations/integrations.service';
import {
    insertItem,
    patch,
    updateItem,
    removeItem
} from '@ngxs/store/operators';
import { Restangular } from '@jobzmall/core/api';

@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: undefined,
        integrations: [],
        organizations: [],
        organizationsInitialized: false,
        needsOnboarding: false,
        initialized: false
    }
})
@Injectable()
export class UserState implements NgxsAfterBootstrap {
    constructor(
        @Inject(MESSAGE_SERVICE) private _messageService: MessageService,
        @Inject(WEBSOCKET_SERVICE) private _websocket: WebsocketService,
        private _integrations: IntegrationsService,
        private _user: UserService,
        private _store: Store,
        private _api: Restangular
    ) {}

    @Selector()
    static user(state: UserStateModel): User | null {
        return state.user;
    }

    @Selector()
    static needsOnboarding(state: UserStateModel): boolean {
        return state.needsOnboarding;
    }

    @Selector()
    static organizations(state: UserStateModel): Array<Organization> {
        return state.organizations;
    }

    @Selector()
    static organizationsInitialized(state: UserStateModel): boolean {
        return state.organizationsInitialized;
    }

    @Selector()
    static initialized(state: UserStateModel): boolean {
        return state.initialized;
    }

    @Selector()
    static integrations(state: UserStateModel): Array<Integration> {
        return state.integrations;
    }

    ngxsAfterBootstrap(ctx: StateContext<UserStateModel>) {
        ctx.dispatch(new RefreshUser());
    }

    @Action(RefreshUser)
    refreshUser(ctx: StateContext<UserStateModel>) {
        return this._user.user().pipe(
            tap((data: User) => {
                ctx.patchState({
                    user: data
                });

                if (data) {
                    ctx.dispatch(new ChangeLanguage(data.language_id));
                    ctx.dispatch(new InitWebsocketConnection());
                    // this._store.dispatch(new SubscribeToWebPushNotifications());
                } else {
                    ctx.dispatch(new SetBrowserLanguage());
                    ctx.dispatch(new DestroyWebsocketConnection());
                    if (this._store.selectSnapshot(AuthState.isAuthenticated)) {
                        ctx.dispatch(new Logout());
                    }
                }
                ctx.dispatch(new RefreshUserIntegrations());
                ctx.dispatch(new RefreshUserOnboarding());
                ctx.dispatch(new RefreshOrganizations());
                ctx.dispatch(new UpdateExternals());
                ctx.patchState({
                    initialized: true
                });
            })
        );
    }

    @Action(RefreshUserIntegrations)
    refreshUserIntegrations(ctx: StateContext<UserStateModel>) {
        if (this._store.selectSnapshot(AuthState.isAuthenticated)) {
            this._integrations
                .getIntegrations()
                .pipe(first())
                .subscribe((value: Array<Integration>) => {
                    ctx.patchState({
                        integrations: value
                    });
                });
        } else {
            ctx.patchState({
                integrations: []
            });
        }
    }

    @Action(TrackDevice)
    trackDevice(ctx: StateContext<UserStateModel>) {
        return firstValueFrom<boolean>(
            this._api
                .all('d')
                .customPOST()
                .pipe(
                    first(),
                    map((res: any) => res.data)
                )
        );
    }

    @Action(RemoveUserIntegration)
    removeUserIntegration(
        ctx: StateContext<UserStateModel>,
        action: CreateOrUpdateUserIntegration
    ) {
        return this._integrations
            .removeIntegration(action.id)
            .pipe(first())
            .subscribe((value: Integration) => {
                ctx.setState(
                    patch({
                        integrations: removeItem<Integration>(
                            (v) => v.id === value.id
                        )
                    })
                );
            });
    }

    @Action(CreateOrUpdateUserIntegration)
    createOrUpdateIntegration(
        ctx: StateContext<UserStateModel>,
        action: CreateOrUpdateUserIntegration
    ) {
        return this._integrations.auth(action.id).then(
            (value: Integration) => {
                let exists = ctx
                    .getState()
                    .integrations.find((v) => v.id === value.id);
                if (exists) {
                    ctx.setState(
                        patch({
                            integrations: updateItem<any>(
                                (r) => r.id == value.id,
                                value
                            )
                        })
                    );
                } else {
                    ctx.setState(
                        patch({
                            integrations: insertItem<Integration>(value)
                        })
                    );
                }
                this._store.dispatch(new UserIntegrationSuccess(action.id));
            },
            () => {
                this._store.dispatch(new UserIntegrationFailed(action.id));
            }
        );
    }

    @Action(InitWebsocketConnection)
    initWebsocket(ctx: StateContext<UserStateModel>) {
        const user = ctx.getState().user;
        if (!this._websocket.websocket) {
            this._websocket.init(user);
            this._messageService.init();
        }
        ctx.dispatch(new GetRequestsCount());
        ctx.dispatch(new GetUnreadCount());
        ctx.dispatch(new GetUnread());
    }

    @Action(DestroyWebsocketConnection)
    destroyWebsocket(ctx: StateContext<UserStateModel>) {
        this._websocket.destroy();
        ctx.dispatch(new StateReset(NotificationState));
    }

    @Action(RefreshOrganizations)
    refreshOrganizations(
        ctx: StateContext<UserStateModel>
    ): Observable<Array<Organization>> {
        ctx.patchState({
            organizationsInitialized: false
        });
        if (this._store.selectSnapshot(AuthState.isAuthenticated)) {
            return this._user.stores().pipe(
                tap(
                    (data: Array<Organization>) => {
                        ctx.patchState({
                            organizations: data,
                            organizationsInitialized: true
                        });
                    },
                    () => {
                        ctx.patchState({
                            organizations: [],
                            organizationsInitialized: true
                        });
                    }
                )
            );
        } else {
            ctx.patchState({
                organizations: [],
                organizationsInitialized: true
            });
        }
    }

    @Action(RefreshUserOnboarding)
    refreshUserOnboarding(ctx: StateContext<UserStateModel>) {
        const user = ctx.getState().user;
        if (user) {
            return this._user.getUserOnboarding(user.id).pipe(
                tap((data: any) => {
                    ctx.patchState({
                        needsOnboarding: data !== undefined
                    });
                })
            );
        } else {
            ctx.patchState({
                needsOnboarding: false
            });
            return of(undefined);
        }
    }

    @Action(UpdateExternals)
    updateExternals(
        ctx: StateContext<UserStateModel>,
        action: UpdateExternals
    ) {
        return this._user.updateExternals(ctx.getState().user);
    }
}
