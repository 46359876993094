import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export class SupplementalCompensation {
    constructor(public id?: number, public name?: string) {}
}

@Injectable({
    providedIn: 'root'
})
export class SupplementalCompensationAdapter
    implements Adapter<SupplementalCompensation>
{
    adapt(item: any): SupplementalCompensation {
        return new SupplementalCompensation(item.id, item.name);
    }
}
