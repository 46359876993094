import * as remark from "remark";
import * as strip from "strip-markdown";
import { BehaviorSubject } from "rxjs";
import { startWith, distinctUntilChanged } from 'rxjs/operators';

export function stripMarkdown(markdown: string) {
    let result = new BehaviorSubject<any>(null);

    remark()
        .use(strip)
        .process(markdown, (err: any, file: any) => {
            if (!err) {
                result.next(file.contents);
            } else {
                result.next("");
            }
        });

    return result.pipe(startWith(null), distinctUntilChanged());
}
