// Import the core angular services.
import { Pipe, TrackByFunction } from '@angular/core';
import { PipeTransform } from '@angular/core';

interface TrackByFunctionCache {
    [propertyName: string]: <T>(index: number, item: T) => any;
}

// Since the resultant TrackBy functions are based purely on a static property name, we
// can cache these Functions across the entire app. No need to generate more than one
// Function for the same property.
let cache: TrackByFunctionCache = Object.create(null);

@Pipe({
    name: 'trackByProperty',
    pure: true
})
export class TrackByPropertyPipe implements PipeTransform {

    // I return a TrackBy function that plucks the given property from the ngFor item.
    transform(propertyName: string): TrackByFunction<any> {

        // Ensure cached function exists.
        if (!cache[propertyName]) {

            cache[propertyName] = function trackByProperty<T>(index: number, item: T): any {
                if (!item) { return null; }

                return item[propertyName] || index;
            };

        }

        return (cache[propertyName]);

    }

}
