import { Pipe, PipeTransform } from '@angular/core';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { xor } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Pipe({
    name: 'mediaActive'
})
export class MediaActivePipe implements PipeTransform {
    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService) {}

    transform(matcher: string | Array<string>): Observable<boolean> {
        return this._fuseMediaWatcherService.onMediaChange$.pipe(
            map((({ matchingAliases }) => {
                return matcher instanceof Array<string> ? matchingAliases.filter(e => matcher.includes(e)).length > 0 : matchingAliases.includes(matcher)
            })
        ))
    }
}
