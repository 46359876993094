

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthState, buildFormData } from '@jobzmall/core';
import { Store } from '@ngxs/store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Upload, upload } from './upload';

@Injectable({ providedIn: 'root' })
export class UploadService {

    constructor(private _store: Store, private http: HttpClient) { }

    upload(key: string = 'file', url: string, file: File, metadata: object = undefined): Observable<Upload> {

        const token = this._store.selectSnapshot<string>(AuthState.token);

        let headerValues = {
            'ngsw-bypass': 'true',
            Accept: "application/x.laravel.v1+json",
        }
        if (headerValues) {
            headerValues['Authorization'] = `Bearer ${token}`;
        }

        const headers = new HttpHeaders(headerValues);


        const data = new FormData();
        if (metadata) {
            buildFormData(data, metadata)
        }
        data.append(key, file);

        return this.http
            .post(environment.apiRoot + url, data, {
                headers,
                reportProgress: true,
                observe: 'events',
                withCredentials: true
            })
            .pipe(upload());
    }
}
