<div *ngIf="init" fxLayout="column" fxLayoutAlign="center center" class="relative video-wrapper">
    <div class="relative bg-black top-1 aspect-w-16 aspect-h-9"></div>
    <div *ngIf="media.explicit_label" class="absolute top-0 right-0 w-full h-full z-1">
        <img class="absolute inset-0 object-cover w-full h-full" src="https://source.unsplash.com/800x600/?video">

        <div
            class="flex flex-col items-center justify-center w-full h-full bg-black rounded-md backdrop-filter backdrop-blur-3xl backdrop-saturate-150 bg-opacity-40">
            <span class="p-2 text-xl text-center text-white">This content has been flagged for removal as it doesn't
                meet our community guidelines</span>
        </div>
    </div>
    <div *ngIf="(!media.available && !usePendingIfUnavailable) && !media.explicit_label" class="absolute top-0 right-0 w-full h-full z-1">
        <img class="absolute inset-0 object-cover w-full h-full" src="https://source.unsplash.com/800x600/?video">
        <div
            class="flex flex-col items-center justify-center w-full h-full bg-black rounded-md backdrop-filter backdrop-blur-3xl backdrop-saturate-150 bg-opacity-40">
            <span class="p-2 text-xl text-center text-white">This video will be available soon</span>
            <mat-progress-spinner class="mt-3" [diameter]="24" [mode]="'indeterminate'">
            </mat-progress-spinner>
        </div>
    </div>
    <plyr *ngIf="!media.external && (media.available || usePendingIfUnavailable)" [plyrPlaysInline]="playsInline"
        [plyrPoster]="media.thumbnail ? media.thumbnail.full_path : media.generated_thumbnail"
        [plyrOptions]="plyrOptions" (plyrPlay)="media.id && view.log('media', media.id)"
        (plyrCanPlay)="autoplay && !media.explicit_label && plyrComponent.play();"
        class="absolute top-0 left-0 block w-full h-full min-w-full m-h-full" (plyrInit)="plyrComponent = $event;"
        [plyrSources]="[
            {
                src: media.available ?
                media.full_path : media.temp_path,
                type: 'video/mp4',
                size: 640,
                provider: media.external ? media.external_type : undefined
            }
        ]">
    </plyr>
    <lib-ngx-responsive-embed *ngIf="media.external && youtubeId" class="absolute top-0 left-0 w-full h-full">
        <iframe [src]="'https://www.youtube.com/embed/' + youtubeId | safe: 'resourceUrl'" frameborder="0"
            allowfullscreen></iframe>
    </lib-ngx-responsive-embed>
    <lib-ngx-responsive-embed *ngIf="media.external && vimeoId" class="absolute top-0 left-0 w-full h-full">
        <iframe [src]="'https://player.vimeo.com/video/' + vimeoId | safe: 'resourceUrl'" frameborder="0"
            allowfullscreen></iframe>
    </lib-ngx-responsive-embed>
</div>
