<div class="flex flex-col w-full max-w-120">
    <p class="overflow-hidden leading-5 break-words whitespace-pre-wrap" *ngIf="body" watchInnerHtml
        (innerHtmlRendered)="onInnerHtmlRender()" [innerHTML]="body | moveLinksToEnd | linky"></p>

    <!-- Preview Card -->
    <div (click)="openUrl(og.url)" *ngIf="og || loading" class="mt-4 mb-4 cursor-pointer">
        <div *ngIf="loading" class="flex flex-row justify-center m-3 item-center">
            <mat-spinner color="accent" [diameter]="45"></mat-spinner>
        </div>
        <div
            class="flex flex-col overflow-hidden text-black bg-white border rounded dark:border-0 dark:bg-gray-800 dark:text-white">
            <div *ngIf="og?.image" class="flex h-80">
                <img   class="object-cover" [src]="og.image" alt="Card cover image">
            </div>
            <div *ngIf="og && !og?.image.indexOf('.gif')" class="m-4">
                <div class="text-lg font-medium">{{og.title}}</div>
                <div class="mt-1 text-secondary">
                    {{og.description}}
                </div>
                <div class="mt-2 text-sm text-hint">{{og.url}}</div>
            </div>
        </div>
    </div>
    <lightgallery *ngIf="youtubeEmbedUrl" [settings]="settings" class="flex flex-1 p-1 space-y-2 rounded">
        <a class="w-full h-full my-2 gallery-item" [href]="youtubeEmbedUrl">
            <img   class="object-cover w-full h-full rounded"
                [src]="'https://img.youtube.com/vi/' + youtubeId + '/maxresdefault.jpg'" />
        </a>
    </lightgallery>
    <lightgallery *ngIf="vimeoEmbedUrl" [settings]="settings" class="flex flex-1 p-1 space-y-2 rounded">
        <a class="w-full h-full my-2 gallery-item" [href]="vimeoEmbedUrl">
            <img   class="object-cover w-full h-full rounded" [src]="vimeoThumbnailUrl | async" />
        </a>
    </lightgallery>
</div>
