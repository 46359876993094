import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { bootloader } from '@angularclass/bootloader';
import { AppModule } from './app/app.module';
import { jbzInjector } from '@jobzmall/injector/injector';
import { datadogRum } from '@datadog/browser-rum';

if (environment.env !== 'local') {
    datadogRum.init({
        applicationId: environment.datadogApplicationId,
        clientToken: environment.datadogClientToken,
        site: 'datadoghq.com',
        service: environment.datadogService,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        env: environment.env,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [environment.apiRoot.replace('/api/', '')]
    });
}

if (environment.production) {
    enableProdMode();
}

const main = () =>
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((appRef: { injector: Injector }) => jbzInjector(appRef.injector))
        .catch((err) => console.error(err));

bootloader(main);
