import { Directive, Input, forwardRef } from '@angular/core';
import {
    ValidationErrors,
    AsyncValidator,
    AbstractControl,
    NG_ASYNC_VALIDATORS
} from '@angular/forms';

import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Restangular } from '../../core/api/ngx-restangular';

@Directive({
    selector: '[uniqueSlug]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => UniqueSlugDirective),
            multi: true
        }
    ]
})
export class UniqueSlugDirective implements AsyncValidator {
    @Input('uniqueSlugReverse') reverse = false;
    @Input('uniqueSlugEntity') entity = 'user';

    @Input('uniqueSlugOriginalValue') originalValue: string;

    constructor(private _api: Restangular) {}

    ngOnInit(): void {}

    ngOnDestroy() {}

    validate(c: AbstractControl): Observable<ValidationErrors> {
        // Here we call our static validator function
        return this.validateSlug(c);
    }

    validateSlug(control: AbstractControl): Observable<ValidationErrors> {
        if (control.value == this.originalValue) {
            return of({});
        }

        return timer(200).pipe(
            switchMap(() =>
                this._api
                    .all(this.entity)
                    .customPOST({ slug: control.value }, 'checkusername')
                    .pipe(
                        map((res: any) => {
                            if (this.reverse) {
                                return { slugNotExists: true };
                            } else {
                                return {};
                            }
                        }),
                        catchError((err: any) => {
                            if (this.reverse) {
                                return of({});
                            } else {
                                return of({ slugTaken: true });
                            }
                        })
                    )
            )
        );
    }
}
