import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ViewChild,
    TemplateRef,
    ViewContainerRef,
    ElementRef,
    Input
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Select } from '@ngxs/store';
import { AuthState } from '@jobzmall/core/auth/ngxs/state';
import { Observable } from 'rxjs';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AppState } from '@jobzmall/state/ngxs/state';
import { UserState } from '@jobzmall/user';
import { Organization, User } from '@jobzmall/models';
import { ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'jm-header',
    templateUrl: './header.component.html',
    styles: [
        `
            jm-header {
                @keyframes animateOut {
                    from {
                        transform: rotateX(0);
                        opacity: 1;
                    }
                    to {
                        transform: rotateX(-15deg);
                        opacity: 0;
                    }
                }
                @keyframes animateIn {
                    from {
                        transform: rotateX(-15deg);
                        opacity: 0;
                    }
                    to {
                        transform: rotateX(0deg);
                        opacity: 1;
                    }
                }
                /* .animating-out {
                    animation-name: animateOut;
                    animation-duration: 300ms;
                    animation-fill-mode: forwards;
                } */
                /* .header-panel-wrapper {
                    animation-name: animateIn;
                    animation-duration: 300ms;
                    animation-fill-mode: forwards;
                } */
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ transform: 'rotateX(-15deg)', opacity: '0' }),
                animate(
                    '300ms ease-out',
                    style({ transform: 'rotateX(0deg)', opacity: '1' })
                )
            ]),
            transition(':leave', [
                style({ transform: 'rotateX(0deg)', opacity: '1' }),
                animate(
                    '300ms ease-out',
                    style({ transform: 'rotateX(-15deg)', opacity: '0' })
                )
            ])
        ]),
        ...fuseAnimations
    ]
})
export class JMHeaderComponent extends JMBaseComponent implements OnInit {
    @Select(AppState.loading) appLoading$: Observable<boolean>;

    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
    @Select(UserState.user) user$: Observable<User>;
    @Select(UserState.organizations) organizations$: Observable<
        Array<Organization>
    >;

    @ViewChild('careerSeekersOrigin') private _careerSeekersOrigin: ElementRef;
    @ViewChild('careerSeekersPanel')
    private _careerSeekersPanel: TemplateRef<any>;

    @ViewChild('recruitersOrigin') private _recruitersOrigin: ElementRef;
    @ViewChild('recruitersPanel') private _recruitersPanel: TemplateRef<any>;

    @ViewChild('organizationsOrigin') private _organizationsOrigin: ElementRef;
    @ViewChild('organizationsPanel')
    private _organizationsPanel: TemplateRef<any>;

    @Input() transparent = false;
    @Input() showLogo = false;
    @Input() showOmni = false;
    @Input() absolute = false;
    @Input() showLanguageSwitcher = true;

    activeIndices: Array<any> = [];
    animatingOutTimeout;
    animatingOut: boolean;

    showMorePanel = false;

    currentIndex: number;

    panels = [
        { name: 'PUBLIC.HEADER.PANEL.CAREER_SEEKERS.TITLE' },
        { name: 'PUBLIC.HEADER.PANEL.RECRUITERS.TITLE' }
    ];

    panelsAuthenticated = [{ name: 'My Organizations' }, { name: 'Status' }];

    private _overlayRef: OverlayRef;
    prevIndex: number;
    user: User;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.sink = this.user$.subscribe((user: User) => {
            this.user = cloneDeep(user);
            if (user) {
                if (user.is_hiring) {
                    this.panelsAuthenticated = [{ name: 'My Organizations' }];
                } else {
                    this.panelsAuthenticated = [{ name: 'Status' }];
                }
            }
            this._changeDetectorRef.markForCheck();
        });

        this.currentIndex = this.activeIndices[this.activeIndices.length - 1];
        this.prevIndex =
            this.activeIndices.length > 1 &&
            this.activeIndices[this.activeIndices.length - 2];
    }

    resetDropdownState = (i) => {
        this.activeIndices = typeof i === 'number' ? [i] : [];
        this.animatingOut = false;
        delete this.animatingOutTimeout;
        this.currentIndex = this.activeIndices[this.activeIndices.length - 1];
        this.prevIndex =
            this.activeIndices.length > 1 &&
            this.activeIndices[this.activeIndices.length - 2];
        this._changeDetectorRef.markForCheck();
    };

    onMouseEnter = (i) => {
        if (this.animatingOutTimeout) {
            clearTimeout(this.animatingOutTimeout);
            this.resetDropdownState(i);
            return;
        }
        if (this.activeIndices[this.activeIndices.length - 1] === i) return;

        this.activeIndices = this.activeIndices.concat(i);
        this.animatingOut = false;
        this.currentIndex = this.activeIndices[this.activeIndices.length - 1];
        this.prevIndex =
            this.activeIndices.length > 1 &&
            this.activeIndices[this.activeIndices.length - 2];

        this._changeDetectorRef.markForCheck();
    };

    onMouseLeave = () => {
        this.animatingOut = true;
        this.animatingOutTimeout = setTimeout(this.resetDropdownState, 300);
        this.activeIndices = [];
        this.currentIndex = this.activeIndices[this.activeIndices.length - 1];
        this.prevIndex =
            this.activeIndices.length > 1 &&
            this.activeIndices[this.activeIndices.length - 2];
        this._changeDetectorRef.markForCheck();
    };

    onJobSeekerStatusChanged(status: string) {
        this.user.jobseeker_status = status;
        this.animatingOut = true;
        this.animatingOutTimeout = setTimeout(this.resetDropdownState, 300);
        this.activeIndices = [];
        this.currentIndex = this.activeIndices[this.activeIndices.length - 1];
        this.prevIndex =
            this.activeIndices.length > 1 &&
            this.activeIndices[this.activeIndices.length - 2];
        this._changeDetectorRef.markForCheck();
    }

    openOrganizationsPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._organizationsOrigin || !this._organizationsPanel) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay(this._organizationsOrigin);
        } else {
            this._overlayRef.dispose();
            this._createOverlay(this._organizationsOrigin);
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._organizationsPanel, this._viewContainerRef)
        );
    }

    openRecruitersPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._recruitersOrigin || !this._recruitersPanel) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay(this._recruitersOrigin);
        } else {
            this._overlayRef.dispose();
            this._createOverlay(this._recruitersOrigin);
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._recruitersPanel, this._viewContainerRef)
        );
    }

    openCareerSeekersPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._careerSeekersPanel || !this._careerSeekersOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay(this._careerSeekersOrigin);
        } else {
            this._overlayRef.dispose();
            this._createOverlay(this._careerSeekersOrigin);
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._careerSeekersPanel, this._viewContainerRef)
        );
    }

    closePanel() {
        if (this._overlayRef) {
            this._overlayRef.detach();
        }
    }

    // mouseLeaveHandler($event: any) {
    //     let element = $event.toElement as HTMLElement;
    //     while (element) {
    //         if (element.classList.contains('header-panel')) {
    //             return;
    //         }
    //         element = element.parentElement;
    //     }
    //     this._overlayRef.detach();
    // }

    private _createOverlay(origin: ElementRef): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: false,
            backdropClass: 'jbz-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(origin.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
