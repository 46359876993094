<!-- Button -->
<div [matMenuTriggerFor]="userActions" *ngIf="(user$ | async)" class="flex flex-row items-center cursor-pointer">
    <button mat-icon-button>
        <span class="relative">
            <div
                class="relative flex w-8 h-8 overflow-hidden text-gray-600 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-200 flex-0">
                <ng-container *ngIf="(user$ | async).image">
                    <img [attr.data-broken-text]="(user$ | async).name.charAt(0)"
                        class="object-scale-down w-full h-full rounded-full" [src]="(user$ | async).image.full_path"
                        [alt]="'Profile avatar'">
                </ng-container>
                <ng-container *ngIf="!(user$ | async).image">
                    <div
                        class="flex items-center justify-center w-full h-full overflow-hidden text-lg font-semibold text-center text-gray-600 uppercase bg-gray-200 rounded-full group-hover:bg-gray-400 dark:group-hover:bg-gray-900 dark:bg-gray-700 dark:text-gray-200">
                        {{(user$ | async).name.charAt(0)}}
                    </div>
                </ng-container>
            </div>
            <span class="absolute bottom-0 right-0 w-2 h-2 rounded-full"
                [ngClass]="{'mr-px mb-px': !showAvatar || !(user$ | async)?.image}"></span>
        </span>
    </button>
    <div *ngIf="showName" class="flex flex-col ml-2 text-md">
        <span>{{(user$ | async).name}}</span>
        <span class="text-secondary">{{(user$ | async).email}}</span>
    </div>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button [routerLink]="['/@' + (user$ | async)?.slug]" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button [routerLink]="['/user/settings']" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>
    <button mat-menu-item [routerLink]="['/help']">
        <mat-icon [svgIcon]="'heroicons_outline:speakerphone'"></mat-icon>
        <span>Help & Support</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="appearance">
        <mat-icon class="far fa-paint-brush icon-size-5"></mat-icon>
        <span>Appearance</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #appearance="matMenu">
    <button (click)="setAuto()" mat-menu-item class="flex items-center justify-start w-full p-0">
        <span class="w-2 h-full" [ngClass]="{'bg-primary-500': isAuto}"></span>
        <mat-icon class="ml-2 icon-size-5 far fa-magic"></mat-icon>
        <span>Automatic</span>
    </button>
    <button (click)="setLight()" mat-menu-item class="flex items-center justify-start w-full p-0">
        <span class="w-2 h-full" [ngClass]="{'bg-primary-500': currentScheme == 'light' && !isAuto}"></span>
        <mat-icon class="ml-2" [svgIcon]="'heroicons_outline:sun'"></mat-icon>
        <span>Light</span>
    </button>
    <button (click)="setDark()" mat-menu-item class="flex items-center justify-start w-full p-0">
        <span class="w-2 h-full" [ngClass]="{'bg-primary-500': currentScheme == 'dark' && !isAuto}"></span>
        <mat-icon class="ml-2" [svgIcon]="'heroicons_outline:moon'"></mat-icon>
        <span>Dark</span>
    </button>
</mat-menu>
