import { Injectable } from '@angular/core';
import { SEO, SEOAdapter } from './seo.model';
import { Adapter } from './adapter';

export class Building {
    constructor(
        public id: number,
        public name: string,
        public icon: string,
        public slug: string,
        public construction: boolean,
        public seo: SEO
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class BuildingAdapter implements Adapter<Building> {
    constructor(private _seoAdapter: SEOAdapter) {}
    adapt(item: any): Building {
        const building = new Building(
            item.id,
            item.name,
            item.icon,
            item.slug,
            item.construction || false,
            this._seoAdapter.adapt(item.seo)
        );
        if (item.name.toLowerCase() === 'freelance') item.construction = true;

        if (!item.icon)
            switch (building.name.toLowerCase()) {
                case 'education':
                    building.icon = 'school';
                    break;
                case 'freelance':
                    building.icon = 'fingerprint';
                    break;
                case 'government':
                    building.icon = 'account_balance';
                    break;
                case 'companies':
                    building.icon = 'business_center';
                    break;
                case 'non-profit':
                    building.icon = 'favorite';
                    break;
                case 'staffing':
                    building.icon = 'group_add';
                    break;
                case 'startup':
                    building.icon = 'cake';
                    break;
                default:
                    break;
            }

        return building;
    }
}
