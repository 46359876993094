import { Directive, ElementRef, Input, AfterViewChecked, AfterViewInit, Renderer2 } from '@angular/core';
import { decode } from "blurhash";

@Directive({
    selector: '[blurHash]',
})
export class BlurHashDirective implements AfterViewInit {
    @Input() blurHash: string;

    constructor(private el: ElementRef, private _renderer2: Renderer2) {

    }

    ngAfterViewInit(): void {
        const pixels = decode(this.blurHash, this.el.nativeElement.offsetWidth, this.el.nativeElement.offsetHeight);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const imageData = ctx.createImageData(this.el.nativeElement.offsetWidth, this.el.nativeElement.offsetHeight);
        imageData.data.set(pixels);
        ctx.putImageData(imageData, 0, 0);
        this._renderer2.setStyle(this.el.nativeElement, 'background-image', `url(${canvas.toDataURL()})`);
        canvas.remove();
    }
}
