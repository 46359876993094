import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseCardModule } from '@fuse/components/card';
import { FuseAlertModule } from '@fuse/components/alert';
import { SharedModule } from '../../shared.module';
import { NgxIntlTelInputModule } from '@jobzmall/telephone-input/ngx-intl-tel-input.module';
import { JMFormsModule } from '@jobzmall/forms/forms.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { UserDetailConfirmationOverlayComponent } from './user-detail-confirmation-overlay/user-detail-confirmation-overlay.component';
import { JMAuthComponentsModule } from '@jobzmall/auth/auth.module';
import { JMPhoneComponentsModule } from '@jobzmall/phone/components/phone-components.module';

const COMPONENTS = [UserDetailConfirmationOverlayComponent];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        JMSharedModule,
        MatDialogModule,
        TranslocoModule,
        JMPhoneComponentsModule,
        JMFormsModule,
        NgxIntlTelInputModule,
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        FuseCardModule,
        FuseAlertModule,
        SharedModule,
        JMAuthComponentsModule
    ],
    exports: COMPONENTS
})
export class SharedAuthComponentsModule {}
