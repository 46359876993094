import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@jobzmall/models';

@Pipe({
    name: 'locationFormat',
    pure: true
})
export class LocationPipe implements PipeTransform {
    transform(value: any): String {
        const loc = value as Location;
        switch (loc.country.code) {
            case 'us':
                return loc.city
                    ? `${loc.city.name}, ${loc.region.code.toUpperCase()}`
                    : loc.region ? `${loc.region.name}` : `${loc.country.name}`;
            case 'ca':
                return loc.city
                    ? `${loc.city.name}, ${loc.region.code.toUpperCase()}, ${
                          loc.country.name
                      }`
                    : loc.region.name
                    ? `${loc.region.code.toUpperCase()}, ${loc.country.name}`
                    : `${loc.country.name}`;
            default:
                return loc.city
                    ? `${loc.city.name}, ${loc.country.name}`
                    : `${loc.country.name}`;
        }
    }
}
