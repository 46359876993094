import { ModuleWithProviders, NgModule } from '@angular/core';

import { AddPipe } from './add.pipe';
import { CalendarPipe } from './calendar.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { DifferencePipe } from './difference.pipe';
import { DurationPipe } from './duration.pipe';
import { FromUnixPipe } from './from-unix.pipe';
import { FromUtcPipe } from './from-utc.pipe';
import { IsAfterPipe } from './is-after.pipe';
import { IsBeforePipe } from './is-before.pipe';
import { LocalTimePipe } from './local.pipe';
import { LocalePipe } from './locale.pipe';
import { ParsePipe } from './parse.pipe';
import { SubtractPipe } from './subtract.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { dayJsTimezoneFormatPipe } from './timezone-format.pipe';
import { UtcPipe } from './utc.pipe';
import { LocalRelativePipe } from './relative.pipe';
import { TimezonePipe } from './timezone.pipe';
import { FilterByIsAfter } from './filter-is-after.pipe';
require('dayjs/locale/en');
require('dayjs/locale/tr');
require('dayjs/locale/es');

const ANGULAR_DAYJS_PIPES = [
    AddPipe,
    CalendarPipe,
    DateFormatPipe,
    DifferencePipe,
    DurationPipe,
    FromUnixPipe,
    ParsePipe,
    SubtractPipe,
    TimeAgoPipe,
    UtcPipe,
    FromUtcPipe,
    LocalTimePipe,
    LocalePipe,
    IsBeforePipe,
    dayJsTimezoneFormatPipe,
    IsAfterPipe,
    LocalRelativePipe,
    TimezonePipe,
    FilterByIsAfter
];

@NgModule({
    declarations: ANGULAR_DAYJS_PIPES,
    exports: ANGULAR_DAYJS_PIPES
})
export class DayJSModule {
    static forRoot(): ModuleWithProviders<DayJSModule> {
        return {
            ngModule: DayJSModule,
            providers: []
        };
    }
}
