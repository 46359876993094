const range = require('lodash/range');
import { Component, Input, Inject, forwardRef, Optional, ViewEncapsulation } from '@angular/core';
import { connectPagination } from 'instantsearch.js/es/connectors';
import { BaseWidget, NgAisIndex, NgAisInstantSearch } from 'angular-instantsearch';
import { noop, parseNumberInput } from '../utils';


@Component({
    selector: 'ais-table-pagination',
    styleUrls: ['./ais-table-pagination.component.scss'],
    template: `
    <div [ngClass]="[cx(), state.nbPages <= 1 ? cx('', 'noRefinement') : '']">
      <ul [class]="cx('list') + ' ' + cx('list', 'outer')">

        <li
          *ngIf="showPrevious"
          (click)="refine($event, state.currentRefinement - 1)"
          [class]="
            cx('item') +
            ' ' +
            cx('item', 'previousPage') +
            (state.currentRefinement === 0 ? ' ' + cx('item', 'disabled') : '')
          "
        >
          <a
            [href]="state.createURL(state.currentRefinement - 1)"
            [class]="cx('link')"
          >
            <mat-icon class="mr-2" [svgIcon]="'heroicons_solid:arrow-narrow-left'"></mat-icon> Previous
          </a>
        </li>
        <ul [class]="cx('list')">
            <li
            [class]="
                cx('item') +
                ' ' +
                cx('item', 'page') +
                (state.currentRefinement === page ? ' ' + cx('item', 'selected') : '')
            "
            *ngFor="let page of pages"
            (click)="refine($event, page)"
            >
            <a
                [class]="cx('link')"
                [href]="state.createURL(page)"
            >
                {{page + 1}}
            </a>
            </li>
        </ul>
        <li
          *ngIf="showNext"
          (click)="refine($event, state.currentRefinement + 1)"
          [class]="
            cx('item') +
            ' ' +
            cx('item', 'nextPage') +
            (state.currentRefinement + 1 === state.nbPages ? ' ' + cx('item', 'disabled') : '')
          "
        >
          <a
            [href]="state.createURL(state.currentRefinement + 1)"
            [class]="cx('link')"
          >
            Next <mat-icon  class="ml-2" [svgIcon]="'heroicons_solid:arrow-narrow-right'"></mat-icon>
          </a>
        </li>
      </ul>
    </div>
  `,
    encapsulation: ViewEncapsulation.None
})
export class AisTablePaginationComponent extends BaseWidget {
    // rendering options
    @Input() public showFirst: boolean = true;
    @Input() public showLast: boolean = true;
    @Input() public showPrevious: boolean = true;
    @Input() public showNext: boolean = true;
    @Input() public padding: number | string = 3;

    // instance options
    @Input() public totalPages?: number | string;

    public state = {
        createURL: noop,
        currentRefinement: 0,
        nbHits: 0,
        nbPages: 0,
        refine: noop,
    };

    get pages() {
        const { nbPages, currentRefinement } = this.state;

        const pagesArray = Array.apply(null, { length: nbPages }).map(
            Number.call,
            Number
        );

        const pagesPadding =
            typeof this.padding === 'string'
                ? parseInt(this.padding, 10)
                : this.padding;

        if (pagesPadding && pagesPadding > 0) {
            // should not display pages that does not exists
            if (nbPages < pagesPadding * 2 + 1) {
                return pagesArray;
            }

            const minDelta = currentRefinement - pagesPadding - 1;
            const maxDelta = currentRefinement + pagesPadding + 1;

            if (minDelta < 0) {
                return range({
                    start: 0,
                    end: currentRefinement + pagesPadding + Math.abs(minDelta),
                });
            }

            if (maxDelta > nbPages) {
                return range({
                    start: currentRefinement - pagesPadding - (maxDelta - nbPages),
                    end: nbPages,
                });
            }

            return range({
                start: currentRefinement - pagesPadding,
                end: currentRefinement + pagesPadding + 1,
            });
        }

        return pagesArray;
    }

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch
    ) {
        super('TablePagination');
    }

    public ngOnInit() {
        this.createWidget(connectPagination, {
            maxPages: parseNumberInput(this.totalPages),
        });
        super.ngOnInit();
    }

    public refine(event: MouseEvent, page: number) {
        event.stopPropagation();
        event.preventDefault();

        if (
            page < 0 ||
            page === this.state.currentRefinement ||
            page >= this.state.nbPages
        ) {
            return;
        }

        this.state.refine(page);
    }
}
