import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { MobileNavigationComponent } from './mobile-navigation.component';
import { UserMenuModule } from '@jobzmall/common/components/user-menu/user-menu.module';
import { MatRippleModule } from '@angular/material/core';
import { MobileBottomSheetComponent } from './mobile-bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NotificationsModule } from '../notifications/notifications.module';

@NgModule({
    declarations: [MobileNavigationComponent, MobileBottomSheetComponent],
    imports: [
        RouterModule,
        OverlayModule,
        PortalModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        SharedModule,
        UserMenuModule,
        MatRippleModule,
        MatBottomSheetModule,
        NotificationsModule
    ],
    exports: [MobileNavigationComponent]
})
export class MobileNavigationModule {}
