import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    EventEmitter
} from '@angular/core';
import { SubSink } from 'subsink';
import { Media } from '../../models/media.model';
import { saveAs } from 'file-saver';
import { Select } from '@ngxs/store';
import { UserState } from '@jobzmall/user';
import { Observable } from 'rxjs';
import { User } from '@jobzmall/models';
import { AppState } from '../../state/ngxs/state';
import { first } from 'rxjs/operators';

@Component({
    template: ''
})
export class JMBaseComponent implements OnInit, OnDestroy {
    protected subscriptions = new SubSink();

    @ViewChild('scrollHandlerTrigger') scrollHandlerTrigger: ElementRef;
    @Select(AppState.timeFormat) timeFormat$: Observable<string>;
    public scrollHandler: Function;
    public onScrollTriggeredUp = new EventEmitter();
    public onScrollTriggeredDown = new EventEmitter();
    public scrollTriggerOffset = 0;

    public loading = false;
    public end = false;
    public page = 1;
    public seed = null;

    public currentLocale: string =
        navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language || 'en';
    public timeFormat: string = 'hh:mm A';

    constructor() {
        this.subscriptions.sink = this.timeFormat$
            .pipe(first())
            .subscribe((val: string) => {
                this.timeFormat = val;
            });
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        (window as any).removeEventListener('scroll', this.scrollHandler, true);
    }

    setMeta() {}

    registerScrollHandler() {
        if (this.scrollHandlerTrigger && !this.scrollHandler) {
            this.scrollHandler = () => {
                const rect =
                    this.scrollHandlerTrigger.nativeElement.getBoundingClientRect();
                if (!rect.height && !rect.width) {
                    return;
                }
                if (rect.top < this.scrollTriggerOffset) {
                    this.onScrollTriggeredDown.emit();
                } else {
                    this.onScrollTriggeredUp.emit();
                }
            };
            (window as any).addEventListener(
                'scroll',
                this.scrollHandler,
                true
            );
            this.scrollHandler();
        }
    }

    openIntercom() {
        (window as any).Intercom('showNewMessage');
    }

    openUrl(url: any) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    download(file: Media, name?: string) {
        saveAs(file.full_path, name || file.src);
    }
}
