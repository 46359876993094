var HRNumbers = require('human-readable-numbers');

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanNumber'})
export class HumanNumberPipe implements PipeTransform  {
  constructor() {}

  transform(value: number) {
    return HRNumbers.toHumanString(value);
  }
}