<div class="flex flex-col w-full">
    <ng-container [ngSwitch]="filter">
        <ng-container *ngSwitchCase="'image'">
            <lightgallery *ngIf="size == 'normal'" [settings]="settings" [onInit]="onInit"
                class="flex flex-1 p-1 space-y-2 rounded">
                <a *ngFor="let item of filteredMedia" class="m-2 gallery-item h-30 w-50 md:h-50"
                    [href]="item.full_path">
                    <img class="object-cover rounded h-30 w-50 md:h-50" [src]="item.full_path" />
                </a>
            </lightgallery>
            <lightgallery *ngIf="size == 'small'" [settings]="settings" [onInit]="onInit" class="grid grid-cols-2 gap-2"
                [class.grid-cols-2]="filteredMedia.length > 1" [class.grid-cols-1]="filteredMedia.length <= 1">
                <a *ngFor="let item of filteredMedia" class="col-span-1 m-2 gallery-item" [href]="item.full_path">
                    <img class="object-cover w-full rounded h-26" [src]="item.full_path" />
                </a>
            </lightgallery>
        </ng-container>
        <ng-container *ngSwitchCase="'video'">
            <div class="mx-auto">
                <jbz-masonry class="-mx-2" [items]="filteredMedia" [columns]="1"
                    [columnsTemplate]="videoColumnTemplate">
                </jbz-masonry>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'document'">
            <div class="mx-auto">
                <jbz-masonry class="-mx-2" [items]="filteredMedia" [columns]="1"
                    [columnsTemplate]="documentColumnTemplate">
                </jbz-masonry>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #imageColumnTemplate let-columns>
    <lightgallery [settings]="settings" [onInit]="onInit">
        <ng-container *ngFor="let column of columns">
            <!-- Column -->
            <div class="flex-1 p-1 space-y-2 rounded">
                <ng-container *ngFor="let item of column.items">
                    <a class="m-2 gallery-item w-50 h-50" [attr.href]="item.full_path">
                        <img class="object-cover rounded w-50 h-50" [src]="item.full_path" />
                    </a>
                </ng-container>
            </div>
        </ng-container>
    </lightgallery>

</ng-template>

<ng-template #videoColumnTemplate let-columns>
    <ng-container *ngFor="let column of columns">
        <!-- Column -->
        <div class="flex-1 p-1 space-y-4 rounded">
            <ng-container *ngFor="let item of column.items">
                <!-- Item -->
                <div class="relative w-auto h-auto">
                    <jm-video class="w-full overflow-hidden rounded-md min-h-80"
                        [ngClass]="{'sm:min-w-120': size !== 'small'}" [media]="item"></jm-video>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #documentColumnTemplate let-columns>
    <ng-container *ngFor="let column of columns">
        <!-- Column -->
        <div class="flex-1 p-1 space-y-4 rounded">
            <ng-container *ngFor="let item of column.items">
                <!-- Item -->
                <jbz-card (click)="openDocument(item)"
                    class="flex flex-row w-full cursor-pointer max-h-20 filter-article">
                    <div class="relative flex flex-col items-center justify-center w-20 h-20 min-w-20">
                        <div class="relative">
                            <mat-icon class="icon-size-14 text-hint" [svgIcon]="'iconsmind:file'"></mat-icon>
                            <div class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                [class.bg-red-600]="item.full_path.includes('.pdf')" [class.bg-blue-600]="item.full_path.includes('.doc') ||
                                item.full_path.includes('.docx')">
                                {{item.full_path.split('.').pop().toUpperCase()}}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-start p-4 overflow-hidden font-bold">
                        <span class="line-clamp-2">{{item.title || item.src}}</span>
                    </div>
                </jbz-card>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
