import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({ name: 'dayJsFromUnix' })
export class FromUnixPipe implements PipeTransform {
    transform(value: number | string, ...args: string[]): any {
        return typeof value === 'string'
            ? dayjs.unix(parseInt(value, 10))
            : dayjs.unix(value);
    }
}
