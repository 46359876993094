import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';
import { Media, MediaAdapter } from './media.model';

export interface IExperience {
    id?: number;
    company?: string;
    description?: string;
    location?: string;
    title?: string;
    start_date?: Date;
    end_date?: Date;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    editing?: boolean;
    delete?: boolean;
    logo?: string;
    order_column?: number;
    media?: Array<Media>;
}

export class Experience implements IExperience {
    id?: number;
    company?: string;
    description?: string;
    location?: string;
    title?: string;
    start_date?: Date;
    end_date?: Date;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    editing?: boolean;
    delete?: boolean;
    order_column?: number;
    media?: Array<Media>;
    logo?: string;

    constructor(
        id?: number,
        company?: string,
        description?: string,
        location?: string,
        title?: string,
        start_date?: Date,
        end_date?: Date,
        created_at?: Date,
        updated_at?: Date,
        deleted_at?: Date,
        order_column?: number,
        media?: Array<Media>
    ) {
        this.id = id;
        this.company = company;
        this.description = description;
        this.location = location;
        this.title = title;
        this.start_date = start_date;
        this.end_date = end_date;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
        this.order_column = order_column;
        this.media = media;
    }
}

@Injectable({ providedIn: 'root' })
export class ExperienceAdapter implements Adapter<Experience> {
    adapt(item: any): Experience {
        return new Experience(
            item.id,
            item.company,
            item.description,
            item.location,
            item.title,
            item.start_date ? dayjs(item.start_date).toDate() : null,
            item.end_date ? dayjs(item.end_date).toDate() : null,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null,
            item.order_column,
            item.media
                ? item.media.map((m: any) => new MediaAdapter().adapt(m))
                : []
        );
    }
}
