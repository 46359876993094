import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';

export var buildFormData = function (formData, data, parentKey = null) {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
};

export var updateValueAndValidity = function (
    formGroup: UntypedFormGroup | UntypedFormArray,
    name = null
) {
    Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            if (name == null) {
                control.updateValueAndValidity();
            } else {
                if (field === name) {
                    control.updateValueAndValidity();
                }
            }
        } else if (
            control instanceof UntypedFormGroup ||
            control instanceof UntypedFormArray
        ) {
            updateValueAndValidity(control);
        }
    });
};

export var validateAllFormFields = function (
    formGroup: UntypedFormGroup | UntypedFormArray,
    name = null
) {
    Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            if (name == null) {
                control.markAsTouched({ onlySelf: true });
            } else {
                if (field === name) {
                    control.markAsTouched({ onlySelf: true });
                }
            }
        } else if (
            control instanceof UntypedFormGroup ||
            control instanceof UntypedFormArray
        ) {
            validateAllFormFields(control, name);
        }
    });
};

export var getAllErrors = function (
    form: UntypedFormGroup | UntypedFormArray
): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        const errors =
            control instanceof UntypedFormGroup || control instanceof UntypedFormArray
                ? getAllErrors(control)
                : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
};
