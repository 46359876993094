<ng-container *transloco="let t">
    <div class="relative flex flex-row items-center rounded-md dark:bg-gray-800"
        [ngClass]="{'bg-transparent': transparent, 'bg-slate-200 text-slate-600  dark:bg-card dark:text-white': !transparent}">
        <mat-icon class="ml-1 icon-size-5" [svgIcon]="'mat_twotone:location_on'"></mat-icon>
        <input class="text-[1rem] md:text-base w-full p-3" [ngClass]="{'placeholder-slate-600 dark:placeholder-neutral-500': !transparent}"
            [placeholder]="t('PUBLIC.SHARED.FORM.SEARCH_LOCATION.PLACEHOLDER')" [matAutocomplete]="auto"
            (keyup)="onKeyUp()" [(ngModel)]="query" />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)">
            <mat-optgroup [label]="'Location Results'">
                <mat-option [value]="result" *ngFor="let result of results; trackBy: ( 'id' | trackByProperty )">
                    {{result.description}}
                </mat-option>
            </mat-optgroup>
            <mat-optgroup [label]="'Other Options'">
                <mat-option [value]="'current'">
                    <mat-icon>my_location</mat-icon>
                    {{t('PUBLIC.SEARCH_LOCATION.CURRENT_LOCATION')}}
                </mat-option>
                <mat-option [value]="'everywhere'">
                    <mat-icon>public</mat-icon>
                    {{t('PUBLIC.SEARCH_LOCATION.EVERYWHERE')}}
                </mat-option>
                <mat-option [value]="'remote'">
                    <mat-icon>weekend</mat-icon>
                    {{t('PUBLIC.SHARED.TERM.REMOTE')}}
                </mat-option>
            </mat-optgroup>
        </mat-autocomplete>
        <mat-icon class="mr-1 icon-size-5" [svgIcon]="'heroicons_solid:x'" (click)="clearLocation()"
            *ngIf="query && (query !== 'Everywhere')">
            close
        </mat-icon>
    </div>
</ng-container>
