import { HttpClient } from '@angular/common/http';
import { ConfigHttpLoader } from '../../config/loaders/http.loader';
import { ConfigMergeLoader } from '../../config/loaders/merge.loader';

import { environment } from 'environments/environment';
import { ConfigStaticLoader } from '@jobzmall/config';
import { JBZ_CONFIG } from '@jobzmall/config/jbz-config';

export const configLoaderFactory = (http: HttpClient) => {
    const localConfigLoader = new ConfigStaticLoader(JBZ_CONFIG);
    return new ConfigMergeLoader([localConfigLoader]).next(
        (res: any) => new ConfigHttpLoader(http, environment.apiRoot + 'config')
    );
};
