import { isEqual } from "lodash-es";

export function indexOfObject(arr: any[], obj: any) {
    for (var i = 0; i < arr.length; i++) {
        if (isEqual(arr[i], obj)) {
            return i;
        }
    }
    return -1;
}
