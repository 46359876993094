import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Media, MediaAdapter } from '@jobzmall/models';

@Component({
    selector: 'video-thumbnail',
    templateUrl: './video-thumbnail.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class VideoThumbnailComponent extends JMBaseComponent implements OnInit {
    @Input() media: Media | any;

    @Input() keepAspectRatio = true;
    @Input() alwaysPlay = false

    constructor(
        private _mediaAdapter: MediaAdapter,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        if (!(this.media instanceof Media)) {
            this.media = this._mediaAdapter.adapt(this.media);
        }
    }
}
