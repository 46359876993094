import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { VideoMeetingDialogComponent } from './video-meeting-dialog/video-meeting-dialog.component';
import { JMFormsModule } from '../../forms/forms.module';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDayjsDateModule } from '@tabuckner/material-dayjs-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DayJSModule } from '@jobzmall/datetime/pipes/dayjs.module';
import { UpcomingMeetingsCardComponent } from './upcoming-meetings-card/upcoming-meetings-card.component';
import { FuseCardModule } from '@fuse/components/card';

const COMPONENTS = [VideoMeetingDialogComponent, UpcomingMeetingsCardComponent];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        RouterModule,
        NgPipesModule,
        JMPipesModule,
        JMCommonDirectivesModule,
        JMSharedModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
        CommonModule,
        JMFormsModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        DayJSModule,
        MatDayjsDateModule,
        MatProgressSpinnerModule,
        FuseCardModule
    ],
    exports: COMPONENTS,
    providers: []
})
export class JMVideoMeetingsModule {}
