import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';

export enum SkillType {
    Soft = <any>'soft',
    Functional = <any>'functional'
}

export interface Skill {
    id?: number;
    name: string;
    type: SkillType;
    created_at?: Date;
    updated_at?: Date;
    approved?: boolean;
    source?: string;
    editing?: boolean;
    delete?: boolean;
    custom?: boolean;
    pivot?: any;
}

export class SoftSkill implements Skill {
    id?: number;
    name: string;
    type: SkillType;
    created_at?: Date;
    updated_at?: Date;
    approved?: boolean;
    source?: string;
    delete?: boolean;
    custom?: boolean;
    pivot?: any;

    constructor(
        name: string,
        type: SkillType,
        created_at?: Date,
        updated_at?: Date,
        approved?: boolean,
        source?: string,
        id?: number,
        custom?: boolean,
        pivot?: any
    ) {
        (this.id = id), (this.name = name);
        this.type = type;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.approved = approved;
        this.source = source;
        this.custom = custom;
        this.pivot = pivot;
    }
}
export class FunctionalSkill implements Skill {
    id?: number;
    name: string;
    type: SkillType;
    rating: number;
    created_at?: Date;
    updated_at?: Date;
    approved?: boolean;
    source?: string;
    editing?: boolean;
    delete?: boolean;
    custom?: boolean;
    pivot?: any;

    constructor(
        name: string,
        type: SkillType,
        rating: number,
        created_at?: Date,
        updated_at?: Date,
        approved?: boolean,
        source?: string,
        id?: number,
        custom?: boolean,
        pivot?: any
    ) {
        (this.id = id), (this.name = name);
        this.type = type;
        this.rating = rating;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.approved = approved;
        this.source = source;
        this.custom = custom;
        this.pivot = pivot;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SkillAdapter implements Adapter<Skill> {
    adapt(item: any): Skill {
        if (item.type == SkillType.Functional) {
            return new FunctionalSkill(
                item.name,
                item.type,
                item.pivot ? item.pivot.rating : item.rating,
                item.created_at ? dayjs(item.created_at).toDate() : null,
                item.updated_at ? dayjs(item.updated_at).toDate() : null,
                item.approved,
                item.source,
                item.id,
                item.custom,
                item.pivot
            );
        } else {
            return new SoftSkill(
                item.name,
                item.type as SkillType,
                item.created_at ? dayjs(item.created_at).toDate() : null,
                item.updated_at ? dayjs(item.updated_at).toDate() : null,
                item.approved,
                item.source,
                item.id,
                item.custom,
                item.pivot
            );
        }
    }
}
