<div class="relative bg-gray-200 group dark:bg-gray-700" [ngClass]="{'h-full w-full': !keepAspectRatio}">
    <div *ngIf="keepAspectRatio" class="aspect-w-16 aspect-h-9"></div>
    <ng-container *ngIf="!alwaysPlay">
        <img class="absolute inset-0 object-cover w-full h-full" [ngClass]="{'group-hover:hidden': media.animated_gif}"
            [src]="media.thumbnail ? media.thumbnail.full_path :
                                                media.generated_thumbnail" />
        <img [ngClass]="{'group-hover:block': media.animated_gif}"
            class="absolute inset-0 hidden object-cover w-full h-full" [src]="media.animated_gif" />
    </ng-container>
    <ng-container *ngIf="alwaysPlay">
        <img *ngIf="!media.animated_gif" [src]="media.generated_thumbnail"
            class="absolute inset-0 object-cover w-full h-full" [src]="media.thumbnail ? media.thumbnail.full_path :
                                                media.generated_thumbnail" />
        <img *ngIf="media.animated_gif" class="absolute inset-0 object-cover w-full h-full"
            [src]="media.animated_gif" />
    </ng-container>

</div>
