import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { take } from 'rxjs/operators';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { LanguageState } from '@jobzmall/language/ngxs/state';
import { Select, Store } from '@ngxs/store';
import { Language } from '@jobzmall/models';
import { Observable } from 'rxjs';
import { ConfigService } from '@jobzmall/config';
import { ChangeLanguage } from '@jobzmall/language';
import { JMBaseComponent } from '@jobzmall/components';

@Component({
    selector: 'language',
    templateUrl: './language.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'language'
})
export class LanguageComponent
    extends JMBaseComponent
    implements OnInit, OnDestroy
{
    @Select(LanguageState.currentLanguage)
    currentLanguage$: Observable<Language>;
    flagCodes: any;
    availableLanguages: Array<Language>;
    currentLanguage: Language;

    /**
     * Constructor
     */
    constructor(
        private _store: Store,
        private _config: ConfigService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.availableLanguages = this._config.getSettings(
            'i18n.availableLanguages'
        );

        this.subscriptions.sink = this.currentLanguage$.subscribe(
            (lang: Language) => {
                this.currentLanguage = lang;
                this._changeDetectorRef.markForCheck();
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: Language): void {
        this._store.dispatch(new ChangeLanguage(lang.id));
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    // private _updateNavigation(lang: string): void
    // {
    //     // For the demonstration purposes, we will only update the Dashboard names
    //     // from the navigation but you can do a full swap and change the entire
    //     // navigation data.
    //     //
    //     // You can import the data from a file or request it from your backend,
    //     // it's up to you.

    //     // Get the component -> navigation data -> item
    //     const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

    //     // Return if the navigation component does not exist
    //     if ( !navComponent )
    //     {
    //         return null;
    //     }

    //     // Get the flat navigation data
    //     const navigation = navComponent.navigation;

    //     // Get the Project dashboard item and update its title
    //     const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
    //     if ( projectDashboardItem )
    //     {
    //         this._translocoService.selectTranslate('Project').pipe(take(1))
    //             .subscribe((translation) => {

    //                 // Set the title
    //                 projectDashboardItem.title = translation;

    //                 // Refresh the navigation component
    //                 navComponent.refresh();
    //             });
    //     }

    //     // Get the Analytics dashboard item and update its title
    //     const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
    //     if ( analyticsDashboardItem )
    //     {
    //         this._translocoService.selectTranslate('Analytics').pipe(take(1))
    //             .subscribe((translation) => {

    //                 // Set the title
    //                 analyticsDashboardItem.title = translation;

    //                 // Refresh the navigation component
    //                 navComponent.refresh();
    //             });
    //     }
    // }
}
