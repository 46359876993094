import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseCardModule } from '@fuse/components/card';
import { FuseAlertModule } from '@fuse/components/alert';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { NgxIntlTelInputModule } from '@jobzmall/telephone-input/ngx-intl-tel-input.module';
import { JMFormsModule } from '@jobzmall/forms/forms.module';
import { TranslocoModule } from '@ngneat/transloco';
import { OneTapComponent } from './one-tap/one-tap.component';
import { MatDialogModule } from '@angular/material/dialog';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JMPhoneComponentsModule } from '@jobzmall/phone/components/phone-components.module';
import { CommonModule } from '@angular/common';
import { AuthOverlayComponent } from './auth-overlay/auth-overlay.component';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { GoogleSigninButtonDirective } from './asl-google-signin-button/asl-google-signin-button.component';

const COMPONENTS = [
    LoginFormComponent,
    AuthOverlayComponent,
    OneTapComponent,
    SignupFormComponent,
    GoogleSigninButtonDirective
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        JMSharedModule,
        MatDialogModule,
        TranslocoModule,
        JMPhoneComponentsModule,
        JMFormsModule,
        NgxIntlTelInputModule,
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        FuseCardModule,
        FuseAlertModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: COMPONENTS
})
export class JMAuthComponentsModule {}
