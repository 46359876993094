   <jbz-card>
       <div class="p-6">
           <h2 id="who-to-follow-heading" class="text-base font-medium ">
               Upcoming Meetings
           </h2>
           <div *ngIf="meetings.length || loading; else noMeetings" class="flow-root mt-6">
               <ul role="list" class="-my-4 divide-y ">
                   <li *ngFor="let meeting of meetings" class="flex py-4 space-x-3">
                       <ng-container *ngIf="meeting.source_type == 'store' && meeting.host.id !== (user$ |async).id">
                           <div class="flex-shrink-0">
                               <div
                                   class="relative flex w-8 h-8 overflow-hidden text-gray-600 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-200 flex-0">
                                   <ng-container *ngIf="meeting.source.image">
                                       <img class="object-scale-down w-full h-full rounded-full"
                                           [src]="meeting.source.image.full_path" [alt]="'Profile avatar'">
                                   </ng-container>
                                   <ng-container *ngIf="!meeting.source.image">
                                       <div
                                           class="flex items-center justify-center w-full h-full overflow-hidden text-lg font-semibold text-center text-gray-600 uppercase bg-gray-200 rounded-full group-hover:bg-gray-400 dark:group-hover:bg-gray-900 dark:bg-gray-700 dark:text-gray-200">
                                           {{meeting.source.name.charAt(0)}}
                                       </div>
                                   </ng-container>
                               </div>
                           </div>
                           <div class="flex-1 min-w-0">
                               <p class="font-medium">
                                   <a
                                       [routerLink]="['/calendar/meeting/' + meeting.code]">{{meeting.source.name}}</a>
                               </p>
                               <p class="text-secondary">
                                   <a [routerLink]="['/calendar/meeting/' + meeting.code]">{{meeting.start_utc | dayJsFromUtc | dayJsTimezone | dayJsDateFormat: 'MMMM Do, YYYY'}}
                                       @
                                       {{meeting.start_utc | dayJsFromUtc | dayJsTimezone | dayJsDateFormat: timeFormat}}</a>
                               </p>
                           </div>
                       </ng-container>
                       <ng-container *ngIf="meeting.source_type == 'user' || meeting.host.id == (user$ |async).id">
                           <div class="flex-shrink-0">
                               <div
                                   class="relative flex w-8 h-8 overflow-hidden text-gray-600 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-200 flex-0">
                                   <ng-container *ngIf="meeting.others[0].image">
                                       <img class="object-scale-down w-full h-full rounded-full"
                                           [src]="meeting.others[0].image.full_path" [alt]="'Profile avatar'">
                                   </ng-container>
                                   <ng-container *ngIf="!meeting.others[0].image">
                                       <div
                                           class="flex items-center justify-center w-full h-full overflow-hidden text-lg font-semibold text-center text-gray-600 uppercase bg-gray-200 rounded-full group-hover:bg-gray-400 dark:group-hover:bg-gray-900 dark:bg-gray-700 dark:text-gray-200">
                                           {{meeting.others[0].name.charAt(0)}}
                                       </div>
                                   </ng-container>
                               </div>
                           </div>
                           <div class="flex-1 min-w-0">
                               <p class="font-medium">
                                   <a
                                       [routerLink]="['/calendar/meeting/' + meeting.code]">{{meeting.others[0].name}}</a>
                               </p>
                               <p class="text-secondary">
                                   <a [routerLink]="['/calendar/meeting/' + meeting.code]">{{meeting.start_utc | dayJsFromUtc | dayJsTimezone | dayJsDateFormat: 'MMMM Do, YYYY'}}
                                       @
                                       {{meeting.start_utc | dayJsFromUtc | dayJsTimezone | dayJsDateFormat: timeFormat}}</a>
                               </p>
                           </div>
                       </ng-container>

                       <div class="flex-shrink-0">
                           <a [href]="meeting.location | safe:'url'" *ngIf="(meeting.location.includes('http://') || meeting.location.includes('https://')) &&
                               (meeting.start_utc | dayJsFromUtc | dayJsTimezone | dayJsDifference : now : 'minutes') <=
                                   15" type="button"
                               class="inline-flex items-center px-3 py-0.5 rounded-full bg-rose-50 text-sm font-medium text-rose-700 hover:bg-rose-100">
                               <span>
                                   Join Now
                               </span>
                           </a>
                       </div>
                   </li>
               </ul>
           </div>
           <div class="mt-6">
               <a [routerLink]="[link]"
                   class="block w-full px-4 py-2 text-sm font-medium text-center border border-gray-300 rounded-md shadow-sm text-secondary bg-card hover:bg-gray-50">
                   View Calendar
               </a>
           </div>
       </div>
   </jbz-card>
   <ng-template #noMeetings>
       <div class="flex flex-col items-center justify-center flex-auto px-8 py-6 pb-0 sm:justify-start">
           <div class="flex items-center justify-center rounded-full flex-0 w-14 h-14 bg-primary-100">
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black"
                   class="w-6 h-6">
                   <path stroke-linecap="round" stroke-linejoin="round"
                       d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
               </svg>
           </div>
           <div class="mt-5 text-2xl font-semibold">No meetings</div>
           <div class="w-full mt-1 text-center max-w-60 text-md text-secondary">When you have meetings,
               they
               will appear here.</div>
       </div>
   </ng-template>
