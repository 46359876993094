import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Observable, Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { Notification } from '@jobzmall/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { NotificationState } from '@jobzmall/notifications/ngxs/state';
import { Select, Store } from '@ngxs/store';
import {
    GetNotifications,
    GetUnreadCount,
    SetRead
} from '@jobzmall/notifications/ngxs/actions';
import { cloneDeep, extend } from 'lodash-es';
import * as Noty from 'noty';
import { StateReset } from 'ngxs-reset-plugin';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent
    extends JMBaseComponent
    implements OnChanges, OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;

    @Select(NotificationState.notifications) notifications$: Observable<
        Array<Notification>
    >;
    @Select(NotificationState.updates) unreadCount$: Observable<number>;
    @Select(NotificationState.loading) loading$: Observable<boolean>;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    /**
     * Constructor
     */
    constructor(
        public router: Router,
        private _store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.subscriptions.sink = this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.closePanel();
            }
        });
        // Notifications
        // if ('notifications' in changes) {
        //     // Store the notifications on the service
        //     this._notificationsService.store(changes.notifications.currentValue);
        // }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.getNotifications();
        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.markAllAsRead();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        super.ngOnDestroy();
        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    getNotifications(refresh = false) {
        this._store
            .dispatch(new GetNotifications(refresh))
            .pipe(first())
            .subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        if (this.router.url.indexOf('/notifications') > -1) {
            return;
        }

        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );

        this._overlayRef
            .detachments()
            .pipe(first())
            .subscribe(() => {
                this.markAllAsRead();
            });

        this.getNotifications(true);
    }

    /**
     * Close the messages panel
     */
    closePanel(): void {
        this.markAllAsRead();
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._store
            .dispatch(new SetRead())
            .pipe(first())
            .subscribe(() => {
                this._changeDetectorRef.detectChanges();
            });
    }

    /**
     * Toggle read status of the given notification
     */
    setRead(notification: Notification): void {
        this._store
            .dispatch(new SetRead(notification))
            .pipe(first())
            .subscribe(() => {
                this._changeDetectorRef.detectChanges();
            });
    }

    testLiveNotification(notification: Notification) {
        new (Noty as any)(
            extend(cloneDeep(notification), {
                timeout: 3000,
                closeWith: ['click']
            })
        ).show();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'jbz-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._notificationsOrigin._elementRef.nativeElement
                )
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
